import React from "react";

const StatusDopdown = ({
  caseStatus,
  candidateId,
  list,
  updateProfile,
  errorClass,
  statusBgColour,
  statusTxColour,
}) => {
  return (
    <>
      <div className="dk-app-caseDrpDwn w-100">
        <div className={errorClass}>
          <div
            variant=""
            className="caseStstoggle dk-app-status-toggle"
            style={{
              backgroundColor: statusBgColour,
              borderColor: statusBgColour,
              color: statusTxColour,
            }}
          >
            {caseStatus}
          </div>
        </div>
      </div>
    </>
  );
};

export default StatusDopdown;
