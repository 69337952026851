import React from "react";
import JoditEditor from "../../../../../ui/JoditEditor";

const Aboutus = ({ companyAboutus }) => {
  return (
    <>
      <div className="dkg-jopspec-pageheader">
        <div className="dkg-jopspec-page-title">Company</div>
      </div>
      <div className="dkg-jobspec-read-editor-Con">
        <div className="dkg-jobspec-read-editor">
          <div
            className="dkg-jobspec-htmleditor"
            dangerouslySetInnerHTML={{
              __html:
                companyAboutus.length > 0 && companyAboutus[0].aboutUs != null
                  ? companyAboutus[0].aboutUs
                  : "",
            }}
          ></div>
          {/* <JoditEditor
            datavalue={
              companyAboutus.length > 0 && companyAboutus[0].aboutUs != null
                ? companyAboutus[0].aboutUs
                : ""
            }
            toolbaarControl={false}
            readonlyControl={true}
            spellcheckControll={false}
            showCharsCounterControll={false}
            showWordsCounterControll={false}
            widthControll={800}
            heightControll={455}
          /> */}
        </div>
      </div>
    </>
  );
};

export default Aboutus;
