import React from "react";

const LocationTab = () => {
  return (
    <>
      <div className="dkg-jopspec-pageheader">
        <div className="dkg-jopspec-page-title">Location</div>
      </div>
      <div className="dkg-jobspec-read-editor-Con">
        <div className="dkg-jobspec-read-editor">
          <div
            className="dkg-jobspec-htmleditor"
            dangerouslySetInnerHTML={{
              __html: " " ? "" : "",
            }}
          ></div>
        </div>
      </div>
    </>
  );
};

export default LocationTab;
