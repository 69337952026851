import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./jobsrightmodal.scss";
import { Modal, Tab, Nav , Dropdown } from "react-bootstrap";
import Summary from "./summary/Summary";
import SalaryTab from "./salarynew/Salary";
import JobbenfitsTab from "./salarynew/Jobbenfits";

import JobSummaryDetails from "./jobsummarydetails/JobSummary";
import CandidateView from "./candidates/Candidate";

import { jobsDetails } from "../../../../../slice/jobs/jobsSlice";

import {
  //getProjectSubCategories,
  //getCategories,
  getproject,
  getroletype,
  getjoinning,
  getrelocation,
  getjoboffer,
  getinterview,
  getwhyapply,
  //getdefaultSubCategories
} from "../../../../../slice/clientProject/clientprojectSlice";
// import {
//   getRoleSubCategories
// } from "../../../../../slice/clientRoleType";

import { getCMS } from "../../../../../slice/cmsSlice";

import {
  projectcomp,
  allJobsSpecCompnay,
  defaultJobsCompnay,
  defaultJobsProject,
  allclientsproject,
  defaultJobsRole,
  allFaqs,
} from "../../../../../slice/jobSpec/jobSpecSlice";

import { fetechById } from "../../../../../slice/companies/clients/clientSlice";
import {
  getCategory,
  getOnClickQuesAns,
} from "../../../../../slice/faqs/newFaqSlice";

import ClientCompany from "./companies/DkgClientCompaines";
import DkgClientProject from "./companies/DkgClientProject";

import ClientInterview from "../../../companies/listview/modal/liveJobTab/interviews/ClientInterviews";
import InterviewsTab from "../../../companies/listview/modal/liveJobTab/company/Interviews";
import JoboffersOfferTab from "../../../companies/listview/modal/liveJobTab/company/Joboffers";
import RelocationTabb from "../../../companies/listview/modal/liveJobTab/company/RelocationTab";
import JoinninggTab from "../../../companies/listview/modal/liveJobTab/company/JoinningTab";
import WhyapplyTabb from "../../../companies/listview/modal/liveJobTab/company/WhyapplyTab";
import FaqOveriew from "../../../companies/listview/modal/faq/Overview";
import DkgRoletype from "../../../companies/listview/modal/liveJobTab/company/Roletype";
import JoditEditor from "../../../../ui/JoditEditor";

const DetialViewPoup = ({
  show,
  onHide,
  jobId,
  popupfor,
  clientidds,
  canjobs,
  companyName,
}) => {
  const dispatch = useDispatch();
  const { isDetailsLoading, jobDetails } = useSelector((state) => state.jobs);
  const { isSuccess, cmsList } = useSelector((state) => state.cmsdata);
  //const { companyDetails } = useSelector((state) => state.companies);
  const {
    categoryList,
    //questionAnsList,
    iscat,
    //isQuesAns
  } = useSelector((state) => state.faqData);

  const [Jobdata, setJobdata] = useState("");
  const [JobInstuctiondata, setJobInstuctiondata] = useState("");
  const [faqcat, setFaqcat] = useState([]);

  useEffect(() => {
    if (show) {
      const fetchRecods = async () => {
        await dispatch(jobsDetails({ id: jobId }));
        await dispatch(getCMS({}));
        await dispatch(allJobsSpecCompnay({}));
        await dispatch(allclientsproject({}));
        await dispatch(allFaqs({}));
      };
      fetchRecods();
    }
  }, [show]);

  useEffect(() => {
    if (jobDetails && !isDetailsLoading) {
      setJobdata(jobDetails);
    }
    if (!isSuccess) {
      setJobInstuctiondata(cmsList);
    }
    if (!iscat) {
      setFaqcat(categoryList);
    }
  }, [isDetailsLoading, isSuccess, iscat]);

  useEffect(() => {
    dispatch(projectcomp({ jobId: canjobs }));
    dispatch(fetechById({ id: clientidds }));
  }, [canjobs]);

  const [eventKey, SetEventKey] = useState("jobSummaryOverview");

  const [changeTabKey, SetChangeTabKey] = useState("full-job-details");

  useEffect(() => {
    if (changeTabKey) {
      switch (changeTabKey) {
        case "full-job-details":
          dispatch(allJobsSpecCompnay({}));
          dispatch(getproject({ jobId: Jobdata.job_id, clientId: clientidds }));
          dispatch(
            getroletype({ jobId: Jobdata.job_id, clientId: clientidds })
          );
          // dispatch(getCategory({ id: Jobdata.job_id }))
          break;

        default:
          console.log("default");
          break;
      }
    }
  }, [changeTabKey]);

 

  useEffect(() => {
    if (eventKey) {
      dispatch(projectcomp({ jobId: canjobs }));
      setFaqcat([]);
      switch (eventKey) {
        case "jobSummaryOverview":
          dispatch(getCMS({}));
          break;
        case "company":
          dispatch(allJobsSpecCompnay({}));
          //dispatch(getSubCategories({ categoryId: 1, keys: "Overview" }));
          break;
        case "jobs-project":
          console.log(Jobdata.job_id)
          console.log(clientidds)
          dispatch(getproject({ jobId: Jobdata.job_id, clientId: clientidds }));
          break;
        case "job-new-role":
          dispatch(
            getroletype({ jobId: Jobdata.job_id, clientId: clientidds })
          );
          break;
        case "dkg-interves":
          dispatch(getinterview({ jobId: Jobdata.job_id }));
          break;
        case "job-offerss":
          dispatch(getjoboffer({ jobId: Jobdata.job_id }));
          break;
        case "dkg-new-relocation":
          dispatch(getrelocation({ jobId: Jobdata.job_id }));
          break;
        case "dkg-joinning":
          dispatch(getjoinning({ jobId: Jobdata.job_id }));
          break;
        case "job-whyapply":
          dispatch(getwhyapply({ jobId: Jobdata.job_id }));
          break;
        case "live-jobs-faq":
          dispatch(getCategory({ id: Jobdata.job_id }));
          break;
      }
    }
  }, [eventKey]);
  // const [selectedItem, setSelectedItem] = useState("Instructions");
  // const handleDropdownChange = (eventKey) => {
  //   setSelectedItem(eventKey);
  // };
  const [selectedItem, setSelectedItem] = useState("Instructions");
  const items = ['Instructions' , 'Overview' ,'Salary' , 'Company' , 'Project' ,'Role Type' ,'Interviews', 'Job Offer' ,'Relocation' ,'Joining' , 'Why Apply', 'FAQ'];

  const handleSelect = (item) => {
    setSelectedItem(item);
  };
  const renderComponent = () => {
    switch (selectedItem) {
      case 'Instructions':
        return <>
          <div className="dk-ClentViewTabPane summaryTabPane dkg-new-jobdet-tbpane-234">
            <div className="instructionTabs">
              {JobInstuctiondata != "" &&
              JobInstuctiondata != null ? (
              <div
                dangerouslySetInnerHTML={{
                  __html:  JobInstuctiondata[0].description,
                }}
              ></div>
                // <JoditEditor
                //   datavalue={
                //     JobInstuctiondata[0]
                //       .description
                //   }
                //   toolbaarControl={false}
                //   readonlyControl={true}
                //   spellcheckControll={false}
                //   showCharsCounterControll={
                //     false
                //   }
                //   showWordsCounterControll={
                //     false
                //   }
                // />
              ) : (
                ""
              )}
            </div>
          </div>
        </>; 
      case 'Overview':
        return  <>
         <div className="dk-ClentViewTabPane  summaryTabPane dkg-new-jobdet-tbpane-234 dkg-jobspec-mo-tabpane dkg-cand-overview-tabpane-con">
            <Summary jobId={jobId} />
          </div>
        </>; 

      case 'Salary':
        return <>
            <div className="dk-ClentViewTabPane summaryTabPane dkg-new-jobdet-tbpane-234">
              <SalaryTab jobId={jobId} />
            </div>
        </>; 

      case 'Company':
        return <>
          <div className="dk-ClentViewTabPane  dkg-jobs-sendout-tabpane-78 dkg-jobspecpopup-contentMain dkg-cand-comapny-tabpane">
            <ClientCompany
              client_idd={clientidds}
              jobId={Jobdata.job_id}
            />
          </div>
        </>;
        
      case 'Project':
        return <>
          <div className="dk-ClentViewTabPane dkg-jobs-sendout-tabpane-78 dkg-jobspecpopup-contentMain">
            <DkgClientProject
              client_idd={clientidds}
              jobId={Jobdata.job_id}
            />
          </div> 
        </>;    
      case 'Role Type':
        return <>
              <div className="dk-ClentViewTabPane dkg-jobs-sendout-tabpane-78 dkg-jobspecpopup-contentMain">
                {/* <DkgClientProject client_idd={clientidds} jobId={Jobdata.job_id} /> */}
                <DkgRoletype
                  client_idd={clientidds}
                  jobId={Jobdata.job_id}
                />
              </div>
        </>;    
      case 'Interviews':
        return <>
           <div className="dk-ClentViewTabPane dkg-jobs-sendout-tabpane-78 dkg-jobspecpopup-contentMain">
                <InterviewsTab
                  client_idd={clientidds}
                  jobId={Jobdata.job_id}
                />
              </div>
        </>;    
      case 'Job Offer':
        return <>
            <div className="dk-ClentViewTabPane dkg-jobs-sendout-tabpane-78 dkg-jobspecpopup-contentMain">
                {/* <DkgClientProject client_idd={clientidds} jobId={Jobdata.job_id} /> */}
                <JoboffersOfferTab />
              </div>
        </>;    
      case 'Relocation':
        return <>
              <div className="dk-ClentViewTabPane dkg-jobs-sendout-tabpane-78 dkg-jobspecpopup-contentMain">
                  <RelocationTabb />
                </div>
        </>;    
      case 'Joining':
        return <>
             <div className="dk-ClentViewTabPane dkg-jobs-sendout-tabpane-78 dkg-jobspecpopup-contentMain">
                                            {/* <DkgClientProject client_idd={clientidds} jobId={Jobdata.job_id} /> */}
                <JoinninggTab />
              </div>
        </>;    
      case 'Why Apply':
        return <>
             <div className="dk-ClentViewTabPane dkg-jobs-sendout-tabpane-78 dkg-jobspecpopup-contentMain">
                  <WhyapplyTabb />
                </div>
        </>;    
      case 'FAQ':
        return <>
          <div className="dk-ClentViewTabPane dkg-jobs-sendout-tabpane-78 dkg-jobspecpopup-contentMain">
           <FaqOveriew jobId={Jobdata.job_id} />
          </div>
        </>;    
      default:
        return null;
    }
  };

  useEffect(() => {
    if (selectedItem) {
      dispatch(projectcomp({ jobId: canjobs }));
      setFaqcat([]);
      switch (selectedItem) {
        case "Instructions":
          dispatch(getCMS({}));
          break;
        case "Company":
          dispatch(allJobsSpecCompnay({}));
          //dispatch(getSubCategories({ categoryId: 1, keys: "Overview" }));
          break;
        case "Project":
          dispatch(getproject({ jobId: Jobdata.job_id, clientId: clientidds }));
          break;
        case "Role Type":
          dispatch(
            getroletype({ jobId: Jobdata.job_id, clientId: clientidds })
          );
          break;
        case "Interviews":
          dispatch(getinterview({ jobId: Jobdata.job_id }));
          break;
        case "Job Offer":
          dispatch(getjoboffer({ jobId: Jobdata.job_id }));
          break;
        case "Relocation":
          dispatch(getrelocation({ jobId: Jobdata.job_id }));
          break;
        case "Joining":
          dispatch(getjoinning({ jobId: Jobdata.job_id }));
          break;
        case "Why Apply":
          dispatch(getwhyapply({ jobId: Jobdata.job_id }));
          break;
        case "FAQ":
          dispatch(getCategory({ id: Jobdata.job_id }));
          break;
      }
    }
  }, [selectedItem]);
  return (
    <React.Fragment>
      <div className="dk-cliDetViewPopup">
        <Modal
          show={show}
          dialogClassName="dk-livejobrightModalDailog"
          aria-labelledby="example-custom-modal-styling-title"
          onHide={onHide}
        >
          <Modal.Header closeButton className="dk-detViewheader">
            <Modal.Title
              className="dk-detViewTitle"
              id="example-custom-modal-styling-title"
            >
              <div className="modalTitleCon">JOB ID - {Jobdata.job_id} </div>
              <div
                className="modalTitleCon"
                // data-id={Jobdata.id}
                // data-column="updated_on"
                // onClick={onDateClick}
              >
                {/* Full Job Details */}
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="dkg-full-jobdetail-modalmody dkg-edit-full-jobdetail-modalmody">
            <div className="dkg-full-jobdetail-maincon dkg-full-edit-jobdetail-maincon">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="full-job-details"
              >
                <div className="dkg-full-jobdetail-mainRow dkg-full-jobdetail-mainRow row">
                  <div className="dkg-full-jobdetail-mainCol dkg-full-edit-jobdetail-mainCol col-9 m-auto">
                    <Nav
                      variant="pills"
                      onSelect={(selectedKey) => SetChangeTabKey(selectedKey)}
                      className="flex-row dkg-full-jobdetail-navpills dkg-full-edit-jobdetail-navpills"
                    >
                      <Nav.Item className="dkg-full-jobdetail-navitem">
                        <Nav.Link
                          eventKey="full-job-details"
                          className="dkg-full-jobdetail-navlink"
                        >
                          {Jobdata.name}
                        </Nav.Link>
                      </Nav.Item>
                      {/* <Nav.Item className="dkg-full-jobdetail-navitem">
                        <Nav.Link eventKey="edit-jobdetails" className="dkg-full-jobdetail-navlink">Edit</Nav.Link>
                      </Nav.Item> */}
                    </Nav>
                  </div>
                  <div className="dkg-full-jobdetail-mainCol-12 dkg-full-edit-jobdetail-mainCol-12 col-12">
                    <Tab.Content className="dkg-full-jobdetail-tabcontent dkg-full-edit-jobdetail-tabcontent">
                      <Tab.Pane
                        eventKey="full-job-details"
                        className="dkg-full-jobdetail-tabpane dkg-full-edit-jobdetail-tabpane"
                      >
                        <div className="dk-LivejobPopCon dk-edit-LivejobPopCon">
                          <div className="dk-clientDetViewTopSec dk-edit-clientDetViewTopSec">
                            <div className="row mr-0 ml-0">
                              <div className="col-md-12 col-sm-12 dk-edit-DetViewBottomSecCon dk-DetViewBottomSecCon pl-0 pr-0">
                              <div className="dkg-cand-jobspec-moCon">
                                <div className="dkg-jobspec-header-centerCon">
                                  <h2 className="dkg-cand-categoty-title w-100 text-center" style={{fontSize: "1.25rem" , fontWeight: "600"}}>Select Category</h2>
                                  <div className="dkg-jobspec-DropdownCon">
                                    <Dropdown className='dkg-jobspec-Dropdown'>
                                      <Dropdown.Toggle variant="" className='dkg-jobspec-Dropdown-toggle'>
                                        {selectedItem}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className='dkg-jobspec-Dropdown-Menu w-100'  aria-labelledby="dropdownMenuButton">
                                          {items.map((item, index) => (
                                          
                                            <Dropdown.Item href="#"
                                               key={index}
                                              className={item === selectedItem ? 'selected-item dropdown-item' : 'dropdown-item'}
                                              onClick={() => handleSelect(item)}>
                                                {item}
                                            </Dropdown.Item>
                                          ))}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                {renderComponent()}
                              </div>
                                <Tab.Container
                                  id="left-tabs-example"
                                  defaultActiveKey="jobSummaryOverview"
                                >
                                  <div className="row mr-0 ml-0">
                                    <div
                                      className="col-md-12 col-sm-12 dk-ClieDetViewSideMenu pl-0 pr-0"
                                      id="dk-cliDetailsV"
                                    >
                                      <Nav
                                        variant="pills"
                                        className="flex-row dkg-detailview-navpills dkg-edit-detailview-navpills"
                                        onSelect={(selectedKey) =>
                                          SetEventKey(selectedKey)
                                        }
                                      >
                                        <Nav.Item className="dkg-detailview-navitem dkg-edit-detailview-navitem">
                                          <Nav.Link eventKey="jobSummaryOverview">
                                            Instructions
                                          </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="dkg-detailview-navitem dkg-edit-detailview-navitem">
                                          <Nav.Link eventKey="details">
                                            Overview
                                          </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="dkg-detailview-navitem dkg-edit-detailview-navitem">
                                          <Nav.Link eventKey="dkg-livejob-salary">
                                            Salary
                                          </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="dkg-detailview-navitem dkg-edit-detailview-navitem">
                                          <Nav.Link eventKey="company">
                                            Company
                                          </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="dkg-detailview-navitem dkg-edit-detailview-navitem">
                                          <Nav.Link eventKey="jobs-project">
                                            Project
                                          </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="dkg-detailview-navitem dkg-edit-detailview-navitem">
                                          <Nav.Link eventKey="job-new-role">
                                            Role Type
                                          </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="dkg-detailview-navitem dkg-edit-detailview-navitem">
                                          <Nav.Link eventKey="dkg-interves">
                                            Interviews
                                          </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="dkg-detailview-navitem dkg-edit-detailview-navitem">
                                          <Nav.Link eventKey="job-offerss">
                                            Job Offer
                                          </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="dkg-detailview-navitem dkg-edit-detailview-navitem">
                                          <Nav.Link eventKey="dkg-new-relocation">
                                            Relocation
                                          </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="dkg-detailview-navitem dkg-edit-detailview-navitem">
                                          <Nav.Link eventKey="dkg-joinning">
                                            Joining
                                          </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="dkg-detailview-navitem dkg-edit-detailview-navitem">
                                          <Nav.Link eventKey="job-whyapply">
                                            Why Apply
                                          </Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item className="dkg-detailview-navitem dkg-edit-detailview-navitem">
                                          <Nav.Link eventKey="live-jobs-faq">
                                            FAQ
                                          </Nav.Link>
                                        </Nav.Item>
                                      </Nav>
                                    </div>
                                    <div className="col-md-12 col-sm-12 dk-ClieDetViewMainCont ttt dkg-jobspecpopup-col-12">
                                      <Tab.Content className="dkg-jobspecpopup-tabContent">
                                        <Tab.Pane
                                          eventKey="jobSummaryDetails"
                                          className="dkg-jobspecpopup-tabpane"
                                        >
                                          <div className="dk-ClentViewTabPane jobSummaryDetails dkg-livejobs-suMMTabpane pl-0 pr-0">
                                            <JobSummaryDetails jobId={jobId} />
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane
                                          eventKey="jobSummaryOverview"
                                          className="dkg-jobspecpopup-tabpane"
                                        >
                                          <div className="dk-ClentViewTabPane summaryTabPane dkg-new-jobdet-tbpane-234">
                                            <div className="instructionTabs">
                                              {JobInstuctiondata != "" &&
                                              JobInstuctiondata != null ? (
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html:  JobInstuctiondata[0].description,
                                                }}
                                              ></div>
                                                // <JoditEditor
                                                //   datavalue={
                                                //     JobInstuctiondata[0]
                                                //       .description
                                                //   }
                                                //   toolbaarControl={false}
                                                //   readonlyControl={true}
                                                //   spellcheckControll={false}
                                                //   showCharsCounterControll={
                                                //     false
                                                //   }
                                                //   showWordsCounterControll={
                                                //     false
                                                //   }
                                                // />
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane
                                          eventKey="details"
                                          className="dkg-jobspecpopup-tabpane"
                                        >
                                          <div className="dk-ClentViewTabPane summaryTabPane dkg-new-jobdet-tbpane-234">
                                            <Summary jobId={jobId} />
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane
                                          eventKey="dkg-livejob-salary"
                                          className="dkg-jobspecpopup-tabpane"
                                        >
                                          <div className="dk-ClentViewTabPane summaryTabPane dkg-new-jobdet-tbpane-234">
                                            <SalaryTab jobId={jobId} />
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane
                                          eventKey="job-offerss"
                                          className="dkg-jobspecpopup-tabpane"
                                        >
                                          <div className="dk-ClentViewTabPane dkg-jobs-sendout-tabpane-78 dkg-jobspecpopup-contentMain">
                                            {/* <DkgClientProject client_idd={clientidds} jobId={Jobdata.job_id} /> */}
                                            <JoboffersOfferTab jobId={jobId} />
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane
                                          eventKey="company"
                                          className="dkg-jobspecpopup-tabpane "
                                        >
                                          <div className="dk-ClentViewTabPane  dkg-jobs-sendout-tabpane-78 dkg-jobspecpopup-contentMain">
                                            <ClientCompany
                                              client_idd={clientidds}
                                              jobId={Jobdata.job_id}
                                            />
                                          </div>
                                        </Tab.Pane>
                                        {/* <Tab.Pane eventKey="salary">
                                            <div className="dk-ClentViewTabPane summaryTabPane">
                                                <Salary jobId={jobId} />
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="benefits">
                                            <div className="dk-ClentViewTabPane summaryTabPane">
                                                <Benefits jobId={jobId} />
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="relocation">
                                            <div className="dk-ClentViewTabPane summaryTabPane">
                                                <Relocation jobId={jobId} />
                                            </div>
                                        </Tab.Pane> */}

                                        <Tab.Pane
                                          eventKey="jobs-project"
                                          className="dkg-jobspecpopup-tabpane"
                                        >
                                          <div className="dk-ClentViewTabPane dkg-jobs-sendout-tabpane-78 dkg-jobspecpopup-contentMain">
                                            <DkgClientProject
                                              client_idd={clientidds}
                                              jobId={Jobdata.job_id}
                                            />
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane
                                          eventKey="job-new-role"
                                          className="dkg-jobspecpopup-tabpane"
                                        >
                                          <div className="dk-ClentViewTabPane dkg-jobs-sendout-tabpane-78 dkg-jobspecpopup-contentMain">
                                            {/* <DkgClientProject client_idd={clientidds} jobId={Jobdata.job_id} /> */}
                                            <DkgRoletype
                                              client_idd={clientidds}
                                              jobId={Jobdata.job_id}
                                            />
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane
                                          eventKey="job-whyapply"
                                          className="dkg-jobspecpopup-tabpane"
                                        >
                                          <div className="dk-ClentViewTabPane dkg-jobs-sendout-tabpane-78 dkg-jobspecpopup-contentMain">
                                            <WhyapplyTabb />
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane
                                          eventKey="job-new-benfits"
                                          className="dkg-jobspecpopup-tabpane"
                                        >
                                          <div className="dk-ClentViewTabPane summaryTabPane dkg-new-jobdet-tbpane-234">
                                            <JobbenfitsTab jobId={jobId} />
                                          </div>
                                        </Tab.Pane>
                                        {/* <Tab.Pane eventKey="job-new-benfits">
                                          <div className="dk-ClentViewTabPane dkg-jobs-sendout-tabpane-78">
                                            <ClientSendouts />
                                          </div>
                                        </Tab.Pane> */}
                                        <Tab.Pane
                                          eventKey="interviews"
                                          className="dkg-jobspecpopup-tabpane"
                                        >
                                          <div className="dk-ClentViewTabPane dkg-jobs-sendout-tabpane-78 dkg-jobspecpopup-contentMain">
                                            <ClientInterview
                                              client_idd={clientidds}
                                              jobId={Jobdata.job_id}
                                            />
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane
                                          eventKey="candidate"
                                          className="dkg-jobspecpopup-tabpane"
                                        >
                                          <div className="dk-ClentViewTabPane dkg-likedislike-tabpane">
                                            <CandidateView jobId={jobId} />
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane
                                          eventKey="dkg-interves"
                                          className="dkg-jobspecpopup-tabpane"
                                        >
                                          <div className="dk-ClentViewTabPane dkg-jobs-sendout-tabpane-78 dkg-jobspecpopup-contentMain">
                                            <InterviewsTab
                                              client_idd={clientidds}
                                              jobId={Jobdata.job_id}
                                            />
                                          </div>
                                        </Tab.Pane>

                                        <Tab.Pane
                                          eventKey="dkg-new-relocation"
                                          className="dkg-jobspecpopup-tabpane"
                                        >
                                          <div className="dk-ClentViewTabPane dkg-jobs-sendout-tabpane-78 dkg-jobspecpopup-contentMain">
                                            <RelocationTabb  jobId={jobId}/>
                                          </div>
                                        </Tab.Pane>

                                        <Tab.Pane
                                          eventKey="dkg-joinning"
                                          className="dkg-jobspecpopup-tabpane"
                                        >
                                          <div className="dk-ClentViewTabPane dkg-jobs-sendout-tabpane-78 dkg-jobspecpopup-contentMain">
                                            {/* <DkgClientProject client_idd={clientidds} jobId={Jobdata.job_id} /> */}
                                            <JoinninggTab />
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane
                                          eventKey="live-jobs-faq"
                                          className="dkg-livejobs-faq-tabpane"
                                        >
                                          <FaqOveriew jobId={Jobdata.job_id} />
                                        </Tab.Pane>

                                        {/* <Tab.Pane eventKey="workflow">
                                              <div className="dk-ClentViewTabPane">
                                                  <Resources jobId={jobId} />
                                              </div>
                                          </Tab.Pane>
                                          <Tab.Pane eventKey="ad-sample">
                                              <div className="dk-ClentViewTabPane">
                                                  <Adsample jobId={jobId} />
                                              </div>
                                          </Tab.Pane> */}
                                      </Tab.Content>
                                    </div>
                                  </div>
                                </Tab.Container>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </div>
              </Tab.Container>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default DetialViewPoup;
