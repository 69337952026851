import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import "./viewpopup.scss";
import { updatewithdrawn } from "../../../../../slice/candidates/list/listSlice";
import { showSuccess } from "../../../../../slice/utils/message/messageSlice";
import SubmitButton from "../../../../ui/submitButton";
import { FaRegSadTear } from "react-icons/fa";
const WithdrawApplication = ({ onHide, itemdata }) => {
  const dispatch = useDispatch();

  const [checkbox_1, setCheckbox_1] = useState(itemdata.withdraw_checkbox_1);
  const [checkbox_2, setCheckbox_2] = useState(itemdata.withdraw_checkbox_2);
  const [checkbox_3, setCheckbox_3] = useState(itemdata.withdraw_checkbox_3);
  const [checkbox_4, setCheckbox_4] = useState(itemdata.withdraw_checkbox_4);
  const [checkbox_5, setCheckbox_5] = useState(itemdata.withdraw_checkbox_5);
  const [checkbox_6, setCheckbox_6] = useState(itemdata.withdraw_checkbox_6);
  const [checkbox_7, setCheckbox_7] = useState(itemdata.withdraw_checkbox_7);
  const [messageBox, setMessageBox] = useState(itemdata.withdraw_msg);
  const [rowid, setRowid] = useState(itemdata.id);

  const { isWithdrwan } = useSelector((state) => state.candidates);

  const [blankForm, setBlankForm] = useState(
    itemdata.withdraw_checkbox_1 == true ||
      itemdata.withdraw_checkbox_2 == true ||
      itemdata.withdraw_checkbox_3 == true ||
      itemdata.withdraw_checkbox_4 == true ||
      itemdata.withdraw_checkbox_5 == true ||
      itemdata.withdraw_checkbox_6 == true ||
      itemdata.withdraw_checkbox_7 == true
      ? //itemdata.withdraw_msg != ""
        true
      : false
  );

  // console.log(itemdata);
  // console.log(blankForm);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleButtonClick = async () => {
    //if (checkbox_1 == "" || checkbox_2 || checkbox_3 ||)
    dispatch(showSuccess({ msg: "Form Submited Successfully." }));
    setSuccess(true);
    setLoading(true);
    setBlankForm(true);
    dispatch(
      updatewithdrawn({
        id: rowid,
        withdraw_checkbox_1: checkbox_1,
        withdraw_checkbox_2: checkbox_2,
        withdraw_checkbox_3: checkbox_3,
        withdraw_checkbox_4: checkbox_4,
        withdraw_checkbox_5: checkbox_5,
        withdraw_checkbox_6: checkbox_6,
        withdraw_checkbox_7: checkbox_7,
        withdraw_msg: messageBox,
        canId: itemdata.canId,
      })
    );
  };

  useEffect(() => {
    if (isWithdrwan) {
      setSuccess(false);
      setLoading(false);
    }
  }, [isWithdrwan]);
  console.log(blankForm);
  const [isDisabled, setIsDisabled] = useState(true);
  return (
    <React.Fragment>
      <Modal show={true} onHide={onHide} className="dkg-application-view-modal">
        <Modal.Header
          closeButton
          className="dkg-app-widthraw-modal-header dkg-app-progress-modal-header"
        >
          <Modal.Title>Withdraw Job Application</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dkg-app-widthraw-modal-body">
          <div className="dkg-app-widthraw-desc-con">
            <h2 className="dkg-app-withdraw-title">
              We are sorry that you do not wish to continue{" "}
              <span className="dkg-withdraw-emoji">
                <FaRegSadTear />
              </span>
            </h2>
            <p className="dkg-app-widthraw-desc">
              Please select the reasons below for your decision.
            </p>
          </div>
          <div className="dkg-app-withdraw-ulCon">
            <ul className="dkg-app-withdraw-ul">
              <li className="dkg-app-withdraw-li">
                <span className="dkg-app-withdraw-checkbox">
                  <input
                    key={itemdata.id}
                    type="checkbox"
                    className="form-control dkg-appwithdraw-checkbox"
                    name={`job-reason-checkbox` + itemdata.id}
                    checked={checkbox_1}
                    onChange={(e) => setCheckbox_1(e.target.checked)}
                    disabled={blankForm ? isDisabled : false}
                  />
                </span>
                <p className="dkg-app-withdraw-reson">
                  I have changed my interest in this job.
                </p>
              </li>
              <li className="dkg-app-withdraw-li">
                <span className="dkg-app-withdraw-checkbox">
                  <input
                    type="checkbox"
                    className="form-control dkg-appwithdraw-checkbox"
                    name={`job-reason-checkbox` + itemdata.id}
                    checked={checkbox_2}
                    onChange={(e) => setCheckbox_2(e.target.checked)}
                    disabled={blankForm ? isDisabled : false}
                  />
                </span>
                <p className="dkg-app-withdraw-reson">
                  I do not like the salary and benefits package anymore.
                </p>
              </li>
              <li className="dkg-app-withdraw-li">
                <span className="dkg-app-withdraw-checkbox">
                  <input
                    type="checkbox"
                    className="form-control dkg-appwithdraw-checkbox"
                    name={`job-reason-checkbox` + itemdata.id}
                    checked={checkbox_3}
                    onChange={(e) => setCheckbox_3(e.target.checked)}
                    disabled={blankForm ? isDisabled : false}
                  />
                </span>
                <p className="dkg-app-withdraw-reson">
                  I am already taking interviews with this hiring company.
                </p>
              </li>
              <li className="dkg-app-withdraw-li">
                <span className="dkg-app-withdraw-checkbox">
                  <input
                    type="checkbox"
                    className="form-control dkg-appwithdraw-checkbox"
                    name={`job-reason-checkbox` + itemdata.id}
                    checked={checkbox_4}
                    onChange={(e) => setCheckbox_4(e.target.checked)}
                    disabled={blankForm ? isDisabled : false}
                  />
                </span>
                <p className="dkg-app-withdraw-reson">
                  I have received another job offer from another employer.
                </p>
              </li>
              <li className="dkg-app-withdraw-li">
                <span className="dkg-app-withdraw-checkbox">
                  <input
                    type="checkbox"
                    className="form-control dkg-appwithdraw-checkbox"
                    name={`job-reason-checkbox` + itemdata.id}
                    checked={checkbox_5}
                    onChange={(e) => setCheckbox_5(e.target.checked)}
                    disabled={blankForm ? isDisabled : false}
                  />
                </span>
                <p className="dkg-app-withdraw-reson">
                  I am not happy with the recruitment process by DK Global.
                </p>
              </li>
              <li className="dkg-app-withdraw-li">
                <span className="dkg-app-withdraw-checkbox">
                  <input
                    type="checkbox"
                    className="form-control dkg-appwithdraw-checkbox"
                    name={`job-reason-checkbox` + itemdata.id}
                    checked={checkbox_6}
                    onChange={(e) => setCheckbox_6(e.target.checked)}
                    disabled={blankForm ? isDisabled : false}
                  />
                </span>
                <p className="dkg-app-withdraw-reson">
                  I am not happy with the recruitment process by the employer.
                </p>
              </li>
              <li className="dkg-app-withdraw-li">
                <span className="dkg-app-withdraw-checkbox">
                  <input
                    type="checkbox"
                    className="form-control dkg-appwithdraw-checkbox"
                    name={`job-reason-checkbox` + itemdata.id}
                    checked={checkbox_7}
                    onChange={(e) => setCheckbox_7(e.target.checked)}
                    disabled={blankForm ? isDisabled : false}
                  />
                </span>
                <p className="dkg-app-withdraw-reson">
                  I have applied this job by mistake and i do not wish to
                  continue.
                </p>
              </li>
            </ul>
            <div className="dkg-appwithdraw-textarea-Con">
              {blankForm ? (
                <textarea
                  readonly
                  disabled={true}
                  style={{ background: "#fff" }}
                  name="appwithdraw-textarea"
                  className="dkg-appwithdraw-textarea"
                  placeholder={"Any other specific reason please mention here"}
                  defaultValue={
                    messageBox ==
                      "Any other specific reason please mention here" ||
                    messageBox == null
                      ? ""
                      : messageBox
                  }
                />
              ) : (
                <textarea
                  name="appwithdraw-textarea"
                  className="dkg-appwithdraw-textarea"
                  placeholder={"Any other specific reason please mention here"}
                  defaultValue={
                    messageBox ==
                      "Any other specific reason please mention here" ||
                    messageBox == null
                      ? ""
                      : messageBox
                  }
                  onChange={(e) => setMessageBox(e.target.value)}
                />
              )}
            </div>
          </div>
          {blankForm ? null : (
            <div className="dkg-appwithdraw-btnCon">
              <SubmitButton
                txt="SAVE"
                loading={loading}
                success={success}
                className="dkg-appwithdraw-btn"
                onClick={handleButtonClick}
              />
            </div>
          )}
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default WithdrawApplication;
