import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import "./testimonial-modal.scss";
import SubmitButton from '../../../../../ui/submitButton'
import Dropdown from "react-bootstrap/Dropdown";


const AddnewTestimonialModal = ({ onHide  }) => {
  return (
    <>
      <Modal
       show={true}
       onHide={onHide}
        className="dkg-testimonial-box-modal-21"
      >
        <Modal.Header closeButton className="dkg-testimonials-modalheader-21">
          <Modal.Title>Add New Testimonial</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dkg-testimonials-modalbody-21">
          <div className="dkg-testimonial-form">
            <div className="dkg-testmonial-form-title">
              {/* <h5 className="pt-2">Add Your Testimonial</h5> */}
              <form className="" action="#">
                <div className="form-group dkg-testimonial-frmgrp">
                  <label className="dkg-testimonial-label" htmlFor="name">
                    Name<span className="dkg-red-star">*</span>
                  </label>
                  <div className="input-group dkg-testimonial-inputgrp">
                    <input
                      type="text"
                      className="form-control dkg-testimonial-input"
                      name="name"
                      id="name"
                      placeholder=""
                    />
                  </div>
                </div>

                <div className="form-group dkg-testimonial-frmgrp">
                  <label className="dkg-testimonial-label" htmlFor="email">
                    E-mail<span className="dkg-red-star">*</span>
                  </label>
                  <div className="input-group dkg-testimonial-inputgrp">
                    <input
                      type="text"
                      className="form-control dkg-testimonial-input"
                      name="email"
                      placeholder=""
                    />
                  </div>
                </div>

                <div className="form-group dkg-testimonial-frmgrp">
                  <label
                    className="dkg-testimonial-label"
                    htmlFor="country"
                  >
                    Country<span className="dkg-red-star">*</span>
                  </label>
                  <div className="input-group dkg-testimonial-inputgrp">
                    <input
                      type="text"
                      className="form-control dkg-testimonial-input"
                      name="country"
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="form-group dkg-testimonial-frmgrp">
                  <label
                    className="dkg-testimonial-label"
                    htmlFor="recruiter"
                  >
                    Select Services<span className="dkg-red-star">*</span>
                  </label>
                  <Dropdown className="dkg-testimonial-drpdwn">
                    <Dropdown.Toggle
                      variant=""
                      id=""
                      className="dkg-testimonial-drpdwn-toggle"
                    ></Dropdown.Toggle>
                    <Dropdown.Menu className="dkg-testimonial-drpdwn-menu">
                      <Dropdown.Item
                        href="#/"
                        className="dkg-testimonial-drpdwn-item"
                      >
                        Select Services 1
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#/"
                        className="dkg-testimonial-drpdwn-item"
                      >
                        Select Services 2
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#/"
                        className="dkg-testimonial-drpdwn-item"
                      >
                        Select Services 3
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="form-group dkg-testimonial-frmgrp">
                  <label
                    className="dkg-testimonial-label"
                    htmlFor="your-testimonial"
                  >
                    Your testimonial <span className="dkg-red-star">*</span>
                  </label>
                  <textarea
                    className="form-control dkg-testimonial-textArea"
                    id="your-testimonial"
                    rows="3"
                  ></textarea>
                </div>
                <div className="form-group dkg-testimonial-frmgrp">
                  <p className="dkg-testimonial-rating-titlle mb-0">
                    Rate our services{" "}
                    <span className="dkg-red-star">*</span>
                  </p>
                  <div className="dkg-rating-service-radio">
                    <div className="input-group dkg-testimonial-inputgrp">
                      <input
                        type="radio"
                        className="form-control dkg-testimonial-radio"
                        name="name"
                        value="1"
                        id="rating_one"
                      />
                      <span className="dkg-test-rating-no">1</span>
                    </div>
                    <div className="input-group dkg-testimonial-inputgrp">
                      <input
                        type="radio"
                        className="form-control dkg-testimonial-radio"
                        name="name"
                        value="2"
                        id="two"
                      />
                      <span className="dkg-test-rating-no">2</span>
                    </div>
                    <div className="input-group dkg-testimonial-inputgrp">
                      <input
                        type="radio"
                        className="form-control dkg-testimonial-radio"
                        name="name"
                        value="3"
                        id="rating_three"
                      />
                      <span className="dkg-test-rating-no">3</span>
                    </div>
                    <div className="input-group dkg-testimonial-inputgrp">
                      <input
                        type="radio"
                        className="form-control dkg-testimonial-radio"
                        name="name"
                        value="4"
                        id="rating_four"
                      />
                      <span className="dkg-test-rating-no">4</span>
                    </div>
                    <div className="input-group dkg-testimonial-inputgrp">
                      <input
                        type="radio"
                        className="form-control dkg-testimonial-radio"
                        name="name"
                        value="5"
                        id="rating_five"
                      />
                      <span className="dkg-test-rating-no">5</span>
                    </div>
                  </div>
                </div>
                <div className="form-group mt-3 dkg-testimonial-frmgrp">
                  <div className="dkg-testimoninal-btn-Con">
                    <SubmitButton
                      txt="SUBMIT"
                      className="dkg-testmonial-sub-btn"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddnewTestimonialModal;
