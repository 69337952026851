import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LegalAlert from "../../../../../assets/images/legaldoc-black.png";
import { FaExclamationTriangle } from "react-icons/fa";
import { getCMSHeadingText } from "../../../../../slice/frontendCms/frontendCmsSlice";
import "./legal.scss";

const BenfitService = () => {
  const dispatch = useDispatch();
  const { isLoading, getcmsList } = useSelector((state) => state.cmslist);
  const [data, setData] = useState([]);
  useEffect(() => {
    if (!isLoading && getcmsList && getcmsList.length > 0) {
      setData(getcmsList);
    }
  }, [isLoading]);
console.log(data)
  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(getCMSHeadingText({}));
      } catch (err) {}
    }
    fetchData();
  }, [dispatch]);

  return (
    <>
      <div className="dkg-legal-services-mainpageCon container-fluid">
        <div className="dkg-legal-service-header">
          <h2 className="dkg-legal-services-page-title">
            {" "}
            {data.length > 0 ? data[21].page_description : ""}
          </h2>
          <p className="dkg-legal-services-page-text">
            {data.length > 0 ? data[22].page_description : ""}
          </p>
        </div>
        <div className="dkg-legal-services-container">
          <div className="row dkg-legal-services-mainRow">
            <div className="col-12 dkg-legal-services-mainCol">
              <div className="dkg-cnacc-legal-mainCon">
                <div className="dkg-cnacc-legal-banner">
                  <div className="dkg-banner-upper-cross">
                    <div className="dkg-pd-legal-content">
                      <div className="dkg-pd-legal-content-title">
                        LEGAL ALERT
                        <span className="dkg-legal-doc-icon">
                          <FaExclamationTriangle />
                        </span>
                      </div>
                      <div className="dkg-pd-legal-content-body">
                        <div className="dkg-pd-legal-content-card">
                          <div className="dkg-legal-content-card-left">
                            <div className="dkg-legal-content-imgCon">
                              <img
                                src={LegalAlert}
                                alt="Legal"
                                className="dkg-legal-content-img"
                              />
                            </div>
                          </div>
                          <div className="dkg-legal-content-card-right">
                            <div className="dkg-legal-card-content">
                              {data.length > 0 ? data[32].page_description : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BenfitService;
