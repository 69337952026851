import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCMSHeadingText } from "../../../../../slice/frontendCms/frontendCmsSlice";
import { Dropdown } from 'react-bootstrap';
import  './consults.scss';
import ConsultCard from './ConsultCard'

const Consults = () => {
  const dispatch = useDispatch();
  const { isLoading, getcmsList } = useSelector((state) => state.cmslist);
  const [data, setData] = useState([]);
  useEffect(() => {
    if (!isLoading && getcmsList && getcmsList.length > 0) {
      setData(getcmsList);
    }
  }, [isLoading]);

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(getCMSHeadingText({}));
      } catch (err) {}
    }
    fetchData();
  }, [dispatch]);
  return (
    <>
      <div className="dkg-consults-mainpageCon container-fluid">
        <div className="dkg-consults-container">
          <div className="row dkg-consults-mainRow">
            <div className="col-12 dkg-consults-mainCol">
              <h2 className="dkg-consults-page-title">
                {data.length > 0 ? data[27].page_description : ""}
              </h2>
              <p className="dkg-consults-page-text">
                {data.length > 0 ? data[26].page_description : ""}
              </p>
              <div className="dkg-service-content-Con">
                <div className="dkg-service-dropdown-header">
                  <div className="dkg-consults-card-headercon">
                    <div className="dkg-service-dropdowns-con">
                      <Dropdown className="dkg-service-dropdown">
                        <Dropdown.Toggle variant="success" id="dropdown-basic" className="dkg-service-dropdown-toggle">
                          Consultant
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dkg-service-dropdown-menu">
                          <Dropdown.Item href="#" className="dkg-service-down-item">Expertise  1</Dropdown.Item>
                          <Dropdown.Item href="#" className="dkg-service-down-item">Expertise  2</Dropdown.Item>
                          <Dropdown.Item href="#" className="dkg-service-down-item">Expertise  3</Dropdown.Item>
                          <Dropdown.Item href="#" className="dkg-service-down-item">Expertise  5</Dropdown.Item>
                          <Dropdown.Item href="#" className="dkg-service-down-item">Expertise  5</Dropdown.Item>
                        </Dropdown.Menu>                   
                      </Dropdown>
                      <Dropdown className="dkg-service-dropdown">
                        <Dropdown.Toggle variant="success" id="dropdown-basic" className="dkg-service-dropdown-toggle">
                          Services 
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dkg-service-dropdown-menu">
                          <Dropdown.Item href="#" className="dkg-service-down-item">Expertise  1</Dropdown.Item>
                          <Dropdown.Item href="#" className="dkg-service-down-item">Expertise  2</Dropdown.Item>
                          <Dropdown.Item href="#" className="dkg-service-down-item">Expertise  3</Dropdown.Item>
                          <Dropdown.Item href="#" className="dkg-service-down-item">Expertise  5</Dropdown.Item>
                          <Dropdown.Item href="#" className="dkg-service-down-item">Expertise  5</Dropdown.Item>
                        </Dropdown.Menu>                   
                      </Dropdown>
                      <Dropdown className="dkg-service-dropdown">
                        <Dropdown.Toggle variant="success" id="dropdown-basic" className="dkg-service-dropdown-toggle">
                          Language 
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dkg-service-dropdown-menu">
                          <Dropdown.Item href="#" className="dkg-service-down-item">Expertise  1</Dropdown.Item>
                          <Dropdown.Item href="#" className="dkg-service-down-item">Expertise  2</Dropdown.Item>
                          <Dropdown.Item href="#" className="dkg-service-down-item">Expertise  3</Dropdown.Item>
                          <Dropdown.Item href="#" className="dkg-service-down-item">Expertise  5</Dropdown.Item>
                          <Dropdown.Item href="#" className="dkg-service-down-item">Expertise  5</Dropdown.Item>
                        </Dropdown.Menu>                   
                      </Dropdown>
                      <Dropdown className="dkg-service-dropdown">
                        <Dropdown.Toggle variant="success" id="dropdown-basic" className="dkg-service-dropdown-toggle">
                         Location 
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dkg-service-dropdown-menu">
                          <Dropdown.Item href="#" className="dkg-service-down-item">Expertise  1</Dropdown.Item>
                          <Dropdown.Item href="#" className="dkg-service-down-item">Expertise  2</Dropdown.Item>
                          <Dropdown.Item href="#" className="dkg-service-down-item">Expertise  3</Dropdown.Item>
                          <Dropdown.Item href="#" className="dkg-service-down-item">Expertise  5</Dropdown.Item>
                          <Dropdown.Item href="#" className="dkg-service-down-item">Expertise  5</Dropdown.Item>
                        </Dropdown.Menu>                   
                      </Dropdown>
                    </div>
                    {/* <div className='dkg-consults-btnCon'>
                      <ButtonGroup className='dk-filterbtnGrp'>
                        <Button className="dk-filterbtntext">Title</Button>
                        <Button className="dk-filterbtnicon"><i className="fas fa-times"></i></Button>
                      </ButtonGroup>
                    </div> */}
                  </div>
                  <div className="dkg-consults-cards-Con row">
                      <ConsultCard />
                      <ConsultCard />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Consults;
