import React, { useState, useEffect } from "react";
import "./delete-account.scss";
import CheckboxPopup from "./CheckboxPopup";
import { getCMS } from "../../../../../slice/cmsSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  showSuccess,
  showError,
} from "../../../../utils/messages/messageSlice";
import { logoutUser } from "../../../../../slice/auth/authSlice";
import { clearState } from "../../../../../slice/candidates/candidatesSlice";

const DeleteAccount = () => {
  const dispatch = useDispatch();
  const { isSuccess, cmsList } = useSelector((state) => state.cmsdata);
  const { isDeleteAc } = useSelector((state) => state.candidate);
  const [details, setDetails] = useState("");
  const [checkkbox, setCheckbox] = useState("blank");

  useEffect(() => {
    dispatch(clearState());
    dispatch(getCMS({}));
  }, [dispatch]);

  useEffect(() => {
    if (!isSuccess && cmsList.length > 0) {
      setDetails(cmsList[8]);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isDeleteAc) {
      dispatch(clearState());
      dispatch(
        showSuccess({
          msg: "Your account is deleted, Now you will automatic logout.",
        })
      );
      const delay = 3;
      setTimeout(() => dispatch(logoutUser()), delay * 1000);
    }
  }, [isDeleteAc]);

  const checkbox = (data) => {
    setCheckbox(data);
  };
  const SubmitDeleteBtn = () => {
    if (checkkbox == "blank") {
      dispatch(showError({ msg: "Please check the checkbox" }));
      return false;
    }
  };

  return (
    <>
      <div
        className="dkg-myprofile-mainpageCon container-fluid dkg-restlogin-mainpageCon"
        // style={{ height: "calc(100vh - 152px)", marginTop: "152px" }}
      >
        <div className="row dk-billingHistroy-fluid">
          <div className="col-9 m-auto dk-contactPerson-form dkg-resetform-Con-234">
            <div className="row">
              <div className="col-md-7 m-auto dkg-reset-rightCon dkg-deleteAcc-rightCon">
                <div className="dk-complaince-main">
                  <div className="title">Delete Account</div>
                  <div className="dk-complaince-cols dkg-delete-acc-cols">
                    <div className="dkg-delete-acc-header">
                      <p>{details != "" ? details.description : ""}</p>
                    </div>
                    <div className="dkg-delete-acc-body">
                      <p className="sb-title">Delete Account</p>
                      <ul>
                        <li>Your account will be deleted from our database.</li>
                        <li>
                          We may keep some information for legal accounts
                          filings.
                        </li>
                        <li>
                          If you would apply job in future, we have to open new
                          account.
                        </li>
                        <li>
                          All your stored data and record will be deleted from
                          our system.
                        </li>
                      </ul>
                      <div className="dkg-delete-checkbox-div d-flex align-items-center mt-4 mb-3">
                        <CheckboxPopup checkbbox={checkbox} />
                        <label
                          data-v-6f11ced0=""
                          for="dd2"
                          className="mb-0 ml-2"
                        >
                         I understand the risk of losing all my account data.
                        </label>
                      </div>
                      <div className="dk-complaince-field">
                        <div className="d-flex justify-content-center">
                          <button
                            type="button"
                            className="dk-deleteBtn hvr-float-shadow btn"
                            onClick={SubmitDeleteBtn}
                          >
                            Delete Account
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteAccount;
