import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./my-referals.scss";
import MoreInfoPopover from "./MoreInfoPopover";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  FaFileInvoice,
  FaEuroSign,
  FaHandshake,
} from "react-icons/fa";

import {
  getCMS,
  // updatecontent,
  // clearState,
} from "../../../../../slice/cmsSlice";
import ServicesModal from "./modal/ServicesModal";

// import AddNewReferrals from "./modal/AddNewReferrals";
const MyReferrals = () => {
  //   const [isAddNewReferral, setIsAddNewReferral] = useState(false);

  //   const showreferralModal = () => {
  //     setIsAddNewReferral(true);
  //   };

  //   const hidereferralModal = () => {
  //     setIsAddNewReferral(false);
  //   };
  //  const { userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { isSuccess, cmsList } = useSelector((state) => state.cmsdata);
  const [details, setDetails] = useState("");
  useEffect(() => {
    dispatch(getCMS({}));
  }, [dispatch]);

  useEffect(() => {
    if (!isSuccess && cmsList.length > 0) {
      setDetails(cmsList[29].description);
    }
  }, [isSuccess]);
  const [isInvoiceModal, setIsInvoiceModal] = useState(false);
  const [isPaymentModal, setIsPaymentModal] = useState(false);
  const [isAgreeMentsModal, setIsAgreeMentsModal] = useState(false);
  const showInvoiceModal = () => {
    setIsInvoiceModal(true);
  };
  const hideconsultModal = () => {
    setIsInvoiceModal(false);
  };
  const showPaymentsModal = () => {
    setIsPaymentModal(true);
  };
  const hidePaymentModal = () => {
    setIsPaymentModal(false);
  };
  const showagrementsModal = () => {
    setIsAgreeMentsModal(true);
  };
  const hideagrementsModal = () => {
    setIsAgreeMentsModal(false);
  };
  return (
    <>
       {isInvoiceModal ? (
        <ServicesModal onHide={hideconsultModal} headerTitle="Invoices" />
      ) : null}
      {isPaymentModal ? (
        <ServicesModal onHide={hidePaymentModal} headerTitle="Payments" />
      ) : null}
      {isAgreeMentsModal ? (
        <ServicesModal onHide={hideagrementsModal} headerTitle="Agreements" />
      ) : null}
      <div className="dkg-myreferals-mainCon">
        <div className="dkg-myreferals-header">
          <div className="dkg-myreferals-headerleftCon">
            <div className="dk-mailshotsSearch mr-2">
              <div className="input-group">
                <input
                  className="form-control"
                  placeholder="Search ..."
                  value=""
                />
                <div className="input-group-append">
                  <button className="btn btn-secondary" type="button">
                    <i className="fa fa-search"></i>
                  </button>
                </div>
              </div>
              <div className="dkg-crossicon-Con d-none">
                <i className="fas fa-times-circle" data-type="search text"></i>
              </div>
            </div>
            {/* <div className="dkg-referal-addnew-btnCon">
              <button
                className="dkg-referal-addnew-btn"
                onClick={showreferralModal}
              >
                <i className="fas fa-plus"></i>
              </button>
  </div> */}
          </div>
          <div className="dkg-myreferals-centreCon">
            <div className="dkg-docs-title">
              MY REFERRALS <MoreInfoPopover details={details} />
            </div>
          </div>
          <div className="dkg-myreferals-rightCon">
            <Dropdown className="dkg-referal-status-drpdwn">
              <Dropdown.Toggle
                variant=""
                id=""
                className="dkg-referal-status-drpdwn-toggle"
              >
                STATUS
              </Dropdown.Toggle>
              <Dropdown.Menu className="dkg-referal-status-drpdwn-menu">
                <Dropdown.Item
                  href="#"
                  className="dkg-referal-status-item"
                  style={{ backgroundColor: "#3a9469", color: "#fff" }}
                >
                  Open
                  <span className="dkg-lv-statuscounter">4</span>
                </Dropdown.Item>
                <Dropdown.Item
                  href="#"
                  className="dkg-referal-status-item"
                  style={{ backgroundColor: "#dc3545", color: "#fff" }}
                >
                  Closed
                  <span className="dkg-lv-statuscounter">6</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="dkg-document-tableCon table-responsive">
          <table className="dkg-document-table table table-bordered">
            <thead>
              <tr>
                <th>No.</th>
                <th>Date</th>
                <th>Status</th>
                <th>Case ID</th>
                <th>Name of Candidate</th>
                <th>Joining Date</th>
                <th>Resign Date</th>
                <th>Job ID</th>
                <th>Company</th>
                <th>Language</th>
                <th>Project</th>
                <th>Role Type</th>
                <th>Country</th>
                <th>Invoices</th>
                <th>Payments</th>
                <th>Agreements</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td> - - </td>
                <td>
                  <Dropdown className="dkg-referal-status-drpdwn">
                    <Dropdown.Toggle
                      variant=""
                      id=""
                      className="dkg-referal-status-drpdwn-toggle"
                    >
                      <div
                        className="statusDiv"
                        style={{
                          backgroundColor: "#3a9469",
                          color: "#fff",
                          textTransform: "uppercase",
                        }}
                      >
                        Open
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dkg-referal-status-drpdwn-menu">
                      <Dropdown.Item
                        href="#"
                        className="dkg-referal-status-item"
                        style={{ backgroundColor: "#3a9469", color: "#fff" }}
                      >
                        Open
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        className="dkg-referal-status-item"
                        style={{ backgroundColor: "#dc3545", color: "#fff" }}
                      >
                        Closed
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
                <td> - - </td>
                <td> - - </td>
                <td> - - </td>
                <td> - - </td>
                <td> - - </td>
                <td> - - </td>
                <td> - - </td>
                <td> - - </td>
                <td> - - </td>
                <td> - - </td>
                <td>
                  <div className="dkg-buy-btn-con text-center w-100">
                    <Link to="#" className="btn dkg-buy-btn" onClick={showInvoiceModal}>
                      View
                      <span className="dkg-invoice-icon">
                        <FaFileInvoice />
                      </span>
                    </Link>
                  </div>  
                </td> 
                <td>
                  <div className="dkg-buy-btn-con text-center w-100">
                    <Link to="#" className="btn dkg-buy-btn" onClick={showPaymentsModal}>
                      View
                      <span className="dkg-invoice-icon">
                        <FaEuroSign />
                      </span>
                    </Link>
                  </div>
                </td>
                <td>
                  <div className="dkg-buy-btn-con text-center w-100">
                    <Link to="#" className="btn dkg-buy-btn" onClick={showagrementsModal}>
                      View
                      <span className="dkg-invoice-icon">
                        <FaHandshake />
                      </span>
                    </Link>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default MyReferrals;
