import React, { useEffect, useState } from "react";
//import { Link } from "react-router-dom";
import { Tab, Nav, Dropdown} from "react-bootstrap";
import FaqAccordion from "./Accordion";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategory
} from "../../../../../../slice/faqs/newFaqSlice";

const Overview = ({ jobId }) => {
  const dispatch = useDispatch();
  const {
    categoryList, iscat,
    isSuccess
  } = useSelector((state) => state.faqData);

  const [faqtitle, setFaqtitle] = useState("");
  const [faqqData, isFaqdata] = useState([]);
 
  const [dropdownlvalue, setDropdownValue] = useState("About Jobs FAQs");

  // const [dropdownlvalue, setDropdownValue] = useState("");
  // useEffect(()=>{
  //   setDropdownValue("About Job FAQ");
  //   dispatch(getCategory({ id: jobId }));  
  // },[])

  useEffect(() => {
    if (!iscat && categoryList.length > 0) {
      isFaqdata(categoryList)
      setFaqtitle(categoryList[0].companyName)
    }
  }, [iscat])

  useEffect(() => {
    async function fetchData() {
      try {
        dispatch(getCategory({ id: jobId }))
      } catch (err) {
        // dispatch(showError({ msg: "Somthings went wrong" }));
      }
    }
    fetchData();
  }, [dispatch]);

  const handleClick = async (id, name) => {
    setDropdownValue(name);
    dispatch(getCategory({ id: jobId }));  
  };
 
  return (
    <>
      <div className="dkg-payment-faq-pageCon dkg-cand-jobspec-faqmain dkg-cand-openjobs-faqcon">
      <div className="dkg-cand-faq-moCon">
          <div className="dkg-faq-header-centerCon">
            <h2
              className="dkg-cand-categoty-title w-100 text-center"
              style={{ fontSize: "1.25rem", fontWeight: "600" }}
            >
              Select Category
            </h2>
            <div className="dkg-faq-DropdownCon">
              <Dropdown className="dkg-faq-Dropdown">
                <Dropdown.Toggle variant="" className="dkg-faq-Dropdown-toggle">
                  {dropdownlvalue}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  className="dkg-faq-Dropdown-Menu w-100"
                  aria-labelledby="dropdownMenuButton"
                >
                  {categoryList &&
                    categoryList.filter(
                      (item) => item.status === "Active"
                    ).map((link, index) => {
                      return (
                        <>
                          <Dropdown.Item href="#"
                            key={link.id}
                            // className="dropdown-item"
                            className={link.name === dropdownlvalue ? 'selected-item dropdown-item' : 'dropdown-item'}
                            onClick={() => handleClick(link.id, link.name)}
                          >
                            {link.name}
                          </Dropdown.Item>
                        </>
                      );
                    })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="dkg-cand-faq-mo-accordianCon">
            {categoryList.length > 0 ? (
              <>
                  {
                    <div className="dkg-myaccount-faq-tabcontent">
                      {
                        faqqData.filter((item) => item.status === "Active" && item.name == dropdownlvalue)
                          .map((cat, index) => {
                            return (
                                <FaqAccordion faqdata={cat.quesAns} />
                            );
                          })

                      }
                    </div>
                  }
              </>
            ) : "null"}
          </div>
          {/* <div className="dkg-cand-faq-mo-accordianCon">test content</div> */}
        </div>
        <div className="row dkg-candprescreen-mainRow">
          <div className="col-md-12 p-0 dkg-clientlist-faq-mainCon">
            <Tab.Container defaultActiveKey="first0" >
              <div className="row m-0 dkg-payment-faq-tab-mainCon">
                <div className="col-md-2 pl-0 dkg-payment-faq-tabCon dkg-faq-sideMenuCon-234">
                  <Nav
                    variant="pills"
                    className="flex-column dkg-payment-faq-tab-navpills"
                  >
                    {
                      faqqData.filter((item) => item.status === "Active")
                        .map((cat, index) => {
                          return (
                            <>
                              <Nav.Item className="dkg-payment-faq-tab-nav-item">
                                {
                                  <Nav.Link eventKey={`first` + index} className={`dkg-payment-faq-tab-navlink`} onClick={() => setFaqtitle(cat.companyName)}
                                  >{cat.name}</Nav.Link>
                                }
                              </Nav.Item>
                            </>
                          );
                        })

                    }
                  </Nav>
                </div>
                <div className="col-md-10 dkg-payment-faq-tabCon-10 dkg-faq-rightMenuCon-234 pr-0">
                  {/* <div className="dkg-jobseeker-faq-titleCon">
                    <h2 className="dkg-jobseeker-faq-title">{isCompanyName}</h2>
                  </div> */}

                  {
                    <Tab.Content className="dkg-payment-faq-tabcontent ddd">
                      {
                        faqqData.filter((item) => item.status === "Active")
                          .map((cat, index) => {
                            return (
                              <Tab.Pane eventKey={`first` + index}>
                                <FaqAccordion faqdata={cat.quesAns} />
                              </Tab.Pane>
                            );
                          })

                      }
                    </Tab.Content>
                  }
                </div>
              </div>
            </Tab.Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
