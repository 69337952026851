import React from "react";
import JoditEditor from "../../../../../ui/JoditEditor";

const RoletypeTab = ({ companyService }) => {
  return (
    <>
      <div className="dkg-jopspec-pageheader">
        <div className="dkg-jopspec-page-title">Services</div>
      </div>
      <div className="dkg-jobspec-read-editor-Con">
        <div className="dkg-jobspec-read-editor">
          <div
            className="dkg-jobspec-htmleditor"
            dangerouslySetInnerHTML={{
              __html:
                companyService.length > 0 && companyService[0].services != null
                  ? companyService[0].services
                  : "",
            }}
          ></div>
          {/* <JoditEditor
            datavalue={
              companyService.length > 0 && companyService[0].services != null
                ? companyService[0].services
                : ""
            }
            toolbaarControl={false}
            readonlyControl={true}
            spellcheckControll={false}
            showCharsCounterControll={false}
            showWordsCounterControll={false}
            widthControll={800}
            heightControll={455}
          /> */}
        </div>
      </div>
    </>
  );
};

export default RoletypeTab;
