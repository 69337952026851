import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./myacc-privacy.scss";
//import { Link } from "react-router-dom";
import { getCMS } from "../../../../slice/cmsSlice";
import JoditEditor from "../../../ui/JoditEditor";

const MyaccPrivacy = () => {
  const dispatch = useDispatch();
  const { isSuccess, cmsList } = useSelector((state) => state.cmsdata);
  const [JobInstuctiondata, setJobInstuctiondata] = useState("");

  useEffect(() => {
    dispatch(getCMS({}));
  }, [dispatch]);

  useEffect(() => {
    if (!isSuccess && cmsList.length > 0) {
      setJobInstuctiondata(cmsList[3]);
    }
  }, [isSuccess]);
  return (
    <>
      <div className="dkg-myacc-priavcy-Con container-fluid">
        <div className="dkg-myacc-priavcy-mainRow">
          <div className="dkg-myacc-priavcy-mainCol">
            <div className="dkg-myacc-privacy-cardCon">
              <div className="dkg-myacc-priavcy-Header">
                <h2 className="dkg-privacy-title">Privacy Policy</h2>
              </div>
              <div className="dkg-myacc-priavcy-Body">
                {JobInstuctiondata != "" && JobInstuctiondata != null ? (
                  // <div
                  //   dangerouslySetInnerHTML={{
                  //     __html: JobInstuctiondata.description,
                  //   }}
                  // ></div>
                  <JoditEditor
                    datavalue={JobInstuctiondata.description}
                    toolbaarControl={false}
                    readonlyControl={true}
                    spellcheckControll={false}
                    showCharsCounterControll={false}
                    showWordsCounterControll={false}
                    widthControll={800}
                    heightControll={1500}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyaccPrivacy;
