import React from "react";
import  './my-applications.scss'

const  MyApplications = () => {

  return (
    <>
      <div className="dkg-myapps-mainpageCon">
        <div className="dkg-myapps-con">
          <div className="dk-myapp-boxCols">
            <div className="title">JOBS APPLIED</div>
            <div className="dkg-myapps-body">
              <ul className="dkg-myapps-ul">
                  <li className="dkg-myapps-li">
                    <span className="dkg-myapps-lefttitle">Opened Case</span>
                    <span className="dkg-myapps-righttitle">2</span>
                  </li>
                  <li className="dkg-myapps-li">
                    <span className="dkg-myapps-lefttitle">Closed Case</span>
                    <span className="dkg-myapps-righttitle">2</span>
                  </li>
              </ul>
            </div>
          </div>
          <div className="dk-myapp-boxCols">
            <div className="title">JOBS INTERVIEWED</div>
            <div className="dkg-myapps-body">
              <ul className="dkg-myapps-ul">
                  <li className="dkg-myapps-li">
                    <span className="dkg-myapps-lefttitle">Opened Case</span>
                    <span className="dkg-myapps-righttitle">2</span>
                  </li>
                  <li className="dkg-myapps-li">
                    <span className="dkg-myapps-lefttitle">Closed Case</span>
                    <span className="dkg-myapps-righttitle">2</span>
                  </li>
              </ul>
            </div>
          </div>
          <div className="dk-myapp-boxCols">
            <div className="title">JOBS OFFERED</div>
            <div className="dkg-myapps-body">
              <ul className="dkg-myapps-ul">
                  <li className="dkg-myapps-li">
                    <span className="dkg-myapps-lefttitle">Opened Case</span>
                    <span className="dkg-myapps-righttitle">2</span>
                  </li>
                  <li className="dkg-myapps-li">
                    <span className="dkg-myapps-lefttitle">Closed Case</span>
                    <span className="dkg-myapps-righttitle">2</span>
                  </li>
              </ul>
            </div>
          </div>
          <div className="dk-myapp-boxCols">
            <div className="title">JOBS JOINED</div>
            <div className="dkg-myapps-body">
              <ul className="dkg-myapps-ul">
                  <li className="dkg-myapps-li">
                    <span className="dkg-myapps-lefttitle">Opened Case</span>
                    <span className="dkg-myapps-righttitle">2</span>
                  </li>
                  <li className="dkg-myapps-li">
                    <span className="dkg-myapps-lefttitle">Closed Case</span>
                    <span className="dkg-myapps-righttitle">2</span>
                  </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyApplications;
