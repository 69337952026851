import React,{useState} from 'react';
import { Link } from 'react-router-dom'
import { FaCalendar,FaUser, FaWrench, FaEnvelope, FaStar  } from 'react-icons/fa';
import { FcServices } from "react-icons/fc";
import Consultant from '../../../../../assets/images/user-defult.jpg'
import Maintenance from '../../../../../assets/images/maintenance.png'
import {Tab , Nav , Dropdown} from 'react-bootstrap';
import Verifyconsults from '../../../../../assets/images/verified.png'
import MyProfileTab from './tabs/MyProfileTab';





const ConsultsfullDetails = () => {
  const [selectedItem, setSelectedItem] = useState("My Profile");
  // const dataItems = ['My Profile' , 'My Services', 'My Reviews' , 'My Calander' , 'My Contact'];
  const dataItems = [
    {
      id: 1,
      tabname : 'My Profile',
    },
    {
      id: 2,
      tabname : 'My Services',
    },
    {
      id: 3,
      tabname : 'My Reviews',
    },
    {
      id: 4,
      tabname : 'My Calander',
    },
    {
      id: 5,
      tabname : 'My Contact',
    },
    
  ];

  const handleSelect = (item) => {
    setSelectedItem(item);
  };
  
  const renderComponent = () => {
    switch (selectedItem) {
      case 'My Profile':
        return <>
          <div className="dkg-consult-det-mo-tabpane">
           <MyProfileTab />
          </div>   
        </>; 
      case 'My Services':
        return <>
          <div className="dkg-consult-det-mo-tabpane">
           <MyProfileTab />
          </div>   
        </>; 
      case 'My Reviews':
        return <>
          <div className="dkg-consult-det-mo-tabpane">
          <div>My Review</div>
          </div>   
        </>; 
      case 'My Calander':
        return <>
          <div className="dkg-consult-det-mo-tabpane">
          <div>My Calander</div>
          </div>   
        </>; 
      case 'My Contact':
        return <>
          <div className="dkg-consult-det-mo-tabpane">
            <div>My Contact</div>
          </div>   
        </>; 

      default:
        return null;     
    }
  }
  return (
    <>
        <div className="dkg-consults-fd-mainpageCon container-fluid">
            <div className="dkg-consults-fd-container">
                <div className="row dkg-consults-fd-mainRow">
                    <div className="col-12 dkg-consults-fd-mainCol">
                      <div className="dkg-fd-consult-breadcrumb-con">
                        <ul className="dkg-fd-consult-breadcrumb-ul">
                          <li className='dkg-fd-consult-breadcrumb-li'>
                            <Link to="/my-services/consultants" className="dkg-fd-consult-breadcrumb-link">Consultants List</Link>
                          </li>
                          <li className='dkg-fd-consult-breadcrumb-li'>
                            <Link to="#" className="dkg-fd-consult-breadcrumb-link dkg-jobseeker-name">Full Name</Link>
                          </li>
                        </ul>
                      </div>
                      <div className="dkg-fd-consult-profile-con">
                        <div className="dkg-fd-consult-profile">
                           <div className="row dkg-fd-consult-profile-flex d-md-flex">
                            <div className="col-12 col-md-auto">
                              <div className="dkg-profile-title-image">
                                <img src={Consultant} alt="Consults Profile " className='dkg-consults-profilepic img-fluid rounded-circle' />
                              </div>
                              <h1 className='dkg-profile-info-name'>Full Name  
                                <span className='dkg-verify-consult'>
                                  <img src={Verifyconsults} className='dkg-verify-consult-img' alt="Verify Consults" />
                                </span>
                              </h1>
                              <div className='dkg-profile-info-subtitle'>
                                <span className='dkg-profile-info-subtitle-span'>DK Global Recruitment Ltd</span>
                              </div>
                              <div className="dkg-consult-left-bottom-cols">
                                <div className="dkg-consul-det-tabCon">
                                  <span className="dkg-consul-det-tab">Recruiter</span>
                                  <span className="dkg-consul-det-tab">Entrepreneur</span>
                                  <span className="dkg-consul-det-tab">Career Consultant</span>
                                  <span className="dkg-consul-det-tab">Team Manager</span>
                                  <span className="dkg-consul-det-tab">Business Analyst</span>
                                  <span className="dkg-consul-det-tab">Business Developer</span>
                                  <span className="dkg-consul-det-tab">Frontend Developer</span>
                                  <span className="dkg-consul-det-tab">IT Product Manager</span>
                                </div>
                              </div>
                            </div>
                            <div className="dkg-profile-info-con col flex-md-grow-1">
                              <div className="dkg-fd-consult-tabcontainer">
                                <div className="dkg-cand-consult-det-moCon">
                                  <div className="dkg-consult-det-centerCon">
                                    <h2 className="dkg-cand-categoty-title w-100 text-center" style={{fontSize: "1.25rem" , fontWeight: "600"}}>Select Tab</h2>
                                    <div className="dkg-consult-det-DropdownCon">
                                      <Dropdown className='dkg-consult-det-Dropdown'>
                                        <Dropdown.Toggle variant="" className='dkg-consult-det-Dropdown-toggle'>
                                          {selectedItem}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className='dkg-consult-det-Dropdown-Menu w-100'  aria-labelledby="dropdownMenuButton">
                                         {
                                          dataItems.map((item , index) =>{
                                            return(
                                              <>
                                                  <Dropdown.Item href="#" key={item.id} 
                                                  className={item.tabname === selectedItem ? 'selected-item ' : ''}
                                                  onClick={() => handleSelect(item.tabname)}
                                                  
                                                  >{item.tabname}</Dropdown.Item>
                                              </>
                                            )

                                          } )
                                         }
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                  {renderComponent()}
                                </div>
                                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                    <div className='row dkg-consults-tab-mainRow'>
                                        <div className='col-12 col-md-12 col-sm-12 dkg-consults-tab-mainCol'>
                                            <Nav variant="pills" className="flex-row dkg-consults-navpills">
                                                <Nav.Item className='dkg-consults-navitem'>
                                                    <Nav.Link eventKey="first" className='dkg-consults-navlink'><FaUser className='mr-2'/>My Profile</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className='dkg-consults-navitem'>
                                                    <Nav.Link eventKey="my-services" className='dkg-consults-navlink'>
                                                    <img src={Maintenance} alt="Service" className='dkg-consult-navlink-img' />  
                                                    My Services</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className='dkg-consults-navitem'>
                                                    <Nav.Link eventKey="second" className='dkg-consults-navlink'> <FaStar  /> My Reviews (0)</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className='dkg-consults-navitem'>
                                                    <Nav.Link eventKey="third" className='dkg-consults-navlink'><FaCalendar className='mr-2'/>My Calander</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className='dkg-consults-navitem'>
                                                    <Nav.Link eventKey="fourth" className='dkg-consults-navlink'><FaEnvelope className='mr-2'/>My Contact</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                            <Tab.Content className='dkg-consults-tabcontent'>
                                                <Tab.Pane eventKey="first" className='dkg-consults-tabpane'>
                                                  <MyProfileTab />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="my-services" className='dkg-consults-tabpane'>
                                                  <MyProfileTab />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="second" className='dkg-consults-tabpane'>
                                                  <MyProfileTab />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="third" className='dkg-consults-tabpane'>
                                                  <MyProfileTab />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="fourth" className='dkg-consults-tabpane'>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </div>                               
                                    </div>
                                </Tab.Container>
                              </div>
                            </div>
                           </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default ConsultsfullDetails