import React from "react";
import { Link } from "react-router-dom";
// import './myacc-submenu.scss';

const ServiceSubmenu = () => {
    const pathname = window.location.pathname;
    const segment2 = pathname.split("/")[2];

    return (
        <React.Fragment>
            <ul className="dkg-myacc-submenu-ul">
                <li>
                    <Link to="/my-services/home" className={`${(segment2 === 'home') ? 'grey-active' : ''}`}>Home</Link>
                </li>
                {/* <li>
                    <Link to="/my-services/about-us" className={`${(segment2 === 'about-us') ? 'blue-active' : ''}`}>About us</Link>
                </li> */}
                <li>
                    <Link to="/my-services/services" className={`${(segment2 === 'services') ? 'blue-active' : ''}`}>Services</Link>
                </li>
                <li>
                    <Link to="/my-services/consultants" className={`${(segment2 === 'consultants') ? 'blue-active' : ''}`}>Consultants</Link>
                </li>
                <li className="pbp">
                    <Link to="/my-services/pricing-list" className={`${(segment2 === 'pricing-list') ? 'blue-active' : ''}`}>Pricing List</Link> 
                </li>
                <li className="psp">
                    <Link to="/my-services/testimonial" className={`${(segment2 === 'testimonial') ? 'blue-active' : ''}`}>Testimonials</Link>
                </li>
                <li>
                    <Link to="/my-services/legal" className={`${(segment2 === 'legal') ? 'blue-active' : ''}`}>Legal Alert</Link>
                </li>
                <li className="psp">
                    <Link to="/my-services/contact-us" className={`${(segment2 === 'contact-us') ? 'blue-active' : ''}`}>Contact us</Link>
                </li> 
                <li className="psp">
                    <Link to="/my-services/faq-s" className={`${(segment2 === 'faq-s') ? 'blue-active' : ''}`}>FAQ’s</Link>
                </li>
                <li className="psp">
                    <Link to="/my-services/my-profile" className={`${(segment2 === 'my-profile') ? 'blue-active' : ''}`} style={{color: "#fff"}}>My Profile</Link>
                </li>
                <li className="psp">
                    <Link to="/my-services/settings" className={`${(segment2 === 'settings') ? 'blue-active' : ''}`} style={{color: "#fff"}}>Settings</Link>
                </li>
                {/*
                <li className="psp">
                    <Link to="/my-services/work-abroad" className={`${(segment2 === 'work-abroad') ? 'blue-active' : ''}`}>Work Abroad</Link>
                </li>
                <li className="psp">
                    <Link to="/my-services/study-abroad" className={`${(segment2 === 'study-abroad') ? 'blue-active' : ''}`}>Study Abroad</Link>
                </li>
                <li className="psp">
                    <Link to="/my-services/plan-carrer" className={`${(segment2 === 'plan-carrer') ? 'blue-active' : ''}`}>Plan Career</Link>
                </li> */}
            </ul>
        </React.Fragment>
    );
}

export default ServiceSubmenu;
