import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./acc-notification.scss";
import Switch from "./Switch";
import {
  getCMS,
  getCookiesNotification,
  updateNotification,
  clearState,
} from "../../../../../slice/cmsSlice";

import {
  showSuccess,
  showError,
} from "../../../../utils/messages/messageSlice";

import { useDispatch, useSelector } from "react-redux";

const AccountNotifiation = () => {
  const dispatch = useDispatch();
  const { isSuccess, cmsList, isCookiesN, isOperation, cookieNotification } =
    useSelector((state) => state.cmsdata);
  const { userInfo } = useSelector((state) => state.auth);

  const [details, setDetails] = useState("");
  const [systemNo, setSystemNo] = useState("");
  //const [dataIdd, setDataIdd] = useState(0);
  const [f1, setF1] = useState("");
  const [f2, setF2] = useState("");
  const [f3, setF3] = useState("");
  const [f4, setF4] = useState("");
  const [f5, setF5] = useState("");
  const [f6, setF6] = useState("");
  const [f7, setF7] = useState("");
  const [f8, setF8] = useState("");
  const [f9, setF9] = useState("");

  useEffect(() => {
    dispatch(getCMS({}));
    dispatch(getCookiesNotification({ canId: userInfo.canId }));
  }, [dispatch]);

  useEffect(() => {
    if (!isSuccess && cmsList.length > 0) {
      setDetails(cmsList[7]);
      setF1(cmsList[18].description);
      setF2(cmsList[19].description);
      setF3(cmsList[20].description);
      setF4(cmsList[21].description);
      setF5(cmsList[22].description);
      setF6(cmsList[23].description);
      setF7(cmsList[24].description);
      setF8(cmsList[25].description);
      setF9(cmsList[26].description);
    }
    if (!isCookiesN && cookieNotification) {
      setSystemNo(cookieNotification);
      //setDataIdd(cookieNotification.id);
    }
  }, [isSuccess, isCookiesN]);

  useEffect(() => {
    if (isOperation) {
      dispatch(clearState());
      dispatch(
        showSuccess({
          msg: "Details are updated successfully.",
        })
      );
    }
  }, [isOperation]);

  const handleToggle = (e) => {
    const keyName = e.target.getAttribute("data-name");
    if (e.target.checked === true) {
      dispatch(
        updateNotification({ id: userInfo.canId, key: keyName, value: 1 })
      );
    } else {
      dispatch(
        updateNotification({ id: userInfo.canId, key: keyName, value: 0 })
      );
    }
  };

  return (
    <>
      <div className="container-fluid dkg-myacc-noti-mainpageCon">
        <div className="row dkg-myacc-noti-mainRow">
          <div className="col-11 m-auto dkg-myacc-noti-mainCol">
            <div className="row dkg-myacc-noti-subRow">
              <div className="col-md-8 m-auto dkg-myacc-noti-rightCon">
                <div className="dkg-myacc-noti-cardbody">
                  <h5 className="dkg-myacc-noti-title"> Email Notifications</h5>
                  <p className="mb-2 Em-tx">
                    {details != "" ? details.description : ""}
                  </p>
                  <ul className="dkg-noti-list-group">
                    <div className="row dkg-noti-list-Row align-items-center">
                      <div className="col-12 col-md-12 dkg-noti-list-Col">
                        <h6 className="dkg-text-dark">System Notifications</h6>
                      </div>
                    </div>
                    <li className="dkg-noti-list">
                      <div className="d-flex align-items-center justify-content-between dkg-sys-noti-text">
                        <span className="dkg-noti-message">{f1}</span>
                        {/* <Switch
                          isDisabled="Yes"
                          id={userInfo.canId}
                          value={systemNo.nsNo1}
                          isChecked={systemNo.nsNo1}
                          fieldname="systemNotification_1"
                        /> */}
                        <div className="dkg-active-btnCon">
                          <button className="dkg-active-btn btn">Activate</button>
                        </div>
                      </div>
                    </li>
                    <li className="dkg-noti-list">
                      <div className="d-flex align-items-center justify-content-between dkg-sys-noti-text">
                        <span className="dkg-noti-message">{f2}</span>
                        {/* <Switch
                          isDisabled="Yes"
                          id={userInfo.canId + "_1"}
                          value={systemNo.nsNo2}
                          isChecked={systemNo.nsNo2}
                          fieldname="systemNotification_2"
                        /> */}
                        <div className="dkg-active-btnCon">
                          <button className="dkg-active-btn btn">Activate</button>
                        </div>
                      </div>
                    </li>
                    <li className="dkg-noti-list">
                      <div className="d-flex align-items-center justify-content-between dkg-sys-noti-text">
                        <span className="dkg-noti-message">{f3}</span>
                        {/* <Switch
                          isDisabled="Yes"
                          id={userInfo.canId + "_2"}
                          value={systemNo.nsNo3}
                          isChecked={systemNo.nsNo3}
                          fieldname="systemNotification_3"
                        /> */}
                        <div className="dkg-active-btnCon">
                          <button className="dkg-active-btn btn">Activate</button>
                        </div>
                      </div>
                    </li>
                    <div className="row dkg-noti-list-Row align-items-center mt-0">
                      <div className="col-12 col-md-12 dkg-noti-list-Col">
                        <h6 className="dkg-text-dark">
                          Job Match Notification
                        </h6>
                      </div>
                    </div>
                    <li className="dkg-noti-list">
                      <div className="d-flex align-items-center justify-content-between">
                        <span className="dkg-noti-message">{f4}</span>
                        <Switch
                          isDisabled="No"
                          handleToggle={handleToggle}
                          id={userInfo.canId + "_3"}
                          value={systemNo.njbNo1}
                          isChecked={systemNo.njbNo1}
                          fieldname="jobNotification_1"
                        />
                      </div>
                    </li>
                    <li className="dkg-noti-list">
                      <div className="d-flex align-items-center justify-content-between">
                        <span className="dkg-noti-message">{f5}</span>
                        <Switch
                          isDisabled="No"
                          handleToggle={handleToggle}
                          id={userInfo.canId + "_4"}
                          value={systemNo.njbNo2}
                          isChecked={systemNo.njbNo2}
                          fieldname="jobNotification_2"
                        />
                      </div>
                    </li>
                    <li className="dkg-noti-list">
                      <div className="d-flex align-items-center justify-content-between">
                        <span className="dkg-noti-message">{f6}</span>
                        <Switch
                          isDisabled="No"
                          handleToggle={handleToggle}
                          id={userInfo.canId + "_5"}
                          value={systemNo.njbNo3}
                          isChecked={systemNo.njbNo3}
                          fieldname="jobNotification_3"
                        />
                      </div>
                    </li>
                    <div className="row dkg-noti-list-Row align-items-center mt-0">
                      <div className="col-12 col-md-12 dkg-noti-list-Col">
                        <h6 className="dkg-text-dark">Job Referral Notifications</h6>
                      </div>
                    </div>
                    <li className="dkg-noti-list">
                      <div className="d-flex align-items-center justify-content-between">
                        <span className="dkg-noti-message">{f7}</span>
                        <Switch
                          isDisabled="No"
                          handleToggle={handleToggle}
                          id={userInfo.canId + "_6"}
                          value={systemNo.njbPNo1}
                          isChecked={systemNo.njbPNo1}
                          fieldname="jobPromotionNotification_1"
                        />
                      </div>
                    </li>
                    <li className="dkg-noti-list">
                      <div className="d-flex align-items-center justify-content-between">
                        <span className="dkg-noti-message">{f8}</span>
                        <Switch
                          isDisabled="No"
                          handleToggle={handleToggle}
                          id={userInfo.canId}
                          value={systemNo.njbPNo2 + "_7"}
                          isChecked={systemNo.njbPNo2}
                          fieldname="jobPromotionNotification_2"
                        />
                      </div>
                    </li>
                    <li className="dkg-noti-list">
                      <div className="d-flex align-items-center justify-content-between">
                        <span className="dkg-noti-message">{f9}</span>
                        <Switch
                          isDisabled="No"
                          handleToggle={handleToggle}
                          id={userInfo.canId + "_8"}
                          value={systemNo.njbPNo3}
                          isChecked={systemNo.njbPNo3}
                          fieldname="jobPromotionNotification_3"
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountNotifiation;
