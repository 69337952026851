import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./my-referals.scss";
import MoreInfoPopover from "./MoreInfoPopover";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { FaFileInvoice, FaEuroSign, FaHandshake } from "react-icons/fa";

import {
  getCMS,
  // updatecontent,
  // clearState,
} from "../../../../../slice/cmsSlice";
// import AddNewReferrals from "./modal/AddNewReferrals";
const MyServices = () => {
  //   const [isAddNewReferral, setIsAddNewReferral] = useState(false);

  //   const showreferralModal = () => {
  //     setIsAddNewReferral(true);
  //   };

  //   const hidereferralModal = () => {
  //     setIsAddNewReferral(false);
  //   };
  //  const { userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { isSuccess, cmsList } = useSelector((state) => state.cmsdata);
  const [details, setDetails] = useState("");
  useEffect(() => {
    dispatch(getCMS({}));
  }, [dispatch]);

  useEffect(() => {
    if (!isSuccess && cmsList.length > 0) {
      setDetails(cmsList[50].description);
    }
  }, [isSuccess]);

  return (
    <>
      <div className="dkg-myreferals-mainCon">
        <div className="dkg-myreferals-header">
          <div className="dkg-myreferals-headerleftCon">
            <div className="dk-mailshotsSearch mr-2">
              <div className="input-group">
                <input
                  className="form-control"
                  placeholder="Search ..."
                  value=""
                />
                <div className="input-group-append">
                  <button className="btn btn-secondary" type="button">
                    <i className="fa fa-search"></i>
                  </button>
                </div>
              </div>
              <div className="dkg-crossicon-Con d-none">
                <i className="fas fa-times-circle" data-type="search text"></i>
              </div>
            </div>
            {/* <div className="dkg-referal-addnew-btnCon">
              <button
                className="dkg-referal-addnew-btn"
                onClick={showreferralModal}
              >
                <i className="fas fa-plus"></i>
              </button>
  </div> */}
          </div>
          <div className="dkg-myreferals-centreCon">
            <div className="dkg-docs-title">
            My DK Services<MoreInfoPopover details={details} />
            </div>
          </div>
          <div className="dkg-myreferals-rightCon">
            <Dropdown className="dkg-referal-status-drpdwn">
              <Dropdown.Toggle
                variant=""
                id=""
                className="dkg-referal-status-drpdwn-toggle"
              >
                INV STATUS
              </Dropdown.Toggle>
              <Dropdown.Menu className="dkg-referal-status-drpdwn-menu">
                <Dropdown.Item
                  href="#"
                  className="dkg-referal-status-item"
                  style={{ backgroundColor: "#3a9469", color: "#fff" }}
                >
                  Open
                  <span className="dkg-lv-statuscounter">4</span>
                </Dropdown.Item>
                <Dropdown.Item
                  href="#"
                  className="dkg-referal-status-item"
                  style={{ backgroundColor: "#dc3545", color: "#fff" }}
                >
                  Closed
                  <span className="dkg-lv-statuscounter">6</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="dkg-document-tableCon table-responsive">
          <table className="dkg-document-table table table-bordered">
            <thead>
              <tr>
                <th>No.</th>
                <th>Inv Status</th>
                <th>Purchase Date</th>
                <th>Name of Service</th>
                <th>Invoice Date</th>
                <th>Inv Number</th>
                <th>View Invoice</th>
                <th>Pay Status</th>
                <th>Pay Date</th>
                <th>Amount</th>
                <th>Pay Receipt</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>
                  <Dropdown className="dkg-referal-status-drpdwn">
                    <Dropdown.Toggle
                      variant=""
                      id=""
                      className="dkg-referal-status-drpdwn-toggle"
                    >
                      <div
                        className="statusDiv"
                        style={{
                          backgroundColor: "#3a9469",
                          color: "#fff",
                          textTransform: "uppercase",
                        }}
                      >
                        Open
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dkg-referal-status-drpdwn-menu">
                      <Dropdown.Item
                        href="#"
                        className="dkg-referal-status-item"
                        style={{ backgroundColor: "#3a9469", color: "#fff" }}
                      >
                        Open
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        className="dkg-referal-status-item"
                        style={{ backgroundColor: "#dc3545", color: "#fff" }}
                      >
                        Closed
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
                <td> – </td>
                <td> – </td>
                <td>  – </td>
                <td>  – </td>
                <td>
                  <div className="dkg-buy-btn-con text-center w-100">
                    <Link to="#" className="btn dkg-buy-btn">
                      View
                      <span className="dkg-invoice-icon">
                        <FaFileInvoice />
                      </span>
                    </Link>
                  </div>
                </td>
                <td> – </td>
                <td>  – </td>
                {/* <td>1200 <FaEuroSign className="ml-1" /></td> */}
                <td> – </td>
                <td>
                  <div className="dkg-buy-btn-con text-center w-100">
                    <Link to="#" className="btn dkg-buy-btn">
                      View
                      <span className="dkg-invoice-icon">
                        <FaEuroSign />
                      </span>
                    </Link>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default MyServices;
