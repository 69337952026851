import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetData, PostData, PutData, addCandidate, DeleteData } from "../../../services";
import { SYSTEM_CONSTANTS } from "../../../constants";

export const getprogress = createAsyncThunk(
    'candidate/getprogress',
    async ({ appid }, thunkAPI) => {
        const res = await GetData(true, '/candidates/applied-progress/' + appid)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updatewithdrawn = createAsyncThunk(
    'candidate/list/withdrawn',
    async ({ id, withdraw_checkbox_1, withdraw_checkbox_2, withdraw_checkbox_3, withdraw_checkbox_4, withdraw_checkbox_5, withdraw_checkbox_6, withdraw_checkbox_7, withdraw_msg, canId }, thunkAPI) => {
        const res = await PutData(true, '/candidates/applications/withdrawnform/' + id, { withdraw_checkbox_1, withdraw_checkbox_2, withdraw_checkbox_3, withdraw_checkbox_4, withdraw_checkbox_5, withdraw_checkbox_6, withdraw_checkbox_7, withdraw_msg, canId })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const summaryFilter = createAsyncThunk(
    'candidate/pipeline/summarycountAll/list',
    async ({ rec_id }, thunkAPI) => {
        const res = await GetData(true, '/candidates/list/pipeline/summaryFilter/' + rec_id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const yearCount = createAsyncThunk(
    'candidate/list/yearData',
    async (thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value/' + SYSTEM_CONSTANTS.DEFAULT_YEAR.yearId)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const apiDppduplicateCheck = createAsyncThunk(
    'candidate/api-ddduplicate-check',
    async ({ name, email, phone }, thunkAPI) => {
        const res = await PostData(true, '/duplicate-check', { name, email, phone })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const apiduplicateCheck = createAsyncThunk(
    'candidate/api-duplicate-check',
    async ({ name, email, phone }, thunkAPI) => {
        const res = await PostData(true, '/duplicate-check', { name, email, phone })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const monthsCount = createAsyncThunk(
    'candidate/list/monthData',
    async (thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value/' + SYSTEM_CONSTANTS.DEFAULT_MONTHS.monthsId)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const countAll = createAsyncThunk(
    'candidate/pipeline/countAll/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/candidates/list/pipeline/count-all')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const countClosedAll = createAsyncThunk(
    'candidate/pipeline/countClosedAll/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/candidates/list/pipeline/count-closed-all')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const allList = createAsyncThunk(
    'candidate/pipeline/all/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/candidates/list/pipeline/all')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const newCvFilterList = createAsyncThunk(
    'candidate/pipeline/new-cv/filter-list',
    async ({ rec_id }, thunkAPI) => {
        const res = await GetData(true, '/candidates/list/pipeline/filter/new-cv/' + rec_id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const newCvList = createAsyncThunk(
    'candidate/pipeline/new-cv/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/candidates/list/pipeline/new-cv')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const jobSpecList = createAsyncThunk(
    'candidate/pipeline/job-spec/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/candidates/list/pipeline/selected')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const callingFilterList = createAsyncThunk(
    'candidate/pipeline/calling/filter-list',
    async ({ rec_id }, thunkAPI) => {
        const res = await GetData(true, '/candidates/list/pipeline/filter/to-process/' + rec_id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const callingList = createAsyncThunk(
    'candidate/pipeline/calling/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/candidates/list/pipeline/to-process')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const intrestedFilterList = createAsyncThunk(
    'candidate/pipeline/intrested/filter-list',
    async ({ rec_id }, thunkAPI) => {
        const res = await GetData(true, '/candidates/list/pipeline/filter/queries/' + rec_id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const intrestedList = createAsyncThunk(
    'candidate/pipeline/intrested/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/candidates/list/pipeline/queries')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const sendoutsFilterList = createAsyncThunk(
    'candidate/pipeline/sendouts/filter-list',
    async ({ rec_id }, thunkAPI) => {
        const res = await GetData(true, '/candidates/list/pipeline/filter/sendout/' + rec_id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const sendoutsList = createAsyncThunk(
    'candidate/pipeline/sendouts/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/candidates/list/pipeline/sendout')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const jobOfferFilterList = createAsyncThunk(
    'candidate/pipeline/jobOffer/filter-list',
    async ({ rec_id }, thunkAPI) => {
        const res = await GetData(true, '/candidates/list/pipeline/filter/job-offer/' + rec_id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const jobOfferList = createAsyncThunk(
    'candidate/pipeline/jobOffer/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/candidates/list/pipeline/job-offer')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const invoiceFilterList = createAsyncThunk(
    'candidate/pipeline/recAdminFilter/list',
    async ({ rec_id }, thunkAPI) => {
        const res = await GetData(true, '/candidates/list/pipeline/filter/invoice/' + rec_id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const recAdminList = createAsyncThunk(
    'candidate/pipeline/recAdmin/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/candidates/list/pipeline/invoice')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const onHoldFilterList = createAsyncThunk(
    'candidate/pipeline/onHold/filter-list',
    async ({ rec_id }, thunkAPI) => {
        const res = await GetData(true, '/candidates/list/pipeline/filter/processing/' + rec_id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const onHoldList = createAsyncThunk(
    'candidate/pipeline/onHold/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/candidates/list/pipeline/processing')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const closedFilterList = createAsyncThunk(
    'candidate/pipeline/closed/filter-list',
    async ({ rec_id }, thunkAPI) => {
        const res = await GetData(true, '/candidates/list/pipeline/filter/closed/' + rec_id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const closedList = createAsyncThunk(
    'candidate/pipeline/closed/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/candidates/list/pipeline/closed')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const dbReadyListFilter = createAsyncThunk(
    'candidate/pipeline/dbReadyFilter/list',
    async ({ rec_id }, thunkAPI) => {
        const res = await GetData(true, '/candidates/list/pipeline/filter/not-reachable/' + rec_id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const dbReadyList = createAsyncThunk(
    'candidate/pipeline/dbReady/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/candidates/list/pipeline/not-reachable')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updateTable = createAsyncThunk(
    'candidate/list/update',
    async ({ id, key, value }, thunkAPI) => {
        const res = await PutData(true, '/candidates/profile/' + id, { key, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updateCandidateProfile = createAsyncThunk(
    'candidate/list/candidates/update',
    async ({ id, key, value }, thunkAPI) => {
        const res = await PutData(true, '/candidates/profile/' + id, { key, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const moveDppCandidateRecords = createAsyncThunk(
    'candidate/move-ddduplicate-records',
    async ({ ids, status }, thunkAPI) => {
        const res = await PostData(true, '/candidates/profile/move-duplicate-records', { ids, status })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const moveDuplicateCandidateRecords = createAsyncThunk(
    'candidate/move-duplicate-records',
    async ({ ids, status }, thunkAPI) => {
        const res = await PostData(true, '/candidates/profile/move-duplicate-records', { ids, status })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const moveCandidateRecords = createAsyncThunk(
    'candidate/move-records',
    async ({ ids, status }, thunkAPI) => {
        const res = await PostData(true, '/candidates/profile/move-records', { ids, status })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const moveDatabaseCandidateRecords = createAsyncThunk(
    'candidate/move-database-records',
    async ({ ids, status, createdAt }, thunkAPI) => {
        const res = await PostData(true, '/candidates/profile/move-database-records', { ids, status, createdAt })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const deleteCandidateRecords = createAsyncThunk(
    'candidate/deleterecords',
    async ({ ids }, thunkAPI) => {
        const res = await PostData(true, '/candidates/profile/delete-records', { ids })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const candidateProfileDetails = createAsyncThunk(
    'candidate/profile/details',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/candidates/profile/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const candidateProfileRecruiterDetails = createAsyncThunk(
    'candidate/profile/rec-details',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/candidates/profile/rec-details/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const candidateProfileJobDetails = createAsyncThunk(
    'candidate/profile/job-details',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/candidates/profile/job-details/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const apiParsing = createAsyncThunk(
    'candidate/add/api-parsing',
    async ({ profileIndicator, flag_type, profileStatus, jobId, resource_team, rec_admin, rec_id, am_id, name, email1, phone1, caseStatus, case_progress, case_stage, category, fileUrl }, thunkAPI) => {
        const res = await PostData(true, '/candidates/profile/api-parsing', { profileIndicator, flag_type, profileStatus, jobId, resource_team, rec_admin, rec_id, am_id, name, email1, phone1, caseStatus, case_progress, case_stage, category, fileUrl })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

// export const addNewCandidate = createAsyncThunk(
//     'candidate/addnew',
//     async ({ category, c_country, c_city, nationality, visaStatus, native1, fluent1, cv_source, fileName }, thunkAPI) => {

//         const res = await addCandidate(true, '/candidates/profile/addnew/', { category, c_country, c_city, nationality, visaStatus, native1, fluent1, cv_source, fileName })
//         if (res.status === 200) {
//             return res.payload
//         } else {
//             return thunkAPI.rejectWithValue();
//         }
//     }

export const addNewCandidate = createAsyncThunk(
    'candidate/addnew',
    async ({ fileName, first_name, last_name, email1 }, thunkAPI) => {
        const res = await addCandidate(true, '/candidates/profile/addnew/', { fileName, first_name, last_name, email1 })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const updateCommentCount = createAsyncThunk(
    'candidate/commentlist/update-comment',
    async ({ id, value, commentAt }, thunkAPI) => {
        const res = await PutData(true, '/candidates/profile/update-comment/' + id, { value, commentAt })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const candidateDatabaseList = createAsyncThunk(
    'candidate/database/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/candidates/list/pipeline/database')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const candidate2021DatabaseCount = createAsyncThunk(
    'candidate/database21Count/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/candidates/list/pipeline/database2021count')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const candidate2020DatabaseCount = createAsyncThunk(
    'candidate/database20Count/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/candidates/list/pipeline/database2020count')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const candidate2019DatabaseCount = createAsyncThunk(
    'candidate/database19Count/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/candidates/list/pipeline/database2019count')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const candidate2018DatabaseCount = createAsyncThunk(
    'candidate/database18Count/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/candidates/list/pipeline/database2018count')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const candidate2017DatabaseCount = createAsyncThunk(
    'candidate/database17Count/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/candidates/list/pipeline/database2017count')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const candidate2016DatabaseCount = createAsyncThunk(
    'candidate/database16Count/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/candidates/list/pipeline/database2016count')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const candidate2022DatabaseList = createAsyncThunk(
    'candidate/database2022/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/candidates/list/pipeline/database2022')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const candidate2021DatabaseList = createAsyncThunk(
    'candidate/database2021/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/candidates/list/pipeline/database2021')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const candidate2020DatabaseList = createAsyncThunk(
    'candidate/database2020/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/candidates/list/pipeline/database2020')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const candidate2019DatabaseList = createAsyncThunk(
    'candidate/database2019/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/candidates/list/pipeline/database2019')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const candidate2018DatabaseList = createAsyncThunk(
    'candidate/database2018/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/candidates/list/pipeline/database2018')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const candidate2017DatabaseList = createAsyncThunk(
    'candidate/database2017/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/candidates/list/pipeline/database2017')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const candidate2016DatabaseList = createAsyncThunk(
    'candidate/database2016/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/candidates/list/pipeline/database2016')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const archiveDataList = createAsyncThunk(
    'candidate/archive/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/candidates/list/pipeline/archive-data-list')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const getEmailLogs = createAsyncThunk(
    'candidate/email/logs',
    async ({ can_id }, thunkAPI) => {
        const res = await GetData(true, '/candidates/profile/email-logs/' + can_id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const getEmailVerificationLogsForEmail1 = createAsyncThunk(
    'candidate/email/verify1',
    async ({ can_id }, thunkAPI) => {
        const res = await GetData(true, '/user/email/list/1/' + can_id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const getEmailVerificationLogsForEmail2 = createAsyncThunk(
    'candidate/email/verify2',
    async ({ can_id }, thunkAPI) => {
        const res = await GetData(true, '/user/email/list/2/' + can_id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const verifyCandidateEmail = createAsyncThunk(
    'candidate/email/verify',
    async (payload, thunkAPI) => {
        const res = await PostData(true, '/user/email/validate', payload)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updateEmailVerifyStatus = createAsyncThunk(
    'candidate/email/change/status',
    async (payload, thunkAPI) => {
        const res = await PostData(true, '/user/email/change/status', payload)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const mailBoxEmailCategory = createAsyncThunk(
    'candidate/list/emailcategory',
    async (thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value/' + SYSTEM_CONSTANTS.MAIL_BOX.categoryId)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const getApplications = createAsyncThunk(
    'candidate/application',
    async ({ can_id }, thunkAPI) => {
        const res = await GetData(true, '/candidates/applications/' + can_id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updateApplication = createAsyncThunk(
    'candidate/list/updateapp',
    async ({ id, key, value, canId }, thunkAPI) => {
        const res = await PutData(true, '/candidates/applications/update/' + id, { key, value, canId })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const addApplication = createAsyncThunk(
    'candidate/addApplication',
    async ({ canId, type }, thunkAPI) => {
        const res = await PostData(true, '/candidates/applications', { canId, type })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const deleteCanApplication = createAsyncThunk(
    'candidate/application/delete',
    async ({ id }, thunkAPI) => {
        const res = await DeleteData(true, '/candidates/applications/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

const listSlice = createSlice({
    name: 'candidateList',
    initialState: {
        dataList: [],
        tempDataList: [],
        totalCount: [],
        totalClosedCount: [],
        defaultYearList: [],
        emailCategoryList: [],
        applicationList: [],
        defaultMonthList: [],
        candidateDataList: [],
        archiveList: [],
        database2021count: [],
        database2020count: [],
        database2019count: [],
        database2018count: [],
        database2017count: [],
        database2016count: [],
        apiDuplicateList: [],
        apiDppDuplicateList: [],
        profileDetails: {},
        profileRecDetails: {},
        profileJobDetails: {},
        isLoading: false,
        isDuplicateLoading: false,
        isProfileLoading: false,
        isProfileRecLoading: false,
        isUpdate: false,
        isAppUpdate: false,
        iscommentUpdate: false,
        isProfileUpdate: false,
        isProfileProjectUpdate: false,
        isdataUpdate: false,
        isError: false,
        isSuccess: false,
        isMoved: false,
        isECategory: false,
        isApplication: false,
        isApiduplicateMoved: false,
        isduplicateMoved: false,
        isdduplicateMoved: false,
        isAPIParseSuccess: false,
        isManualParseSuccess: false,
        message: '',
        isDelete: false,
        isAppDelete: false,
        emailLogs: [],
        isLoadingEmailLogs: false,
        verifyEmail1: [],
        isVerifyEmail1getSuccess: false,
        isVerifyEmail1Error: false,
        isLoadingEmail1Verification: false,
        verifyEmail2: [],
        isVerifyEmail2getSuccess: false,
        isVerifyEmail2Error: false,
        isLoadingEmail2Verification: false,
        isLoadingVerifyBtn: false,
        isVerifyEmailError: false,
        isVerifyEmailSucces: false,
        isApplictionAdd: false,
        isWithdrwan: false,
        isProgress: false,
        progressList: []

    },
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isWithdrwan = false;
            state.isApplictionAdd = false;
            state.isProfileLoading = false;
            state.isAppUpdate = false;
            state.isProfileRecLoading = false;
            state.isDuplicateLoading = false;
            state.isLoading = false;
            state.isProfileUpdate = false;
            state.isProfileProjectUpdate = false;
            state.isdataUpdate = false;
            state.isUpdate = false;
            state.iscommentUpdate = false;
            state.isSuccess = false;
            state.isMoved = false;
            state.isECategory = false;
            state.isApplication = false;
            state.isApiduplicateMoved = false;
            state.isduplicateMoved = false;
            state.isdduplicateMoved = false;
            state.isDelete = false;
            state.isAppDelete = false;
            state.isAPIParseSuccess = false;
            state.isManualParseSuccess = false;
            state.isProgress = false;
            state.message = '';
        },
        updateProfileDetails: (state, action) => {
            const key = action.payload.key
            const value = action.payload.value
            state.profileDetails = ({ ...state.profileDetails, [key]: value })
        },
    },
    extraReducers: (builder) => {

        builder.addCase(
            getprogress.pending, (state) => {
                state.isProgress = false;
            });
        builder.addCase(
            getprogress.fulfilled, (state, { payload }) => {
                state.isProgress = true;
                state.progressList = payload.result
            });
        builder.addCase(
            getprogress.rejected, (state, action) => {
                state.isProgress = true;
                state.isError = true;
                state.message = 'Something went wrong';
            });


        builder.addCase(
            deleteCanApplication.pending, (state) => {
                state.isAppDelete = false;
            });
        builder.addCase(
            deleteCanApplication.fulfilled, (state, { payload }) => {
                state.isAppDelete = true;
            });
        builder.addCase(
            deleteCanApplication.rejected, (state, action) => {
                state.isError = true;
                state.isAppDelete = false;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            candidate2021DatabaseCount.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            candidate2021DatabaseCount.fulfilled, (state, { payload }) => {
                state.database2021count = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            candidate2021DatabaseCount.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            candidate2020DatabaseCount.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            candidate2020DatabaseCount.fulfilled, (state, { payload }) => {
                state.database2020count = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            candidate2020DatabaseCount.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            candidate2019DatabaseCount.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            candidate2019DatabaseCount.fulfilled, (state, { payload }) => {
                state.database2019count = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            candidate2019DatabaseCount.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            candidate2018DatabaseCount.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            candidate2018DatabaseCount.fulfilled, (state, { payload }) => {
                state.database2018count = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            candidate2018DatabaseCount.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            candidate2017DatabaseCount.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            candidate2017DatabaseCount.fulfilled, (state, { payload }) => {
                state.database2017count = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            candidate2017DatabaseCount.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            candidate2016DatabaseCount.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            candidate2016DatabaseCount.fulfilled, (state, { payload }) => {
                state.database2016count = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            candidate2016DatabaseCount.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });


        builder.addCase(
            candidateDatabaseList.pending, (state) => {
                state.isLoading = true;
                state.isProfileUpdate = false;
            });
        builder.addCase(
            candidateDatabaseList.fulfilled, (state, { payload }) => {
                state.candidateDataList = payload.result
                state.isLoading = false;
                state.isProfileUpdate = false;
            });
        builder.addCase(
            candidateDatabaseList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isProfileUpdate = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            candidate2022DatabaseList.pending, (state) => {
                state.isLoading = true;
                state.candidate2022DatabaseList = true;
            });
        builder.addCase(
            candidate2022DatabaseList.fulfilled, (state, { payload }) => {
                state.candidateDataList = payload.result
                state.isLoading = false;
                state.candidate2022DatabaseList = false;
            });
        builder.addCase(
            candidate2022DatabaseList.rejected, (state, action) => {
                state.isLoading = false;
                state.candidate2022DatabaseList = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            candidate2021DatabaseList.pending, (state) => {
                state.isLoading = true;
                state.candidate2021DatabaseList = true;
            });
        builder.addCase(
            candidate2021DatabaseList.fulfilled, (state, { payload }) => {
                state.candidateDataList = payload.result
                state.isLoading = false;
                state.candidate2021DatabaseList = false;
            });
        builder.addCase(
            candidate2021DatabaseList.rejected, (state, action) => {
                state.isLoading = false;
                state.candidate2021DatabaseList = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            candidate2020DatabaseList.pending, (state) => {
                state.isLoading = true;
                state.isProfileUpdate = true;
            });
        builder.addCase(
            candidate2020DatabaseList.fulfilled, (state, { payload }) => {
                state.candidateDataList = payload.result
                state.isLoading = false;
                state.isProfileUpdate = false;
            });
        builder.addCase(
            candidate2020DatabaseList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isProfileUpdate = true;
                state.message = 'Something went wrong';
            });


        builder.addCase(
            candidate2019DatabaseList.pending, (state) => {
                state.isLoading = true;
                state.isProfileUpdate = true;
            });
        builder.addCase(
            candidate2019DatabaseList.fulfilled, (state, { payload }) => {
                state.candidateDataList = payload.result
                state.isLoading = false;
                state.isProfileUpdate = false;
            });
        builder.addCase(
            candidate2019DatabaseList.rejected, (state, action) => {
                state.isLoading = false;
                state.isProfileUpdate = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            candidate2018DatabaseList.pending, (state) => {
                state.isLoading = true;
                state.isProfileUpdate = true;
            });
        builder.addCase(
            candidate2018DatabaseList.fulfilled, (state, { payload }) => {
                state.candidateDataList = payload.result
                state.isLoading = false;
                state.isProfileUpdate = false;
            });
        builder.addCase(
            candidate2018DatabaseList.rejected, (state, action) => {
                state.isLoading = false;
                state.isProfileUpdate = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            candidate2017DatabaseList.pending, (state) => {
                state.isLoading = true;
                state.isProfileUpdate = true;
            });
        builder.addCase(
            candidate2017DatabaseList.fulfilled, (state, { payload }) => {
                state.candidateDataList = payload.result
                state.isLoading = false;
                state.isProfileUpdate = false;
            });
        builder.addCase(
            candidate2017DatabaseList.rejected, (state, action) => {
                state.isLoading = false;
                state.isProfileUpdate = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            candidate2016DatabaseList.pending, (state) => {
                state.isLoading = true;
                state.isProfileUpdate = true;
            });
        builder.addCase(
            candidate2016DatabaseList.fulfilled, (state, { payload }) => {
                state.candidateDataList = payload.result
                state.isLoading = false;
                state.isProfileUpdate = false;
            });
        builder.addCase(
            candidate2016DatabaseList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isProfileUpdate = true;
                state.message = 'Something went wrong';
            });


        builder.addCase(
            archiveDataList.pending, (state) => {
                state.isLoading = true;
                state.isProfileUpdate = true;
            });
        builder.addCase(
            archiveDataList.fulfilled, (state, { payload }) => {
                state.archiveList = payload.result
                state.isLoading = false;
                state.isProfileUpdate = false;
            });
        builder.addCase(
            archiveDataList.rejected, (state, action) => {
                state.isLoading = false;
                state.isProfileUpdate = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            countClosedAll.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            countClosedAll.fulfilled, (state, { payload }) => {
                state.totalClosedCount = payload.result
            });
        builder.addCase(
            countClosedAll.rejected, (state, action) => {
                state.isMoved = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            countAll.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            countAll.fulfilled, (state, { payload }) => {
                state.totalCount = payload.result
            });
        builder.addCase(
            countAll.rejected, (state, action) => {
                state.isMoved = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            mailBoxEmailCategory.pending, (state) => {
                state.isECategory = false;
            });
        builder.addCase(
            mailBoxEmailCategory.fulfilled, (state, { payload }) => {
                state.isECategory = true;
                state.emailCategoryList = payload.result
            });
        builder.addCase(
            mailBoxEmailCategory.rejected, (state, action) => {
                state.isECategory = true;
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            getApplications.pending, (state) => {
                state.isApplication = false;
            });
        builder.addCase(
            getApplications.fulfilled, (state, { payload }) => {
                state.isApplication = true;
                state.applicationList = payload.result
            });
        builder.addCase(
            getApplications.rejected, (state, action) => {
                state.isApplication = true;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            yearCount.pending, (state) => {
            });
        builder.addCase(
            yearCount.fulfilled, (state, { payload }) => {
                state.defaultYearList = payload.result
            });
        builder.addCase(
            yearCount.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            monthsCount.pending, (state) => {
            });
        builder.addCase(
            monthsCount.fulfilled, (state, { payload }) => {
                state.defaultMonthList = payload.result
            });
        builder.addCase(
            monthsCount.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            summaryFilter.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            summaryFilter.fulfilled, (state, { payload }) => {
                state.totalCount = payload.result
            });
        builder.addCase(
            summaryFilter.rejected, (state, action) => {
                state.isMoved = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            updatewithdrawn.pending, (state) => {
                state.isWithdrwan = false
            });
        builder.addCase(
            updatewithdrawn.fulfilled, (state, { payload }) => {
                state.isWithdrwan = true
                state.applicationList = payload.result;
            });
        builder.addCase(
            updatewithdrawn.rejected, (state, action) => {
                state.isError = true;
                state.isWithdrwan = false
            });

        builder.addCase(
            allList.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            allList.fulfilled, (state, { payload }) => {
                state.dataList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            allList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            newCvFilterList.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            newCvFilterList.fulfilled, (state, { payload }) => {
                state.dataList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            newCvFilterList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            newCvList.pending, (state) => {
                state.isLoading = true;
                state.isUpdate = true;
                state.isdataUpdate = true;
            });
        builder.addCase(
            newCvList.fulfilled, (state, { payload }) => {
                state.dataList = payload.result
                state.isLoading = false;
                state.isUpdate = false;
                state.isdataUpdate = false;
            });
        builder.addCase(
            newCvList.rejected, (state, action) => {
                state.isLoading = false;
                state.isUpdate = false;
                state.isdataUpdate = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            jobSpecList.pending, (state) => {
                state.isLoading = true;
                state.isProfileUpdate = true;
            });
        builder.addCase(
            jobSpecList.fulfilled, (state, { payload }) => {
                state.dataList = payload.result
                state.isLoading = false;
                state.isProfileUpdate = false;
            });
        builder.addCase(
            jobSpecList.rejected, (state, action) => {
                state.isLoading = false;
                state.isProfileUpdate = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            callingFilterList.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            callingFilterList.fulfilled, (state, { payload }) => {
                state.dataList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            callingFilterList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            callingList.pending, (state) => {
                state.isLoading = true;
                state.isProfileUpdate = true;
            });
        builder.addCase(
            callingList.fulfilled, (state, { payload }) => {
                state.dataList = payload.result
                state.isLoading = false;
                state.isProfileUpdate = false;
            });
        builder.addCase(
            callingList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isProfileUpdate = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            intrestedFilterList.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            intrestedFilterList.fulfilled, (state, { payload }) => {
                state.dataList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            intrestedFilterList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            intrestedList.pending, (state) => {
                state.isLoading = true;
                state.isProfileUpdate = true;
            });
        builder.addCase(
            intrestedList.fulfilled, (state, { payload }) => {
                state.dataList = payload.result
                state.isLoading = false;
                state.isProfileUpdate = false;
            });
        builder.addCase(
            intrestedList.rejected, (state, action) => {
                state.isLoading = false;
                state.isProfileUpdate = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            sendoutsFilterList.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            sendoutsFilterList.fulfilled, (state, { payload }) => {
                state.dataList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            sendoutsFilterList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            sendoutsList.pending, (state) => {
                state.isLoading = true;
                state.isProfileUpdate = true;
            });
        builder.addCase(
            sendoutsList.fulfilled, (state, { payload }) => {
                state.dataList = payload.result
                state.isLoading = false;
                state.isProfileUpdate = false;
            });
        builder.addCase(
            sendoutsList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isProfileUpdate = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            jobOfferFilterList.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            jobOfferFilterList.fulfilled, (state, { payload }) => {
                state.dataList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            jobOfferFilterList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            jobOfferList.pending, (state) => {
                state.isLoading = true;
                state.isProfileUpdate = true;
            });
        builder.addCase(
            jobOfferList.fulfilled, (state, { payload }) => {
                state.dataList = payload.result
                state.isLoading = false;
                state.isProfileUpdate = false;
            });
        builder.addCase(
            jobOfferList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isProfileUpdate = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            invoiceFilterList.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            invoiceFilterList.fulfilled, (state, { payload }) => {
                state.dataList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            invoiceFilterList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            recAdminList.pending, (state) => {
                state.isLoading = true;
                state.isProfileUpdate = true;
            });
        builder.addCase(
            recAdminList.fulfilled, (state, { payload }) => {
                state.dataList = payload.result
                state.isLoading = false;
                state.isProfileUpdate = false;
            });
        builder.addCase(
            recAdminList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isProfileUpdate = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            onHoldFilterList.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            onHoldFilterList.fulfilled, (state, { payload }) => {
                state.dataList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            onHoldFilterList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            onHoldList.pending, (state) => {
                state.isLoading = true;
                state.isProfileUpdate = true;
            });
        builder.addCase(
            onHoldList.fulfilled, (state, { payload }) => {
                state.dataList = payload.result
                state.isLoading = false;
                state.isProfileUpdate = false;
            });
        builder.addCase(
            onHoldList.rejected, (state, action) => {
                state.isLoading = false;
                state.isProfileUpdate = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            closedFilterList.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            closedFilterList.fulfilled, (state, { payload }) => {
                state.dataList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            closedFilterList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            closedList.pending, (state) => {
                state.isLoading = true;
                state.isProfileUpdate = true;
            });
        builder.addCase(
            closedList.fulfilled, (state, { payload }) => {
                state.dataList = payload.result
                state.isLoading = false;
                state.isProfileUpdate = false;
            });
        builder.addCase(
            closedList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isProfileUpdate = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            dbReadyList.pending, (state) => {
                state.isLoading = true;
                state.isProfileUpdate = true;
            });
        builder.addCase(
            dbReadyList.fulfilled, (state, { payload }) => {
                state.dataList = payload.result
                state.isLoading = false;
                state.isProfileUpdate = false;
            });
        builder.addCase(
            dbReadyList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isProfileUpdate = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            dbReadyListFilter.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            dbReadyListFilter.fulfilled, (state, { payload }) => {
                state.dataList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            dbReadyListFilter.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            updateCandidateProfile.pending, (state) => {
            });
        builder.addCase(
            updateCandidateProfile.fulfilled, (state, { payload }) => {
                // let mainArray = state.dataList;
                state.isProfileUpdate = true
            });
        builder.addCase(
            updateCandidateProfile.rejected, (state, action) => {
                state.isError = true;
            });

        builder.addCase(
            updateTable.pending, (state) => {
            });
        builder.addCase(
            updateTable.fulfilled, (state, { payload }) => {
                let mainArray = state.dataList;
                // let finalResult = mainArray.map((r) => {
                //     if (r.id === payload.result.id) {
                //         return (payload.result)
                //     } else {
                //         return r
                //     }
                // })
                state.isUpdate = true
                // state.dataList = finalResult;
            });
        builder.addCase(
            updateTable.rejected, (state, action) => {
                state.isError = true;
                // state.message = action.error.message;
            });

        builder.addCase(
            updateApplication.pending, (state) => {
                state.isAppUpdate = false
                state.isApplication = false
            });
        builder.addCase(
            updateApplication.fulfilled, (state, { payload }) => {
                state.isAppUpdate = true
                state.isApplication = true
                state.applicationList = payload.result;
            });
        builder.addCase(
            updateApplication.rejected, (state, action) => {
                state.isError = true;
                state.isAppUpdate = false
                state.isApplication = false
            });

        builder.addCase(
            deleteCandidateRecords.pending, (state) => {
                state.isDelete = false
            });
        builder.addCase(
            deleteCandidateRecords.fulfilled, (state, { payload }) => {
                // let mainArray = state.dataList;
                // let finalResult = mainArray.map((r) => {
                //     if (r.id === payload.result.id) {
                //         return (payload.result)
                //     } else {
                //         return r
                //     }
                // })

                //state.dataList = finalResult;
                state.isDelete = true
            });
        builder.addCase(
            deleteCandidateRecords.rejected, (state, action) => {
                state.isError = true;
            });

        builder.addCase(
            apiduplicateCheck.pending, (state) => {
                state.isDuplicateLoading = false;
            });
        builder.addCase(
            apiduplicateCheck.fulfilled, (state, { payload }) => {
                state.isDuplicateLoading = true;
                state.apiDuplicateList = payload.result
            });
        builder.addCase(
            apiduplicateCheck.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            apiDppduplicateCheck.pending, (state) => {
                state.isduplicateMoved = false;
            });
        builder.addCase(
            apiDppduplicateCheck.fulfilled, (state, { payload }) => {
                state.isduplicateMoved = true;
                state.apiDppDuplicateList = payload.result

            });
        builder.addCase(
            apiDppduplicateCheck.rejected, (state, action) => {
                state.isError = true;
                state.isduplicateMoved = false;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            moveDppCandidateRecords.pending, (state) => {
                state.isdduplicateMoved = false;
                state.isduplicateMoved = false;
            });
        builder.addCase(
            moveDppCandidateRecords.fulfilled, (state, action) => {
                state.isdduplicateMoved = true;
                state.isduplicateMoved = true;
                state.message = 'Records moved successfully'
            });
        builder.addCase(
            moveDppCandidateRecords.rejected, (state, action) => {
                state.isdduplicateMoved = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            moveDuplicateCandidateRecords.pending, (state) => {
            });
        builder.addCase(
            moveDuplicateCandidateRecords.fulfilled, (state, action) => {
                state.isApiduplicateMoved = true;
                state.message = 'Records moved successfully'
            });
        builder.addCase(
            moveDuplicateCandidateRecords.rejected, (state, action) => {
                state.isApiduplicateMoved = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });


        builder.addCase(
            moveCandidateRecords.pending, (state) => {
            });
        builder.addCase(
            moveCandidateRecords.fulfilled, (state, action) => {
                state.isMoved = true;
                state.message = 'Records moved successfully'
            });
        builder.addCase(
            moveCandidateRecords.rejected, (state, action) => {
                state.isMoved = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            moveDatabaseCandidateRecords.pending, (state) => {
            });
        builder.addCase(
            moveDatabaseCandidateRecords.fulfilled, (state, action) => {
                state.isMoved = true;
                state.message = 'Records moved successfully'
            });
        builder.addCase(
            moveDatabaseCandidateRecords.rejected, (state, action) => {
                state.isMoved = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            candidateProfileDetails.pending, (state) => {
                state.isProfileLoading = true;
                state.isProfileProjectUpdate = false;
                state.iscommentUpdate = false;
                state.isCommentDelete = false;
            });
        builder.addCase(
            candidateProfileDetails.fulfilled, (state, { payload }) => {
                state.isProfileLoading = false;
                state.profileDetails = payload.result
                state.isProfileProjectUpdate = true;
                // state.dataList = payload.result
            });
        builder.addCase(
            candidateProfileDetails.rejected, (state, action) => {
                state.isProfileLoading = false;
                state.isProfileProjectUpdate = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            candidateProfileRecruiterDetails.pending, (state) => {
                state.isProfileRecLoading = true;
            });
        builder.addCase(
            candidateProfileRecruiterDetails.fulfilled, (state, { payload }) => {
                state.isProfileRecLoading = false;
                state.profileRecDetails = payload.result
            });
        builder.addCase(
            candidateProfileRecruiterDetails.rejected, (state, action) => {
                state.isProfileRecLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            candidateProfileJobDetails.pending, (state) => {
                state.isProfileJobLoading = true;
            });
        builder.addCase(
            candidateProfileJobDetails.fulfilled, (state, { payload }) => {
                state.isProfileJobLoading = false;
                state.profileJobDetails = payload.result
            });
        builder.addCase(
            candidateProfileJobDetails.rejected, (state, action) => {
                state.isProfileJobLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            apiParsing.pending, (state) => {
            });
        builder.addCase(
            apiParsing.fulfilled, (state, { payload }) => {
                if (payload.message == "Duplicate Records Found") {
                    state.isError = true;
                    state.message = payload.message;
                } else {
                    state.dataList.unshift(payload.result)
                    state.isAPIParseSuccess = true;
                }
            });
        builder.addCase(
            apiParsing.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.isAPIParseSuccess = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            addNewCandidate.pending, (state) => {
                state.isLoading = true;
                state.isSuccess = false;
            });
        builder.addCase(
            addNewCandidate.fulfilled, (state, { payload }) => {
                state.dataList.unshift(payload.result)
                state.message = payload.message;
                state.isSuccess = payload.status;
                state.isLoading = false;
                state.isManualParseSuccess = true;
            });
        builder.addCase(
            addNewCandidate.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            updateCommentCount.pending, (state) => {
                state.isLoading = false;
                state.iscommentUpdate = false;
            });
        builder.addCase(
            updateCommentCount.fulfilled, (state, action) => {
                let mainArray = state.dataList;
                state.isLoading = true;
                let finalResult = mainArray.map((r) => {

                    if (r.canId === action.meta.arg.id) {
                        return ({ ...r, commentCount: action.meta.arg.value, commentAt: action.meta.arg.commentAt })
                    } else {
                        return r
                    }
                })

                state.iscommentUpdate = true
                state.dataList = finalResult
            });
        builder.addCase(
            updateCommentCount.rejected, (state, action) => {
                state.isError = true;
                // state.message = action.error.message;
            });


        builder.addCase(
            getEmailLogs.pending, (state) => {
                state.isLoadingEmailLogs = true;
            });
        builder.addCase(
            getEmailLogs.fulfilled, (state, { payload }) => {
                state.emailLogs = payload.result
                state.isLoadingEmailLogs = false;
            });
        builder.addCase(
            getEmailLogs.rejected, (state, action) => {
                state.isLoadingEmailLogs = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            getEmailVerificationLogsForEmail1.pending, (state) => {
                state.isLoadingEmail1Verification = true;
            });
        builder.addCase(
            getEmailVerificationLogsForEmail1.fulfilled, (state, { payload }) => {
                state.verifyEmail1 = payload.result
                state.isLoadingEmail1Verification = false;
            });
        builder.addCase(
            getEmailVerificationLogsForEmail1.rejected, (state, action) => {
                state.isLoadingEmail1Verification = false;
                state.isVerifyEmail2Error = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            getEmailVerificationLogsForEmail2.pending, (state) => {
                state.isLoadingEmail2Verification = true;
            });
        builder.addCase(
            getEmailVerificationLogsForEmail2.fulfilled, (state, { payload }) => {
                state.verifyEmail2 = payload.result
                state.isLoadingEmail2Verification = false;
            });
        builder.addCase(
            getEmailVerificationLogsForEmail2.rejected, (state, action) => {
                state.isLoadingEmail2Verification = false;
                state.isVerifyEmail2Error = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            verifyCandidateEmail.pending, (state) => {
                state.isLoadingVerifyBtn = true;
                state.isVerifyEmailSucces = false;
                state.isVerifyEmailError = false;
            });
        builder.addCase(
            verifyCandidateEmail.fulfilled, (state, { payload }) => {
                state.isVerifyEmailSucces = true;
                state.isLoadingVerifyBtn = false;
                state.message = payload.message;
                state.isVerifyEmailError = false;
            });
        builder.addCase(
            verifyCandidateEmail.rejected, (state, action) => {
                state.isVerifyEmailSucces = false;
                state.isLoadingVerifyBtn = false;
                state.isVerifyEmailError = true;
                state.message = "Something went wrong";
            });
        builder.addCase(
            updateEmailVerifyStatus.pending, (state) => {
                state.isLoadingVerifyBtn = true;
                state.isVerifyEmailSucces = false;
                state.isVerifyEmailError = false;
            });
        builder.addCase(
            updateEmailVerifyStatus.fulfilled, (state, { payload }) => {
                state.isVerifyEmailSucces = true;
                state.isLoadingVerifyBtn = false;
                state.message = payload.message;
                state.isVerifyEmailError = false;
            });
        builder.addCase(
            updateEmailVerifyStatus.rejected, (state, action) => {
                state.isVerifyEmailSucces = false;
                state.isLoadingVerifyBtn = false;
                state.isVerifyEmailError = true;
                state.message = "Something went wrong";
            });

        builder.addCase(
            addApplication.pending, (state) => {
                state.isApplictionAdd = false;
                state.isApplication = false;
            });
        builder.addCase(
            addApplication.fulfilled, (state, { payload }) => {
                state.isApplictionAdd = true;
                state.isApplication = true;
                state.applicationList = payload.result

            });
        builder.addCase(
            addApplication.rejected, (state, action) => {
                state.isError = true;
                state.isApplictionAdd = false;
                state.isApplication = false;
                state.message = 'Something went wrong';
            });
    }
});
// function isEmpty(obj) {
//     for (var i in obj) { return false; }
//     return true;
// }
export const { clearState, updateProfileDetails, updateRa, updateRc, updateAm, updateOtherdata } = listSlice.actions;
export default listSlice.reducer;