import React from 'react';
import  './consult-full-detail.scss'


const ConsultsDetails = () => {
  return (
    <>
        <div className="dkg-consults-mainpageCon container-fluid">
            <div className="dkg-consults-container">
                <div className="row dkg-consults-mainRow">
                    <div className="col-12 dkg-consults-mainCol"></div>
                </div>
            </div>
        </div>
    </>
  )
}

export default ConsultsDetails