import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Tab, Nav, Dropdown } from "react-bootstrap";
// import "./jobspec-company.scss";
// import "../../../elements/header/modal/candidateprescreen/inustructions/pt-questions.scss";
import {
  showSuccess,
  showError,
  clearMessage,
} from "../../../../../utils/messages/messageSlice";
import {
  getCompanyFunction,
  clearState,
} from "../../../../../../slice/clientCompany/clientCompanySlice";
//import { allJobsSpecCompnay } from "../../../../../../../slice/jobSpec/jobSpecSlice";

import CompanyTab from "./CompanyTab";
//import ProjectTab from "./ProjectTab";
import RoletypeTab from "./RoletypeTab";
import AboutusTab from "./AboutusTab";
import VideoTab from "./VideoTab";
import AlbumsTab from "./AlbumsTab";
import MediaTab from "./MediaTab";
import LocationTab from "./LocationTab";
import "./jobspec-company.scss";

const DkgClientCompaines = ({ client_idd, jobId }) => {
  const dispatch = useDispatch();
  const { allCompanyList, isjobLoading, companyList } = useSelector(
    (state) => state.jobsSpec
  );

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(getCompanyFunction({}));
        //await dispatch(allJobsSpecCompnay({}));
      } catch (err) {
        dispatch(showError({ msg: "Somthings went wrong" }));
      }
    }
    fetchData();
  }, [dispatch]);

  const [eventKey, SetEventKey] = useState("dkg-company-info");

  const sepratedata = allCompanyList.filter((data) => data.jobsId === jobId);
  const [selectedItem, setSelectedItem] = useState("Overview");
  const items = ['Overview' , 'Company', 'Services' , 'Videos' , 'Albums' , 'Media'];

  const handleSelect = (item) => {
    setSelectedItem(item);
  };
  const renderComponent = () => {
    switch (selectedItem) {
      case 'Overview':
        return <>
          <div className="dk-ClentViewTabPane  summaryTabPane dkg-new-jobdet-tbpane-234 dkg-jobspec-mo-tabpane">
            <CompanyTab
              companyInfo={
                sepratedata.length > 0
                  ? sepratedata[0].companyJobSp
                  : []
              }
            />
          </div>   
        </>;    
      case 'Company':
        return <>
          <div className="dk-ClentViewTabPane  summaryTabPane dkg-new-jobdet-tbpane-234 dkg-jobspec-mo-tabpane">
            <AboutusTab
              companyAboutus={
                sepratedata.length > 0
                  ? sepratedata[0].companyJobSp
                  : []
              }
            />
          </div> 
        </>;    
      case 'Services':
        return <>
          <div className="dk-ClentViewTabPane  summaryTabPane dkg-new-jobdet-tbpane-234 dkg-jobspec-mo-tabpane">
            <RoletypeTab
              companyService={
                sepratedata.length > 0
                  ? sepratedata[0].companyJobSp
                  : []
              }
            />
          </div> 
        </>;    
      case 'Videos':
        return <>
          <div className="dk-ClentViewTabPane  summaryTabPane dkg-new-jobdet-tbpane-234 dkg-jobspec-mo-tabpane">
            <VideoTab
              compVideo={
                sepratedata.length > 0
                  ? sepratedata[0].companyVideoJobSp
                  : []
              }
            />
          </div>  
        </>;    
      case 'Albums':
        return <>
          <div className="dk-ClentViewTabPane  summaryTabPane dkg-new-jobdet-tbpane-234 dkg-jobspec-mo-tabpane">
            <AlbumsTab
              compAlbum={
                sepratedata.length > 0
                  ? sepratedata[0].companyAlbumsJobSp
                  : []
              }
            />
          </div>  
        </>;    
      case 'Media':
        return <>
          <div className="dk-ClentViewTabPane  summaryTabPane dkg-new-jobdet-tbpane-234 dkg-jobspec-mo-tabpane">
            <MediaTab
              companySocialMedia={
                sepratedata.length > 0
                  ? sepratedata[0].companyJobSp
                  : []
              }
            />
          </div> 
        </>;    
      default:
        return null;
    }
  };
  return (
    <>
      <div className="dkg-jobspec-company-mainCon dkg-jobspec-modal-company-mainCon" style={{ marginTop: "20px", height: "calc(100vh - 92px)" }}>
        <div className="dkg-cand-jobspec-moCon dkg-cand-js-company-moCon">
          <div className="dkg-jobspec-header-centerCon">
            <h2 className="dkg-cand-categoty-title w-100 text-center" style={{fontSize: "1.25rem" , fontWeight: "600"}}> {sepratedata.length > 0 ? sepratedata[0].clientName : ""}</h2>
            <div className="dkg-jobspec-DropdownCon">
              <Dropdown className='dkg-jobspec-Dropdown'>
                <Dropdown.Toggle variant="" className='dkg-jobspec-Dropdown-toggle'>
                  {selectedItem}
                </Dropdown.Toggle>
                <Dropdown.Menu className='dkg-jobspec-Dropdown-Menu w-100'  aria-labelledby="dropdownMenuButton">
                    {items.map((item, index) => (
                      <Dropdown.Item href="#"
                        key={index}
                        className={item === selectedItem ? 'selected-item dropdown-item' : 'dropdown-item'}
                        onClick={() => handleSelect(item)}>
                          {item}
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          {renderComponent()}
        </div>
        <div className="dkg-jobspec-company-header dkg-jobspec-mo-cmpny-hdr">
          <div className="dkg-jobpsec-DropdownCon">
            <div className="dkg-jobpsec-page-title">
              {sepratedata.length > 0 ? sepratedata[0].clientName : ""}
            </div>
          </div>
        </div>
        {sepratedata.length > 0 ? (
          <>
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey="jobpsec-company"
            >
              <div className="row dkg-jobspec-company-mainRow dkg-mo-company-mainRow">
                <div className="col-2 dkg-jobspec-company-sideMenu">
                  <Nav
                    variant=""
                    className="dkg-jobspec-company-navpills flex-column"
                    onSelect={(selectedKey) => SetEventKey(selectedKey)}
                  >
                    <Nav.Item className="dkg-jobspec-company-navitem">
                      <Nav.Link
                        eventKey="jobpsec-company"
                        className="dkg-jobspec-company-navlink"
                      >
                        Overview
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="dkg-jobspec-company-navitem d-none">
                      <Nav.Link
                        eventKey="jobpsec-location"
                        className="dkg-jobspec-company-navlink"
                      >
                        Location 
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="dkg-jobspec-company-navitem">
                      <Nav.Link
                        eventKey="jobpsec-aboutus"
                        className="dkg-jobspec-company-navlink"
                      >
                        Company
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="dkg-jobspec-company-navitem">
                      <Nav.Link
                        eventKey="jobpsec-services"
                        className="dkg-jobspec-company-navlink"
                      >
                        Services
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="dkg-jobspec-company-navitem">
                      <Nav.Link
                        eventKey="jobpsec-videos"
                        className="dkg-jobspec-company-navlink"
                      >
                        Videos
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="dkg-jobspec-company-navitem">
                      <Nav.Link
                        eventKey="jobpsec-images"
                        className="dkg-jobspec-company-navlink"
                      >
                        Albums
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="dkg-jobspec-company-navitem">
                      <Nav.Link
                        eventKey="jobpsec-media"
                        className="dkg-jobspec-company-navlink"
                      >
                        Media
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <div className="dkg-jobspec-rightCon col-md-10">
                  <Tab.Content className="dkg-jobspec-tabcontent">
                    <Tab.Pane
                      eventKey="jobpsec-company"
                      className="dkg-jobspec-tabpane"
                    >
                      <CompanyTab
                        companyInfo={
                          sepratedata.length > 0
                            ? sepratedata[0].companyJobSp
                            : []
                        }
                      />
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="jobpsec-location"
                      className="dkg-jobspec-tabpane"
                    >
                      <LocationTab />
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="jobpsec-aboutus"
                      className="dkg-jobspec-tabpane"
                    >
                      <AboutusTab
                        companyAboutus={
                          sepratedata.length > 0
                            ? sepratedata[0].companyJobSp
                            : []
                        }
                      />
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="jobpsec-services"
                      className="dkg-jobspec-tabpane"
                    >
                      <RoletypeTab
                        companyService={
                          sepratedata.length > 0
                            ? sepratedata[0].companyJobSp
                            : []
                        }
                      />
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="jobpsec-videos"
                      className="dkg-jobspec-tabpane"
                    >
                      <VideoTab
                        compVideo={
                          sepratedata.length > 0
                            ? sepratedata[0].companyVideoJobSp
                            : []
                        }
                      />
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="jobpsec-images"
                      className="dkg-jobspec-tabpane"
                    >
                      <AlbumsTab
                        compAlbum={
                          sepratedata.length > 0
                            ? sepratedata[0].companyAlbumsJobSp
                            : []
                        }
                      />
                    </Tab.Pane>

                    <Tab.Pane
                      eventKey="jobpsec-media"
                      className="dkg-jobspec-tabpane"
                    >
                      <MediaTab
                        companySocialMedia={
                          sepratedata.length > 0
                            ? sepratedata[0].companyJobSp
                            : []
                        }
                      />
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          </>
        ) : null}
      </div>
    </>
  );
};

export default DkgClientCompaines;
