
import React, { useState } from "react";
// import './moreinfo.scss';
import { FaInfoCircle } from "react-icons/fa";
import { Modal } from 'react-bootstrap';



const MobileMoreInfoPopover = ({ details , onHide }) => {
   
    return (
        <>
            <Modal
                show={true}
                onHide={onHide}
                className="dkg-mobile-pophvr-modal-21"
                
            >
                <Modal.Header className="dkg-mobile-pophvr-modal-hdr" closeButton>
                    {/* <Modal.Title></Modal.Title> */}
                </Modal.Header>
                <Modal.Body className="dkg-mobile-popuphvr-body">
                    <div className="dkg-pophvr-content-con">
                        <p>
                            {details}
                        </p>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    )
}

export default MobileMoreInfoPopover;
