import React from "react";
import { Link } from "react-router-dom";
import './myacc-submenu.scss';

const SettingsSubmenu = () => {
    const pathname = window.location.pathname;
    const segment2 = pathname.split("/")[2];

    return (
        <React.Fragment>

            <ul className="dkg-myacc-submenu-ul">
                <li>
                    <Link to="/settings/faq" className={`${(segment2 === 'faq') ? 'grey-active' : ''}`}>FAQ</Link>
                </li>

                <li>
                    <Link to="/settings/privacy" className={`${(segment2 === 'privacy') ? 'grey-active' : ''}`}>Privacy</Link>
                </li>
                <li>
                    <Link to="/settings/gdpr-explained" className={`${(segment2 === 'gdpr-explained') ? 'grey-active' : ''}`}>GDPR</Link>
                </li>
                <li className="psp">
                    <Link to="/settings/terms-of-use" className={`${(segment2 === 'terms-of-use') ? 'grey-active' : ''}`}>Terms</Link>
                </li>
                <li className="pbp">
                    <Link to="/settings/cookies" className={`${(segment2 === 'cookies') ? 'grey-active' : ''}`}>Cookies</Link>
                </li>
                <li>
                    <Link to="/settings/notifications" className={`${(segment2 === 'notifications') ? 'grey-active' : ''}`}>Notifications</Link>
                </li>
                <li>
                    <Link to="/settings/give-feedback" className={`${(segment2 === 'give-feedback') ? 'grey-active' : ''}`}>Give Feedback</Link>
                </li>
                <li>
                    <Link to="/settings/reset-logins" className={`${(segment2 === 'reset-logins') ? 'grey-active' : ''}`}>Reset Logins</Link>
                </li>
                <li>
                    <Link to="/settings/delete-account" className={`${(segment2 === 'delete-account') ? 'grey-active' : ''}`}>Delete A/C</Link>
                </li>

                <li>
                    <Link to="/settings/about-us" className={`${(segment2 === 'about-us') ? 'grey-active' : ''}`}>About us</Link>
                </li>
                <li>
                    <Link to="/settings/contact-us" className={`${(segment2 === 'contact-us') ? 'grey-active' : ''}`}>Contact us</Link>
                </li>
            </ul>
        </React.Fragment>
    );
}

export default SettingsSubmenu;
