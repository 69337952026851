import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
//import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { useSelector } from "react-redux";

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export default function CustomizedAccordions({ quesAnswer }) {
  const { isSuccess } = useSelector((state) => state.candidateservicefaqData);
  const [data, setData] = useState([]);
  useEffect(() => {
    if (isSuccess) {
      setData(quesAnswer);
    }
  }, [isSuccess]);

  const [expanded, setExpanded] = React.useState("panes0");
  // const [expanded, setExpanded] = React.useState("panel0");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const textRef = React.useRef();
  const [value, setValue] = React.useState();
  // const onChnage = (event) => {
  //     setValue(event.target.value);
  // };
  React.useEffect(() => {
    if (textRef && textRef.current) {
      textRef.current.style.height = "0px";
      const taHeight = textRef.current.scrollHeight;
      textRef.current.style.height = taHeight + "px";
    }
  }, [value]);
  const dummyData = [
    {
      name: "john",
      age: 12,
      gender: "male",
    },
    {
      name: "jane",
      age: 15,
      gender: "female",
    },
    {
      name: "julie",
      age: 20,
      gender: "trans",
    },
  ];
  return (
    <>
      {data.length > 0 ? (
        <div className="dkg-screen-faq-accodionCon">
          {data.map((queAns, index) => {
            return (
              <Accordion
                expanded={expanded == `panel` + index}
                onChange={handleChange(`panel` + index)}
                className="dkg-screen-faq-accodion"
              >
                <AccordionSummary
                  aria-controls={`panel` + index + `d-content`}
                  id={`panel` + index + `d-header`}
                  className="dkg-screen-faq-accodion-suMM"
                >
                  <Typography>
                    {/* {queAns.question} */}
                    {queAns.question != "" && queAns.question != null ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: queAns.question,
                        }}
                      ></div>
                    ) : (
                      ""
                    )}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{ backgroundColor: "#24475f", color: "#fff" }}
                >
                  <Typography>
                    {/* <TextareaAutosize
                                                aria-label="minimum height"
                                                // rowsMin={3}
                                                className="w-100 dkg-faq-answertextArea"
                                                value={queAns.answer}
                                                style={{
                                                    backgroundColor: "#24475f",
                                                    color: "#fff",
                                                    border: "none",
                                                }}
                                            /> */}
                    {queAns.answer != "" && queAns.answer != null ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: queAns.answer,
                        }}
                        style={{
                          backgroundColor: "#24475f",
                          color: "#fff",
                          border: "none",
                        }}
                      ></div>
                    ) : (
                      ""
                    )}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      ) : null}
    </>
  );
}
