import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import "./service-overview.scss";
import { useDispatch, useSelector } from "react-redux";

//import { FeaturedPlayListRounded } from "@material-ui/icons";
import Onlineservice from "../../../../../assets/images/online-course.png";
import ServiceConsult from "../../../../../assets/images/service-consult.png";
import Serviceemailsupport from "../../../../../assets/images/email-support.png";
import InternetEtiquette from "../../../../../assets/images/net-equit-icon.png";
import EnglishSpk from "../../../../../assets/images/eng-spk-icon.png";
import WorkingExperience from "../../../../../assets/images/work-experiencee.png";
import PricingEmail from "../../../../../assets/images/pricing-email.png";
import PricingMeeting from "../../../../../assets/images/pricing-meeting.png";
import PricingOnline from "../../../../../assets/images/online-learning.png";
import PricingEdu from "../../../../../assets/images/pricing-education.png";
import ServicePricing from "../../../../../assets/images/servicing-price.png";
import DKGTeam from "../../../../../assets/images/deepak.png";
import Dropdown from "react-bootstrap/Dropdown";

import ServicesModal from "./modal/ServicesModal";
import SendqueryModal from "./modal/SendqueryModal";
import { FaPaperPlane , FaExclamationTriangle , FaRegPaperPlane , FaXRay,FaEnvelope, FaRocketchat , FaGlobeEurope, FaFacebook, FaGooglePlus, FaInstagram, FaLinkedin} from "react-icons/fa";
import { getCMSHeadingText } from "../../../../../slice/frontendCms/frontendCmsSlice";

import AboutsBanner from '../../../../../assets/images/about-banner.jpg' 
import LowCost from '../../../../../assets/images/low-cost.png'
import QualityWork from '../../../../../assets/images/quailty-work.png' 
import QuickDelvery from '../../../../../assets/images/quick-delvery.png'
import LegalAlert from "../../../../../assets/images/legaldoc-black.png";
import TestimonialSlider from "./TestimonialSlider";
import ConsultantsSlider from "./ConsultantsSlider";
import MoreInfoPopover from "../tooltip/MoreInfoPopover";


const Paidserveoverview = () => {
  const dispatch = useDispatch();
  const { isLoading, getcmsList } = useSelector((state) => state.cmslist);
  const [data, setData] = useState([]);
  useEffect(() => {
    if (!isLoading && getcmsList && getcmsList.length > 0) {
      setData(getcmsList);
    }
  }, [isLoading]);

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(getCMSHeadingText({}));
      } catch (err) {}
    }
    fetchData();
  }, [dispatch]);

  const [isSendQuery, setIsSendQuery] = useState(false);
  const [isJobOffer, setIsJobOffer] = useState(false);
  const [isCreateCV, setIsCreateCV] = useState(false);
  const [isDesiredJob, setIsDesiredJob] = useState(false);
  const [isSearchingJob, setIsSearchingJob] = useState(false);
  const [isInterviews, setIsInterviews] = useState(false);
  const [isPromotionsModal, setIsPromotionsModal] = useState(false);
  const [isResignations, setIsResignations] = useState(false);
  const [isNegotiations, setIsNegotiations] = useState(false);
  const [isCommunications, setIsCommunications] = useState(false);
  const [isStudyingAbroad, setIsStudyingAbroad] = useState(false);
  const [isWorkingAbroad, setIsWorkingAbroad] = useState(false);
  const [isCareerPlanning, setIsCareerPlanning] = useState(false);
  const [isEnglishSpeaking, setIsEnglishSpeaking] = useState(false);
  const [isInternetEquite, setIsInternetEquite] = useState(false);
  const [isWorkExperence, setIsWorkExperence] = useState(false);
  

  const showconsultModal = () => {
    setIsCreateCV(true);
  };
  const hideconsultModal = () => {
    setIsCreateCV(false);
  };
  const showmentorshipModal = () => {
    setIsDesiredJob(true);
  };
  const hidementorshipModal = () => {
    setIsDesiredJob(false);
  };
  const showInternshipModal = () => {
    setIsSearchingJob(true);
  };
  const hideInternshipModal = () => {
    setIsSearchingJob(false);
  };
  const showonlinetoolModal = () => {
    setIsInterviews(true);
  };
  const hideonlinetoolModal = () => {
    setIsInterviews(false);
  };
  const showemailsupportModal = () => {
    setIsPromotionsModal(true);
  };
  const hideemailsupportModal = () => {
    setIsPromotionsModal(false);
  };
  const showserviceModal = () => {
    setIsJobOffer(true);
  };
  const hideserviceModal = () => {
    setIsJobOffer(false);
  };
  const showsendqueryModal = () => {
    setIsSendQuery(true);
  };
  const hidesendqueryModal = () => {
    setIsSendQuery(false);
  };

 const showresignationsModal = () =>{
  setIsResignations(true);
 }
 const hideresignationsModal = () =>{
  setIsResignations(false);
 }
 const shownegotiationsModal = () =>{
  setIsNegotiations(true);
 }
 const hidenegotiationsModal = () =>{
  setIsNegotiations(false);
 }
 const showcommunicationModal = () =>{
  setIsCommunications(true);
 }
 const hidecommunicationModal = () =>{
  setIsCommunications(false);
 }
 const showStudyAboardModal = () =>{
  setIsStudyingAbroad(true);
 }
 const hideStudyAboardModal = () =>{
  setIsStudyingAbroad(false);
 }
 const showWorkingAbroadModal = () =>{
  setIsWorkingAbroad(true);
 }
 const hideWorkingAbroadModal = () =>{
  setIsWorkingAbroad(false);
 }
 const showcareerplanModal = () =>{
  setIsCareerPlanning(true);
 }
 const hidecareerplanModal = () =>{
  setIsCareerPlanning(false);
 }
 const showengSpeakModal = () =>{
  setIsEnglishSpeaking(true);
 }
 const hideengSpeakModal = () =>{
  setIsEnglishSpeaking(false);
 }
 const showinternetquiteModal = () =>{
  setIsInternetEquite(true);
 }
 const hideinternetquiteModal = () =>{
  setIsInternetEquite(false);
 }
 const showworkexpModal = () =>{
  setIsWorkExperence(true);
 }
 const hideworkexpModal = () =>{
  setIsWorkExperence(false);
 }

//Start Code For About US Sec


useEffect(() => {
  async function fetchData() {
    try {
      await dispatch(getCMSHeadingText({}));
    } catch (err) {}
  }
  fetchData();
}, [dispatch]);
//End Code For About US Sec
  return (
    <>
      {isCreateCV ? (
        <ServicesModal onHide={hideconsultModal} headerTitle="Create CV" />
      ) : null}
      {isDesiredJob ? (
        <ServicesModal onHide={hidementorshipModal} headerTitle="Desired Job" />
      ) : null}
      {isSearchingJob ? (
        <ServicesModal onHide={hideInternshipModal} headerTitle="SEARCH JOBS" />
      ) : null}
      {isInterviews ? (
        <ServicesModal onHide={hideonlinetoolModal} headerTitle="Interviews" />
      ) : null}
      {isPromotionsModal ? (
        <ServicesModal
          onHide={hideemailsupportModal}
          headerTitle="Promotions"
        />
      ) : null}
      {isJobOffer ? (
        <ServicesModal onHide={hideserviceModal} headerTitle="Job Offer" />
      ) : null}
      {isResignations ? (
        <ServicesModal onHide={hideresignationsModal} headerTitle="Resignations" />
      ) : null}

      {isNegotiations ? (
        <ServicesModal onHide={hidenegotiationsModal} headerTitle="Negotiations" />
      ) : null}

      {isCommunications ? (
        <ServicesModal onHide={hidecommunicationModal} headerTitle="Communications" />
      ) : null}
      {isStudyingAbroad ? (
        <ServicesModal onHide={hideStudyAboardModal} headerTitle="Studying Abroad" />
      ) : null}
      {isWorkingAbroad ? (
        <ServicesModal onHide={hideWorkingAbroadModal} headerTitle="Working Abroad" />
      ) : null}
      {isCareerPlanning ? (
        <ServicesModal onHide={hidecareerplanModal} headerTitle="Career Planning" />
      ) : null}
      {isInternetEquite ? (
        <ServicesModal onHide={hideinternetquiteModal} headerTitle="Internet Etiquette" />
      ) : null}
      {isEnglishSpeaking ? (
        <ServicesModal onHide={hideengSpeakModal} headerTitle="ENGLISH SPEAKING" />
      ) : null}
      {isWorkExperence ? (
        <ServicesModal onHide={hideworkexpModal} headerTitle="Work Experience" />
      ) : null}

      {isSendQuery ? <SendqueryModal onHide={hidesendqueryModal} /> : null}
      <div className="dkg-paidservices-homeMain">
        <div className="dkg-paidservices-main-banner">
          <div className="dkg-paidservice-main-con">
            <div className="dkg-service-btncontent-con">
              <div className="dkg-paidservice-content">
                <h2 className="dkg-paidservice-title">
                  {data.length > 0 ? data[28].heading : ""}
                </h2>
                <p className="dkg-paidservice-subtitle">
                  {data.length > 0 ? data[28].heading_decription : ""}
                </p>
              </div>
              <div className="dkg-book-appointment-btnCon">
                <button
                  className="btn dkg-book-appointment-btn"
                  onClick={showsendqueryModal}
                >
                  Send Your Query
                  <span className="dkg-calender-icon">
                    <FaPaperPlane />
                  </span>
                </button>
              </div>
            </div>
            <div className="dkg-paidservice-bottom-sec d-none">
              <div className="dkg-paidservice-bottom-ul">
                <li
                  className="dkg-paidservice-bottom-li"
                  onClick={showconsultModal}
                >
                  <div className="dkg-service-icon-con">
                    <img
                      src={
                        data.length > 0
                          ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                            "/" +
                            data[2].image
                          : ""
                      }
                      className="dkg-service-icon"
                      alt= {data.length > 0 ? data[2].heading : ""}
                    />
                  </div>
                  <div className="dkg-service-name"> {data.length > 0 ? data[2].heading : ""}</div>
                </li>
                <li
                  className="dkg-paidservice-bottom-li"
                  onClick={showmentorshipModal}
                >
                  <div className="dkg-service-icon-con">
                    <img
                      src={
                        data.length > 0
                          ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                            "/" +
                            data[3].image
                          : ""
                      }
                      className="dkg-service-icon"
                      alt={data.length > 0 ? data[3].heading : ""}
                    />
                  </div>
                  <div className="dkg-service-name">{data.length > 0 ? data[3].heading : ""}</div>
                </li>
                <li
                  className="dkg-paidservice-bottom-li"
                  onClick={showInternshipModal}
                >
                  <div className="dkg-service-icon-con">
                    <img
                      src={
                        data.length > 0
                          ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                            "/" +
                            data[4].image
                          : ""
                      }
                      className="dkg-service-icon"
                      alt={data.length > 0 ? data[2].heading : ""}
                    />
                  </div>
                  <div className="dkg-service-name">{data.length > 0 ? data[4].heading : ""}</div>
                </li>
                <li
                  className="dkg-paidservice-bottom-li"
                  onClick={showonlinetoolModal}
                >
                  <div className="dkg-service-icon-con">
                    <img
                      src={
                        data.length > 0
                          ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                            "/" +
                            data[5].image
                          : ""
                      }
                      className="dkg-service-icon"
                      alt={data.length > 0 ? data[5].heading : ""}
                    />
                  </div>
                  <div className="dkg-service-name">{data.length > 0 ? data[5].heading : ""}</div>
                </li>
                <li
                  className="dkg-paidservice-bottom-li"
                  onClick={showserviceModal}
                >
                  <div className="dkg-service-icon-con">
                    <img
                      src={
                        data.length > 0
                          ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                            "/" +
                            data[6].image
                          : ""
                      }
                      className="dkg-service-icon"
                      alt={data.length > 0 ? data[6].heading : ""}
                    />
                  </div>
                  <div className="dkg-service-name">{data.length > 0 ? data[6].heading : ""}</div>
                </li>
                <li
                  className="dkg-paidservice-bottom-li"
                  onClick={showemailsupportModal}
                >
                  <div className="dkg-service-icon-con">
                    <img
                      src={
                        data.length > 0
                          ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                            "/" +
                            data[7].image
                          : ""
                      }
                      className="dkg-service-icon"
                      alt={data.length > 0 ? data[7].heading : ""}
                    />
                  </div>
                  <div className="dkg-service-name">{data.length > 0 ? data[7].heading : ""}</div>
                </li>
              </div>
              <div className="dkg-paidservice-bottom-ul">
                <li
                  className="dkg-paidservice-bottom-li"
                  onClick={showresignationsModal}
                >
                  <div className="dkg-service-icon-con">
                    <img
                      src={
                        data.length > 0
                          ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                            "/" +
                            data[8].image
                          : ""
                      }
                      className="dkg-service-icon"
                      alt={data.length > 0 ? data[8].heading : ""}
                    />
                  </div>
                  <div className="dkg-service-name">{data.length > 0 ? data[8].heading : ""}</div>
                </li>
                <li
                  className="dkg-paidservice-bottom-li"
                  onClick={shownegotiationsModal}
                >
                  <div className="dkg-service-icon-con">
                    <img
                      src={
                        data.length > 0
                          ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                            "/" +
                            data[9].image
                          : ""
                      }
                      className="dkg-service-icon"
                      alt={data.length > 0 ? data[9].heading : ""}
                    />
                  </div>
                  <div className="dkg-service-name">{data.length > 0 ? data[9].heading : ""}</div>
                </li>
                <li
                  className="dkg-paidservice-bottom-li"
                  onClick={showcommunicationModal}
                >
                  <div className="dkg-service-icon-con">
                    <img
                      src={
                        data.length > 0
                          ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                            "/" +
                            data[10].image
                          : ""
                      }
                      className="dkg-service-icon"
                      alt={data.length > 0 ? data[10].heading : ""}
                    />
                  </div>
                  <div className="dkg-service-name">{data.length > 0 ? data[10].heading : ""}</div>
                </li>
                <li
                  className="dkg-paidservice-bottom-li"
                  onClick={showStudyAboardModal}
                >
                  <div className="dkg-service-icon-con">
                    <img
                      src={
                        data.length > 0
                          ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                            "/" +
                            data[12].image
                          : ""
                      }
                      className="dkg-service-icon"
                      alt={data.length > 0 ? data[12].heading : ""}
                    />
                  </div>
                  <div className="dkg-service-name">{data.length > 0 ? data[12].heading : ""}</div>
                </li>
                <li
                  className="dkg-paidservice-bottom-li"
                  onClick={showWorkingAbroadModal}
                >
                  <div className="dkg-service-icon-con">
                    <img
                      src={
                        data.length > 0
                          ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                            "/" +
                            data[13].image
                          : ""
                      }
                      className="dkg-service-icon"
                      alt={data.length > 0 ? data[13].heading : ""}
                    />
                  </div>
                  <div className="dkg-service-name">{data.length > 0 ? data[13].heading : ""}</div>
                </li>
                <li
                  className="dkg-paidservice-bottom-li"
                  onClick={showcareerplanModal}
                >
                  <div className="dkg-service-icon-con">
                    <img
                      src={
                        data.length > 0
                          ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                            "/" +
                            data[14].image
                          : ""
                      }
                      className="dkg-service-icon"
                      alt={data.length > 0 ? data[14].heading : ""}
                    />
                  </div>
                  <div className="dkg-service-name">{data.length > 0 ? data[14].heading : ""}</div>
                </li>
              </div>
            </div>
          </div>
        </div>
        <div className="dkg-service-second-sec">
           <div className="dkg-service-header">
             <h2 className="dkg-service-header-title">
              {data.length > 0 ? data[1].page_description : ""}
             </h2>
             <div className="dkg-service-header-subtitle">
             {data.length > 0 ? data[15].page_description : ""}
             </div>
           </div>
           <div className="dkg-service-maincontent">
            <div className="dkg-paidservice-bottom-sec">
                <div className="dkg-paidservice-bottom-ul">
                  <li
                    className="dkg-paidservice-bottom-li"
                    onClick={showconsultModal}
                  >
                    <div className="dkg-service-icon-con">
                      <img
                        src={
                          data.length > 0
                            ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                              "/" +
                              data[2].image
                            : ""
                        }
                        className="dkg-service-icon"
                        alt= "Create CV"
                      />
                    </div>
                    <div className="dkg-service-name">Create CV</div>
                  </li>
                  <li
                    className="dkg-paidservice-bottom-li"
                    onClick={showmentorshipModal}
                  >
                    <div className="dkg-service-icon-con">
                      <img
                        src={
                          data.length > 0
                            ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                              "/" +
                              data[3].image
                            : ""
                        }
                        className="dkg-service-icon"
                        alt="Desired Job"
                      />
                    </div>
                    <div className="dkg-service-name">Desired Job</div>
                  </li>
                  <li
                    className="dkg-paidservice-bottom-li"
                    onClick={showInternshipModal}
                  >
                    <div className="dkg-service-icon-con">
                      <img
                        src={
                          data.length > 0
                            ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                              "/" +
                              data[4].image
                            : ""
                        }
                        className="dkg-service-icon"
                        alt="Search Jobs"
                      />
                    </div>
                    <div className="dkg-service-name">Search Jobs</div>
                  </li>
                  <li
                    className="dkg-paidservice-bottom-li"
                    onClick={showonlinetoolModal}
                  >
                    <div className="dkg-service-icon-con">
                      <img
                        src={
                          data.length > 0
                            ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                              "/" +
                              data[5].image
                            : ""
                        }
                        className="dkg-service-icon"
                        alt="Interviews"
                      />
                    </div>
                    <div className="dkg-service-name">Interviews</div>
                  </li>
                  <li
                    className="dkg-paidservice-bottom-li"
                    onClick={showserviceModal}
                  >
                    <div className="dkg-service-icon-con">
                      <img
                        src={
                          data.length > 0
                            ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                              "/" +
                              data[6].image
                            : ""
                        }
                        className="dkg-service-icon"
                        alt="Job Offers"
                      />
                    </div>
                    <div className="dkg-service-name">Job Offers</div>
                  </li>
                  <li
                    className="dkg-paidservice-bottom-li"
                    onClick={shownegotiationsModal}
                  >
                    <div className="dkg-service-icon-con">
                      <img
                        src={
                          data.length > 0
                            ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                              "/" +
                              data[9].image

                            : ""
                        }
                        className="dkg-service-icon"
                        alt="Negotiations"
                      />
                    </div>
                    <div className="dkg-service-name">Negotiations</div>
                  </li>
                  <li
                    className="dkg-paidservice-bottom-li"
                    onClick={showemailsupportModal}

                  >
                    <div className="dkg-service-icon-con">
                      <img
                        src={
                          data.length > 0
                            ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                              "/" +
                              data[7].image
                            : ""
                        }
                        className="dkg-service-icon"
                        alt="Promotions"
                      />
                    </div>
                    <div className="dkg-service-name">Promotions</div>
                  </li>
                  <li
                    className="dkg-paidservice-bottom-li"
                    onClick={showresignationsModal}

                  >
                    <div className="dkg-service-icon-con">
                      <img
                        src={
                          data.length > 0
                            ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                              "/" +
                              data[8].image
                            : ""
                        }
                        className="dkg-service-icon"
                        alt="Resignation"
                      />
                    </div>
                    <div className="dkg-service-name">Resignation</div>
                  </li>
                  <li
                    className="dkg-paidservice-bottom-li"
                    onClick={showStudyAboardModal}

                  >
                    <div className="dkg-service-icon-con">
                      <img
                        src={
                          data.length > 0
                            ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                              "/" +
                              data[12].image
                            : ""
                        }
                        className="dkg-service-icon"
                        alt="Studying Aboard"
                      />
                    </div>
                    <div className="dkg-service-name">Studying Aboard</div>
                  </li>
                  <li
                    className="dkg-paidservice-bottom-li"
                    onClick={showWorkingAbroadModal}

                  >
                    <div className="dkg-service-icon-con">
                      <img
                        src={
                          data.length > 0
                            ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                              "/" +
                              data[13].image
                            : ""
                        }
                        className="dkg-service-icon"
                        alt="Working Aboard"
                      />
                    </div>
                    <div className="dkg-service-name">Working Aboard</div>
                  </li>
                  <li
                    className="dkg-paidservice-bottom-li"
                    onClick={showcareerplanModal}

                  >
                    <div className="dkg-service-icon-con">
                      <img
                        src={
                          data.length > 0
                            ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                              "/" +
                              data[13].image
                            : ""
                        }
                        className="dkg-service-icon"
                        alt="Career planning"
                      />
                    </div>
                    <div className="dkg-service-name">Career planning</div>
                  </li>
                  <li
                  className="dkg-paidservice-bottom-li"
                  onClick={showcommunicationModal}

                >
                  <div className="dkg-service-icon-con">
                    <img
                      src={
                        data.length > 0
                          ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                            "/" +
                            data[10].image
                          : ""
                      }
                      className="dkg-service-icon"
                      alt="Communications"
                    />
                  </div>
                  <div className="dkg-service-name">Communications</div>
                  </li>
                  <li
                    className="dkg-paidservice-bottom-li"
                    onClick={showinternetquiteModal}
                  >
                    <div className="dkg-service-icon-con">
                      <img
                        src={InternetEtiquette}
                        className="dkg-service-icon"
                        alt="Internet  Etiquette"
                      />
                    </div>
                    <div className="dkg-service-name">Internet  Etiquette</div>
                  </li>
                  <li
                    className="dkg-paidservice-bottom-li"
                    onClick={showengSpeakModal}
                  >
                    <div className="dkg-service-icon-con">
                      <img
                        src={EnglishSpk}
                        className="dkg-service-icon"
                        alt="English speaking"
                      />
                    </div>
                    <div className="dkg-service-name">English speaking</div>
                  </li>
                  <li
                    className="dkg-paidservice-bottom-li"
                    onClick={showworkexpModal}
                  >
                    <div className="dkg-service-icon-con">
                      <img
                        src={WorkingExperience}
                        className="dkg-service-icon"
                        alt="Work Experience"
                      />
                    </div>
                    <div className="dkg-service-name">Work Experience</div>
                  </li>
                </div>
            </div>
           </div>
        </div>
        <div className="dkg-service-about-sec">
          <div className="dkg-services-mainCol">
            <h2 className="dkg-services-page-title">
              {data.length > 0 ? data[17].page_description : ""}
            </h2>

            <p className="dkg-services-page-text">
              {data.length > 0 ? data[18].page_description : ""}
            </p>
          </div>
          <div className="dkg-about-service-blueCon">
            <div className="dkg-service-block-con">
              <div className="dkg-service-mainRow row">
                <div className="dkg-about-service-leftcon col-md-6 col-sm-12">
                    <div className="dkg-about-service-imgCon">
                      <img src={AboutsBanner} alt="About Image" className="dkg-about-service-img" />
                    </div>
                </div>
                <div className="dkg-about-service-rightcon col-md-6 col-sm-12">
                  {/* <div className="dkg-about-us-subtitleCon">
                    <h2 className="dkg-about-us-subtitle">Why Choose Us?</h2>
                  </div> */}
                  <div className="dkg-about-us-desc">
                    <p>{data.length > 0 ? data[35].page_description : ""}
                    </p>
                    <p>{data.length > 0 ? data[36].page_description : ""}
                    </p>
                  </div>
                  <div className="dkg-aboutus-cardCon">
                    <ul className="dkg-aboutus-card-ul">
                      <li className="dkg-aboutus-card-li">
                        <Link to="#" className="dkg-aboutus-card">
                          <div className="dkg-aboutus-icon">
                            <img src={LowCost} className="dkg-aboutus-img" alt="Low Cost" />
                          </div>
                          <div className="dkg-aboutus-cardtitle">Low Cost</div>
                        </Link>
                      </li>
                      <li className="dkg-aboutus-card-li">
                        <Link to="#" className="dkg-aboutus-card">
                          <div className="dkg-aboutus-icon">
                            <img src={QualityWork} className="dkg-aboutus-img" alt="Quality Work" />
                          </div>
                          <div className="dkg-aboutus-cardtitle">High Quality</div>
                        </Link>
                      </li>
                      <li className="dkg-aboutus-card-li">
                        <Link to="#" className="dkg-aboutus-card">
                          <div className="dkg-aboutus-icon">
                            <img src={QuickDelvery} className="dkg-aboutus-img" alt="Fast Delevery" />
                          </div>
                          <div className="dkg-aboutus-cardtitle">Fast Delivery</div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dkg-service-team-sec">
          <div className="dkg-services-mainCol">
            <h2 className="dkg-services-page-title">
              MEET OUT CONSULTANTS
            </h2>
            <p className="dkg-services-page-text">
            {data.length > 0 ? data[26].page_description : ""}
            </p>
          </div>
          <div className="dkg-teams-box-main-con d-none">
            <div className="row dkg-teams-box-main-Row">
                <div className="col-md-3 col-sm-6 dkg-teams-box-cols">
                    <div className="dkg-our-team">
                        <div className="dkg-our-team-img">
                            <img src={DKGTeam} alt="Team" className="dkg-teammate-img" />
                        </div>
                        <div className="dkg-team-content">
                            <h3 className="dkg-teammate-name">Full Name</h3>
                            <span className="dkg-team-post">Consultant</span>
                        </div>
                        <div className="dkg-ourteam-overlay">
                          <ul className="dkg-our-team-social">
                              <li>
                                  <Link to="#">
                                    <FaFacebook />
                                  </Link>
                              </li>
                              <li>
                                  <Link to="#">
                                    <FaGooglePlus />
                                  </Link>
                              </li>
                              <li>
                                  <Link to="#">
                                  <FaInstagram />
                                  </Link>
                              </li>
                              <li>
                                  <Link to="#">
                                    <FaLinkedin />
                                  </Link>
                              </li>
                          </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-sm-6 dkg-teams-box-cols">
                    <div className="dkg-our-team">
                        <div className="dkg-our-team-img">
                          <img src={DKGTeam} alt="Team" className="dkg-teammate-img" />
                        </div>
                        <div className="dkg-team-content">
                          <h3 className="dkg-teammate-name">Full Name</h3>
                          <span className="dkg-team-post">Consultant</span>
                        </div>
                        <div className="dkg-ourteam-overlay">
                          <ul className="dkg-our-team-social">
                              <li>
                                  <Link to="#">
                                    <FaFacebook />
                                  </Link>
                              </li>
                              <li>
                                  <Link to="#">
                                    <FaGooglePlus />
                                  </Link>
                              </li>
                              <li>
                                  <Link to="#">
                                  <FaInstagram />
                                  </Link>
                              </li>
                              <li>
                                  <Link to="#">
                                    <FaLinkedin />
                                  </Link>
                              </li>
                          </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-sm-6 dkg-teams-box-cols">
                    <div className="dkg-our-team">
                        <div className="dkg-our-team-img">
                          <img src={DKGTeam} alt="Team" className="dkg-teammate-img" />
                        </div>
                        <div className="dkg-team-content">
                          <h3 className="dkg-teammate-name">Full Name</h3>
                          <span className="dkg-team-post">Consultant</span>
                        </div>
                        <div className="dkg-ourteam-overlay">
                          <ul className="dkg-our-team-social">
                              <li>
                                  <Link to="#">
                                    <FaFacebook />
                                  </Link>
                              </li>
                              <li>
                                  <Link to="#">
                                    <FaGooglePlus />
                                  </Link>
                              </li>
                              <li>
                                  <Link to="#">
                                  <FaInstagram />
                                  </Link>
                              </li>
                              <li>
                                  <Link to="#">
                                    <FaLinkedin />
                                  </Link>
                              </li>
                          </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-sm-6 dkg-teams-box-cols">
                    <div className="dkg-our-team">
                        <div className="dkg-our-team-img">
                          <img src={DKGTeam} alt="Team" className="dkg-teammate-img" />
                        </div>
                        <div className="dkg-team-content">
                          <h3 className="dkg-teammate-name">Full Name</h3>
                          <span className="dkg-team-post">Consultant</span>
                        </div>
                        <div className="dkg-ourteam-overlay">
                          <ul className="dkg-our-team-social">
                              <li>
                                  <Link to="#">
                                    <FaFacebook />
                                  </Link>
                              </li>
                              <li>
                                  <Link to="#">
                                    <FaGooglePlus />
                                  </Link>
                              </li>
                              <li>
                                  <Link to="#">
                                  <FaInstagram />
                                  </Link>
                              </li>
                              <li>
                                  <Link to="#">
                                    <FaLinkedin />
                                  </Link>
                              </li>
                          </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="dkg-show-more-btnCon">
              <Link className="dkg-show-more-btn" to="/my-services/consultants">View All</Link>
              </div>
          </div>
          <div className="dkg-consultants-sliderCon">
              <ConsultantsSlider />
              <div className='dkg-show-more-btnCon'>
                <Link to="/my-services/consultants" className='dkg-show-more-btn'>View All</Link>
              </div>
          </div>
        </div>
        <div className="dkg-serive-testimonial-sec">
          <div className="dkg-services-mainCol">
            <h2 className="dkg-services-page-title">
              {data.length > 0 ? data[29].heading : ""}
            </h2>

            <p className="dkg-services-page-text">
              {data.length > 0 ? data[29].heading_decription : ""}
            </p>
          </div>
          <div className="dkg-testimonial-sliderCon">
              <TestimonialSlider />
              <div className='dkg-show-more-btnCon'>
                <Link to="/my-services/testimonial" className='dkg-show-more-btn'>View All</Link>
              </div>
          </div>
        </div>
        <div className="dkg-service-legal-sec d-none">
          <div className="dkg-legal-service-header">
            <h2 className="dkg-legal-services-page-title">
              {" "}
              {data.length > 0 ? data[21].page_description : ""}
            </h2>
            <p className="dkg-legal-services-page-text">
              {data.length > 0 ? data[22].page_description : ""}
            </p>
          </div>
          <div className="dkg-legal-services-container">
            <div className="row dkg-legal-services-mainRow">
              <div className="col-12 dkg-legal-services-mainCol">
                <div className="dkg-cnacc-legal-mainCon">
                  <div className="dkg-cnacc-legal-banner">
                    <div className="dkg-banner-upper-cross">
                      <div className="dkg-pd-legal-content">
                        <div className="dkg-pd-legal-content-title d-none">
                          LEGAL ALERT
                          <span className="dkg-legal-doc-icon">
                            <FaExclamationTriangle />
                          </span>
                        </div>
                        <div className="dkg-pd-legal-content-body">
                          <div className="dkg-pd-legal-content-card">
                            <div className="dkg-legal-content-card-left">
                              <div className="dkg-legal-content-imgCon">
                                <img
                                  src={LegalAlert}
                                  alt="Legal"
                                  className="dkg-legal-content-img"
                                />
                              </div>
                            </div>
                            <div className="dkg-legal-content-card-right">
                              <div className="dkg-legal-card-content">
                                {data.length > 0 ? data[32].page_description : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dkg-service-legal-sec">
          <div className="dkg-legal-service-header">
            <h2 className="dkg-legal-services-page-title">
              PRICING
            </h2>
            <p className="dkg-legal-services-page-text">
            {data.length > 0 ? data[16].page_description : ""}
            </p>
          </div>
          <div className="dkg-pricing-services-container">
            <div className="dkg-pricePlan-mainContainer">
              <div className="dk-pricePlan-main">
                <div className="table-responsive">
                  <table className="table">
                      <thead>
                        <tr>
                          <th>EMPLOYMENT SERVICES</th>
                          <th>
                            <span className="dkg-pricing-icon-con">
                              {/* <FaEnvelope /> */} 
                              <img src={PricingEmail} alt="" className="dkg-pricing-img" />
                            </span>
                          </th>
                          <th>
                            <span className="dkg-pricing-icon-con">
                              <img src={PricingOnline} alt="" className="dkg-pricing-img" />
                            </span>
                          </th>
                          <th>
                            <span className="dkg-pricing-icon-con">
                              <img src={PricingMeeting} alt="" className="dkg-pricing-img" />
                            </span>
                          </th>
                          <th>
                            <span className="dkg-pricing-icon-con">
                              <img src={PricingEdu} alt="" className="dkg-pricing-img" />
                            </span>
                          </th>
                          <th>
                            <span className="dkg-pricing-icon-con">
                                <img src={ServicePricing} alt="" className="dkg-pricing-img" />
                            </span>
                          </th>
                        </tr>
                      </thead>
                  </table>
                  <table className="table table_2">
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>NAME OF SERVICES</th>
                        <th>Email Only</th>
                        <th>Online Course</th>
                        <th>1 to 1 Session</th>
                        <th>Group Session</th>
                        <th>Service Pricing</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="active">1</td>
                        <td>CREATE CV 
                          <span className="pull-right">
                            <MoreInfoPopover />
                          </span>
                        </td>
                        <td>
                          <span className="cross"><i className="fas fa-times"></i></span>
                        </td>
                        <td>
                          <span className="check"><i className="fas fa-check"></i></span>
                        </td>
                        <td>
                          <span className="cross"><i className="fas fa-times"></i></span>
                        </td>
                        <td>
                          <span className="check"><i className="fas fa-check"></i></span>
                        </td>
                        <td>
                          <span className="check euro-sign"><i className="fas fa-euro-sign"></i></span>
                        </td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>DESIRED JOB<span className="pull-right">
                            <MoreInfoPopover />
                          </span></td>
                        <td><span className="cross"><i className="fas fa-times"></i></span></td>
                        <td><span className="check"><i className="fas fa-check"></i></span></td>
                        <td><span className="check"><i className="fas fa-check"></i></span></td>
                        <td><span className="check"><i className="fas fa-check"></i></span></td>
                        <td>
                          <span className="check euro-sign"><i className="fas fa-euro-sign"></i></span>
                        </td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>SEARCH JOBS<span className="pull-right">
                            <MoreInfoPopover />
                          </span></td>
                        <td><span className="cross"><i className="fas fa-times"></i></span></td>
                        <td><span className="check"><i className="fas fa-check"></i></span></td>
                        <td><span className="check"><i className="fas fa-check"></i></span></td>
                        <td><span className="check"><i className="fas fa-check"></i></span></td>
                        <td><span className="check euro-sign"><i className="fas fa-euro-sign"></i></span></td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>INTERVIEWS<span className="pull-right">
                            <MoreInfoPopover />
                          </span></td>
                        <td>
                          <span className="cross"><i className="fas fa-times"></i></span>
                        </td>
                        <td>
                          <span className="check"><i className="fas fa-check"></i></span>
                        </td>
                        <td>
                          <span className="check"><i className="fas fa-check"></i></span>
                        </td>
                        <td>
                          <span className="check"><i className="fas fa-check"></i></span>
                        </td>
                        <td>
                          <span className="check euro-sign"><i className="fas fa-euro-sign"></i></span>
                        </td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>JOB OFFERS<span className="pull-right">
                            <MoreInfoPopover />
                          </span></td>
                        <td>
                          <span className="cross"><i className="fas fa-times"></i></span>
                        </td>
                        <td>
                          <span className="check"><i className="fas fa-check"></i></span>
                        </td>
                        <td>
                          <span className="check"><i className="fas fa-check"></i></span>
                        </td>
                        <td>
                          <span className="check"><i className="fas fa-check"></i></span>
                        </td>
                        <td>
                          <span className="check euro-sign"><i className="fas fa-euro-sign"></i></span>
                        </td>
                      </tr>
                      <tr>
                        <td>6</td>
                        <td>NEGOTIATIONS<span className="pull-right">
                            <MoreInfoPopover />
                          </span></td>
                        <td><span className="cross"><i className="fas fa-times"></i></span></td>
                        <td><span className="check"><i className="fas fa-check"></i></span></td>
                        <td><span className="check"><i className="fas fa-check"></i></span></td>
                        <td><span className="check"><i className="fas fa-check"></i></span></td>
                        <td>
                          <span className="check euro-sign"><i className="fas fa-euro-sign"></i></span>
                        </td>
                      </tr>
                      <tr>
                        <td>7</td>
                        <td>PROMOTIONS<span className="pull-right">
                            <MoreInfoPopover />
                          </span></td>
                        <td>
                          <span className="cross"><i className="fas fa-times"></i></span>
                        </td>
                        <td>
                          <span className="check"><i className="fas fa-check"></i></span>
                        </td>
                        <td>
                          <span className="check"><i className="fas fa-check"></i></span>
                        </td>
                        <td>
                          <span className="check"><i className="fas fa-check"></i></span>
                        </td>
                        <td>
                          <span className="check euro-sign"><i className="fas fa-euro-sign"></i></span>
                        </td>
                      </tr>
                      <tr>
                        <td>8</td>
                        <td>RESIGNATIONS 
                          <span className="pull-right">
                            <MoreInfoPopover />
                          </span>
                        </td>
                        <td>
                          <span className="cross"><i className="fas fa-times"></i></span>
                        </td>
                        <td>
                          <span className="check"><i className="fas fa-check"></i></span>
                        </td>
                        <td>
                          <span className="check"><i className="fas fa-check"></i></span>
                        </td>
                        <td>
                          <span className="check"><i className="fas fa-check"></i></span>
                        </td>
                        <td>
                          <span className="check euro-sign"><i className="fas fa-euro-sign"></i></span>
                        </td>
                      </tr>
                      <tr>
                        <td>9</td>
                        <td>STUDYING ABROAD
                          <span className="pull-right">
                            <MoreInfoPopover />
                          </span>
                        </td>
                        <td>
                          <span className="cross"><i className="fas fa-times"></i></span>
                        </td>
                        <td>
                          <span className="check"><i className="fas fa-check"></i></span>
                        </td>
                        <td>
                          <span className="check"><i className="fas fa-check"></i></span>
                        </td>
                        <td>
                          <span className="check"><i className="fas fa-check"></i></span>
                        </td>
                        <td>
                          <span className="check euro-sign"><i className="fas fa-euro-sign"></i></span>
                        </td>
                      </tr>
                      <tr>
                        <td>10</td>
                        <td>WORKING ABROAD
                          <span className="pull-right">
                            <MoreInfoPopover />
                          </span>
                        </td>
                        <td>
                          <span className="cross"><i className="fas fa-times"></i></span>
                        </td>
                        <td>
                          <span className="check"><i className="fas fa-check"></i></span>
                        </td>
                        <td>
                          <span className="check"><i className="fas fa-check"></i></span>
                        </td>
                        <td>
                          <span className="check"><i className="fas fa-check"></i></span>
                        </td>
                        <td>
                          <span className="check euro-sign"><i className="fas fa-euro-sign"></i></span>
                        </td>
                      </tr>
                      <tr>
                        <td>11</td>
                        <td>CAREER PLANNING 
                          <span className="pull-right">
                            <MoreInfoPopover />
                          </span>
                        </td>
                        <td>
                          <span className="cross"><i className="fas fa-times"></i></span>
                        </td>
                        <td>
                          <span className="cross"><i className="fas fa-times"></i></span>
                        </td>
                        <td>
                          <span className="check"><i className="fas fa-check"></i></span>
                        </td>
                        <td>
                          <span className="cross"><i className="fas fa-times"></i></span>
                        </td>
                        <td>
                          <span className="check euro-sign"><i className="fas fa-euro-sign"></i></span>
                        </td>
                      </tr>
                      <tr>
                        <td>12</td>
                        <td>COMMUNICATIONS
                          <span className="pull-right">
                            <MoreInfoPopover />
                          </span>
                        </td>
                        <td>
                          <span className="cross"><i className="fas fa-times"></i></span>
                        </td>
                        <td>
                          <span className="cross"><i className="fas fa-times"></i></span>
                        </td>
                        <td>
                          <span className="check"><i className="fas fa-check"></i></span>
                        </td>
                        <td>
                          <span className="cross"><i className="fas fa-times"></i></span>
                        </td>
                        <td>
                        <span className="check euro-sign"><i className="fas fa-euro-sign"></i></span>
                        </td>
                      </tr>
                      <tr>
                        <td>13</td>
                        <td>INTERNET ETIQUETTES 
                          <span className="pull-right">
                            <MoreInfoPopover />
                          </span>
                        </td>
                        <td>
                          <span className="cross"><i className="fas fa-times"></i></span>
                        </td>
                        <td>
                          <span className="cross"><i className="fas fa-times"></i></span>
                        </td>
                        <td>
                          <span className="check"><i className="fas fa-check"></i></span>
                        </td>
                        <td>
                          <span className="cross"><i className="fas fa-times"></i></span>
                        </td>
                        <td>
                          <span className="check euro-sign"><i className="fas fa-euro-sign"></i></span>
                        </td>
                      </tr>
                      <tr>
                        <td>14</td>
                        <td>ENGLISH SPEAKING
                          <span className="pull-right">
                            <MoreInfoPopover />
                          </span>
                        </td>
                        <td>
                          <span className="cross"><i className="fas fa-times"></i></span>
                        </td>
                        <td>
                          <span className="cross"><i className="fas fa-times"></i></span>
                        </td>
                        <td>
                          <span className="check"><i className="fas fa-check"></i></span>
                        </td>
                        <td>
                          <span className="cross"><i className="fas fa-times"></i></span>
                        </td>
                        <td>
                           <span className="check euro-sign"><i className="fas fa-euro-sign"></i></span>
                        </td>
                      </tr>
                      <tr>
                        <td>15</td>
                        <td>WORK EXPERIENCE 
                          <span className="pull-right">
                            <MoreInfoPopover />
                          </span>
                        </td>
                        <td>
                          <span className="cross"><i className="fas fa-times"></i></span>
                        </td>
                        <td>
                          <span className="cross"><i className="fas fa-times"></i></span>
                        </td>
                        <td>
                          <span className="check"><i className="fas fa-check"></i></span>
                        </td>
                        <td>
                          <span className="cross"><i className="fas fa-times"></i></span>
                        </td>
                        <td>
                          <span className="check euro-sign"><i className="fas fa-euro-sign"></i></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dkg-service-contact-sec">
          <div className="dkg-feedback-service-container">
            <div className="row dkg-feedback-service-mainRow">
              <div className="col-12 dkg-feedback-service-mainCol">
                <h2 className="dkg-fd-service-page-title">
                  {" "}
                  {data.length > 0 ? data[19].page_description : ""}
                </h2>
                <p className="dkg-fd-service-page-text">
                  {data.length > 0 ? data[20].page_description : ""}
                </p>
              </div>
              <div className="dkg-serivce-contact-maincon">
                <div className="dkg-cn-feedback-maincard">
                  <div className="dkg-cn-feedback-leftcon">
                    <ul className="dkg-cn-feedback-ul">
                      <li className="dkg-cn-feedback-li">
                        <div className="dkg-feedback-li-icon">
                          <div className="dkg-cn-feedback-icon">
                            <FaXRay />
                          </div>
                        </div>
                        <div className="dkg-feedback-li-titleCon">
                          <div className="dkg-feedback-li-title">
                            General Support
                          </div>
                          <div className="dkg-feedback-li-subtitle">
                            Get queries answered
                          </div>
                        </div>
                      </li>
                      <li className="dkg-cn-feedback-li">
                        <div className="dkg-feedback-li-icon">
                          <div className="dkg-cn-feedback-icon">
                            <FaEnvelope />
                          </div>
                        </div>
                        <div className="dkg-feedback-li-titleCon">
                          <div className="dkg-feedback-li-title">
                            Giving Feedback
                          </div>
                          <div className="dkg-feedback-li-subtitle">
                            Tell us your experience
                          </div>
                        </div>
                      </li>
                      <li className="dkg-cn-feedback-li">
                        <div className="dkg-feedback-li-icon">
                          <div className="dkg-cn-feedback-icon">
                            <FaRocketchat />
                          </div>
                        </div>
                        <div className="dkg-feedback-li-titleCon">
                          <div className="dkg-feedback-li-title">
                            Giving Suggestion
                          </div>
                          <div className="dkg-feedback-li-subtitle">
                            Give us your suggestions
                          </div>
                        </div>
                      </li>
                      <li className="dkg-cn-feedback-li">
                        <div className="dkg-feedback-li-icon">
                          <div className="dkg-cn-feedback-icon">
                            <FaGlobeEurope />
                          </div>
                        </div>
                        <div className="dkg-feedback-li-titleCon">
                          <div className="dkg-feedback-li-title">
                            Service Not Working
                          </div>
                          <div className="dkg-feedback-li-subtitle">
                            Inform the technical faults
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="dkg-cn-feedback-rihtcon">
                    <div className="cn-feedback-right-title">
                      How can we help you today?
                    </div>
                    <div className="cn-feedback-right-subtitle d-none">
                      Let us know who are you and what you are looking for below
                    </div>
                    <div className="dkg-cn-feedback-form">
                      <div className="dkg-cn-feedback-formgrp dkg-cn-fd-form-inputgrp">
                        <input
                          type="text"
                          className="dkg-cn-feedback-input form-control"
                          placeholder="Your Name"
                          name="feedback-name"
                          id=""
                        />
                      </div>
                      <div className="dkg-cn-feedback-formgrp dkg-cn-fd-form-inputgrp">
                        <input
                          type="email"
                          className="dkg-cn-feedback-input form-control"
                          placeholder="Email Address"
                          name="feedback-email"
                          id=""
                        />
                      </div>
                      <div className="dkg-cn-feedback-formgrp">
                        <Dropdown className="dkg-cn-feedback-dropdown">
                          <Dropdown.Toggle
                            variant=""
                            id=""
                            className="dkg-cn-fd-dropdown-toogle"
                          >
                            Select Support Type
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dkg-cn-fd-dropdown-menu">
                            <Dropdown.Item
                              href="#"
                              className="dkg-cn-fd-dropdown-item"
                            >
                              Action
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#"
                              className="dkg-cn-fd-dropdown-item"
                            >
                              Another action
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#"
                              className="dkg-cn-fd-dropdown-item"
                            >
                              Something else
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div className="dkg-cn-feedback-formgrp">
                        <textarea
                          className="dkg-cn-feedback-message"
                          placeholder="Write Message Here"
                          name=""
                          id=""
                        />
                      </div>
                      <div className="dkg-cn-feedback-formgrp">
                        <div className="dkg-feedback-submit-btnCon">
                          <button className="dkg-feedback-submit-btn btn">
                            CLICK HERE TO SEND
                            <span className="dkg-feedback-plane">
                              <FaRegPaperPlane />
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Paidserveoverview;
