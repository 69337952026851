import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./referrals.scss";
import { Link } from "react-router-dom";
import { Nav, Tab } from "react-bootstrap";
import { FaEuroSign, FaInfo, FaSearch , FaInfoCircle } from "react-icons/fa";
import TeleperformanceLogo from "../../../../assets/images/dk-global-rec-logos.png";
import RightModalFilter from "./modal/rightModalFilter";
import ViewtermsModal from "./modal/view/ViewtermsModal";
import InstallmentsModal from "./modal/view/InstallmentsModal";
import ApplytermsModal from "./modal/view/ApplytermsModal";
import MoreInfoPopover from "./MoreInfoPopover";
import { getCMSHeadingText } from "../../../../slice/frontendCms/frontendCmsSlice";
import MobileMoreInfoPopover from "./MobileMoreInfoPopover";


const Referrals = () => {
  const dispatch = useDispatch();
  const { isLoading, getcmsList } = useSelector((state) => state.cmslist);
  const [data, setData] = useState([]);
  useEffect(() => {
    if (!isLoading && getcmsList && getcmsList.length > 0) {
      setData(getcmsList);
    }
  }, [isLoading]);

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(getCMSHeadingText({}));
      } catch (err) {}
    }
    fetchData();
  }, [dispatch]);

  const [isViewTerms, setIsViewTerms] = useState(false);
  const [isInstallments, setIsInstallments] = useState(false);
  const [isApplyTerm, setIsApplyTerm] = useState(false);
  const showtermsviewModal = () => {
    setIsViewTerms(true);
  };
  const hidetermsviewModal = () => {
    setIsViewTerms(false);
  };
  const showinstallmentsModal = () => {
    setIsInstallments(true);
  };
  const hideinstallmentsModal = () => {
    setIsInstallments(false);
  };
  const showtermsapplyModal = () => {
    setIsApplyTerm(true);
  };
  const hidetermsapplyModal = () => {
    setIsApplyTerm(false);
  };
  const [isMobilePophover, setIsMobilePophover] = useState(false);

  const showmobilePopuphvr = () =>{
    setIsMobilePophover(true);
  }
  const hidemobilePopuphvr = () =>{
    setIsMobilePophover(false);
  }
  return (
    <>
      {isViewTerms ? <ViewtermsModal onHide={hidetermsviewModal} /> : null}
      {isInstallments ? <InstallmentsModal onHide={hideinstallmentsModal} /> : null}
      {isApplyTerm ? <ApplytermsModal onHide={hidetermsapplyModal} /> : null}
      {
        (isMobilePophover) ? < MobileMoreInfoPopover details={data.length > 0 ? data[33].page_description : ""} onHide={hidemobilePopuphvr} /> : null
      }
      <div className="dkg-referrals-mainCon"
      style={{
        marginTop: "110px",
        padding: "25px 0",
        backgroundColor: "#eaeaea",
        height: "calc(100vh - 124px)",
        overflowY: "hidden"
      }}
      >
        <div className="dkg-referrals-content">
          <div className="dkg-referrals-mainRow">
            <div className="col-md-12 col-12 dkg-referrals-mainCol">
              <div className="dkg-refreral-srchinputCon">
                <div className="dkg-refreral-srchinput">
                  <input
                    type="text"
                    className="dkg-search-input"
                    placeholder="Search…"
                  />
                  <span className="dkg-search-icon">
                    <FaSearch />
                  </span>
                </div>
              </div>
              <div className="dkg-prefrefreal-page-header">
                <h2 className="dkg-services-page-title">
                 REFERRALS{" "}
                  <MoreInfoPopover
                    details={data.length > 0 ? data[33].page_description : ""}
                  />
                </h2>
                <h2 className="dkg-services-page-mo-title">
                 REFERRALS{" "}
                  <span className="dkg-mo-pophvr-icon" onClick={showmobilePopuphvr}>
                    <FaInfoCircle />
                  </span>
                </h2>
              </div>
              <div className="dkg-referrals-filter-Con">
                <RightModalFilter />
              </div>
            </div>
            <div className="col-md-12 col-12 dkg-referrals-Cols dkg-referrals-col-12">
              <div className="dkg-referrals-tableCon">
                <table className="table dkg-referrals-table table-bordered">
                  <thead>
                    <tr>
                      <th>Status</th>
                      <th>Posted</th>
                      <th>Company</th>
                      <th>Job Title</th>
                      <th>Amount</th>
                      <th>Timelines</th>
                      <th>Conditions</th>
                      <th>Apply</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div
                          className="dkg-referal-status-bg-cols"
                          style={{ backgroundColor: "#03A786", color: "#fff" }}
                        >
                          <div className="dkg-referal-status-title">Sample</div>
                        </div>
                      </td>
                      <td>30 min</td>
                      <td>
                        <div className="dkg-refreral-comapny-logoCon">
                          <img
                            src={TeleperformanceLogo}
                            className="dkg-refreral-comapny-logo"
                            alt="Company Logo"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="dkg-referal-jobtitle-cols">
                          <h5 className="dkg-referal-jobtitle">
                            Customer Services Job in Europe
                          </h5>
                          <div className="dkg-referal-bottom-sec">
                            <ul className="dkg-referal-bottom-ul">
                              <li className="dkg-referal-bottom-li">
                                <span className="dkg-referal-li-icon">
                                  <i className="far fa fa-building"></i>
                                </span>
                                Company
                              </li>
                              <li className="dkg-referal-bottom-li">
                                <span className="dkg-referal-li-icon">
                                  <i className="far fa fa-building"></i>
                                </span>
                                Project
                              </li>
                            </ul>
                            <ul className="dkg-referal-bottom-ul">
                              <li className="dkg-referal-bottom-li">
                                <span className="dkg-referal-li-icon">
                                  <i className="far fa fa-user"></i>
                                </span>
                                Role Type
                              </li>
                              <li className="dkg-referal-bottom-li">
                                <span className="dkg-referal-li-icon">
                                  <i className="far fa fa-language"></i>
                                </span>
                                Language
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                      <td><FaEuroSign /></td>
                      <td>
                        <div className="dkg-buy-btn-con text-center w-100">
                          <Link
                            to="#"
                            className="btn dkg-buy-btn"
                            onClick={showinstallmentsModal}
                          >
                            View Details
                          </Link>
                        </div>
                      </td>
                      <td>
                        <div className="dkg-buy-btn-con text-center w-100">
                          <Link
                            to="#"
                            className="btn dkg-buy-btn"
                            onClick={showtermsviewModal}
                          >
                            View Details
                          </Link>
                        </div>
                      </td>
                      <td>
                        <div className="dkg-buy-btn-con text-center w-100">
                          <Link
                            to="#"
                            className="btn dkg-buy-btn"
                            onClick={showtermsapplyModal}
                          >
                            Apply
                          </Link>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Referrals;
