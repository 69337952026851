import React from 'react'
import  './myprofile.scss'

const index = () => {
  return (
    <>
      <div className="dkg-service-myprofile-main"></div>
    </>
  )
}

export default index