
import React, { useState } from "react";
// import './moreinfo.scss';
import { FaInfoCircle } from "react-icons/fa";
import { Modal } from 'react-bootstrap';



const MobileMoreInfoPopover = ({ details , onHide }) => {
    const [display, setDisplay] = useState("notdisplayed");
    const showButton = e => {
        e.preventDefault();
        setDisplay("displayed");
    };

    const hideButton = e => {
        e.preventDefault();
        setDisplay("notdisplayed");
    };
    return (
        <>
            <Modal
                show={true}
                onHide={onHide}
                className="dkg-mobile-pophvr-modal-21"
                
            >
                <Modal.Header className="dkg-mobile-pophvr-modal-hdr" closeButton>
                    {/* <Modal.Title></Modal.Title> */}
                </Modal.Header>
                <Modal.Body className="dkg-mobile-popuphvr-body">
                    {/* <span className='dkg-infoicon' onMouseEnter={e => showButton(e)} onMouseLeave={e => hideButton(e)}><FaInfoCircle />
                        <div className={display}>
                            <p>
                                {details}
                            </p>
                        </div>
                    </span> */}
                    <div className="dkg-pophvr-content-con">
                        <p>
                            {details}
                        </p>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    )
}

export default MobileMoreInfoPopover;
