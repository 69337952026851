import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./services-overview.scss";
import { getCMSHeadingText } from "../../../../../slice/frontendCms/frontendCmsSlice";
import { FaUser } from "react-icons/fa"; 
import AboutsBanner from '../../../../../assets/images/about-banner.jpg' 
import LowCost from '../../../../../assets/images/low-cost.png'
import QualityWork from '../../../../../assets/images/quailty-work.png' 
import QuickDelvery from '../../../../../assets/images/quick-delvery.png'

const AboutService = () => {
  const dispatch = useDispatch();
  const { isLoading, getcmsList } = useSelector((state) => state.cmslist);
  const [data, setData] = useState([]);
  useEffect(() => {
    if (!isLoading && getcmsList && getcmsList.length > 0) {
      setData(getcmsList);
    }
  }, [isLoading]);

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(getCMSHeadingText({}));
      } catch (err) {}
    }
    fetchData();
  }, [dispatch]);

  // const [isViewmoreModal, setIsViewmoreModal] = useState(false);
  // const showviewmoreModal = () => {
  //   setIsViewmoreModal(true);
  // };
  // const hideviewmoreModal = () => {
  //   setIsViewmoreModal(false);
  // };

  return (
    <>
      <div className="dkg-services-mainpageCon container-fluid">
        <div className="dkg-services-mainCol">
          <h2 className="dkg-services-page-title">
            {data.length > 0 ? data[17].page_description : ""}
          </h2>

          <p className="dkg-services-page-text">
            {data.length > 0 ? data[18].page_description : ""}
          </p>
        </div>
        <div className="dkg-about-service-blueCon">
          <div className="dkg-service-block-con">
            <div className="dkg-service-mainRow row">
              <div className="dkg-about-service-leftcon col-md-6 col-sm-12">
                  <div className="dkg-about-service-imgCon">
                    <img src={AboutsBanner} alt="About Image" className="dkg-about-service-img" />
                  </div>
              </div>
              <div className="dkg-about-service-rightcon col-md-6 col-sm-12">
                <div className="dkg-about-us-subtitleCon">
                  <h2 className="dkg-about-us-subtitle">Why Choose Us?</h2>
                </div>
                <div className="dkg-about-us-desc">
                  <p>{data.length > 0 ? data[35].page_description : ""}
                  </p>
                  <p>{data.length > 0 ? data[36].page_description : ""}
                  </p>
                </div>
                <div className="dkg-aboutus-cardCon">
                  <ul className="dkg-aboutus-card-ul">
                    <li className="dkg-aboutus-card-li">
                      <Link to="#" className="dkg-aboutus-card">
                        <div className="dkg-aboutus-icon">
                          <img src={LowCost} className="dkg-aboutus-img" alt="Low Cost" />
                        </div>
                        <div className="dkg-aboutus-cardtitle">Low Cost</div>
                      </Link>
                    </li>
                    <li className="dkg-aboutus-card-li">
                      <Link to="#" className="dkg-aboutus-card">
                        <div className="dkg-aboutus-icon">
                          <img src={QualityWork} className="dkg-aboutus-img" alt="Quality Work" />
                        </div>
                        <div className="dkg-aboutus-cardtitle">High Quality</div>
                      </Link>
                    </li>
                    <li className="dkg-aboutus-card-li">
                      <Link to="#" className="dkg-aboutus-card">
                        <div className="dkg-aboutus-icon">
                          <img src={QuickDelvery} className="dkg-aboutus-img" alt="Fast Delevery" />
                        </div>
                        <div className="dkg-aboutus-cardtitle">Fast Delivery</div>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutService;
