import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetData, PostData, PutData } from "../../services";

export const addNewFolder = createAsyncThunk(
  "jobspec/addnewfolder",
  async ({ name, status }, thunkAPI) => {
    const res = await PostData(true, "/jobSpec/jobsApi/add-new-folder/", {
      status,
      name,
    });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const applicationList = createAsyncThunk(
  "jobspec/application/list",
  async ({ status }, thunkAPI) => {
    const res = await PostData(
      true,
      "/jobSpec/list/livejobs/application-list",
      {
        status,
      }
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteJobSpec = createAsyncThunk(
  "jobspec/deleteJobSpec",
  async ({ id }, thunkAPI) => {
    const res = await PostData(true, "/jobSpec/jobsApi/delete-job-spec/", {
      id,
    });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateFileMail = createAsyncThunk(
  "jobspec/updateMailFile",
  async ({ internal_mailbox_id, type, filename, file_url }, thunkAPI) => {
    const res = await PostData(true, "/jobSpec/jobsApi/update-mail-file", {
      internal_mailbox_id,
      type,
      filename,
      file_url,
    });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateJobSpec = createAsyncThunk(
  "jobspec/update-new-job",
  async (
    { id, job_id, category, company, project, role, lang, name, status },
    thunkAPI
  ) => {
    const res = await PostData(true, "/jobSpec/jobsApi/update-new-job/", {
      id,
      job_id,
      category,
      company,
      project,
      role,
      lang,
      name,
      status,
    });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const addNewJob = createAsyncThunk(
  "jobspec/addnew",
  async (
    { job_id, category, company, project, role, lang, name, status },
    thunkAPI
  ) => {
    const res = await PostData(true, "/jobSpec/jobsApi/add-new-job/", {
      job_id,
      category,
      company,
      project,
      role,
      lang,
      name,
      status,
    });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const addJobApplicationForm = createAsyncThunk(
  "jobspec/add-application-job",
  async (
    { values, canEmail, screeningQuestions, contactDetailsQuestions },
    thunkAPI
  ) => {
    const res = await PostData(true, "/jobSpec/jobsApi/add-application-job/", {
      values,
      canEmail,
      screeningQuestions,
      contactDetailsQuestions,
    });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateHowToApplyDetails = createAsyncThunk(
  "jobspec/updateHowToApplyDetails",
  async ({ key, value }, thunkAPI) => {
    const res = await PostData(
      true,
      "/jobSpec/jobsApi/update-how-to-apply-details/",
      {
        key,
        value,
      }
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const HowToApplyDetails = createAsyncThunk(
  "jobspec/HowToApplyDetails",
  async ({}, thunkAPI) => {
    const res = await PostData(true, "/jobSpec/jobsApi/how-to-apply-details");
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const QuestionsList = createAsyncThunk(
  "jobspec/QuestionsList",
  async ({}, thunkAPI) => {
    const res = await PostData(true, "/jobSpec/jobsApi/questions-list");
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const jobsCount = createAsyncThunk(
  "jobspec/saved-inactive/specCount",
  async ({ status, job_status }, thunkAPI) => {
    const res = await PostData(true, "/jobSpec/jobsApi/job-count", {
      status,
      job_status,
    });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const folderJobsCount = createAsyncThunk(
  "jobspec/folder/FolderspecCount",
  async ({ status }, thunkAPI) => {
    const res = await PostData(true, "/jobSpec/jobsApi/folder-job-count", {
      status,
    });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateJobSpecFolder = createAsyncThunk(
  "jobs/folder/update",
  async ({ id, key, value }, thunkAPI) => {
    const res = await PutData(true, "/jobSpec/jobsApi/" + id, { key, value });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateJob = createAsyncThunk(
  "jobspec/active-jobs/update",
  async ({ id, key, value }, thunkAPI) => {
    const res = await PutData(true, "/jobSpec/jobsApi/update-spec/" + id, {
      key,
      value,
    });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const closedlist = createAsyncThunk(
  "jobspec/closed-jobs/list",
  async ({ company, status }, thunkAPI) => {
    const res = await PostData(true, "/jobSpec/list/livejobs", {
      company,
      status,
    });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateApplication = createAsyncThunk(
  "jobspec/live-jobs/update-application",
  async ({ id, key, value }, thunkAPI) => {
    const res = await PutData(
      true,
      "/jobSpec/list/livejobs/update-application/" + id,
      { key, value }
    );
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

const jobsSlice = createSlice({
  name: "jobspeclist",
  initialState: {
    applicationListData: [],
    applicationListStatus: [],
    jobSpecDataList: [],
    howToApplyDetails: [],
    questionsList: [],
    FolderJobSpecDataList: [],
    jobSpecCount: [],
    folderJobSpecCount: [],
    isLoading: false,
    isUpdate: false,
    isError: false,
    closedjobspecDataList: [],
    isSuccess: false,
    message: "",
    isSpecCountLoading: false,
    isFolderSpecCountLoading: false,
    doc: "",
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isUpdate = false;
      state.isSpecCountLoading = false;
      state.isFolderSpecCountLoading = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addNewFolder.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isFolderSpecCountLoading = true;
    });
    builder.addCase(addNewFolder.fulfilled, (state, { payload }) => {
      if (payload.status) {
        state.FolderJobSpecDataList = payload.result;
        state.isFolderSpecCountLoading = false;
        state.folderJobSpecCount.unshift(payload.result);
        state.isSuccess = payload.status;
      } else {
        state.isSuccess = "error";
      }
      state.message = payload.message;
      state.isLoading = false;
    });

    builder.addCase(addNewFolder.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
      state.isFolderSpecCountLoading = false;
    });

    builder.addCase(folderJobsCount.pending, (state) => {
      state.isFolderSpecCountLoading = true;
    });
    builder.addCase(folderJobsCount.fulfilled, (state, { payload }) => {
      state.folderJobSpecCount = payload.result;
      state.isFolderSpecCountLoading = false;
    });
    builder.addCase(folderJobsCount.rejected, (state, action) => {
      state.isFolderSpecCountLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(addNewJob.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(addNewJob.fulfilled, (state, { payload }) => {
      state.jobSpecDataList = payload.result;
      state.message = payload.message;
      state.isSuccess = payload.status;
      state.isLoading = false;
    });
    builder.addCase(addNewJob.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(addJobApplicationForm.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(addJobApplicationForm.fulfilled, (state, { payload }) => {
      state.doc = payload.doc;
      state.message = payload.message;
      state.isSuccess = payload.status;
      state.isLoading = false;
    });
    builder.addCase(addJobApplicationForm.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(updateHowToApplyDetails.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isUpdate = true;
    });
    builder.addCase(updateHowToApplyDetails.fulfilled, (state, { payload }) => {
      state.howToApplyDetails = payload.result;
      state.message = payload.message;
      state.isSuccess = payload.status;
      state.isLoading = false;
      state.isUpdate = true;
    });
    builder.addCase(updateHowToApplyDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(HowToApplyDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(HowToApplyDetails.fulfilled, (state, { payload }) => {
      state.howToApplyDetails = payload.result;
      state.message = payload.message;
      state.isLoading = false;
    });
    builder.addCase(HowToApplyDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(QuestionsList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(QuestionsList.fulfilled, (state, { payload }) => {
      state.questionsList = payload.result;
      state.message = payload.message;
      state.isLoading = false;
    });
    builder.addCase(QuestionsList.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobsCount.pending, (state) => {
      state.isSpecCountLoading = true;
    });
    builder.addCase(jobsCount.fulfilled, (state, { payload }) => {
      state.jobSpecCount = payload.result;
      state.isSpecCountLoading = false;
    });
    builder.addCase(jobsCount.rejected, (state, action) => {
      state.isSpecCountLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(updateJobSpecFolder.pending, (state) => {
      state.isFolderSpecCountLoading = true;
    });

    builder.addCase(updateJobSpecFolder.fulfilled, (state, { payload }) => {
      let mainArray = state.folderJobSpecCount;
      let finalResult = [];
      finalResult = mainArray.map((r) => {
        if (r.id == payload.result.id) {
          return {
            id: r.id,
            name: r.name,
            status: payload.result.status,
            count: r.count,
          };
        } else {
          return r;
        }
      });

      state.isFolderSpecCountLoading = false;
      state.folderJobSpecCount = finalResult;
      state.message = payload.message;
      state.isSuccess = payload.status;
      state.isLoading = false;
    });

    builder.addCase(updateJobSpecFolder.rejected, (state, action) => {
      state.isError = true;
      state.isFolderSpecCountLoading = false;
      state.message = "Something went wrong";
    });

    builder.addCase(updateJobSpec.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(updateJobSpec.fulfilled, (state, { payload }) => {
      state.message = payload.message;
      state.isSuccess = payload.status;
      state.isLoading = false;
      state.isUpdate = true;
    });
    builder.addCase(updateJobSpec.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(deleteJobSpec.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(deleteJobSpec.fulfilled, (state, { payload }) => {
      state.message = payload.message;
      state.isSuccess = payload.status;
      state.isLoading = false;
      state.isUpdate = true;
    });
    builder.addCase(deleteJobSpec.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(updateFileMail.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(updateFileMail.fulfilled, (state, { payload }) => {
      state.message = payload.message;
      state.isSuccess = payload.status;
      state.isLoading = false;
      state.isUpdate = true;
    });
    builder.addCase(updateFileMail.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(closedlist.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(closedlist.fulfilled, (state, { payload }) => {
      state.closedjobspecDataList = payload.result;
      state.message = payload.message;
      state.isLoading = false;
    });
    builder.addCase(closedlist.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(updateJob.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(updateJob.fulfilled, (state, action) => {
      state.isUpdate = true;
      state.isLoading = true;
    });

    builder.addCase(updateJob.rejected, (state, action) => {
      state.isError = true;
      // state.message = action.error.message;
    });

    builder.addCase(updateApplication.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(updateApplication.fulfilled, (state, action) => {
      state.isUpdate = true;
      state.isLoading = true;
    });

    builder.addCase(updateApplication.rejected, (state, action) => {
      state.isError = true;
      // state.message = action.error.message;
    });

    builder.addCase(applicationList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(applicationList.fulfilled, (state, { payload }) => {
      state.applicationListData = payload.result;
      state.applicationListStatus = {
        newCase: payload.newCase,
        inProgress: payload.inProgress,
        closed: payload.closed,
      };
      state.message = payload.message;
      state.isLoading = false;
    });
    builder.addCase(applicationList.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });
  },
});
export const { clearState } = jobsSlice.actions;
export default jobsSlice.reducer;
