import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import "./testimonial-modal.scss";

const FilterTestimonialModal = ({ onHide  }) => {
  return (
    <>
      <Modal
       show={true}
       onHide={onHide}
        className="dkg-testimonial-box-modal-21"
      >
        <Modal.Header closeButton className="dkg-testimonials-modalheader-21">
          <Modal.Title>FILTER TESTIMONIALS</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dkg-testimonials-modalbody-21">
          <div className="dk-testmonial-filter">
            <div className="dk-testmonial-filter-search">
              <div className="input-group dk-testmonials-search-box">
                <input
                  type="text"
                  className="dk-test-search-input form-control"
                  placeholder="Search Keywords"
                />
                <div className="input-group-append dkg-input-append">
                  <button
                    className="btn btn-secondary dk-test-search-box-addon"
                    type="button"
                  >
                    <i className="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="dk-testmonial-filter-cols">
              <div className="dkg-filtertitle">Timeline <span className="dkg-testimonial-count">Counts</span></div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="timeline1"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="timeline1"
                  >
                    All Time
                  </label>
                </div>
                <span>233</span>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="timeline2"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="timeline2"
                  >
                    Last 30 days
                  </label>
                </div>
                <span>333</span>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="timeline3"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="timeline3"
                  >
                    Last 6 months
                  </label>
                </div>
                <span>93</span>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="timeline4"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="timeline4"
                  >
                    Last 1 years
                  </label>
                </div>
                <span>23</span>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="timeline5"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="timeline5"
                  >
                    Last 2 years
                  </label>
                </div>
                <span>52</span>
              </div>
            </div>
            <div className="dk-testmonial-filter-cols">
              <div className="dkg-filtertitle">Services</div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="Services1"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="Services1"
                  >
                    Services
                  </label>
                </div>
                <span>73</span>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="Services2"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="Services2"
                  >
                    Services
                  </label>
                </div>
                <span>86</span>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="Services3"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="Services3"
                  >
                    Services
                  </label>
                </div>
                <span>63</span>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="Services4"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="Services4"
                  >
                    Services
                  </label>
                </div>
                <span>28</span>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="Services5"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="Services5"
                  >
                    Services
                  </label>
                </div>
                <span>35</span>
              </div>
            </div>
            <div className="dk-testmonial-filter-cols">
              <div className="dkg-filtertitle">Country</div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="location-checkbox-1"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="location-checkbox-1"
                  >
                    Ireland
                  </label>
                </div>
                <span>33</span>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="location-checkbox-2"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="location-checkbox-2"
                  >
                    India
                  </label>
                </div>
                <span>44</span>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="location-checkbox-3"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="location-checkbox-3"
                  >
                    Germany
                  </label>
                </div>
                <span>55</span>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="location-checkbox-4"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="location-checkbox-4"
                  >
                    Greece
                  </label>
                </div>
                <span>66</span>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="location-checkbox-5"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="location-checkbox-5"
                  >
                    Norway
                  </label>
                </div>
                <span>77</span>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FilterTestimonialModal;
