import React,{useState , useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { FaInfo } from "react-icons/fa"; 
import {getCMS} from "../../../../../slice/cmsSlice";



const DkgResumeTab = () => {
    const dispatch = useDispatch();
    const { isSuccess, cmsList } = useSelector((state) => state.cmsdata);
    const [dkgResume, setDKGResume] = useState("");
    useEffect(() => {
        dispatch(getCMS({}));
      }, [dispatch]);
    
      useEffect(() => {
        if (!isSuccess && cmsList.length > 0) {
            setDKGResume(cmsList[34].description);
        }
    }, [isSuccess]);
  return (
    <>
       <div className="dkg-desiredjob-header">
            <h1 className="dkg-contact-title">MY DK RESUME</h1>
            <span className="dkg-desired-info d-none">
            <FaInfo />
            </span>
        </div>
        <div className="dkg-desired-job-mainCon dkg-cv-preview-main dkg-myresume-main-234">
            <div className="dkg-desired-textarea-Con">
            <div className="form-control dkg-desired-textarea">
                {dkgResume}
            </div>
            </div>
        </div>
    </>
  )
}

export default DkgResumeTab