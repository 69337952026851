import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Modal, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SubmitButton from "../../../../ui/submitButton";
import FilePreview from "./attachmentPreview/FilePreview";
import {
  faEnvelope,
  faPaperclip,
  faXmark,
  faPaperPlane,
  faSignature,
  faFloppyDisk,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Autocomplete, TextField } from "@mui/material";
import "./composemail.scss";
import MessageBox from "./MessageBox";
import { useDispatch, useSelector } from "react-redux";
import { FaRegEdit } from "react-icons/fa";
import { Form, Row } from "react-bootstrap";
import {
  clearState,
  getInternalMailCount,
  getUserMailboxSignature,
  sendInternalMail,
} from "../../../../../slice/mailbox/mailboxSlice";
import {
  showError,
  showSuccess,
} from "../../../../../slice/utils/message/messageSlice";
import { GetData } from "../../../../../services";
import { createFilterOptions } from "@material-ui/lab";

const ReplyMail = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const {
    isLoading,
    isBtnSuccess,
    message,
    signature,
    isInternalEmailCategory,
    internalEmailCategoryList,
    singleInternalMail,
  } = useSelector((state) => state.mailbox);
  const { isProfileLoading, profileDetails } = useSelector(
    (state) => state.candidates
  );
  const { userInfo } = useSelector((state) => state.auth);

  //const { userInfo } = useSelector((state) => state.auth);
  const fileInput = useRef();

  const [emailCategory, setEmailCategory] = useState([]);
  const [categoryName, setCategoryName] = useState("Select...");
  const [mailList, setMailList] = useState([]);
  const [tempMailList, setTempMailList] = useState([]);
  const [from, setFrom] = useState("jobs@dkgrecruitment.com");
  const [to, setTo] = useState([]);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [isAttachment, setIsAttachment] = useState(1);
  const [attachment, setAttachment] = useState([]);
  const [displayFileAttachment, setDisplayFileAttachment] = useState([]);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fileName, setFileName] = useState("");
  const [currentAccount, setCurrentAccount] = useState("Form");
  const [systemEmail, setSystemEmail] = useState("");
  const [candidateId, setCandidateId] = useState("");
  const [id, setId] = useState("");
  const [categoryValue, setCategoryValue] = useState("");

  const [eventKey, SetEventKey] = useState("tabs_1");

  const filterOptions = createFilterOptions({
    limit: 30,
  });

  useEffect(() => {
    if (isInternalEmailCategory && internalEmailCategoryList.length > 0) {
      setEmailCategory(internalEmailCategoryList);
    }
  }, [isInternalEmailCategory]);

  useEffect(() => {
    setSystemEmail(profileDetails.system_email);
    setCandidateId(profileDetails.canId);
    setId(profileDetails.id);
  }, [isProfileLoading]);

  const fileUploadHandler = (e) => {
    setAttachment([...e.target.files]);
    setFileName(e.target.files.length + " files");
  };

  const removeAttachFile = (attachmentIndex) => {
    if (attachmentIndex > -1) {
      setAttachment((prevAttachments) => {
        const updatedAttachments = [...prevAttachments];
        updatedAttachments.splice(attachmentIndex, 1);
        return updatedAttachments;
      });
    }
  };

  useEffect(() => {
    if (attachment.length === 0) {
      setFileName("");
    } else {
      setFileName(attachment.length + " files");
    }
  }, [attachment]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccess(false);
    setLoading(true);
    if (!body) {
      dispatch(showError({ msg: "All fields required" }));
      setLoading(false);
    } else {
      console.log("singleInternalMail -->", singleInternalMail);
      const formData = new FormData();
      formData.append("from", systemEmail);
      formData.append("to", process.env.REACT_APP_RECRUITER_EMAIL);
      formData.append(
        "subject",
        +props.email?.mailbox?.subject.includes("Re:")
          ? +props.email?.mailbox?.subject
          : "Re: " + props.email?.mailbox?.subject
      );
      formData.append("body", body);
      formData.append("category_id", props.email.category.id);
      formData.append("candidate_id", candidateId);
      formData.append("in_reply_id", singleInternalMail.mailbox.id);
      if (attachment.length > 0) {
        formData.append("isAttachment", 1);
        // formData.append("attachments", attachment);
        // for (let i = 0; i < attachment.length; i++) {
        // }
        attachment.forEach((file) => {
          // Append each attachment as an array with the same key
          formData.append("attachments", file);
        });
      }

      await dispatch(sendInternalMail(formData));
    }
  };

  const clearForm = () => {
    // setLoading(false);
    setFileName("");
    setTo([]);
    setSubject("");
    setBody("");
    setCategoryValue("");
    setCategoryName("Select...");
    // fileInput.current.value = ''
    setIsAttachment(0);
    setAttachment([]);
    setDisplayFileAttachment([]);
  };

  const deleteImg = () => {
    setFileName("");
    fileInput.current.value = "";
    setIsAttachment(0);
    setAttachment([]);
    setDisplayFileAttachment([]);
  };

  useEffect(() => {
    if (isBtnSuccess) {
      // setLoading(false);
      setFileName("");
      setSubject("");
      setBody("");
      setCategoryValue("");
      setCategoryName("Select...");
      // fileInput.current.value = ''
      setIsAttachment(0);
      setLoading(false);
      setAttachment([]);
      setDisplayFileAttachment([]);
      dispatch(showSuccess({ msg: message }));
      dispatch(getInternalMailCount({ can_id: id, role: userInfo.role }));
      setShow(false);
      dispatch(clearState());
    }
  }, [isBtnSuccess]);

  const selectCategory = (e) => {
    const type = e.target.dataset.type;
    const value = e.target.dataset.value;
    const name = e.target.dataset.name;
    setCategoryValue(value);
    setCategoryName(name);
  };

  const [shoePreview, setPreview] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const [fileType, setFileType] = useState(null);

  const openPreview = (item) => {
    const split = item.name.split('.')
    const ext = split[split.length - 1]
    setFileType(ext);
    setPreview(true);
    setPreviewFile(URL.createObjectURL(item));
  };

  const closePreview = () => {
    setPreview(false);
    setPreviewFile(null);
  };

  return (
    <>
      <FilePreview show={shoePreview} file={previewFile} frameType={'pdf'} onClose={closePreview} />
      <span className="d-flex flex-column px-3 action-button" onClick={handleShow}>
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="reply"
          className="svg-inline--fa fa-reply "
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            fill="currentColor"
            d="M205 34.8c11.5 5.1 19 16.6 19 29.2v64H336c97.2 0 176 78.8 176 176c0 113.3-81.5 163.9-100.2 174.1c-2.5 1.4-5.3 1.9-8.1 1.9c-10.9 0-19.7-8.9-19.7-19.7c0-7.5 4.3-14.4 9.8-19.5c9.4-8.8 22.2-26.4 22.2-56.7c0-53-43-96-96-96H224v64c0 12.6-7.4 24.1-19 29.2s-25 3-34.4-5.4l-160-144C3.9 225.7 0 217.1 0 208s3.9-17.7 10.6-23.8l160-144c9.4-8.5 22.9-10.6 34.4-5.4z"
          ></path>
        </svg>{" "}
        Reply
      </span>
      <Modal
        className="dk-composeMailModal dkg-myacc-composeModal right"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header
          closeButton
          className="dkg-myacc-composeModalHeader dkg-myacc-newHeader"
        >
          <div className="dk-composeMail-option">
            <div className="d-flex align-items-center justify-content-end">
              <div className="dk-composeMail-PanelCols">
                <div className="dk-mailAttach-file">
                  {/* <input type="file" name="" id="" name="attachment-files" onChange={fileUploadHandler} ref={fileInput} multiple /> */}
                  <span>
                    <FontAwesomeIcon icon={faPaperclip} />
                  </span>
                  <Form.File
                    type="file"
                    className="custom-file-label"
                    ref={fileInput}
                    id="inputGroupFile01"
                    label={fileName}
                    onClick={(e) => setAttachment([])}
                    onChange={fileUploadHandler}
                    multiple
                    custom
                  />
                  {/* <span><FontAwesomeIcon icon={faPaperclip} /> Attach File</span> */}
                </div>
              </div>
              <div className="dk-composeMail-PanelCols">
                <div className="dk-mailAttach-file">
                  <Link to="#" onClick={deleteImg} className="d-none">
                    <FontAwesomeIcon icon={faTrashAlt} /> Delete
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <Modal.Title>REPLY MAIL</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dkg-myacc-composeModalBody">
          <div className="dkg-sendemail-boxCon">
            <form onSubmit={handleSubmit} enctype="multipart/form-data">
              <div className="d-flex">
                <div className="dk-mailField">
                  <div className="dkg-cand-mail-mainCon">
                    <div className="dkg-cand-mail-leftCon">
                      <div className="dk-mailSendOption">
                        <div className="dk-mailOption-items">To...</div>
                        <input
                          type="text"
                          value="recruiter@dkgrecruitment.com"
                          className="form-control readonly"
                          readOnly
                        />
                        {/* <div className="dk-composeMail-option">
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="dk-composeMail-PanelCols">
                              <div className="dk-mailAttach-file">
                                <span>
                                  <FontAwesomeIcon icon={faPaperclip} />
                                </span>
                                <Form.File type="file" className="custom-file-label" ref={fileInput} id="inputGroupFile01" label={fileName} onClick={(e) => setAttachment([])} onChange={fileUploadHandler} multiple custom />
                              </div>
                            </div>
                            <div className="dk-composeMail-PanelCols">
                              <div className="dk-mailAttach-file">
                                <Link to="#" onClick={deleteImg} className="d-none">
                                  <FontAwesomeIcon icon={faTrashAlt} /> Delete
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                      <div className="dk-mailSendOption">
                        <div className="dk-mailOption-items">From </div>
                        <input
                          type="text"
                          className="form-control readonly"
                          value={systemEmail}
                          readOnly
                        />
                      </div>
                      <div className="dk-mailSendOption">
                        <div className="dk-mailOption-items">Category</div>
                        <div className="d-flex w-100">
                          <div className="dk-mailsCategory dkg-disable-category">
                            <Dropdown
                              className="dkg-company-selectDropdown"
                              disabled
                            >
                              <Dropdown.Toggle
                                variant=""
                                className="dkg-company-selectDropdown-toggle"
                                disabled
                              >
                                {props.email.category.name}
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="dkg-company-selectDropdown-Menu">
                                {emailCategory.map((data, index) => (
                                  <Dropdown.Item
                                    href="#"
                                    key={index + 1}
                                    data-type="category"
                                    data-value={data.id}
                                    data-name={data.name}
                                    onClick={selectCategory}
                                    className="dkg-company-selectDropdown-Item"
                                  >
                                    {data.name}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="dkg-cand-mail-rightCon">
                      <div className="dkg-cand-mail-attachtment-ul">
                        {attachment.map((item, index) => (
                          <li className="dkg-cand-mail-attachtment-li">
                            <div className="dkg-file-typeicon">
                              <i className="far fa-file-pdf"></i>
                            </div>
                            <div className="dkg-cand-mail-file">
                              <div className="dkg-cand-mail-file-name">
                                {item.name}
                              </div>
                              <div className="dkg-cand-mail-cross-icon" onClick={() => openPreview(item)}>
                                <i className="fas fa-eye"></i>
                              </div>
                              <div
                                className="dkg-cand-mail-cross-icon"
                                onClick={() => removeAttachFile(index)}
                              >
                                <i className="fas fa-times"></i>
                              </div>
                            </div>
                          </li>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="dk-mailSendOption mb-2">
                    <div className="dk-mailOption-items mb-2">Subject</div>
                    <div className="d-flex w-100">
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        value={
                          props.email?.mailbox?.subject.includes("Re:")
                            ? props.email?.mailbox?.subject
                            : "Re: " + props.email?.mailbox?.subject
                        }
                        style={{ backgroundColor: "#f9f9f9" }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="dk-textCompose-mail">
                <div className="dk-mailAttach-file d-none">
                  {/* <Link to="#"><FontAwesomeIcon icon={faSignature} /> Add Signature</Link> */}
                  <Dropdown>
                    <Dropdown.Toggle variant="" id="dropdown-basic">
                      <FontAwesomeIcon icon={faSignature} /> Add Signature
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">DK Global</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Deepak Kumar
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        DK Global CRM
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <MessageBox
                  signature={signature}
                  onChange={(value) => setBody(value)}
                />
                <div className="dk-send-email-btnCon" style={{ top: "60px" }}>
                  <SubmitButton
                    txt="Send"
                    loading={isLoading}
                    success={isBtnSuccess}
                    position="justify-content-center"
                  />
                </div>
              </div>

              <div className="dk-composeMail-option">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="dk-composeMail-PanelCols"></div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ReplyMail;
