import React, { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom'
import { Nav, Tab , Dropdown} from "react-bootstrap";
import ContactDetailsTab from "./ContactDetailsTab";
import DkgResumeTab from "./DkgResumeTab";
import ProfileSummaryTab from "./ProfileSummaryTab";
import WrokexpTab from "./WrokexpTab";
import QualificationTab from "./QualificationTab";
import WorkplaceTab from "./WorkplaceTab";
import TechnicalskillTab from "./TechnicalskillTab";
import LanguageskillTab from "./LanguageskillTab";
import AchivementsTab from "./AchivementsTab";
import WorktrainingTab from "./WorktrainingTab";
import HobbiesTab from "./HobbiesTab";
import EmployerTab from "./EmployerTab";
import GdprConsentTab from "./GdprConsentTab";

const CoverLetter = () => {
  // const selecttabList = ['My DK Resume','Contact Details' , 'Profile Summary' ,'Work Experience','Qualifications',
  // 'Workplace Skills' ,'Technical Skills' , 'Language Skills','Achievements','Work Training','Hobbies & Interests',
  // 'Employer Reference','GDPR Consent'
  // ]

  const desiredjobitems = [
    { id: 1 , value: "My DK Resume" },
    { id: 2, value: "Contact Details" },
    { id: 3, value: "Profile Summary" },
    { id: 4, value: "Work Experience" },
    { id: 5, value: "Qualifications" },
    { id: 6, value: "Workplace Skills" },
    { id: 7, value: "Technical Skills" },
    { id: 8, value: "Language Skills" },
    { id: 9, value: "Achievements" },
    { id: 10, value: "Work Training" },
    { id: 11, value: "Hobbies & Interests" },
    { id: 12, value: "Employer Reference" },
    { id: 13, value: "GDPR Consent" },
  ];
  const [selectedItem, setSelectedItem] = useState("My DK Resume");
  const handleDropdownChange = (item) => {
    setSelectedItem(item);
  };
  const renderComponent = () => {
    switch (selectedItem) {
      case 'My DK Resume':
        return  <DkgResumeTab />; 
      case 'Contact Details':
        return <ContactDetailsTab/>;
      case 'Profile Summary':
        return <ProfileSummaryTab />; 
        
      case 'Work Experience':
        return <WrokexpTab/>; 

      case 'Qualifications':
        return <QualificationTab/>;

      case 'Workplace Skills':
        return <WorkplaceTab/>; 

      case 'Technical Skills':
        return <TechnicalskillTab/>; 
    
      case 'Language Skills':
        return <LanguageskillTab/>;  

      case 'Achievements':
        return <AchivementsTab/>;

      case 'Work Training':
        return <WorktrainingTab/>;

      case 'Hobbies & Interests':
        return <HobbiesTab/>; 

      case 'Employer Reference':
        return <EmployerTab/>; 

      case 'GDPR Consent':
        return <GdprConsentTab/>; 
  
      default:
        return null;
    }
  };
  return (
    <>
      <div className="dkg-contact-mainCon dkg-cand-resumes-pageCon">
        <div className="dkg-cand-resumes-moCon">
          <div className="dkg-resumes-header-centerCon">
             <h2 className="dkg-cand-categoty-title w-100 text-center" style={{fontSize: "1.25rem" , fontWeight: "600"}}>Select Category</h2>
            <div className="dkg-resumes-DropdownCon">
               <Dropdown className='dkg-resumes-Dropdown'>
                  <Dropdown.Toggle variant="" className='dkg-resumes-Dropdown-toggle'>
                    {selectedItem}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className='dkg-resumes-Dropdown-Menu w-100'>
                      {/* {
                        selecttabList.map((item , i) =>{
                            return(
                              <>
                                  <Dropdown.Item className="dropdown-item"  onClick={() => handleDropdownChange(`${item}`)}  key={i+1} >{item}</Dropdown.Item>
                              </>
                            )
                        })
                      } */}
                       {
                        desiredjobitems.map((item , index) =>{
                          return(
                            <>
                                <Dropdown.Item href="#" key={item.id} 
                                className={item.value === selectedItem ? 'selected-item ' : ''}
                                onClick={() => handleDropdownChange(item.value)}
                                
                                >{item.value}</Dropdown.Item>
                            </>
                          )

                        } )
                      }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
          </div>
           {renderComponent()}
        </div>
        <div className="dkg-contact-main">
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="dkg-cv-preview"
          >
            <div className="row justify-content-center dkg-contact-mainRow">
              <div className="col-md-2 dkg-contact-main-sideCol">
                <Nav
                  variant="pills dkg-aboutus-navpills dkg-mydesired-job-navpills"
                  className="flex-column"
                >
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-cv-preview"
                      className="dkg-aboutus-navlink"
                    >
                      CV Preview
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-contact-details"
                      className="dkg-aboutus-navlink"
                    >
                      Contact Details
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-cv-summary"
                      className="dkg-aboutus-navlink"
                    >
                      Profile Summary
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-experience"
                      className="dkg-aboutus-navlink"
                    >
                      Work Experience
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-education"
                      className="dkg-aboutus-navlink"
                    >
                      Qualifications
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-work-training"
                      className="dkg-aboutus-navlink"
                    >
                     Job Training
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-cv-achievements"
                      className="dkg-aboutus-navlink"
                    >
                      Achievements
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-cv-language"
                      className="dkg-aboutus-navlink"
                    >
                      Workplace Skills
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-technical-skills"
                      className="dkg-aboutus-navlink"
                    >
                      Technical Skills
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-cv-new-language"
                      className="dkg-aboutus-navlink"
                    >
                      Language Skills
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-hobbies-interest"
                      className="dkg-aboutus-navlink"
                    >
                      Hobbies & Interests
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-refrences"
                      className="dkg-aboutus-navlink"
                    >
                      Employer Reference
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-gdpr-consent"
                      className="dkg-aboutus-navlink"
                    >
                      Give GDPR Consent
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <div className="col-md-10 col-10 dkg-contact-mainCol">
                <Tab.Content className="dkg-aboutus-tabcontent">
                  <Tab.Pane
                    eventKey="dkg-cv-preview"
                    className="dkg-aboutus-tabpane"
                  >
                    <DkgResumeTab />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-contact-details"
                    className="dkg-aboutus-tabpane"
                  >
                    <ContactDetailsTab />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-cv-summary"
                    className="dkg-aboutus-tabpane"
                  >
                   <ProfileSummaryTab />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-experience"
                    className="dkg-aboutus-tabpane"
                  >
                    <WrokexpTab />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-education"
                    className="dkg-aboutus-tabpane"
                  >
                    <QualificationTab />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-cv-language"
                    className="dkg-aboutus-tabpane"
                  >
                    <WorkplaceTab />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-technical-skills"
                    className="dkg-aboutus-tabpane"
                  >
                    <TechnicalskillTab />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-cv-new-language"
                    className="dkg-aboutus-tabpane"
                  >
                    <LanguageskillTab />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-cv-achievements"
                    className="dkg-aboutus-tabpane"
                  >
                    <AchivementsTab />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-work-training"
                    className="dkg-aboutus-tabpane"
                  >
                    <WorktrainingTab />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-hobbies-interest"
                    className="dkg-aboutus-tabpane"
                  >
                    <HobbiesTab />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-refrences"
                    className="dkg-aboutus-tabpane"
                  >
                    <EmployerTab />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-gdpr-consent"
                    className="dkg-aboutus-tabpane"
                  >
                    <GdprConsentTab />
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};

export default CoverLetter;
