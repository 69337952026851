import React from "react";
import JoditEditor from "../../../../../ui/JoditEditor";

const ProjectTab = ({ pdata, title }) => {
  return (
    <>
      <div className="dkg-jopspec-pageheader">
        <div className="dkg-jopspec-page-title">{title}</div>
      </div>
      <div className="dkg-jobspec-read-editor-Con">
        <div className="dkg-jobspec-read-editor">
          {/* <JoditEditor
            datavalue={pdata}
            toolbaarControl={false}
            readonlyControl={true}
            spellcheckControll={false}
            showCharsCounterControll={false}
            showWordsCounterControll={false}
            widthControll={800}
            heightControll={455}
          /> */}
          <div
            className="dkg-jobspec-htmleditor"
            dangerouslySetInnerHTML={{
              __html:
              pdata,
            }}
          ></div>
        </div>
      </div>
    </>
  );
};

export default ProjectTab;
