import React from "react";
import { Link } from "react-router-dom";
// import './myacc-submenu.scss';

const MyProfileSubmenu = () => {
    const pathname = window.location.pathname;
    const segment2 = pathname.split("/")[2];

    return (
        <React.Fragment>
            <ul className="dkg-myacc-submenu-ul">
                <li>
                    <Link to="/my-profile/summary" className={`${(segment2 === 'summary') ? 'grey-active' : ''}`}>Summary</Link>
                </li>
                <li>
                    <Link to="/my-profile/my-account" className={`${(segment2 === 'my-account') ? 'grey-active' : ''}`}>My Account</Link>
                </li>
                <li>
                    <Link to="/my-profile/my-contact" className={`${(segment2 === 'my-contact') ? 'grey-active' : ''}`}>My Contact</Link>
                </li>
                <li>
                    <Link to="/my-profile/my-profile" className={`${(segment2 === 'my-profile') ? 'grey-active' : ''}`}>My Profile</Link>
                </li>
                <li>
                    <Link to="/my-profile/my-applications" className={`${(segment2 === 'my-applications') ? 'grey-active' : ''}`}>My Applications</Link>
                </li>
                <li>
                    <Link to="/my-profile/my-resume" className={`${(segment2 === 'my-resume') ? 'grey-active' : ''}`}>My Resume</Link>
                </li>
                <li>
                    <Link to="/my-profile/my-referrals" className={`${(segment2 === 'my-referrals') ? 'grey-active' : ''}`}>My Referrals</Link>
                </li>
                <li className="psp">
                    <Link to="/my-profile/my-documents" className={`${(segment2 === 'my-documents') ? 'grey-active' : ''}`}>My Documents</Link>
                </li>
                <li>
                    <Link to="/my-profile/my-dk-services" className={`${(segment2 === 'my-dk-services') ? 'grey-active' : ''}`}>My DK Services</Link>
                </li>
                {/* <li>
                    <Link to="/my-profile/upload-cv" className={`${(segment2 === 'upload-cv') ? 'grey-active' : ''}`}>Upload CV</Link>
                </li> */}
                <li className="psp">
                    <Link to="/my-profile/my-dk-resume" className={`${(segment2 === 'my-dk-resume') ? 'grey-active' : ''}`}>My DK Resume</Link>
                </li>
                <li className="pbp">
                    <Link to="/my-profile/my-desired-job" className={`${(segment2 === 'my-desired-job') ? 'grey-active' : ''}`}>My Desired Job</Link>
                </li>
                
            </ul>
        </React.Fragment>
    );
}

export default MyProfileSubmenu;
