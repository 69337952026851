import React,{useState , useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { FaInfo } from "react-icons/fa"; 
import {getCMS} from "../../../../../slice/cmsSlice"
import AddNewExpModal from './modal/AddNewExp'

const EmployerTab = () => {
    const dispatch = useDispatch();
    const { isSuccess, cmsList } = useSelector((state) => state.cmsdata);
    const [refrence, setRefrence] = useState("");
    const [isAddnewExp , setIsAddnewExp]= useState(false);
    useEffect(() => {
        dispatch(getCMS({}));
      }, [dispatch]);
      useEffect(() => {
        if (!isSuccess && cmsList.length > 0) {
          setRefrence(cmsList[44].description);
        }
      }, [isSuccess]);
      const showaddnewexpModal = () =>{
        setIsAddnewExp(true)
      }
      const hideaddnewexpModal = () =>{
        setIsAddnewExp(false)
      } 
  return (
    <>
        {
            (isAddnewExp)? <AddNewExpModal  onHide={hideaddnewexpModal} /> : null
        }
       <div className="dkg-desiredjob-header">
          <h1 className="dkg-contact-title">EMPLOYER REFERENCES</h1>
          <span className="dkg-desired-info d-none">
            <FaInfo />
          </span>
        </div>
        <div className="dkg-desired-job-mainCon dkg-cv-preview-main">
          <div className="dkg-desired-textarea-Con">
            <div className="form-control dkg-desired-textarea">
              {refrence}
            </div>
          </div>
          <div className="dkg-cvpreview-center-sec dkg-workexpirece-mainCon">
            <div className="dkg-workexpirece-rightcon">
              <div className="dkg-work-exp-right-header">
                <div className="dkg-work-exp-right-title">Employer Reference</div>
                  <ul className="dkg-work-exp-right-btn-ul">
                    <li className="dkg-work-exp-right-btn-li"> 
                      <Link to="#" className="dkg-work-exp-right-btn" onClick={showaddnewexpModal} >Add Employer Reference</Link>
                    </li>
                  </ul>
              </div>
              <div className="dkg-work-exp-textarea-Con">
                <div name="" id="" className="dkg-work-exp-textarea" > </div>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}

export default EmployerTab;