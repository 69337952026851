
import React, { useState } from "react";
// import './moreinfo.scss';
import { FaInfoCircle } from "react-icons/fa";


const MoreInfoPopover = ({ details }) => {
    const [display, setDisplay] = useState("notdisplayed");
    const showButton = e => {
        e.preventDefault();
        setDisplay("displayed");
    };

    const hideButton = e => {
        e.preventDefault();
        setDisplay("notdisplayed");
    };
    return (
        <>
           
            <span className='dkg-infoicon' onMouseEnter={e => showButton(e)} onMouseLeave={e => hideButton(e)}><FaInfoCircle />
                <div className={display}>
                    <p>
                        {details}
                    </p>
                </div>
            </span>
            {/* <span className="dkg-jobs-faqicon">
                <Link to="#" className="dkg-jobsfaq-tab"><FaInfo /> </Link>
            </span> */}
        </>
    )
}

export default MoreInfoPopover;
