import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import DkGlobalCRMLogoRecImg from "../../../../../../assets/images/dkgcrmlogo.png";
// import DkGlobalRecruitment from "../../../../../../assets/images/dk-global-rec-logo.png";
// import DkGlobalJobs from "../../../../../../assets/images/dkg-jobs.png";
// import DkGConsults from "../../../../../../assets/images/dkg-consults.png";
// import DkGlobalAcademy from "../../../../../../assets/images/dkg-acadmey.png";
// import DkGRecruiters from "../../../../../../assets/images/dkg-recruiters.png";
// import { FaInfoCircle} from 'react-icons/fa';
// import ProgressBar from "./ProgressBar";
import ViewProject from "./ViewProject";
//import { getprojectData } from "../../../../../../slice/cmsSlice";

const OtherProject = ({ otherProjectdetails, projectinfo }) => {
  const { projectDataList } = useSelector((state) => state.cmsdata);
  return (
    <>
      <div className="dk-otherprojectMain">
        <div className="dkg-other-project-header">
          <p className="dkg-otherprject-page-title">
            {otherProjectdetails != ""
              ? otherProjectdetails.otherProjectOverview
              : ""}
          </p>
        </div>
        <div className="dkg-projectsbox-Con">
          <div className="dkg-projectbox-mainCon">
            <div className="dk-compnyproject-cols">
              <div className="dkg-compnyproject-box">
                <Link
                  onClick={() =>
                    window.open(
                      projectDataList != "" ? projectDataList[0].site_url : "",
                      "_blank"
                    )
                  }
                >
                  <div className="dk-visitLogo">
                    <img
                      src={
                        projectDataList != ""
                          ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                            projectDataList[0].image
                          : ""
                      }
                      alt=""
                      style={{ maxWidth: "162px" }}
                    />
                  </div>
                </Link>
                <div className="dkg-project-content">
                  <div className="dkg-content-title-desc">
                    <div className="dkg-pj-content-title">
                      {projectDataList != "" ? projectDataList[0].title : ""}
                    </div>
                    <div className="dkg-pj-content-desc">
                      {projectDataList != "" ? projectDataList[0].details : ""}
                    </div>
                  </div>
                  <div className="dkg-content-bottom-sec-Con">
                    <div className="dkg-roject-cmplition-Con">
                      <button className="dkg-project-comletetion">
                        Project Completed :{" "}
                        {projectDataList != ""
                          ? projectDataList[0].completed
                          : ""}{" "}
                        %
                      </button>
                    </div>
                  </div>
                </div>
                <div className="d-flex dkg-project-btn-progess-Con">
                  <ViewProject
                    summary={
                      projectDataList != "" ? projectDataList[0].details : ""
                    }
                  />
                  <div className="dkg-progress-barCon">
                    {projectDataList != ""
                      ? "Completed - " + projectDataList[0].completed + "%"
                      : "-"}

                    {/* <ProgressBar bgColor={"#3c97b6"} progress={progress} /> */}
                  </div>
                </div>
              </div>
              <div className="dkg-compnyproject-box">
                <Link
                  onClick={() =>
                    window.open(
                      projectDataList != "" ? projectDataList[1].site_url : "",
                      "_blank"
                    )
                  }
                >
                  <div className="dk-visitLogo">
                    <img
                      src={
                        projectDataList != ""
                          ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                            projectDataList[1].image
                          : ""
                      }
                      alt=""
                      style={{ maxWidth: "162px" }}
                    />
                  </div>
                </Link>
                <div className="dkg-project-content">
                  <div className="dkg-content-title-desc">
                    <div className="dkg-pj-content-title">
                      {projectDataList != "" ? projectDataList[1].title : ""}
                    </div>
                    <div className="dkg-pj-content-desc">
                      {projectDataList != "" ? projectDataList[1].details : ""}
                    </div>
                  </div>
                  <div className="dkg-content-bottom-sec-Con">
                    <div className="dkg-roject-cmplition-Con">
                      <button className="dkg-project-comletetion">
                        Project Completed :{" "}
                        {projectDataList != ""
                          ? projectDataList[1].completed
                          : ""}{" "}
                        %
                      </button>
                    </div>
                  </div>
                </div>
                <div className="d-flex dkg-project-btn-progess-Con">
                  <ViewProject
                    summary={
                      projectDataList != "" ? projectDataList[1].details : ""
                    }
                  />
                  <div className="dkg-progress-barCon">
                    {projectDataList != ""
                      ? "Completed - " + projectDataList[1].completed + "%"
                      : "-"}
                    {/* <ProgressBar bgColor={"#3c97b6"} progress={progress} /> */}
                  </div>
                </div>
              </div>
              <div className="dkg-compnyproject-box">
                <Link
                  onClick={() =>
                    window.open(
                      projectDataList != "" ? projectDataList[2].site_url : "",
                      "_blank"
                    )
                  }
                >
                  <div className="dk-visitLogo">
                    <img
                      src={
                        projectDataList != ""
                          ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                            projectDataList[2].image
                          : ""
                      }
                      alt=""
                      style={{ maxWidth: "162px" }}
                    />
                  </div>
                </Link>
                <div className="dkg-project-content">
                  <div className="dkg-content-title-desc">
                    <div className="dkg-pj-content-title">
                      {projectDataList != "" ? projectDataList[2].title : ""}
                    </div>
                    <div className="dkg-pj-content-desc">
                      {projectDataList != "" ? projectDataList[2].details : ""}
                    </div>
                  </div>
                  <div className="dkg-content-bottom-sec-Con">
                    <div className="dkg-roject-cmplition-Con">
                      <button className="dkg-project-comletetion">
                        Project Completed :{" "}
                        {projectDataList != ""
                          ? projectDataList[2].completed
                          : ""}{" "}
                        %
                      </button>
                    </div>
                  </div>
                </div>
                <div className="d-flex dkg-project-btn-progess-Con">
                  <ViewProject
                    summary={
                      projectDataList != "" ? projectDataList[2].details : ""
                    }
                  />
                  <div className="dkg-progress-barCon">
                    {projectDataList != ""
                      ? "Completed - " + projectDataList[2].completed + "%"
                      : "-"}
                    {/* <ProgressBar bgColor={"#3c97b6"} progress={progress} /> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="dk-compnyproject-cols">
              <div className="dkg-compnyproject-box">
                <Link
                  onClick={() =>
                    window.open(
                      projectDataList != "" ? projectDataList[3].site_url : "",
                      "_blank"
                    )
                  }
                >
                  <div className="dk-visitLogo">
                    <img
                      src={
                        projectDataList != ""
                          ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                            projectDataList[3].image
                          : ""
                      }
                      alt=""
                      style={{ maxWidth: "162px" }}
                    />
                  </div>
                </Link>
                <div className="dkg-project-content">
                  <div className="dkg-content-title-desc">
                    <div className="dkg-pj-content-title">
                      {projectDataList != "" ? projectDataList[3].title : ""}
                    </div>
                    <div className="dkg-pj-content-desc">
                      {projectDataList != "" ? projectDataList[3].details : ""}
                    </div>
                  </div>
                  <div className="dkg-content-bottom-sec-Con">
                    <div className="dkg-roject-cmplition-Con">
                      <button className="dkg-project-comletetion">
                        Project Completed :{" "}
                        {projectDataList != ""
                          ? projectDataList[3].completed
                          : ""}{" "}
                        %
                      </button>
                    </div>
                  </div>
                </div>
                <div className="d-flex dkg-project-btn-progess-Con">
                  <ViewProject
                    summary={
                      projectDataList != "" ? projectDataList[3].details : ""
                    }
                  />
                  <div className="dkg-progress-barCon">
                    {projectDataList != ""
                      ? "Completed - " + projectDataList[3].completed + "%"
                      : "-"}
                    {/* <ProgressBar bgColor={"#3c97b6"} progress={progress} /> */}
                  </div>
                </div>
              </div>
              <div className="dkg-compnyproject-box">
                <Link
                  onClick={() =>
                    window.open(
                      projectDataList != "" ? projectDataList[4].site_url : "",
                      "_blank"
                    )
                  }
                >
                  <div className="dk-visitLogo">
                    <img
                      src={
                        projectDataList != ""
                          ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                            projectDataList[4].image
                          : ""
                      }
                      alt=""
                      style={{ maxWidth: "162px" }}
                    />
                  </div>
                </Link>
                <div className="dkg-project-content">
                  <div className="dkg-content-title-desc">
                    <div className="dkg-pj-content-title">
                      {projectDataList != "" ? projectDataList[4].title : ""}
                    </div>
                    <div className="dkg-pj-content-desc">
                      {projectDataList != "" ? projectDataList[4].details : ""}
                    </div>
                  </div>
                  <div className="dkg-content-bottom-sec-Con">
                    <div className="dkg-roject-cmplition-Con">
                      <button className="dkg-project-comletetion">
                        Project Completed :{" "}
                        {projectDataList != ""
                          ? projectDataList[4].completed
                          : ""}{" "}
                        %
                      </button>
                    </div>
                  </div>
                </div>
                <div className="d-flex dkg-project-btn-progess-Con">
                  <ViewProject
                    summary={
                      projectDataList != "" ? projectDataList[4].details : ""
                    }
                  />
                  <div className="dkg-progress-barCon">
                    {projectDataList != ""
                      ? "Completed - " + projectDataList[4].completed + "%"
                      : "-"}
                    {/* <ProgressBar bgColor={"#3c97b6"} progress={progress} /> */}
                  </div>
                </div>
              </div>
              <div className="dkg-compnyproject-box">
                <Link
                  onClick={() =>
                    window.open(
                      projectDataList != "" ? projectDataList[5].site_url : "",
                      "_blank"
                    )
                  }
                >
                  <div className="dk-visitLogo">
                    <img
                      src={
                        projectDataList != ""
                          ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                            projectDataList[5].image
                          : ""
                      }
                      alt=""
                      style={{ maxWidth: "162px" }}
                    />
                  </div>
                </Link>
                <div className="dkg-project-content">
                  <div className="dkg-content-title-desc">
                    <div className="dkg-pj-content-title">
                      {projectDataList != "" ? projectDataList[5].title : ""}
                    </div>
                    <div className="dkg-pj-content-desc">
                      {projectDataList != "" ? projectDataList[5].details : ""}
                    </div>
                  </div>
                  <div className="dkg-content-bottom-sec-Con">
                    <div className="dkg-roject-cmplition-Con">
                      <button className="dkg-project-comletetion">
                        Project Completed :{" "}
                        {projectDataList != ""
                          ? projectDataList[5].completed
                          : ""}{" "}
                        %
                      </button>
                    </div>
                  </div>
                </div>
                <div className="d-flex dkg-project-btn-progess-Con">
                  <ViewProject
                    summary={
                      projectDataList != "" ? projectDataList[5].details : ""
                    }
                  />
                  <div className="dkg-progress-barCon">
                    {projectDataList != ""
                      ? "Completed - " + projectDataList[5].completed + "%"
                      : "-"}
                    {/* <ProgressBar bgColor={"#3c97b6"} progress={progress} /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtherProject;
