import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Nav } from "react-bootstrap";
import AirDatepicker from "air-datepicker";
import en from "air-datepicker/locale/en";
import "air-datepicker/air-datepicker.css";
import "../../../addnew/addnewjob.scss";
import Editjobdetails from "./editjobdetails/Editjobdetails";
import { clearState, updateJob } from "../../../../../../slice/jobs/jobsSlice";
import { companyProjectData, companyRoleData } from "../../../../../../slice/companies/clients/clientSlice";
import SavedAds from "../saved_ads/SavedAds";

const Jobdetails = ({ jobId, company_name }) => {
  const dispatch = useDispatch();
  const { isDetailsLoading, jobDetails, isUpdate } = useSelector(
    (state) => state.jobs
  );

  const [data, setData] = useState([]);
  const [newStatus, setNewStatus] = useState('')
  const [taxcal, setTaxCal] = useState(jobDetails.taxCalculator)
  const [livingCost, setLivingCost] = useState(jobDetails.costOfLiving)

  useEffect(() => {
    if (jobDetails && !isDetailsLoading) {
      setData(jobDetails);
      setNewStatus(jobDetails.status);
      setTaxCal(jobDetails.taxCalculator);
      setLivingCost(jobDetails.costOfLiving);
      dispatch(companyProjectData({ name: company_name }))
      dispatch(companyRoleData({ name: company_name }))

    }
  }, [isDetailsLoading]);

  useEffect(() => {
    if (isUpdate) {
      dispatch(clearState());
      setData(jobDetails);
      setNewStatus(jobDetails.status);
    }
  }, [isUpdate, newStatus]);
  let ids = data.id;


  new AirDatepicker("#date1", {
    position: "bottom center",
    locale: en,
    dateFormat: "d MMM yy",
    onSelect({ date, formattedDate, datepicker }) {
      dispatch(updateJob({ id: ids, key: "date1", value: formattedDate }));
    },
  });

  new AirDatepicker("#date2", {
    position: "bottom center",
    locale: en,
    dateFormat: "d MMM yy",
    onSelect({ date, formattedDate, datepicker }) {
      dispatch(updateJob({ id: ids, key: "date2", value: formattedDate }));
    },
  });

  new AirDatepicker("#date3", {
    position: "bottom center",
    locale: en,
    dateFormat: "d MMM yy",
    onSelect({ date, formattedDate, datepicker }) {
      dispatch(updateJob({ id: ids, key: "date3", value: formattedDate }));
    },
  });

  let gross_value = "";
  if (data.gross_title === null) {
    gross_value = "Monthly Gross";
  } else {
    if (data.gross_title === "Monthly Gross") {
      gross_value = "Annual Gross";
    } else {
      gross_value = "Monthly Gross";
    }
  }
  const onItemClick = (id, columnId, value) => {
    dispatch(updateJob({ id, key: columnId, value }));
  };
  const updateProfile = async (e) => {
    //const id = e.target.dataset.id;
    const keys = e.target.dataset.key;
    const values = e.target.dataset.value;
    await dispatch(updateJob({ id: jobId, key: keys, value: values }));
  };


  return (
    <>
      <Editjobdetails jobId={jobId} />
    </>
  );
};

export default Jobdetails;
