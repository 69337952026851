import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ButtonGroup, Button, Dropdown } from "react-bootstrap";
// import './applied-jobs.scss'
import { FaFilter, FaInfo ,FaInfoCircle } from "react-icons/fa";
import MoreInfoPopover from "./MoreInfoPopover";
import MobileMoreInfoPopover from "./MobileMoreInfoPopover";

import { getCMS } from "../../../../slice/cmsSlice";
//import OfferedTable from './OfferedTable'
import OfferedTable from "../../../../components/pages/myaccount/applied/AppliedTable";
import { getApplications } from "../../../../slice/candidates/candidatesSlice";

const JoinedTbl = () => {
  const dispatch = useDispatch();
  const { isSuccess, cmsList } = useSelector((state) => state.cmsdata);
  const { userInfo } = useSelector((state) => state.auth);
  const { applicationList, isApplication, isWithdrwan } = useSelector(
    (state) => state.candidate
  );

  const [applicationData, setApplicationData] = useState([]);
  const [openCount, setOpenCount] = useState(0);
  const [closedCount, setClosedCount] = useState(0);

  useEffect(() => {
    if (isApplication) {
      setApplicationData(applicationList);
      setOpenCount(applicationList.length > 0 ? applicationList.filter((item) => item.applicationType === "Joined" && item.progressMList[0]?.status === "Open").length : 0)
      setClosedCount(applicationList.length > 0 ? applicationList.filter((item) => item.applicationType === "Joined" && item.progressMList[0]?.status === "Closed").length : 0)
    }
  }, [isApplication]);

  const [details, setDetails] = useState("");
  useEffect(() => {
    dispatch(getApplications({ can_id: userInfo.canId }));
    dispatch(getCMS({}));
  }, [dispatch]);

  useEffect(() => {
    if (!isSuccess && cmsList.length > 0) {
      setDetails(cmsList[55].description);
    }
  }, [isSuccess]);
   // Filter data based on search input
   const [searchText, setSearchText] = useState('');
   const onChangeSearchTitle = (e) => {
    setSearchText(e.target.value)
     const filteredData = applicationData
     .filter(item => item.applicationType === "Joined")
     .filter(item => 
       item.category.toLowerCase().includes(searchText.toLowerCase()) ||
       item.company.toLowerCase().includes(searchText.toLowerCase()) ||
       item.jobSpecTitle.toLowerCase().includes(searchText.toLowerCase()) ||
       item.jobtitle.toLowerCase().includes(searchText.toLowerCase()) ||
       item.project.toLowerCase().includes(searchText.toLowerCase()) ||
       item.role.toLowerCase().includes(searchText.toLowerCase())||
       item.language.toLowerCase().includes(searchText.toLowerCase())
     );
     setApplicationData(filteredData)   
   };
 
   const clearSearch = () => {
     setSearchText("")
     setApplicationData(applicationList)
   };
   const showAlldata = () => {
     setApplicationData(applicationList)
   };
 

   const [filterTags, setFilterTags] = useState("Status");
   const [bgcolor, setBgColor] = useState("#fff");
 
   const filterRecord = async(v,bgcolor)=>{
     setFilterTags(v)
     setBgColor(bgcolor)
     const filteredData = applicationList.length>0? applicationList.filter((item) => item.applicationType === "Joined" && (item.progressMList[0]?.status === v)): [];
     setApplicationData(filteredData);
    
   }
 
   const removeTag = async (e) => {    
     setBgColor("#fff")
     setFilterTags(e.target.dataset.type)  
     setApplicationData(applicationList) 
   };
   const [isMobilePophover, setIsMobilePophover] = useState(false);

  const showmobilePopuphvr = () =>{
    setIsMobilePophover(true);
  }
  const hidemobilePopuphvr = () =>{
    setIsMobilePophover(false);
  }
  return (
    <>
      {
        (isMobilePophover) ? < MobileMoreInfoPopover details={details} onHide={hidemobilePopuphvr} /> : null
      }
      <div
        className="container-fluid dk-cliBoardViewPageCont"
        style={{
          marginTop: "110px",
          padding: "25px 15px",
          backgroundColor: "#eaeaea",
          height: "calc(100vh - 50px)",
          overflowY: "hidden"
        }}
      >
        <div className="row">
          <div className="col-12 col-md-12 pl-0 pr-0">
            <div className="dk-qulaifyTopHead pl-3 pr-3 dkg-js-applied-job-topHead">
              <div className="dk-qulaifyLeftTopHead dkg-js-applied-job-leftHead">
                <div className="dk-SrchInputBox mr-2">
                  <div className="input-group">
                  <input
                      className="form-control"
                      placeholder={`Search...`}
                      value={searchText}
                      onChange={onChangeSearchTitle}
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) => (e.target.placeholder = "Search...")}
                    />
                   
                    {searchText !== undefined && searchText.length > 0 ? (
                        <div className="input-group-append dkg-js-applied-input-append">
                          <button
                            className="btn btn-secondary"
                            type="button"
                            onClick={clearSearch}
                          >
                            <i className="fa fa-times"></i>
                          </button>
                        </div>
                      ) : (
                        <div className="input-group-append dkg-js-applied-input-append">
                          <button className="btn btn-secondary" type="button" onClick={showAlldata}>
                            <i className="fa fa-search"></i>
                          </button>
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className="dk-qulaifyTopHeadTitle">
                <h3 className="title">
                  JOINED
                  <MoreInfoPopover details={details} />
                </h3>
                <h3 className="dkg-mobinfo-title">
                  JOINED 
                  <span className="dkg-mo-pophvr-icon" onClick={showmobilePopuphvr}>
                    <FaInfoCircle />
                  </span>
                </h3>
              </div>
              <div className="dk-qulaifyRightTopHead">
                <button className="btn btn-secondary" type="button">
                  <FaFilter />
                </button>
                <Dropdown className="dkg-referal-status-drpdwn">
                  <Dropdown.Toggle
                    variant=""
                    id=""
                    className="dkg-referal-status-drpdwn-toggle"
                    style={{ backgroundColor: bgcolor }}
                  >
                      {filterTags}
                  </Dropdown.Toggle>
                  {
                    filterTags!=="Status" ? <><i
                    className="fas fa-times-circle crossUserFilter statusFilter dk-lrftCross"
                    //  style={{ right: "250px" }}
                    data-type="Status"
                    onClick={removeTag}
                  ></i></>
                   : null
                  }
                  <Dropdown.Menu className="dkg-referal-status-drpdwn-menu">
                    <Dropdown.Item
                      href="#"
                      className="dkg-referal-status-item"
                      style={{ backgroundColor: "#3a9469", color: "#fff" }}
                      onClick={()=>filterRecord('Open',"#3a9469 !important")}
                    >
                      Open
                      <span className="dkg-lv-statuscounter">{ openCount }</span>
                      <span className="dkg-lv-statuscounter"></span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#"
                      className="dkg-referal-status-item"
                      style={{ backgroundColor: "#dc3545", color: "#fff" }}
                      onClick={()=>filterRecord('Closed',"#dc3545 !importan")}
                    >
                      Closed
                      <span className="dkg-lv-statuscounter">{closedCount}</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="dk-jobboardTableCon dkg-jobapplied-mainCon">
              <div className="dkg-jobapplied-con-fluid container-fluid">
                <div className="dkg-jobapplied-mainRow row">
                  <div className="dkg-jobapplied-mainCol col-lg-9 col-md-9 col-sm-12 m-auto">
                    <div className="dkg-app-applied-tableCon">
                      <table className="table dkg-app-applied-table mt-0 mb-0">
                        {applicationData.length > 0 ? (
                          <>
                            {applicationData
                            .filter((data) => data.applicationType === "Joined")
                            .map((item, index) => (
                              <OfferedTable key={index + 1} itemdata={item} />
                            ))}
                          </>
                        ) : null}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JoinedTbl;
