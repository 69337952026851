import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//import SubmitButton from "../../../../ui/submitButton";
import {
  FaXRay,
  FaGlobeEurope,
  FaRocketchat,
  FaEnvelope,
} from "react-icons/fa";
import "./feedback-service.scss";
import Dropdown from "react-bootstrap/Dropdown";
import { getCMSHeadingText } from "../../../../../slice/frontendCms/frontendCmsSlice";
import { FaRegPaperPlane } from "react-icons/fa";

const Index = () => {
  const dispatch = useDispatch();
  const { isLoading, getcmsList } = useSelector((state) => state.cmslist);
  const [data, setData] = useState([]);
  useEffect(() => {
    if (!isLoading && getcmsList && getcmsList.length > 0) {
      setData(getcmsList);
    }
  }, [isLoading]);

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(getCMSHeadingText({}));
      } catch (err) {}
    }
    fetchData();
  }, [dispatch]);

  return (
    <>
      <div className="dkg-feedback-service-mainpageCon container-fluid">
        <div className="dkg-feedback-service-container">
          <div className="row dkg-feedback-service-mainRow">
            <div className="col-12 dkg-feedback-service-mainCol">
              <h2 className="dkg-fd-service-page-title">
                {" "}
                {data.length > 0 ? data[19].page_description : ""}
              </h2>
              <p className="dkg-fd-service-page-text">
                {data.length > 0 ? data[20].page_description : ""}
              </p>
            </div>
            <div className="dkg-serivce-contact-maincon">
              <div className="dkg-cn-feedback-maincard">
                <div className="dkg-cn-feedback-leftcon">
                  <ul className="dkg-cn-feedback-ul">
                    <li className="dkg-cn-feedback-li">
                      <div className="dkg-feedback-li-icon">
                        <div className="dkg-cn-feedback-icon">
                          <FaXRay />
                        </div>
                      </div>
                      <div className="dkg-feedback-li-titleCon">
                        <div className="dkg-feedback-li-title">
                          General Support
                        </div>
                        <div className="dkg-feedback-li-subtitle">
                          Get queries answered
                        </div>
                      </div>
                    </li>
                    <li className="dkg-cn-feedback-li">
                      <div className="dkg-feedback-li-icon">
                        <div className="dkg-cn-feedback-icon">
                          <FaEnvelope />
                        </div>
                      </div>
                      <div className="dkg-feedback-li-titleCon">
                        <div className="dkg-feedback-li-title">
                          Giving Feedback
                        </div>
                        <div className="dkg-feedback-li-subtitle">
                          Tell us your experience
                        </div>
                      </div>
                    </li>
                    <li className="dkg-cn-feedback-li">
                      <div className="dkg-feedback-li-icon">
                        <div className="dkg-cn-feedback-icon">
                          <FaRocketchat />
                        </div>
                      </div>
                      <div className="dkg-feedback-li-titleCon">
                        <div className="dkg-feedback-li-title">
                          Giving Suggestion
                        </div>
                        <div className="dkg-feedback-li-subtitle">
                          Give us your suggestions
                        </div>
                      </div>
                    </li>
                    <li className="dkg-cn-feedback-li">
                      <div className="dkg-feedback-li-icon">
                        <div className="dkg-cn-feedback-icon">
                          <FaGlobeEurope />
                        </div>
                      </div>
                      <div className="dkg-feedback-li-titleCon">
                        <div className="dkg-feedback-li-title">
                          Service Not Working
                        </div>
                        <div className="dkg-feedback-li-subtitle">
                          Inform the technical faults
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="dkg-cn-feedback-rihtcon">
                  <div className="cn-feedback-right-title">
                    How can we help you today?
                  </div>
                  <div className="cn-feedback-right-subtitle d-none">
                    Let us know who are you and what you are looking for below
                  </div>
                  <div className="dkg-cn-feedback-form">
                    <div className="dkg-cn-feedback-formgrp dkg-cn-fd-form-inputgrp">
                      <input
                        type="text"
                        className="dkg-cn-feedback-input form-control"
                        placeholder="Your Name"
                        name="feedback-name"
                        id=""
                      />
                    </div>
                    <div className="dkg-cn-feedback-formgrp dkg-cn-fd-form-inputgrp">
                      <input
                        type="email"
                        className="dkg-cn-feedback-input form-control"
                        placeholder="Email Address"
                        name="feedback-email"
                        id=""
                      />
                    </div>
                    <div className="dkg-cn-feedback-formgrp">
                      <Dropdown className="dkg-cn-feedback-dropdown">
                        <Dropdown.Toggle
                          variant=""
                          id=""
                          className="dkg-cn-fd-dropdown-toogle"
                        >
                          Select Support Type
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dkg-cn-fd-dropdown-menu">
                          <Dropdown.Item
                            href="#"
                            className="dkg-cn-fd-dropdown-item"
                          >
                            General Support
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="#"
                            className="dkg-cn-fd-dropdown-item"
                          >
                            Giving Feedback
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="#"
                            className="dkg-cn-fd-dropdown-item"
                          >
                            Giving Suggestion
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="#"
                            className="dkg-cn-fd-dropdown-item"
                          >
                            Service Not Working
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="dkg-cn-feedback-formgrp">
                      <textarea
                        className="dkg-cn-feedback-message"
                        placeholder="Write Message Here"
                        name=""
                        id=""
                      />
                    </div>
                    <div className="dkg-cn-feedback-formgrp">
                      <div className="dkg-feedback-submit-btnCon">
                        <button className="dkg-feedback-submit-btn btn">
                          CLICK HERE TO SEND
                          <span className="dkg-feedback-plane">
                            <FaRegPaperPlane />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
