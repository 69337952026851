import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Nav, Dropdown,DropdownButton,ButtonGroup, Button, Tab } from "react-bootstrap";
import { FaInfo } from "react-icons/fa";
import SubmitButton from "../../../../ui/submitButton";
import {
  getCMS,
  updatecontent,
  clearState,
} from "../../../../../slice/cmsSlice";
import MessageBox from "../../../../utils/joditEditor/MessageBox";

const MyaccResume = () => {
  const dispatch = useDispatch();
  const { isSuccess, cmsList, isError, isInsert, msg, isLoading } = useSelector(
    (state) => state.cmsdata
  );
  const [details, setDetails] = useState("");
  const [coverLetter, setCoverLetter] = useState("");

  useEffect(() => {
    dispatch(getCMS({}));
  }, [dispatch]);

  useEffect(() => {
    if (!isSuccess && cmsList.length > 0) {
      setCoverLetter(cmsList[11].otherProjectOverview);
      setDetails(cmsList);
    }
  }, [isSuccess]);

  const SubmitBtn = (id, key, value) => {
    dispatch(
      updatecontent({
        id,
        key,
        value,
      })
    );
  };
  const desiredjobitems = [
    { id: 1 , value: "Summary" },
    { id: 2, value: "My Cover Message" },
    { id: 3, value: "My Desired Countries" },
    { id: 4, value: "My Desired City/Town" },
    { id: 5, value: "My Desired Industries" },
    { id: 6, value: "My Desired Positions" },
    { id: 7, value: "My Desired Job Titles" },
    { id: 8, value: "My Desired Workplace" },
    { id: 9, value: "My Desired Salaries" },
    { id: 10, value: "My Desired Benefits" }
  ];
  // const desiredjobitems = ["Summary" ,"My Cover Message" , "My Desired Countries" , "My Desired City/Town" ,"My Desired Industries" , "My Desired Positions" , "My Desired Job Titles" ,"My Desired Workplace",
  //    "My Desired Salaries" ,"My Desired Benefits"] 

  const [selected, setSelected] = useState("Summary");
  const handleDropdownChange = (item) => {
    setSelected(item);
  };
  console.log(selected);
  const renderComponent = () => {
    switch (selected) {
      case 'Summary':
        return  <>
          <div className="dkg-desiredjob-header">
            <h1 className="dkg-contact-title">
              MY DESIRED JOB
            </h1>
            <span className="dkg-desired-info d-none">
              <FaInfo />
            </span>
          </div>
          <div className="dkg-desired-job-mainCon dkg-desired-job-summ-mainCon">
            <div className="dkg-desired-textarea-Con">
              <div className="form-control dkg-desired-textarea">
                {details != "" ? details[10].description : ""}
              </div>
            </div>
            <div className="dkg-desired-summ-body-234">
              <div className="dkg-desired-summ-box-cols">
                <div className="dkg-desired-summ-1stcols">
                  <div className="dkg-desired-summ-title">
                    My COVER MESSAGE
                  </div>
                </div>
                <div className="dkg-desired-summ-2ndcols">
                  <div className="dkg-covermsg-textCon">
                    <p className="dkg-covermsg-text">
                      Lorem ipsum dolor sit amet consectetur
                      adipisicing elit. Vero a obcaecati quod animi
                      hic ea laboriosam similique vel quia!.
                    </p>
                  </div>
                </div>
              </div>
              <div className="dkg-desired-summ-box-cols">
                <div className="dkg-desired-summ-1stcols">
                  <div className="dkg-desired-summ-title">
                    My Desired Countries
                  </div>
                </div>
                <div className="dkg-desired-summ-2ndcols">
                  <div className="dkg-myprofile-summCon">
                    <div className="dkg-myprofile-summ-btn-tagCon">
                      <Button className="dkg-desired-btntext">
                        India{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Ireland{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        UK{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        USA{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Grecee{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Germany{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Ireland{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        UK{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        USA{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Grecee{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dkg-desired-summ-box-cols">
                <div className="dkg-desired-summ-1stcols">
                  <div className="dkg-desired-summ-title">
                    My Desired City/Town
                  </div>
                </div>
                <div className="dkg-desired-summ-2ndcols">
                  <div className="dkg-myprofile-summCon">
                    <div className="dkg-myprofile-summ-btn-tagCon">
                      <Button className="dkg-desired-btntext">
                        India{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Ireland{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        UK{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        USA{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Grecee{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Germany{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Ireland{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        UK{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        USA{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Grecee{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dkg-desired-summ-box-cols">
                <div className="dkg-desired-summ-1stcols">
                  <div className="dkg-desired-summ-title">
                    My Desired Industries
                  </div>
                </div>
                <div className="dkg-desired-summ-2ndcols">
                  <div className="dkg-myprofile-summCon">
                    <div className="dkg-myprofile-summ-btn-tagCon">
                      <Button className="dkg-desired-btntext">
                        India{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Ireland{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        UK{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        USA{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Grecee{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Germany{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Ireland{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        UK{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        USA{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Grecee{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dkg-desired-summ-box-cols">
                <div className="dkg-desired-summ-1stcols">
                  <div className="dkg-desired-summ-title">
                    My Desired Positions
                  </div>
                </div>
                <div className="dkg-desired-summ-2ndcols">
                  <div className="dkg-myprofile-summCon">
                    <div className="dkg-myprofile-summ-btn-tagCon">
                      <Button className="dkg-desired-btntext">
                        India{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Ireland{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        UK{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        USA{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Grecee{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Germany{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Ireland{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        UK{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        USA{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Grecee{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dkg-desired-summ-box-cols">
                <div className="dkg-desired-summ-1stcols">
                  <div className="dkg-desired-summ-title">
                    My Desired Job Titles
                  </div>
                </div>
                <div className="dkg-desired-summ-2ndcols">
                  <div className="dkg-myprofile-summCon">
                    <div className="dkg-myprofile-summ-btn-tagCon">
                      <Button className="dkg-desired-btntext">
                        India{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Ireland{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        UK{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        USA{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Grecee{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Germany{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Ireland{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        UK{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        USA{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Grecee{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dkg-desired-summ-box-cols">
                <div className="dkg-desired-summ-1stcols">
                  <div className="dkg-desired-summ-title">
                    My Desired Workplace
                  </div>
                </div>
                <div className="dkg-desired-summ-2ndcols">
                  <div className="dkg-myprofile-summCon">
                    <div className="dkg-myprofile-summ-btn-tagCon">
                      <Button className="dkg-desired-btntext">
                        India{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Ireland{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        UK{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        USA{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Grecee{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Germany{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Ireland{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        UK{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        USA{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Grecee{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dkg-desired-summ-box-cols">
                <div className="dkg-desired-summ-1stcols">
                  <div className="dkg-desired-summ-title">
                    My Desired Salaries
                  </div>
                </div>
                <div className="dkg-desired-summ-2ndcols">
                  <div className="dkg-myprofile-summCon">
                    <div className="dkg-myprofile-summ-btn-tagCon">
                      <Button className="dkg-desired-btntext">
                        India{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Ireland{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        UK{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        USA{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Grecee{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Germany{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Ireland{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        UK{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        USA{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Grecee{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dkg-desired-summ-box-cols">
                <div className="dkg-desired-summ-1stcols">
                  <div className="dkg-desired-summ-title">
                    My Desired Benefits
                  </div>
                </div>
                <div className="dkg-desired-summ-2ndcols">
                  <div className="dkg-myprofile-summCon">
                    <div className="dkg-myprofile-summ-btn-tagCon">
                      <Button className="dkg-desired-btntext">
                        India{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Ireland{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        UK{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        USA{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Grecee{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Germany{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Ireland{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        UK{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        USA{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Grecee{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>; 
        break;
      case 'My Cover Message':
        return <>
         <div className="dkg-desiredjob-header">
            <h1 className="dkg-contact-title">My COVER MESSAGE</h1>
            <span className="dkg-desired-info d-none">
              <FaInfo />
            </span>
          </div>
          <div className="dkg-desired-job-mainCon">
            <div className="dkg-desired-textarea-Con">
              <div className="form-control dkg-desired-textarea">
                {details != "" ? details[11].description : ""}
              </div>
            </div>
            <div className="dkg-desired-center-sec dkg-desired-job-centersec">
              <textarea className="dkg-createcv-textarea"></textarea>

              {/* <MessageBox
                value={
                  coverLetter != ""
                    ? details[11].otherProjectOverview
                    : ""
                }
                onChange={(value) => setCoverLetter(value)}
              /> */}
            </div>
            <div className="dkg-desired-save-btnCon">
              <SubmitButton
                txt="Save"
                className="dkg-desired-save-btn btn "
                onClick={(e) =>
                  SubmitBtn(12, "otherProjectOverview", coverLetter)
                }
              />
            </div>
          </div>
        </>;
      break;
      case 'My Desired Countries':
        return <>
          <div className="dkg-desiredjob-header">
            <h1 className="dkg-contact-title">My Desired Countries</h1>
            <span className="dkg-desired-info d-none">
              <FaInfo />
            </span>
          </div>
          <div className="dkg-desired-job-mainCon">
            <div className="dkg-desired-textarea-Con">
              <div className="form-control dkg-desired-textarea">
                {details != "" ? details[12].description : ""}
              </div>
            </div>
            <div className="dkg-desired-center-sec dkg-desired-job-centersec">
              <div className="dkg-desired-job-leftCon">
                <div className="dkg-selected-tagCon">
                  <div className="dkg-selected-title">
                     SELECT FROM LIBRARY
                  </div>
                  <div className="dkg-st-tag-job-btn-tagCon">
                    <Button className="dkg-st-tag-btntext">
                      India{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-plus"></i>
                      </span>
                    </Button>
                    <Button className="dkg-st-tag-btntext">
                      Ireland{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-plus"></i>
                      </span>
                    </Button>
                    <Button className="dkg-st-tag-btntext">
                      UK{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-plus"></i>
                      </span>
                    </Button>
                    <Button className="dkg-st-tag-btntext">
                      USA{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-plus"></i>
                      </span>
                    </Button>
                    <Button className="dkg-st-tag-btntext">
                      Grecee{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-plus"></i>
                      </span>
                    </Button>
                    <Button className="dkg-st-tag-btntext">
                      Germany{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-plus"></i>
                      </span>
                    </Button>
                  </div>
                </div>
                <div className="dkg-dj-rightsecond-cols">
                  <div className="dkg-desired-job-inputCon">
                    <div className="dkg-createnew-tag-title">
                      Create New Tag
                    </div>
                    <div className="dkg-desired-job-inputCon">
                      <input
                        type="text"
                        className="dkg-desired-job-input form-control"
                        placeholder=""
                        defaultValue=""
                      />
                      <div className="dkg-desired-job-save-btnCon">
                        <SubmitButton
                          txt="Save"
                          className="dkg-desired-job-save-btn btn"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dkg-desired-job-rightCon">
                <div className="dkg-dj-rightfirst-cols">
                  <div className="dkg-saved-cv-tag-title">
                    MY PROFILE TAGS
                  </div>
                  <div className="dkg-desired-job-btn-tagCon">
                    <Button className="dkg-desired-btntext">
                      India{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-times"></i>
                      </span>
                    </Button>
                    <Button className="dkg-desired-btntext">
                      Ireland{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-times"></i>
                      </span>
                    </Button>
                    <Button className="dkg-desired-btntext">
                      UK{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-times"></i>
                      </span>
                    </Button>
                    <Button className="dkg-desired-btntext">
                      USA{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-times"></i>
                      </span>
                    </Button>
                    <Button className="dkg-desired-btntext">
                      Grecee{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-times"></i>
                      </span>
                    </Button>
                    <Button className="dkg-desired-btntext">
                      Germany{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-times"></i>
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>; 
        break; 
      case 'My Desired City/Town':
        return <>
        
        <div className="dkg-desiredjob-header">
          <h1 className="dkg-contact-title">
            My Desired City/Town
          </h1>
          <span className="dkg-desired-info d-none">
            <FaInfo />
          </span>
        </div>
        <div className="dkg-desired-job-mainCon">
          <div className="dkg-desired-textarea-Con">
            <div className="form-control dkg-desired-textarea">
              {details != "" ? details[32].description : ""}
            </div>
          </div>
          <div className="dkg-desired-center-sec dkg-desired-job-centersec">
            <div className="dkg-desired-job-leftCon">
              <div className="dkg-selected-tagCon">
                <div className="dkg-selected-title">
                   SELECT FROM LIBRARY
                </div>
                <div className="dkg-st-tag-job-btn-tagCon">
                  <Button className="dkg-st-tag-btntext">
                    India{" "}
                    <span className="dkg-tag-icon">
                      {" "}
                      <i className="fas fa-plus"></i>
                    </span>
                  </Button>
                  <Button className="dkg-st-tag-btntext">
                    Ireland{" "}
                    <span className="dkg-tag-icon">
                      {" "}
                      <i className="fas fa-plus"></i>
                    </span>
                  </Button>
                  <Button className="dkg-st-tag-btntext">
                    UK{" "}
                    <span className="dkg-tag-icon">
                      {" "}
                      <i className="fas fa-plus"></i>
                    </span>
                  </Button>
                  <Button className="dkg-st-tag-btntext">
                    USA{" "}
                    <span className="dkg-tag-icon">
                      {" "}
                      <i className="fas fa-plus"></i>
                    </span>
                  </Button>
                  <Button className="dkg-st-tag-btntext">
                    Grecee{" "}
                    <span className="dkg-tag-icon">
                      {" "}
                      <i className="fas fa-plus"></i>
                    </span>
                  </Button>
                  <Button className="dkg-st-tag-btntext">
                    Germany{" "}
                    <span className="dkg-tag-icon">
                      {" "}
                      <i className="fas fa-plus"></i>
                    </span>
                  </Button>
                </div>
              </div>
              <div className="dkg-dj-rightsecond-cols">
                <div className="dkg-desired-job-inputCon">
                  <div className="dkg-createnew-tag-title">
                    Create New Tag
                  </div>
                  <div className="dkg-desired-job-inputCon">
                    <input
                      type="text"
                      className="dkg-desired-job-input form-control"
                      placeholder=""
                      defaultValue=""
                    />
                    <div className="dkg-desired-job-save-btnCon">
                      <SubmitButton
                        txt="Save"
                        className="dkg-desired-job-save-btn btn"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="dkg-desired-job-rightCon">
              <div className="dkg-dj-rightfirst-cols">
                <div className="dkg-saved-cv-tag-title">
                  MY PROFILE TAGS
                </div>
                <div className="dkg-desired-job-btn-tagCon">
                  <Button className="dkg-desired-btntext">
                    India{" "}
                    <span className="dkg-tag-icon">
                      {" "}
                      <i className="fas fa-times"></i>
                    </span>
                  </Button>
                  <Button className="dkg-desired-btntext">
                    Ireland{" "}
                    <span className="dkg-tag-icon">
                      {" "}
                      <i className="fas fa-times"></i>
                    </span>
                  </Button>
                  <Button className="dkg-desired-btntext">
                    UK{" "}
                    <span className="dkg-tag-icon">
                      {" "}
                      <i className="fas fa-times"></i>
                    </span>
                  </Button>
                  <Button className="dkg-desired-btntext">
                    USA{" "}
                    <span className="dkg-tag-icon">
                      {" "}
                      <i className="fas fa-times"></i>
                    </span>
                  </Button>
                  <Button className="dkg-desired-btntext">
                    Grecee{" "}
                    <span className="dkg-tag-icon">
                      {" "}
                      <i className="fas fa-times"></i>
                    </span>
                  </Button>
                  <Button className="dkg-desired-btntext">
                    Germany{" "}
                    <span className="dkg-tag-icon">
                      {" "}
                      <i className="fas fa-times"></i>
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        </>; 
 break;
      case 'My Desired Industries':
        return <>
         <div className="dkg-desiredjob-header">
            <h1 className="dkg-contact-title">My Desired Industries</h1>
            <span className="dkg-desired-info d-none">
              <FaInfo />
            </span>
          </div>
          <div className="dkg-desired-job-mainCon">
            <div className="dkg-desired-textarea-Con">
              <div className="form-control dkg-desired-textarea">
                {details != "" ? details[13].description : ""}
              </div>
            </div>
            <div className="dkg-desired-center-sec dkg-desired-job-centersec">
              <div className="dkg-desired-job-leftCon">
                <div className="dkg-selected-tagCon">
                  <div className="dkg-selected-title">
                     SELECT FROM LIBRARY
                  </div>
                  <div className="dkg-st-tag-job-btn-tagCon">
                    <Button className="dkg-st-tag-btntext">
                      India{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-plus"></i>
                      </span>
                    </Button>
                    <Button className="dkg-st-tag-btntext">
                      Ireland{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-plus"></i>
                      </span>
                    </Button>
                    <Button className="dkg-st-tag-btntext">
                      UK{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-plus"></i>
                      </span>
                    </Button>
                    <Button className="dkg-st-tag-btntext">
                      USA{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-plus"></i>
                      </span>
                    </Button>
                    <Button className="dkg-st-tag-btntext">
                      Grecee{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-plus"></i>
                      </span>
                    </Button>
                    <Button className="dkg-st-tag-btntext">
                      Germany{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-plus"></i>
                      </span>
                    </Button>
                  </div>
                </div>
                <div className="dkg-dj-rightsecond-cols">
                  <div className="dkg-desired-job-inputCon">
                    <div className="dkg-createnew-tag-title">
                      Create New Tag
                    </div>
                    <div className="dkg-desired-job-inputCon">
                      <input
                        type="text"
                        className="dkg-desired-job-input form-control"
                        placeholder=""
                        defaultValue=""
                      />
                      <div className="dkg-desired-job-save-btnCon">
                        <SubmitButton
                          txt="Save"
                          className="dkg-desired-job-save-btn btn"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dkg-desired-job-rightCon">
                <div className="dkg-dj-rightfirst-cols">
                  <div className="dkg-saved-cv-tag-title">
                    MY PROFILE TAGS
                  </div>
                  <div className="dkg-desired-job-btn-tagCon">
                    <Button className="dkg-desired-btntext">
                      India{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-times"></i>
                      </span>
                    </Button>
                    <Button className="dkg-desired-btntext">
                      Ireland{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-times"></i>
                      </span>
                    </Button>
                    <Button className="dkg-desired-btntext">
                      UK{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-times"></i>
                      </span>
                    </Button>
                    <Button className="dkg-desired-btntext">
                      USA{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-times"></i>
                      </span>
                    </Button>
                    <Button className="dkg-desired-btntext">
                      Grecee{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-times"></i>
                      </span>
                    </Button>
                    <Button className="dkg-desired-btntext">
                      Germany{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-times"></i>
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>;
        break;
      case 'My Desired Positions':
        return <>
         <div className="dkg-desiredjob-header">
            <h1 className="dkg-contact-title">My Desired Position</h1>
            <span className="dkg-desired-info d-none">
              <FaInfo />
            </span>
          </div>
          <div className="dkg-desired-job-mainCon">
            <div className="dkg-desired-textarea-Con">
              <div className="form-control dkg-desired-textarea">
                {details != "" ? details[14].description : ""}
              </div>
            </div>
            <div className="dkg-desired-center-sec dkg-desired-job-centersec">
              <div className="dkg-desired-job-leftCon">
                <div className="dkg-selected-tagCon">
                  <div className="dkg-selected-title">
                     SELECT FROM LIBRARY
                  </div>
                  <div className="dkg-st-tag-job-btn-tagCon">
                    <Button className="dkg-st-tag-btntext">
                      India{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-plus"></i>
                      </span>
                    </Button>
                    <Button className="dkg-st-tag-btntext">
                      Ireland{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-plus"></i>
                      </span>
                    </Button>
                    <Button className="dkg-st-tag-btntext">
                      UK{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-plus"></i>
                      </span>
                    </Button>
                    <Button className="dkg-st-tag-btntext">
                      USA{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-plus"></i>
                      </span>
                    </Button>
                    <Button className="dkg-st-tag-btntext">
                      Grecee{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-plus"></i>
                      </span>
                    </Button>
                    <Button className="dkg-st-tag-btntext">
                      Germany{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-plus"></i>
                      </span>
                    </Button>
                  </div>
                </div>
                <div className="dkg-dj-rightsecond-cols">
                  <div className="dkg-desired-job-inputCon">
                    <div className="dkg-createnew-tag-title">
                      Create New Tag
                    </div>
                    <div className="dkg-desired-job-inputCon">
                      <input
                        type="text"
                        className="dkg-desired-job-input form-control"
                        placeholder=""
                        defaultValue=""
                      />
                      <div className="dkg-desired-job-save-btnCon">
                        <SubmitButton
                          txt="Save"
                          className="dkg-desired-job-save-btn btn"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dkg-desired-job-rightCon">
                <div className="dkg-dj-rightfirst-cols">
                  <div className="dkg-saved-cv-tag-title">
                    MY PROFILE TAGS
                  </div>
                  <div className="dkg-desired-job-btn-tagCon">
                    <Button className="dkg-desired-btntext">
                      India{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-times"></i>
                      </span>
                    </Button>
                    <Button className="dkg-desired-btntext">
                      Ireland{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-times"></i>
                      </span>
                    </Button>
                    <Button className="dkg-desired-btntext">
                      UK{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-times"></i>
                      </span>
                    </Button>
                    <Button className="dkg-desired-btntext">
                      USA{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-times"></i>
                      </span>
                    </Button>
                    <Button className="dkg-desired-btntext">
                      Grecee{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-times"></i>
                      </span>
                    </Button>
                    <Button className="dkg-desired-btntext">
                      Germany{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-times"></i>
                      </span>
                    </Button>
                  </div>
                </div>
                <div className="dkg-dj-rightsecond-cols">
                  <div className="dkg-desired-job-inputCon">
                    <div className="dkg-createnew-tag-title">
                      Create New Tag
                    </div>
                    <div className="dkg-desired-job-inputCon">
                      <input
                        type="text"
                        className="dkg-desired-job-input form-control"
                        placeholder=""
                        defaultValue=""
                      />
                      <div className="dkg-desired-job-save-btnCon">
                        <SubmitButton
                          txt="Save"
                          className="dkg-desired-job-save-btn btn"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>; 
       break;
      case 'My Desired Job Titles':
        return <>
         <div className="dkg-desiredjob-header">
            <h1 className="dkg-contact-title">My Desired Job Titles</h1>
            <span className="dkg-desired-info d-none">
              <FaInfo />
            </span>
          </div>
          <div className="dkg-desired-job-mainCon">
            <div className="dkg-desired-textarea-Con">
              <div className="form-control dkg-desired-textarea">
                {details != "" ? details[15].description : ""}
              </div>
            </div>
            <div className="dkg-desired-center-sec dkg-desired-job-centersec">
              <div className="dkg-desired-job-leftCon">
                <div className="dkg-selected-tagCon">
                  <div className="dkg-selected-title">
                     SELECT FROM LIBRARY
                  </div>
                  <div className="dkg-st-tag-job-btn-tagCon">
                    <Button className="dkg-st-tag-btntext">
                      India{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-plus"></i>
                      </span>
                    </Button>
                    <Button className="dkg-st-tag-btntext">
                      Ireland{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-plus"></i>
                      </span>
                    </Button>
                    <Button className="dkg-st-tag-btntext">
                      UK{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-plus"></i>
                      </span>
                    </Button>
                    <Button className="dkg-st-tag-btntext">
                      USA{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-plus"></i>
                      </span>
                    </Button>
                    <Button className="dkg-st-tag-btntext">
                      Grecee{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-plus"></i>
                      </span>
                    </Button>
                    <Button className="dkg-st-tag-btntext">
                      Germany{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-plus"></i>
                      </span>
                    </Button>
                  </div>
                </div>
                <div className="dkg-dj-rightsecond-cols">
                  <div className="dkg-desired-job-inputCon">
                    <div className="dkg-createnew-tag-title">
                      Create New Tag
                    </div>
                    <div className="dkg-desired-job-inputCon">
                      <input
                        type="text"
                        className="dkg-desired-job-input form-control"
                        placeholder=""
                        defaultValue=""
                      />
                      <div className="dkg-desired-job-save-btnCon">
                        <SubmitButton
                          txt="Save"
                          className="dkg-desired-job-save-btn btn"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dkg-desired-job-rightCon">
                <div className="dkg-dj-rightfirst-cols">
                  <div className="dkg-saved-cv-tag-title">
                    MY PROFILE TAGS
                  </div>
                  <div className="dkg-desired-job-btn-tagCon">
                    <Button className="dkg-desired-btntext">
                      India{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-times"></i>
                      </span>
                    </Button>
                    <Button className="dkg-desired-btntext">
                      Ireland{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-times"></i>
                      </span>
                    </Button>
                    <Button className="dkg-desired-btntext">
                      UK{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-times"></i>
                      </span>
                    </Button>
                    <Button className="dkg-desired-btntext">
                      USA{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-times"></i>
                      </span>
                    </Button>
                    <Button className="dkg-desired-btntext">
                      Grecee{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-times"></i>
                      </span>
                    </Button>
                    <Button className="dkg-desired-btntext">
                      Germany{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-times"></i>
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>; 
        break;
      case 'My Desired Workplace':
        return <>
        <div className="dkg-desiredjob-header">
          <h1 className="dkg-contact-title">My Desired Workplace</h1>
          <span className="dkg-desired-info d-none">
            <FaInfo />
          </span>
        </div>
        <div className="dkg-desired-job-mainCon">
          <div className="dkg-desired-textarea-Con">
            <div className="form-control dkg-desired-textarea">
              {details != "" ? details[33].description : ""}
            </div>
          </div>
          <div className="dkg-desired-center-sec dkg-desired-job-centersec">
            <div className="dkg-desired-job-leftCon">
              <div className="dkg-selected-tagCon">
                <div className="dkg-selected-title">
                   SELECT FROM LIBRARY
                </div>
                <div className="dkg-st-tag-job-btn-tagCon">
                  <Button className="dkg-st-tag-btntext">
                    India{" "}
                    <span className="dkg-tag-icon">
                      {" "}
                      <i className="fas fa-plus"></i>
                    </span>
                  </Button>
                  <Button className="dkg-st-tag-btntext">
                    Ireland{" "}
                    <span className="dkg-tag-icon">
                      {" "}
                      <i className="fas fa-plus"></i>
                    </span>
                  </Button>
                  <Button className="dkg-st-tag-btntext">
                    UK{" "}
                    <span className="dkg-tag-icon">
                      {" "}
                      <i className="fas fa-plus"></i>
                    </span>
                  </Button>
                  <Button className="dkg-st-tag-btntext">
                    USA{" "}
                    <span className="dkg-tag-icon">
                      {" "}
                      <i className="fas fa-plus"></i>
                    </span>
                  </Button>
                  <Button className="dkg-st-tag-btntext">
                    Grecee{" "}
                    <span className="dkg-tag-icon">
                      {" "}
                      <i className="fas fa-plus"></i>
                    </span>
                  </Button>
                  <Button className="dkg-st-tag-btntext">
                    Germany{" "}
                    <span className="dkg-tag-icon">
                      {" "}
                      <i className="fas fa-plus"></i>
                    </span>
                  </Button>
                </div>
              </div>
              <div className="dkg-dj-rightsecond-cols">
                <div className="dkg-desired-job-inputCon">
                  <div className="dkg-createnew-tag-title">
                    Create New Tag
                  </div>
                  <div className="dkg-desired-job-inputCon">
                    <input
                      type="text"
                      className="dkg-desired-job-input form-control"
                      placeholder=""
                      defaultValue=""
                    />
                    <div className="dkg-desired-job-save-btnCon">
                      <SubmitButton
                        txt="Save"
                        className="dkg-desired-job-save-btn btn"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="dkg-desired-job-rightCon">
              <div className="dkg-dj-rightfirst-cols">
                <div className="dkg-saved-cv-tag-title">
                  MY PROFILE TAGS
                </div>
                <div className="dkg-desired-job-btn-tagCon">
                  <Button className="dkg-desired-btntext">
                    India{" "}
                    <span className="dkg-tag-icon">
                      {" "}
                      <i className="fas fa-times"></i>
                    </span>
                  </Button>
                  <Button className="dkg-desired-btntext">
                    Ireland{" "}
                    <span className="dkg-tag-icon">
                      {" "}
                      <i className="fas fa-times"></i>
                    </span>
                  </Button>
                  <Button className="dkg-desired-btntext">
                    UK{" "}
                    <span className="dkg-tag-icon">
                      {" "}
                      <i className="fas fa-times"></i>
                    </span>
                  </Button>
                  <Button className="dkg-desired-btntext">
                    USA{" "}
                    <span className="dkg-tag-icon">
                      {" "}
                      <i className="fas fa-times"></i>
                    </span>
                  </Button>
                  <Button className="dkg-desired-btntext">
                    Grecee{" "}
                    <span className="dkg-tag-icon">
                      {" "}
                      <i className="fas fa-times"></i>
                    </span>
                  </Button>
                  <Button className="dkg-desired-btntext">
                    Germany{" "}
                    <span className="dkg-tag-icon">
                      {" "}
                      <i className="fas fa-times"></i>
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        </>;  
        break;
      case 'My Desired Salaries':
        return <>
           <div className="dkg-desiredjob-header">
              <h1 className="dkg-contact-title">My Desired Salaries</h1>
              <span className="dkg-desired-info d-none">
                <FaInfo />
              </span>
            </div>
            <div className="dkg-desired-job-mainCon">
              <div className="dkg-desired-textarea-Con">
                <div className="form-control dkg-desired-textarea">
                  {details != "" ? details[16].description : ""}
                </div>
              </div>
              <div className="dkg-desired-center-sec dkg-desired-job-centersec">
                <div className="dkg-desired-job-leftCon">
                  <div className="dkg-selected-tagCon">
                    <div className="dkg-selected-title">
                       SELECT FROM LIBRARY
                    </div>
                    <div className="dkg-st-tag-job-btn-tagCon">
                      <Button className="dkg-st-tag-btntext">
                        India{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-plus"></i>
                        </span>
                      </Button>
                      <Button className="dkg-st-tag-btntext">
                        Ireland{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-plus"></i>
                        </span>
                      </Button>
                      <Button className="dkg-st-tag-btntext">
                        UK{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-plus"></i>
                        </span>
                      </Button>
                      <Button className="dkg-st-tag-btntext">
                        USA{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-plus"></i>
                        </span>
                      </Button>
                      <Button className="dkg-st-tag-btntext">
                        Grecee{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-plus"></i>
                        </span>
                      </Button>
                      <Button className="dkg-st-tag-btntext">
                        Germany{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-plus"></i>
                        </span>
                      </Button>
                    </div>
                  </div>
                  <div className="dkg-dj-rightsecond-cols">
                    <div className="dkg-desired-job-inputCon">
                      <div className="dkg-createnew-tag-title">
                        Create New Tag
                      </div>
                      <div className="dkg-desired-job-inputCon">
                        <input
                          type="text"
                          className="dkg-desired-job-input form-control"
                          placeholder=""
                          defaultValue=""
                        />
                        <div className="dkg-desired-job-save-btnCon">
                          <SubmitButton
                            txt="Save"
                            className="dkg-desired-job-save-btn btn"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dkg-desired-job-rightCon">
                  <div className="dkg-dj-rightfirst-cols">
                    <div className="dkg-saved-cv-tag-title">
                      MY PROFILE TAGS
                    </div>
                    <div className="dkg-desired-job-btn-tagCon">
                      <Button className="dkg-desired-btntext">
                        India{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Ireland{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        UK{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        USA{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Grecee{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                      <Button className="dkg-desired-btntext">
                        Germany{" "}
                        <span className="dkg-tag-icon">
                          {" "}
                          <i className="fas fa-times"></i>
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </>;
       break;
      case 'My Desired Benefits':
        return <>
          <div className="dkg-desiredjob-header">
            <h1 className="dkg-contact-title">My Desired Benefits</h1>
            <span className="dkg-desired-info d-none">
              <FaInfo />
            </span>
          </div>
          <div className="dkg-desired-job-mainCon">
            <div className="dkg-desired-textarea-Con">
              <div className="form-control dkg-desired-textarea">
                {details != "" ? details[17].description : ""}
              </div>
            </div>
            <div className="dkg-desired-center-sec dkg-desired-job-centersec">
              <div className="dkg-desired-job-leftCon">
                <div className="dkg-selected-tagCon">
                  <div className="dkg-selected-title">
                     SELECT FROM LIBRARY
                  </div>
                  <div className="dkg-st-tag-job-btn-tagCon">
                    <Button className="dkg-st-tag-btntext">
                      India{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-plus"></i>
                      </span>
                    </Button>
                    <Button className="dkg-st-tag-btntext">
                      Ireland{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-plus"></i>
                      </span>
                    </Button>
                    <Button className="dkg-st-tag-btntext">
                      UK{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-plus"></i>
                      </span>
                    </Button>
                    <Button className="dkg-st-tag-btntext">
                      USA{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-plus"></i>
                      </span>
                    </Button>
                    <Button className="dkg-st-tag-btntext">
                      Grecee{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-plus"></i>
                      </span>
                    </Button>
                    <Button className="dkg-st-tag-btntext">
                      Germany{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-plus"></i>
                      </span>
                    </Button>
                  </div>
                </div>
                <div className="dkg-dj-rightsecond-cols">
                  <div className="dkg-desired-job-inputCon">
                    <div className="dkg-createnew-tag-title">
                      Create New Tag
                    </div>
                    <div className="dkg-desired-job-inputCon">
                      <input
                        type="text"
                        className="dkg-desired-job-input form-control"
                        placeholder=""
                        defaultValue=""
                      />
                      <div className="dkg-desired-job-save-btnCon">
                        <SubmitButton
                          txt="Save"
                          className="dkg-desired-job-save-btn btn"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dkg-desired-job-rightCon">
                <div className="dkg-dj-rightfirst-cols">
                  <div className="dkg-saved-cv-tag-title">
                    MY PROFILE TAGS
                  </div>
                  <div className="dkg-desired-job-btn-tagCon">
                    <Button className="dkg-desired-btntext">
                      India{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-times"></i>
                      </span>
                    </Button>
                    <Button className="dkg-desired-btntext">
                      Ireland{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-times"></i>
                      </span>
                    </Button>
                    <Button className="dkg-desired-btntext">
                      UK{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-times"></i>
                      </span>
                    </Button>
                    <Button className="dkg-desired-btntext">
                      USA{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-times"></i>
                      </span>
                    </Button>
                    <Button className="dkg-desired-btntext">
                      Grecee{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-times"></i>
                      </span>
                    </Button>
                    <Button className="dkg-desired-btntext">
                      Germany{" "}
                      <span className="dkg-tag-icon">
                        {" "}
                        <i className="fas fa-times"></i>
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>;
        break; 
      default:
        return null;
    }
  };
  return (
    <>
      <div className="dkg-contact-mainCon dk-mydesired-jobs-pageCon">
        <div className="dkg-contact-main">
          {/* <div className='dkg-desired-pageheader pt-3'>
                  <h2 className='dkg-desired-pagetitle'>MY JOB REQUIREMENTS</h2>
               </div> */}
          <div className="dkg-cand-resumes-moCon">
          <div className="dkg-resumes-header-centerCon">
             <h2 className="dkg-cand-categoty-title w-100 text-center" style={{fontSize: "1.25rem" , fontWeight: "600"}}>Select Category</h2>
            <div className="dkg-resumes-DropdownCon">
                <Dropdown className='dkg-resumes-Dropdown'>
                  <Dropdown.Toggle variant="" className='dkg-resumes-Dropdown-toggle'>
                    {selected}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className='dkg-resumes-Dropdown-Menu w-100'>
                      {
                        desiredjobitems.map((item , index) =>{
                          return(
                            <>
                                <Dropdown.Item href="#" key={item.id} 
                                className={item.value === selected ? 'selected-item ' : ''}
                                onClick={() => handleDropdownChange(item.value)}
                                
                                >{item.value}</Dropdown.Item>
                            </>
                          )

                        } )
                      }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
          </div>
          {renderComponent()}
        </div>     
          <Tab.Container id="left-tabs-example" defaultActiveKey="dkg-company">
            <div className="row justify-content-center dkg-contact-mainRow">
              <div className="col-md-2 dkg-contact-main-sideCol">
                <Nav
                  variant="pills dkg-aboutus-navpills dkg-mydesired-job-navpills"
                  className="flex-column"
                >
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-company"
                      className="dkg-aboutus-navlink"
                    >
                       Summary
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-description"
                      className="dkg-aboutus-navlink"
                    >
                      My Cover Message
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-services"
                      className="dkg-aboutus-navlink"
                    >
                      My Desired Countries
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-city-town"
                      className="dkg-aboutus-navlink"
                    >
                      My Desired City/Town
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-logos"
                      className="dkg-aboutus-navlink"
                    >
                      My Desired Industries
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-social"
                      className="dkg-aboutus-navlink"
                    >
                      My Desired Positions
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-contact-us"
                      className="dkg-aboutus-navlink"
                    >
                      My Desired Job Titles
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-workplace"
                      className="dkg-aboutus-navlink"
                    >
                      My Desired Workplace
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="desired-salaries"
                      className="dkg-aboutus-navlink"
                    >
                      My Desired Salaries
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="desired-benefits"
                      className="dkg-aboutus-navlink"
                    >
                      My Desired Benefits
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <div className="col-md-10 col-10 dkg-contact-mainCol">
                <Tab.Content className="dkg-aboutus-tabcontent">
                  <Tab.Pane
                    eventKey="dkg-company"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-desiredjob-header">
                      <h1 className="dkg-contact-title">
                        MY DESIRED JOB
                      </h1>
                      <span className="dkg-desired-info d-none">
                        <FaInfo />
                      </span>
                    </div>
                    <div className="dkg-desired-job-mainCon dkg-desired-job-summ-mainCon">
                      <div className="dkg-desired-textarea-Con">
                        <div className="form-control dkg-desired-textarea">
                          {details != "" ? details[10].description : ""}
                        </div>
                      </div>
                      <div className="dkg-desired-summ-body-234">
                        <div className="dkg-desired-summ-box-cols">
                          <div className="dkg-desired-summ-1stcols">
                            <div className="dkg-desired-summ-title">
                              My COVER MESSAGE
                            </div>
                          </div>
                          <div className="dkg-desired-summ-2ndcols">
                            <div className="dkg-covermsg-textCon">
                              <p className="dkg-covermsg-text">
                                Lorem ipsum dolor sit amet consectetur
                                adipisicing elit. Vero a obcaecati quod animi
                                hic ea laboriosam similique vel quia!.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="dkg-desired-summ-box-cols">
                          <div className="dkg-desired-summ-1stcols">
                            <div className="dkg-desired-summ-title">
                              My Desired Countries
                            </div>
                          </div>
                          <div className="dkg-desired-summ-2ndcols">
                            <div className="dkg-myprofile-summCon">
                              <div className="dkg-myprofile-summ-btn-tagCon">
                                <Button className="dkg-desired-btntext">
                                  India{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  Ireland{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  UK{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  USA{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  Grecee{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  Germany{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  Ireland{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  UK{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  USA{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  Grecee{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="dkg-desired-summ-box-cols">
                          <div className="dkg-desired-summ-1stcols">
                            <div className="dkg-desired-summ-title">
                              My Desired City/Town
                            </div>
                          </div>
                          <div className="dkg-desired-summ-2ndcols">
                            <div className="dkg-myprofile-summCon">
                              <div className="dkg-myprofile-summ-btn-tagCon">
                                <Button className="dkg-desired-btntext">
                                  India{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  Ireland{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  UK{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  USA{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  Grecee{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  Germany{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  Ireland{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  UK{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  USA{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  Grecee{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="dkg-desired-summ-box-cols">
                          <div className="dkg-desired-summ-1stcols">
                            <div className="dkg-desired-summ-title">
                              My Desired Industries
                            </div>
                          </div>
                          <div className="dkg-desired-summ-2ndcols">
                            <div className="dkg-myprofile-summCon">
                              <div className="dkg-myprofile-summ-btn-tagCon">
                                <Button className="dkg-desired-btntext">
                                  India{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  Ireland{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  UK{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  USA{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  Grecee{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  Germany{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  Ireland{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  UK{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  USA{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  Grecee{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="dkg-desired-summ-box-cols">
                          <div className="dkg-desired-summ-1stcols">
                            <div className="dkg-desired-summ-title">
                              My Desired Positions
                            </div>
                          </div>
                          <div className="dkg-desired-summ-2ndcols">
                            <div className="dkg-myprofile-summCon">
                              <div className="dkg-myprofile-summ-btn-tagCon">
                                <Button className="dkg-desired-btntext">
                                  India{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  Ireland{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  UK{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  USA{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  Grecee{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  Germany{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  Ireland{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  UK{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  USA{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  Grecee{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="dkg-desired-summ-box-cols">
                          <div className="dkg-desired-summ-1stcols">
                            <div className="dkg-desired-summ-title">
                              My Desired Job Titles
                            </div>
                          </div>
                          <div className="dkg-desired-summ-2ndcols">
                            <div className="dkg-myprofile-summCon">
                              <div className="dkg-myprofile-summ-btn-tagCon">
                                <Button className="dkg-desired-btntext">
                                  India{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  Ireland{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  UK{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  USA{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  Grecee{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  Germany{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  Ireland{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  UK{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  USA{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  Grecee{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="dkg-desired-summ-box-cols">
                          <div className="dkg-desired-summ-1stcols">
                            <div className="dkg-desired-summ-title">
                              My Desired Workplace
                            </div>
                          </div>
                          <div className="dkg-desired-summ-2ndcols">
                            <div className="dkg-myprofile-summCon">
                              <div className="dkg-myprofile-summ-btn-tagCon">
                                <Button className="dkg-desired-btntext">
                                  India{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  Ireland{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  UK{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  USA{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  Grecee{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  Germany{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  Ireland{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  UK{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  USA{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  Grecee{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="dkg-desired-summ-box-cols">
                          <div className="dkg-desired-summ-1stcols">
                            <div className="dkg-desired-summ-title">
                              My Desired Salaries
                            </div>
                          </div>
                          <div className="dkg-desired-summ-2ndcols">
                            <div className="dkg-myprofile-summCon">
                              <div className="dkg-myprofile-summ-btn-tagCon">
                                <Button className="dkg-desired-btntext">
                                  India{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  Ireland{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  UK{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  USA{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  Grecee{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  Germany{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  Ireland{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  UK{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  USA{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  Grecee{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="dkg-desired-summ-box-cols">
                          <div className="dkg-desired-summ-1stcols">
                            <div className="dkg-desired-summ-title">
                              My Desired Benefits
                            </div>
                          </div>
                          <div className="dkg-desired-summ-2ndcols">
                            <div className="dkg-myprofile-summCon">
                              <div className="dkg-myprofile-summ-btn-tagCon">
                                <Button className="dkg-desired-btntext">
                                  India{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  Ireland{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  UK{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  USA{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  Grecee{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  Germany{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  Ireland{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  UK{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  USA{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                                <Button className="dkg-desired-btntext">
                                  Grecee{" "}
                                  <span className="dkg-tag-icon">
                                    {" "}
                                    <i className="fas fa-times"></i>
                                  </span>
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-description"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-desiredjob-header">
                      <h1 className="dkg-contact-title">My COVER MESSAGE</h1>
                      <span className="dkg-desired-info d-none">
                        <FaInfo />
                      </span>
                    </div>
                    <div className="dkg-desired-job-mainCon">
                      <div className="dkg-desired-textarea-Con">
                        <div className="form-control dkg-desired-textarea">
                          {details != "" ? details[11].description : ""}
                        </div>
                      </div>
                      <div className="dkg-desired-center-sec dkg-desired-job-centersec">
                        <textarea className="dkg-createcv-textarea" placeholder="Write massage here"></textarea>

                        {/* <MessageBox
                          value={
                            coverLetter != ""
                              ? details[11].otherProjectOverview
                              : ""
                          }
                          onChange={(value) => setCoverLetter(value)}
                        /> */}
                      </div>
                      <div className="dkg-desired-save-btnCon">
                        <SubmitButton
                          txt="Save"
                          className="dkg-desired-save-btn btn "
                          onClick={(e) =>
                            SubmitBtn(12, "otherProjectOverview", coverLetter)
                          }
                        />
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-city-town"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-desiredjob-header">
                      <h1 className="dkg-contact-title">
                        My Desired City/Town 
                      </h1>
                      <span className="dkg-desired-info d-none">
                        <FaInfo />
                      </span>
                    </div>
                    <div className="dkg-desired-job-mainCon">
                      <div className="dkg-desired-textarea-Con">
                        <div className="form-control dkg-desired-textarea">
                          {details != "" ? details[32].description : ""}
                        </div>
                      </div>
                      <div className="dkg-desired-center-sec dkg-desired-job-centersec">
                        <div className="dkg-desired-job-leftCon">
                          <div className="dkg-selected-tagCon">
                            <div className="dkg-selected-title">
                               SELECT FROM LIBRARY
                            </div>
                            {/* <div className="dkg-st-tag-job-btn-tagCon">
                              <Button className="dkg-st-tag-btntext">
                                India{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                              <Button className="dkg-st-tag-btntext">
                                Ireland{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                              <Button className="dkg-st-tag-btntext">
                                UK{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                              <Button className="dkg-st-tag-btntext">
                                USA{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                              <Button className="dkg-st-tag-btntext">
                                Grecee{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                              <Button className="dkg-st-tag-btntext">
                                Germany{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                            </div> */}
                          </div>
                          <div className="dkg-dj-rightsecond-cols">
                            <div className="dkg-desired-job-inputCon">
                              <div className="dkg-createnew-tag-title">
                                Create New Tag
                              </div>
                              <div className="dkg-desired-job-inputCon">
                                <input
                                  type="text"
                                  className="dkg-desired-job-input form-control"
                                  placeholder=""
                                  defaultValue=""
                                />
                                <div className="dkg-desired-job-save-btnCon">
                                  <SubmitButton
                                    txt="Save"
                                    className="dkg-desired-job-save-btn btn"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="dkg-desired-job-rightCon">
                          <div className="dkg-dj-rightfirst-cols">
                            <div className="dkg-saved-cv-tag-title">
                              MY PROFILE TAGS
                            </div>
                            {/* <div className="dkg-desired-job-btn-tagCon">
                              <Button className="dkg-desired-btntext">
                                India{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                              <Button className="dkg-desired-btntext">
                                Ireland{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                              <Button className="dkg-desired-btntext">
                                UK{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                              <Button className="dkg-desired-btntext">
                                USA{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                              <Button className="dkg-desired-btntext">
                                Grecee{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                              <Button className="dkg-desired-btntext">
                                Germany{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-services"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-desiredjob-header">
                      <h1 className="dkg-contact-title">My Desired Countries</h1>
                      <span className="dkg-desired-info d-none">
                        <FaInfo />
                      </span>
                    </div>
                    <div className="dkg-desired-job-mainCon">
                      <div className="dkg-desired-textarea-Con">
                        <div className="form-control dkg-desired-textarea">
                          {details != "" ? details[12].description : ""}
                        </div>
                      </div>
                      <div className="dkg-desired-center-sec dkg-desired-job-centersec">
                        <div className="dkg-desired-job-leftCon">
                          <div className="dkg-selected-tagCon">
                            <div className="dkg-selected-title">
                               SELECT FROM LIBRARY
                            </div>
                            {/* <div className="dkg-st-tag-job-btn-tagCon">
                              <Button className="dkg-st-tag-btntext">
                                India{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                              <Button className="dkg-st-tag-btntext">
                                Ireland{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                              <Button className="dkg-st-tag-btntext">
                                UK{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                              <Button className="dkg-st-tag-btntext">
                                USA{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                              <Button className="dkg-st-tag-btntext">
                                Grecee{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                              <Button className="dkg-st-tag-btntext">
                                Germany{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                            </div> */}
                          </div>
                          <div className="dkg-dj-rightsecond-cols">
                            <div className="dkg-desired-job-inputCon">
                              <div className="dkg-createnew-tag-title">
                                Create New Tag
                              </div>
                              <div className="dkg-desired-job-inputCon">
                                <input
                                  type="text"
                                  className="dkg-desired-job-input form-control"
                                  placeholder=""
                                  defaultValue=""
                                />
                                <div className="dkg-desired-job-save-btnCon">
                                  <SubmitButton
                                    txt="Save"
                                    className="dkg-desired-job-save-btn btn"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="dkg-desired-job-rightCon">
                          <div className="dkg-dj-rightfirst-cols">
                            <div className="dkg-saved-cv-tag-title">
                              MY PROFILE TAGS
                            </div>
                            {/* <div className="dkg-desired-job-btn-tagCon">
                              <Button className="dkg-desired-btntext">
                                India{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                              <Button className="dkg-desired-btntext">
                                Ireland{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                              <Button className="dkg-desired-btntext">
                                UK{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                              <Button className="dkg-desired-btntext">
                                USA{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                              <Button className="dkg-desired-btntext">
                                Grecee{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                              <Button className="dkg-desired-btntext">
                                Germany{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-logos"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-desiredjob-header">
                      <h1 className="dkg-contact-title">My Desired Industries</h1>
                      <span className="dkg-desired-info d-none">
                        <FaInfo />
                      </span>
                    </div>
                    <div className="dkg-desired-job-mainCon">
                      <div className="dkg-desired-textarea-Con">
                        <div className="form-control dkg-desired-textarea">
                          {details != "" ? details[13].description : ""}
                        </div>
                      </div>
                      <div className="dkg-desired-center-sec dkg-desired-job-centersec">
                        <div className="dkg-desired-job-leftCon">
                          <div className="dkg-selected-tagCon">
                            <div className="dkg-selected-title">
                               SELECT FROM LIBRARY
                            </div>
                            {/* <div className="dkg-st-tag-job-btn-tagCon">
                              <Button className="dkg-st-tag-btntext">
                                India{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                              <Button className="dkg-st-tag-btntext">
                                Ireland{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                              <Button className="dkg-st-tag-btntext">
                                UK{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                              <Button className="dkg-st-tag-btntext">
                                USA{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                              <Button className="dkg-st-tag-btntext">
                                Grecee{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                              <Button className="dkg-st-tag-btntext">
                                Germany{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                            </div> */}
                          </div>
                          <div className="dkg-dj-rightsecond-cols">
                            <div className="dkg-desired-job-inputCon">
                              <div className="dkg-createnew-tag-title">
                                Create New Tag
                              </div>
                              <div className="dkg-desired-job-inputCon">
                                <input
                                  type="text"
                                  className="dkg-desired-job-input form-control"
                                  placeholder=""
                                  defaultValue=""
                                />
                                <div className="dkg-desired-job-save-btnCon">
                                  <SubmitButton
                                    txt="Save"
                                    className="dkg-desired-job-save-btn btn"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="dkg-desired-job-rightCon">
                          <div className="dkg-dj-rightfirst-cols">
                            <div className="dkg-saved-cv-tag-title">
                              MY PROFILE TAGS
                            </div>
                            {/* <div className="dkg-desired-job-btn-tagCon">
                              <Button className="dkg-desired-btntext">
                                India{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                              <Button className="dkg-desired-btntext">
                                Ireland{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                              <Button className="dkg-desired-btntext">
                                UK{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                              <Button className="dkg-desired-btntext">
                                USA{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                              <Button className="dkg-desired-btntext">
                                Grecee{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                              <Button className="dkg-desired-btntext">
                                Germany{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-social"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-desiredjob-header">
                      <h1 className="dkg-contact-title">My Desired Position</h1>
                      <span className="dkg-desired-info d-none">
                        <FaInfo />
                      </span>
                    </div>
                    <div className="dkg-desired-job-mainCon">
                      <div className="dkg-desired-textarea-Con">
                        <div className="form-control dkg-desired-textarea">
                          {details != "" ? details[14].description : ""}
                        </div>
                      </div>
                      <div className="dkg-desired-center-sec dkg-desired-job-centersec">
                        <div className="dkg-desired-job-leftCon">
                          <div className="dkg-selected-tagCon">
                            <div className="dkg-selected-title">
                               SELECT FROM LIBRARY
                            </div>
                            {/* <div className="dkg-st-tag-job-btn-tagCon">
                              <Button className="dkg-st-tag-btntext">
                                India{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                              <Button className="dkg-st-tag-btntext">
                                Ireland{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                              <Button className="dkg-st-tag-btntext">
                                UK{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                              <Button className="dkg-st-tag-btntext">
                                USA{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                              <Button className="dkg-st-tag-btntext">
                                Grecee{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                              <Button className="dkg-st-tag-btntext">
                                Germany{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                            </div> */}
                          </div>
                          <div className="dkg-dj-rightsecond-cols">
                            <div className="dkg-desired-job-inputCon">
                              <div className="dkg-createnew-tag-title">
                                Create New Tag
                              </div>
                              <div className="dkg-desired-job-inputCon">
                                <input
                                  type="text"
                                  className="dkg-desired-job-input form-control"
                                  placeholder=""
                                  defaultValue=""
                                />
                                <div className="dkg-desired-job-save-btnCon">
                                  <SubmitButton
                                    txt="Save"
                                    className="dkg-desired-job-save-btn btn"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="dkg-desired-job-rightCon">
                          <div className="dkg-dj-rightfirst-cols">
                            <div className="dkg-saved-cv-tag-title">
                              MY PROFILE TAGS
                            </div>
                            {/* <div className="dkg-desired-job-btn-tagCon">
                              <Button className="dkg-desired-btntext">
                                India{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                              <Button className="dkg-desired-btntext">
                                Ireland{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                              <Button className="dkg-desired-btntext">
                                UK{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                              <Button className="dkg-desired-btntext">
                                USA{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                              <Button className="dkg-desired-btntext">
                                Grecee{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                              <Button className="dkg-desired-btntext">
                                Germany{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                            </div> */}
                          </div>
                          <div className="dkg-dj-rightsecond-cols">
                            <div className="dkg-desired-job-inputCon">
                              <div className="dkg-createnew-tag-title">
                                Create New Tag
                              </div>
                              <div className="dkg-desired-job-inputCon">
                                <input
                                  type="text"
                                  className="dkg-desired-job-input form-control"
                                  placeholder=""
                                  defaultValue=""
                                />
                                <div className="dkg-desired-job-save-btnCon">
                                  <SubmitButton
                                    txt="Save"
                                    className="dkg-desired-job-save-btn btn"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-contact-us"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-desiredjob-header">
                      <h1 className="dkg-contact-title">My Desired Job Titles</h1>
                      <span className="dkg-desired-info d-none">
                        <FaInfo />
                      </span>
                    </div>
                    <div className="dkg-desired-job-mainCon">
                      <div className="dkg-desired-textarea-Con">
                        <div className="form-control dkg-desired-textarea">
                          {details != "" ? details[15].description : ""}
                        </div>
                      </div>
                      <div className="dkg-desired-center-sec dkg-desired-job-centersec">
                        <div className="dkg-desired-job-leftCon">
                          <div className="dkg-selected-tagCon">
                            <div className="dkg-selected-title">
                               SELECT FROM LIBRARY
                            </div>
                            {/* <div className="dkg-st-tag-job-btn-tagCon">
                              <Button className="dkg-st-tag-btntext">
                                India{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                              <Button className="dkg-st-tag-btntext">
                                Ireland{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                              <Button className="dkg-st-tag-btntext">
                                UK{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                              <Button className="dkg-st-tag-btntext">
                                USA{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                              <Button className="dkg-st-tag-btntext">
                                Grecee{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                              <Button className="dkg-st-tag-btntext">
                                Germany{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                            </div> */}
                          </div>
                          <div className="dkg-dj-rightsecond-cols">
                            <div className="dkg-desired-job-inputCon">
                              <div className="dkg-createnew-tag-title">
                                Create New Tag
                              </div>
                              <div className="dkg-desired-job-inputCon">
                                <input
                                  type="text"
                                  className="dkg-desired-job-input form-control"
                                  placeholder=""
                                  defaultValue=""
                                />
                                <div className="dkg-desired-job-save-btnCon">
                                  <SubmitButton
                                    txt="Save"
                                    className="dkg-desired-job-save-btn btn"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="dkg-desired-job-rightCon">
                          <div className="dkg-dj-rightfirst-cols">
                            <div className="dkg-saved-cv-tag-title">
                              MY PROFILE TAGS
                            </div>
                            {/* <div className="dkg-desired-job-btn-tagCon">
                              <Button className="dkg-desired-btntext">
                                India{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                              <Button className="dkg-desired-btntext">
                                Ireland{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                              <Button className="dkg-desired-btntext">
                                UK{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                              <Button className="dkg-desired-btntext">
                                USA{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                              <Button className="dkg-desired-btntext">
                                Grecee{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                              <Button className="dkg-desired-btntext">
                                Germany{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-workplace"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-desiredjob-header">
                      <h1 className="dkg-contact-title">My Desired Workplace</h1>
                      <span className="dkg-desired-info d-none">
                        <FaInfo />
                      </span>
                    </div>
                    <div className="dkg-desired-job-mainCon">
                      <div className="dkg-desired-textarea-Con">
                        <div className="form-control dkg-desired-textarea">
                          {details != "" ? details[33].description : ""}
                        </div>
                      </div>
                      <div className="dkg-desired-center-sec dkg-desired-job-centersec">
                        <div className="dkg-desired-job-leftCon">
                          <div className="dkg-selected-tagCon">
                            <div className="dkg-selected-title">
                               SELECT FROM LIBRARY
                            </div>
                            {/* <div className="dkg-st-tag-job-btn-tagCon">
                              <Button className="dkg-st-tag-btntext">
                                India{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                              <Button className="dkg-st-tag-btntext">
                                Ireland{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                              <Button className="dkg-st-tag-btntext">
                                UK{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                              <Button className="dkg-st-tag-btntext">
                                USA{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                              <Button className="dkg-st-tag-btntext">
                                Grecee{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                              <Button className="dkg-st-tag-btntext">
                                Germany{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                            </div> */}
                          </div>
                          <div className="dkg-dj-rightsecond-cols">
                            <div className="dkg-desired-job-inputCon">
                              <div className="dkg-createnew-tag-title">
                                Create New Tag
                              </div>
                              <div className="dkg-desired-job-inputCon">
                                <input
                                  type="text"
                                  className="dkg-desired-job-input form-control"
                                  placeholder=""
                                  defaultValue=""
                                />
                                <div className="dkg-desired-job-save-btnCon">
                                  <SubmitButton
                                    txt="Save"
                                    className="dkg-desired-job-save-btn btn"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="dkg-desired-job-rightCon">
                          <div className="dkg-dj-rightfirst-cols">
                            <div className="dkg-saved-cv-tag-title">
                              MY PROFILE TAGS
                            </div>
                            {/* <div className="dkg-desired-job-btn-tagCon">
                              <Button className="dkg-desired-btntext">
                                India{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                              <Button className="dkg-desired-btntext">
                                Ireland{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                              <Button className="dkg-desired-btntext">
                                UK{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                              <Button className="dkg-desired-btntext">
                                USA{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                              <Button className="dkg-desired-btntext">
                                Grecee{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                              <Button className="dkg-desired-btntext">
                                Germany{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="desired-salaries"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-desiredjob-header">
                      <h1 className="dkg-contact-title">My Desired Salaries</h1>
                      <span className="dkg-desired-info d-none">
                        <FaInfo />
                      </span>
                    </div>
                    <div className="dkg-desired-job-mainCon">
                      <div className="dkg-desired-textarea-Con">
                        <div className="form-control dkg-desired-textarea">
                          {details != "" ? details[16].description : ""}
                        </div>
                      </div>
                      <div className="dkg-desired-center-sec dkg-desired-job-centersec">
                        <div className="dkg-desired-job-leftCon">
                          <div className="dkg-selected-tagCon">
                            <div className="dkg-selected-title">
                               SELECT FROM LIBRARY
                            </div>
                            {/* <div className="dkg-st-tag-job-btn-tagCon">
                              <Button className="dkg-st-tag-btntext">
                                India{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                              <Button className="dkg-st-tag-btntext">
                                Ireland{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                              <Button className="dkg-st-tag-btntext">
                                UK{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                              <Button className="dkg-st-tag-btntext">
                                USA{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                              <Button className="dkg-st-tag-btntext">
                                Grecee{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                              <Button className="dkg-st-tag-btntext">
                                Germany{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                            </div> */}
                          </div>
                          <div className="dkg-dj-rightsecond-cols">
                            <div className="dkg-desired-job-inputCon">
                              <div className="dkg-createnew-tag-title">
                                Create New Tag
                              </div>
                              <div className="dkg-desired-job-inputCon">
                                <input
                                  type="text"
                                  className="dkg-desired-job-input form-control"
                                  placeholder=""
                                  defaultValue=""
                                />
                                <div className="dkg-desired-job-save-btnCon">
                                  <SubmitButton
                                    txt="Save"
                                    className="dkg-desired-job-save-btn btn"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="dkg-desired-job-rightCon">
                          <div className="dkg-dj-rightfirst-cols">
                            <div className="dkg-saved-cv-tag-title">
                              MY PROFILE TAGS
                            </div>
                            {/* <div className="dkg-desired-job-btn-tagCon">
                              <Button className="dkg-desired-btntext">
                                India{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                              <Button className="dkg-desired-btntext">
                                Ireland{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                              <Button className="dkg-desired-btntext">
                                UK{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                              <Button className="dkg-desired-btntext">
                                USA{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                              <Button className="dkg-desired-btntext">
                                Grecee{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                              <Button className="dkg-desired-btntext">
                                Germany{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="desired-benefits"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-desiredjob-header">
                      <h1 className="dkg-contact-title">My Desired Benefits</h1>
                      <span className="dkg-desired-info d-none">
                        <FaInfo />
                      </span>
                    </div>
                    <div className="dkg-desired-job-mainCon">
                      <div className="dkg-desired-textarea-Con">
                        <div className="form-control dkg-desired-textarea">
                          {details != "" ? details[17].description : ""}
                        </div>
                      </div>
                      <div className="dkg-desired-center-sec dkg-desired-job-centersec">
                        <div className="dkg-desired-job-leftCon">
                          <div className="dkg-selected-tagCon">
                            <div className="dkg-selected-title">
                               SELECT FROM LIBRARY
                            </div>
                            {/* <div className="dkg-st-tag-job-btn-tagCon">
                              <Button className="dkg-st-tag-btntext">
                                India{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                              <Button className="dkg-st-tag-btntext">
                                Ireland{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                              <Button className="dkg-st-tag-btntext">
                                UK{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                              <Button className="dkg-st-tag-btntext">
                                USA{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                              <Button className="dkg-st-tag-btntext">
                                Grecee{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                              <Button className="dkg-st-tag-btntext">
                                Germany{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                            </div> */}
                          </div>
                          <div className="dkg-dj-rightsecond-cols">
                            <div className="dkg-desired-job-inputCon">
                              <div className="dkg-createnew-tag-title">
                                Create New Tag
                              </div>
                              <div className="dkg-desired-job-inputCon">
                                <input
                                  type="text"
                                  className="dkg-desired-job-input form-control"
                                  placeholder=""
                                  defaultValue=""
                                />
                                <div className="dkg-desired-job-save-btnCon">
                                  <SubmitButton
                                    txt="Save"
                                    className="dkg-desired-job-save-btn btn"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="dkg-desired-job-rightCon">
                          <div className="dkg-dj-rightfirst-cols">
                            <div className="dkg-saved-cv-tag-title">
                              MY PROFILE TAGS
                            </div>
                            {/* <div className="dkg-desired-job-btn-tagCon">
                              <Button className="dkg-desired-btntext">
                                India{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                              <Button className="dkg-desired-btntext">
                                Ireland{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                              <Button className="dkg-desired-btntext">
                                UK{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                              <Button className="dkg-desired-btntext">
                                USA{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                              <Button className="dkg-desired-btntext">
                                Grecee{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                              <Button className="dkg-desired-btntext">
                                Germany{" "}
                                <span className="dkg-tag-icon">
                                  {" "}
                                  <i className="fas fa-times"></i>
                                </span>
                              </Button>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};

export default MyaccResume;
