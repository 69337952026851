import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./paidservice.scss";
import {
  getCMSHeadingText,
  getService,
  getBenifits,
} from "../../../../../slice/frontendCms/frontendCmsSlice";
import { getInternalMailCount } from "../../../../../slice/mailbox/mailboxSlice";
import { getCMS, getCookiesNotification } from "../../../../../slice/cmsSlice";
import BuyingModal from "./modal/BuyingModal";

const Paidserviceprice = () => {
  const dispatch = useDispatch();
  const {
    isLoading,
    getcmsList,
    serviceList,
    // benifitslist,
    // isBenifits,
    isSuccess,
  } = useSelector((state) => state.cmslist);
  //const { jobseDataList } = useSelector((state) => state.jobs);
  const { userInfo } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  useEffect(() => {
    dispatch(
      getInternalMailCount({ can_id: userInfo.id, role: userInfo.role })
    );
  }, []);
  useEffect(() => {
    if (!isLoading && getcmsList && getcmsList.length > 0) {
      setData(getcmsList);
    }
    if (!isSuccess && serviceList && serviceList.length > 0) {
      setServiceData(serviceList);
    }
  }, [isLoading, isSuccess]);

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(getCMSHeadingText({}));
        await dispatch(getService({}));
        await dispatch(getBenifits({}));
        await dispatch(getCMS({}));
        await dispatch(getCookiesNotification({ canId: userInfo.canId }));
      } catch (err) {}
    }
    fetchData();
  }, [dispatch]);

  const [isBuyingModal, setIsBuyingModal] = useState(false);

  const showbuyingModal = () => {
    setIsBuyingModal(true);
  };
  const hidebuyingModal = () => {
    setIsBuyingModal(false);
  };
  return (
    <>
      {isBuyingModal ? <BuyingModal onHide={hidebuyingModal} /> : null}
      <div className="dkg-paidservices-mainpageCon">
        <div className="dkg-paidservices-con">
          <div className="dkg-paidservices-mainRow row m-0">
            <div className="dkg-paidservices-mainCol">
              <h2 className="dkg-services-page-title">
                {data.length > 0 ? data[0].page_description : ""}
              </h2>
              <p className="dkg-services-page-text">
                {data.length > 0 ? data[16].page_description : ""}
              </p>
            </div>
            <div className="dkg-paidservices-rightcon dkg-profile-info-rightCon col-md-12">
              {/* <h2 className="dkg-paidservice-title">Services Overiew</h2> */}
              <div className="dk-dashboard-panel dkg-account-benfits-panel">
                <div className="dkg-account-benfits-table-con">
                  <table className="table dkg-account-benfits-table table-bordered">
                    <thead>
                      <tr>
                        <th>ICON</th>
                        <th>STATUS</th>
                        <th>CATEGORY</th>
                        <th>METHOD</th>
                        <th>DESCRIPTION</th>
                        <th>PRICING</th>
                        <th>BUYING</th>
                      </tr>
                    </thead>
                    <tbody>
                      {serviceData.map((item, index) => (
                        <tr key={index + 1}>
                          <td>
                            <div className="dkg-benifts-iconCon">
                              <img
                                src={
                                  process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                                  "/" +
                                  item.image
                                }
                                className="dkg-benifts-icon"
                              />
                            </div>
                          </td>
                          {item.status === 1 ? (
                            <>
                              <td
                                className="dkg-acc-status-righttitle"
                                style={{ color: "green" }}
                              >
                                Available
                              </td>
                            </>
                          ) : (
                            <>
                              <td
                                className="dkg-acc-status-righttitle"
                                style={{ color: "red" }}
                              >
                                Unavailable
                              </td>
                            </>
                          )}
                          <td>{item.categoryName}</td>
                          <td>{item.type}</td>
                          <td>
                            <div className="dkg-acc-status-lefttitle">
                              <div className="dkg-paid-service-title">
                                {item.service_name}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="dkg-acc-status-price-cols text-center w-100">
                              <span className="dkg-paid-amoun-col">
                                {item.price}
                              </span>
                            </div>
                          </td>
                          <td>
                            <div className="dkg-buy-btn-con text-center w-100">
                              <Link
                                to="#"
                                className="btn dkg-buy-btn"
                                onClick={showbuyingModal}
                              >
                                {" "}
                                HOW TO BUY
                              </Link>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Paidserviceprice;
