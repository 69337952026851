import React from 'react'

const MyProfileTab = () => {
  return (
    <>
        <div className="dkg-consults-bioSec">
          <div className="dkg-consult-bio">Coming soon…..</div>
        </div>
    </>
  )
}

export default MyProfileTab