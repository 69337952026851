import React from 'react'
import Slider from "react-slick";
import   '../../../../../../node_modules/slick-carousel/slick/slick.css'
import   '../../../../../../node_modules/slick-carousel/slick/slick-theme.css'
import { FaLongArrowAltLeft , FaLongArrowAltRight } from "react-icons/fa";
import User from '../../../../../assets/images/deepak.png'

const TestimonialSlider = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        // infinite: true,
        nextArrow: <FaLongArrowAltRight />,
        prevArrow: <FaLongArrowAltLeft />,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };
  return (
    <>
       <Slider {...settings}>
            <div className='dkg-testimonial-cardCon'>
                <div className='dkg-testimonialCols-flex'>
                    <div className='dkg-testimonialCols'>
                        <div className='dkg-testimonialtext'>
                            It is a long established fact that a reader wil be distracted by the readable content of a page when 
                            looking at its layout. The point of using Lorem Ipsum is that is has a more-or-less normal.
                        </div>
                        <div className='dkg-testimonialImg'>
                            <div className='d-flex align-items-center'>
                                <div className='dkg-testiImage'>
                                    <img src={User} className='dkg-testi-user' alt='User' />
                                </div>
                                <div className='dkg-testiText'>
                                    <div className="dkg-testiTitle">DEEPAK KUMAR</div>
                                    <small className='dkg-countryname'>Ireland</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='dkg-testimonial-cardCon'>
                <div className='dkg-testimonialCols-flex'>
                    <div className='dkg-testimonialCols'>
                        <div className='dkg-testimonialtext'>
                            It is a long established fact that a reader wil be distracted by the readable content of a page when 
                            looking at its layout. The point of using Lorem Ipsum is that is has a more-or-less normal.
                        </div>
                        <div className='dkg-testimonialImg'>
                            <div className='d-flex align-items-center'>
                                <div className='dkg-testiImage'>
                                    <img src={User} className='dkg-testi-user' alt='User' />
                                </div>
                                <div className='dkg-testiText'>
                                    <div className="dkg-testiTitle">DEEPAK KUMAR</div>
                                    <small className='dkg-countryname'>Ireland</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='dkg-testimonial-cardCon'>
                <div className='dkg-testimonialCols-flex'>
                    <div className='dkg-testimonialCols'>
                        <div className='dkg-testimonialtext'>
                            It is a long established fact that a reader wil be distracted by the readable content of a page when 
                            looking at its layout. The point of using Lorem Ipsum is that is has a more-or-less normal.
                        </div>
                        <div className='dkg-testimonialImg'>
                            <div className='d-flex align-items-center'>
                                <div className='dkg-testiImage'>
                                    <img src={User} className='dkg-testi-user' alt='User' />
                                </div>
                                <div className='dkg-testiText'>
                                    <div className="dkg-testiTitle">DEEPAK KUMAR</div>
                                    <small className='dkg-countryname'>Ireland</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='dkg-testimonial-cardCon'>
                <div className='dkg-testimonialCols-flex'>
                    <div className='dkg-testimonialCols'>
                        <div className='dkg-testimonialtext'>
                            It is a long established fact that a reader wil be distracted by the readable content of a page when 
                            looking at its layout. The point of using Lorem Ipsum is that is has a more-or-less normal.
                        </div>
                        <div className='dkg-testimonialImg'>
                            <div className='d-flex align-items-center'>
                                <div className='dkg-testiImage'>
                                    <img src={User} className='dkg-testi-user' alt='User' />
                                </div>
                                <div className='dkg-testiText'>
                                    <div className="dkg-testiTitle">DEEPAK KUMAR</div>
                                    <small className='dkg-countryname'>Ireland</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='dkg-testimonial-cardCon'>
                <div className='dkg-testimonialCols-flex'>
                    <div className='dkg-testimonialCols'>
                        <div className='dkg-testimonialtext'>
                            It is a long established fact that a reader wil be distracted by the readable content of a page when 
                            looking at its layout. The point of using Lorem Ipsum is that is has a more-or-less normal.
                        </div>
                        <div className='dkg-testimonialImg'>
                            <div className='d-flex align-items-center'>
                                <div className='dkg-testiImage'>
                                    <img src={User} className='dkg-testi-user' alt='User' />
                                </div>
                                <div className='dkg-testiText'>
                                    <div className="dkg-testiTitle">DEEPAK KUMAR</div>
                                    <small className='dkg-countryname'>Ireland</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='dkg-testimonial-cardCon'>
                <div className='dkg-testimonialCols-flex'>
                    <div className='dkg-testimonialCols'>
                        <div className='dkg-testimonialtext'>
                            It is a long established fact that a reader wil be distracted by the readable content of a page when 
                            looking at its layout. The point of using Lorem Ipsum is that is has a more-or-less normal.
                        </div>
                        <div className='dkg-testimonialImg'>
                            <div className='d-flex align-items-center'>
                                <div className='dkg-testiImage'>
                                    <img src={User} className='dkg-testi-user' alt='User' />
                                </div>
                                <div className='dkg-testiText'>
                                    <div className="dkg-testiTitle">DEEPAK KUMAR</div>
                                    <small className='dkg-countryname'>Ireland</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='dkg-testimonial-cardCon'>
                <div className='dkg-testimonialCols-flex'>
                    <div className='dkg-testimonialCols'>
                        <div className='dkg-testimonialtext'>
                            It is a long established fact that a reader wil be distracted by the readable content of a page when 
                            looking at its layout. The point of using Lorem Ipsum is that is has a more-or-less normal.
                        </div>
                        <div className='dkg-testimonialImg'>
                            <div className='d-flex align-items-center'>
                                <div className='dkg-testiImage'>
                                    <img src={User} className='dkg-testi-user' alt='User' />
                                </div>
                                <div className='dkg-testiText'>
                                    <div className="dkg-testiTitle">DEEPAK KUMAR</div>
                                    <small className='dkg-countryname'>Ireland</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='dkg-testimonial-cardCon'>
                <div className='dkg-testimonialCols-flex'>
                    <div className='dkg-testimonialCols'>
                        <div className='dkg-testimonialtext'>
                            It is a long established fact that a reader wil be distracted by the readable content of a page when 
                            looking at its layout. The point of using Lorem Ipsum is that is has a more-or-less normal.
                        </div>
                        <div className='dkg-testimonialImg'>
                            <div className='d-flex align-items-center'>
                                <div className='dkg-testiImage'>
                                    <img src={User} className='dkg-testi-user' alt='User' />
                                </div>
                                <div className='dkg-testiText'>
                                    <div className="dkg-testiTitle">DEEPAK KUMAR</div>
                                    <small className='dkg-countryname'>Ireland</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Slider>
    </>
  )
}

export default TestimonialSlider  