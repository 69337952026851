import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
//import { Link } from "react-router-dom";
import { Tab, Nav, Dropdown } from "react-bootstrap";

import ProjectTab from "./ProjectTab";

const DkgClientCompaines = ({ client_idd, jobId  }) => {
  //const dispatch = useDispatch();
  const { activeprojecList, isProjectLoading } = useSelector(
    (state) => state.clientproject
  );

  const [eventKey, SetEventKey] = useState("jobpsec-company");
  useEffect(() => {
    if (eventKey) {
      switch (eventKey) {
        case "jobpsec-company":
          //console.log("job deails");
          break;
        case "jobpsec-company":
          //console.log("job deails");
          break;
        default:
          // console.log("default");
          break;
      }
    }
  }, [eventKey]);

  const [data, setData] = useState([]);

  useEffect(() => {
    if (!isProjectLoading) {
      setData(activeprojecList.projectdetails);
    }
  }, [isProjectLoading]);
  const [selectedItem, setSelectedItem] = useState("Website");
//  console.log('selected item ' , selectedItem)
  const handleSelect = (item) => {
    setSelectedItem(item);
  };
  const renderComponent = () => {
    switch (selectedItem) {
      case "":
        return <>
            
        </>;    
      default:
        return null;
    }
  };
  return (
    <>
      <div className="dkg-jobspec-company-mainCon dkg-jobspec-modal-company-mainCon" style={{ marginTop: "20px", height: "calc(100vh - 92px)" }}>
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey="jobpsec-company0"
        >
        <div className="dkg-cand-jobspec-moCon dkg-cand-js-company-moCon">
          <div className="dkg-jobspec-header-centerCon">
            <h2 className="dkg-cand-categoty-title w-100 text-center" style={{fontSize: "1.25rem" , fontWeight: "600"}}> {data ? activeprojecList.companyName : ""}</h2>
            <div className="dkg-jobspec-DropdownCon">
              <Dropdown className='dkg-jobspec-Dropdown'>
                <Dropdown.Toggle variant="" className='dkg-jobspec-Dropdown-toggle'>
                  {selectedItem}
                </Dropdown.Toggle>
                <Dropdown.Menu className='dkg-jobspec-Dropdown-Menu w-100'  aria-labelledby="dropdownMenuButton">
                  {data &&
                    data.map((item, index) => (
                       <Dropdown.Item href="#"
                       eventKey={`jobpsec-company` + index}
                        key={index}
                        // className="dropdown-item"
                       className={item.subCategoryName === selectedItem ? 'selected-item dropdown-item' : 'dropdown-item'}
                       onClick={() => handleSelect(item.subCategoryName)}
                       >
                         {item.subCategoryName}
                     </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className="dkg-jobspec-company-header">
          <div className="dkg-jobpsec-DropdownCon">
            <div className="dkg-jobpsec-page-title">
              {data ? activeprojecList.companyName : ""}
            </div>
          </div>
        </div>
          <div className="row dkg-jobspec-company-mainRow">
            <div className="col-2 dkg-jobspec-company-sideMenu">
              <Nav
                variant=""
                className="dkg-jobspec-company-navpills flex-column"
                onSelect={(selectedKey) => SetEventKey(selectedKey)}
              >
                <Nav.Item className="dkg-jobspec-company-navitem">
                  {data &&
                    data.map((item, index) => (
                      <Nav.Link
                        eventKey={`jobpsec-company` + index}
                        className="dkg-jobspec-company-navlink"
                      >
                        {item.subCategoryName}
                      </Nav.Link>
                    ))}
                </Nav.Item>
              </Nav>
            </div>
            <div className="dkg-jobspec-rightCon col-md-10">
              <Tab.Content className="dkg-jobspec-tabcontent">
                {data &&
                  data.map((item, index) => (
                    <Tab.Pane
                      eventKey={`jobpsec-company` + index}
                      className="dkg-jobspec-tabpane"
                    >
                      <ProjectTab
                        pdata={item.description}
                        title={item.subCategoryName}
                      />
                    </Tab.Pane>
                  ))}
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
      </div>
    </>
  );
};

export default DkgClientCompaines;
