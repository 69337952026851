import React, { useState, useCallback, useRef, useEffect } from "react";
import { FaEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

//import DummyUser from "../../../../../assets/images/user_dummay.png";
import {
  getCMS,
  // updatecontent,
  // clearState,
} from "../../../../../slice/cmsSlice";
import {
  getBirthCertificate,
  getCurrentJobLevel,
  getJobIndustryLevel,
  getTechnicalLevel,
  getEducationLevel,
  getOverallWorkExp,
} from "../../../../../slice/layout/layoutSlice";
import SubmitButton from "../../../../../components/ui/submitButton";

import AWS from "aws-sdk";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Modal, Dropdown } from "react-bootstrap";
import { Buffer } from "buffer";
import { updateCanDetails } from "../../../../../slice/auth/authSlice";
import {
  updateTable,
  candidateProfileDetails,
} from "../../../../../slice/candidates/list/listSlice";

const MyProfile = () => {
  const dispatch = useDispatch();
  const { userDetails, userInfo } = useSelector((state) => state.auth);

  const {
    birthcertificateList,
    currentJoblevelList,
    jobindustryList,
    technicalList,
    educationList,
    workexpList,
    isjoblevelSuccess,
    isIndustrySuccess,
    isTechSuccess,
    isEduSuccess,
    isWorkExpSuccess,
    isDataSuccess,
  } = useSelector((state) => state.common);
  const [birthCertifate, setBirthCertifate] = useState([]);
  const [jobArrlevel, setJobArrlevel] = useState([]);
  const [jobArrindustry, setJobArrindustry] = useState([]);
  const [technicalArrlevel, setTechnicalArrlevel] = useState([]);
  const [educationArr, setEducationArr] = useState([]);
  const [workArrexp, setWorkArrexp] = useState([]);

  const [bCertifateValue, setBCertifateValue] = useState(
    userInfo.birth_certificate != null
      ? userInfo.birth_certificate
      : "Select ..."
  );
  const [joblevel, setJoblevel] = useState(
    userInfo.current_job_level != null
      ? userInfo.current_job_level
      : "Select ..."
  );
  const [jobindustry, setJobindustry] = useState(
    userInfo.current_job_industry != null
      ? userInfo.current_job_industry
      : "Select ..."
  );
  const [technicalevel, setTechnicalevel] = useState(
    userInfo.current_tech_level != null
      ? userInfo.current_tech_level
      : "Select ..."
  );
  const [education, setEducation] = useState(
    userInfo.current_edu_level != null
      ? userInfo.current_edu_level
      : "Select ..."
  );
  const [workexp, setWorkexp] = useState(
    userInfo.overall_work_exp != null ? userInfo.overall_work_exp : "Select ..."
  );

  useEffect(() => {
    if (isDataSuccess && birthcertificateList.length > 0) {
      setBirthCertifate(birthcertificateList);
    }
    if (isjoblevelSuccess && currentJoblevelList.length > 0) {
      setJobArrlevel(currentJoblevelList);
    }
    if (isIndustrySuccess && jobindustryList.length > 0) {
      setJobArrindustry(jobindustryList);
    }
    if (isTechSuccess && technicalList.length > 0) {
      setTechnicalArrlevel(technicalList);
    }
    if (isEduSuccess && educationList.length > 0) {
      setEducationArr(educationList);
    }
    if (isWorkExpSuccess && workexpList.length > 0) {
      setWorkArrexp(workexpList);
    }
  }, [
    isDataSuccess,
    isjoblevelSuccess,
    isIndustrySuccess,
    isTechSuccess,
    isEduSuccess,
    isWorkExpSuccess,
  ]);

  const { isProfileLoading, profileDetails } = useSelector(
    (state) => state.candidates
  );

  // const [fullname, setFullName] = useState("");
  // const [phone1, setPhone1] = useState("");
  // const [primaryEmail, setPrimaryEmail] = useState("");
  // const [systemEmail, setSystemEmail] = useState("");
  const [nativelang, setNativeLang] = useState("");
  const [fluentlang, setFluentLang] = useState("");

  // const [phone2, setPhone2] = useState("");
  // const [email2, setEmail2] = useState("");
  // const [homeAddress, setHomeAddress] = useState("");
  // const [Ccity, setCcity] = useState("");
  // const [Ccountry, setCcountry] = useState("");
  // const [state, setState] = useState("");
  // const [postal, setPostal] = useState("");

  // const [dobdate, setDobdate] = useState("");
  // const [dobmonth, setDobmonth] = useState("");
  // const [dobyear, setDobyear] = useState("");

  useEffect(() => {
    const fetchCandidateRecods = async () => {
      await dispatch(candidateProfileDetails({ id: userInfo.canId }));
      await dispatch(getBirthCertificate({}));

      await dispatch(getCurrentJobLevel({}));
      await dispatch(getJobIndustryLevel({}));
      await dispatch(getTechnicalLevel({}));
      await dispatch(getEducationLevel({}));
      await dispatch(getOverallWorkExp({}));
    };
    fetchCandidateRecods();
  }, []);

  useEffect(() => {
    if (!isProfileLoading && profileDetails) {
      //setFullName(profileDetails.first_name + " " + profileDetails.last_name);
      //setPhone1(profileDetails.phone1);
      //setPrimaryEmail(profileDetails.email1);
      //setSystemEmail(profileDetails.system_email);
      setNativeLang(profileDetails.native1);
      setFluentLang(profileDetails.fluent1);

      //setPhone2(profileDetails.phone2);
      //setEmail2(profileDetails.email2);
      //setHomeAddress(profileDetails.address);
      //setCcity(profileDetails.c_city);
      //setState(profileDetails.state);
      //setCcountry(profileDetails.c_country);
      // setPostal(profileDetails.postal);
      //setDobdate(profileDetails.dob_date);
      //setDobmonth(profileDetails.dob_month);
      //setDobyear(profileDetails.dob_year);
    }
  }, [isProfileLoading]);

  //image crop start
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  //const [file, setFile] = useState(userInfo.profileImage);
  //const [isImageUpdate, setIsImageUpdate] = useState(false);
  const [newImageData, setNewImageData] = useState("");
  //const [profileImage, setProfileImage] = useState(userInfo.profileImage);

  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({
    unit: "px",
    width: 150,
    height: 150,
    aspect: 1 / 1,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [cropModal, setCropModal] = useState(false);

  // useEffect(() => {
  //   if (userInfo.profileImage != "users/images/user.png") {
  //     //setFile(userInfo.profileImage);
  //   }
  // }, []);

  // const onSelectFile = (e) => {
  //   if (e.target.files && e.target.files.length > 0) {
  //     const reader = new FileReader();
  //     reader.addEventListener("load", () => setUpImg(reader.result));
  //     reader.readAsDataURL(e.target.files[0]);
  //     setCropModal(true);
  //   }
  // };
  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }
    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
    const base64Image = canvas.toDataURL("image/jpeg");
    //setIsImageUpdate(true);
    setNewImageData(base64Image);
  }, [completedCrop]);

  const handleClose = () => {
    setCropModal(false);
  };
  const handleCropDone = () => {
    // image upload
    console.log("submit crop");
    let s3bucket = new AWS.S3({
      accessKeyId: process.env.REACT_APP_IAM_USER_KEY,
      secretAccessKey: process.env.REACT_APP_IAM_USER_SECRET,
      Bucket: process.env.REACT_APP_BUCKET_NAME,
    });
    let buf = new Buffer(
      newImageData.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );
    s3bucket.createBucket(function () {
      var params = {
        Bucket: process.env.REACT_APP_BUCKET_NAME + "/users/images",
        Key: Date.now() + ".png",
        ContentEncoding: "base64",
        ContentType: "image/png",
        Body: buf,
      };
      s3bucket.upload(params, function (err, data) {
        if (err) {
          console.log(err);
        } else {
          //setProfileImage(data.key);
          dispatch(
            updateCanDetails({
              id: userInfo.id,
              profileImage: "/" + data.key,
            })
          );
        }
      });
    });
    setCropModal(false);
  };

  const cancelCrop = () => {
    setCropModal(false);
    setNewImageData("");
    //setIsImageUpdate(false);
    setCompletedCrop(null);
  };
  //image crop end

  const { isSuccess, cmsList, isError, isInsert, msg, isLoading } = useSelector(
    (state) => state.cmsdata
  );
  // const [details, setDetails] = useState("");
  const [requireddat, setRequireddat] = useState("");
  const [optionaldata, setOptionaldata] = useState("");

  useEffect(() => {
    dispatch(getCMS({}));
  }, [dispatch]);

  useEffect(() => {
    if (!isSuccess && cmsList.length > 0) {
      setRequireddat(cmsList[31].description);
      setOptionaldata(cmsList[49].description);
      //setDetails(cmsList);
    }
  }, [isSuccess]);

  // const updateCandidateInfo = async (key, value) => {
  //   await dispatch(updateTable({ id: userInfo.id, key, value }));
  // };

  const handleValueChange = async (value, key) => {
    switch (key) {
      case "birth_certificate":
        setBCertifateValue(value);
        break;
      case "current_job_level":
        setJoblevel(value);
        break;
      case "current_job_industry":
        setJobindustry(value);
        break;
      case "current_tech_level":
        setTechnicalevel(value);
        break;
      case "current_edu_level":
        setEducation(value);
        break;
      case "overall_work_exp":
        setWorkexp(value);
        break;
      default:
        break;
    }

    await dispatch(updateTable({ id: userInfo.id, key, value }));
  };

  return (
    <>
      {cropModal ? (
        <Modal
          className="dk-uploadImgModal"
          centered
          show={true}
          onHide={handleClose}
        >
          <Modal.Body>
            <ReactCrop
              src={upImg}
              onImageLoaded={onLoad}
              crop={crop}
              onChange={(c) => setCrop(c)}
              onComplete={(c) => setCompletedCrop(c)}
            />
          </Modal.Body>
          <Modal.Footer>
            <div className="col-md-12">
              <div className="d-flex justify-content-center btn-container">
                <button className="dk-cancelBtn" onClick={cancelCrop}>
                  Cancel
                </button>
                <SubmitButton
                  txt="Use This"
                  loading={loading}
                  success={success}
                  onClick={handleCropDone}
                  position=""
                  className="dk-updateBtn"
                />
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      ) : null}

      <div className="dkg-myprofile-mainpageCon">
        <div className="dkg-myprofile-con">
          <div className="dkg-myprofile-mainRow row m-0">
            <div className="dkg-myprofile-leftcon dkg-profile-info-leftCon col-md-6">
              <h2 className="dkg-profile-frm-title">PROFILE INFORMATION</h2>
              <div className="dkg-profile-frm-subtitle">
                <p className="dkg-frm-subtitle-desc">{requireddat}</p>
              </div>
              <div className="form-group dkg-profile-frmgrp">
                <label htmlFor="" className="dkg-profile-label">
                  Country <span className="dkg-profile-star">*</span>
                </label>
                <input
                  type="text"
                  className="form-control dkg-profile-input dkg-lightgrey-placeholder"
                  name="backup-phone"
                  id="backup-phone"
                  placeholder={userInfo.c_country}
                />
              </div>
              <div className="form-group dkg-profile-frmgrp">
                <label htmlFor="" className="dkg-profile-label">
                  City/Town<span className="dkg-profile-star">*</span>
                </label>
                <input
                  type="email"
                  className="form-control dkg-profile-input dkg-lightgrey-placeholder"
                  name="backup-mail"
                  id="backup-mail"
                  placeholder={userInfo.c_city}
                />
              </div>
              <div className="form-group dkg-profile-frmgrp">
                <label htmlFor="" className="dkg-profile-label">
                  Nationality<span className="dkg-profile-star">*</span>
                </label>
                <input
                  type="text"
                  className="form-control dkg-profile-input dkg-lightgrey-placeholder"
                  name="1st-line"
                  id="1st-line"
                  placeholder={userInfo.nationality}
                />
              </div>
              <div className="form-group dkg-profile-frmgrp">
                <label htmlFor="" className="dkg-profile-label">
                  Visa Status<span className="dkg-profile-star">*</span>
                </label>
                <input
                  type="text"
                  className="form-control dkg-profile-input dkg-lightgrey-placeholder"
                  name="2nd-line"
                  id="2nd-line"
                  placeholder={userInfo.visaStatus}
                />
              </div>
              <div className="form-group dkg-profile-frmgrp">
                <label htmlFor="" className="dkg-profile-label">
                  Native Language<span className="dkg-profile-star">*</span>
                </label>
                <input
                  type="text"
                  className="form-control dkg-profile-input dkg-lightgrey-placeholder"
                  name="native-language"
                  id="native-language"
                  placeholder={nativelang}
                />
              </div>
              <div className="form-group dkg-profile-frmgrp">
                <label htmlFor="" className="dkg-profile-label">
                  Fluent Language<span className="dkg-profile-star">*</span>
                </label>
                <input
                  type="text"
                  className="form-control dkg-profile-input dkg-lightgrey-placeholder"
                  name="fluent-language"
                  id="fluent-language"
                  placeholder={fluentlang}
                />
              </div>
            </div>
            <div className="dkg-myprofile-rightcon dkg-profile-info-rightCon col-md-6">
              <h2 className="dkg-profile-frm-title">OPTIONAL INFORMATION</h2>
              <div className="dkg-profile-frm-subtitle">
                <p className="dkg-frm-subtitle-desc">{optionaldata}</p>
              </div>
              <div className="col-md-12">
                <div className="form-group dkg-profile-frmgrp pl-0 pr-0 ">
                  <label htmlFor="" className="dkg-profile-label">
                    Birth Certificate
                  </label>
                  <div className="dkg-app-select-dropdown-Con">
                    <Dropdown className="dkg-app-select-dropdown">
                      <Dropdown.Toggle
                        variant=""
                        id=""
                        className="dkg-app-select-dropdown-toggle"
                      >
                        {bCertifateValue}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dkg-app-select-dropdown-menu">
                        {birthCertifate.map((data, index) => (
                          <Dropdown.Item
                            href="#"
                            className="dkg-app-select-dropdown-item"
                            key={index + 1}
                            onClick={() =>
                              handleValueChange(data.value, "birth_certificate")
                            }
                          >
                            {data.value}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="form-group dkg-profile-frmgrp pl-0 pr-0 ">
                  <label htmlFor="" className="dkg-profile-label">
                    Current Job Level
                  </label>
                  <div className="dkg-app-select-dropdown-Con">
                    <Dropdown className="dkg-app-select-dropdown">
                      <Dropdown.Toggle
                        variant=""
                        id=""
                        className="dkg-app-select-dropdown-toggle"
                      >
                        {joblevel}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dkg-app-select-dropdown-menu">
                        {jobArrlevel.map((data, index) => (
                          <Dropdown.Item
                            href="#"
                            className="dkg-app-select-dropdown-item"
                            key={index + 1}
                            onClick={() =>
                              handleValueChange(data.value, "current_job_level")
                            }
                          >
                            {data.value}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="form-group dkg-profile-frmgrp pl-0 pr-0 ">
                  <label htmlFor="" className="dkg-profile-label">
                    Current Job Industry
                  </label>
                  <div className="dkg-app-select-dropdown-Con">
                    <Dropdown className="dkg-app-select-dropdown">
                      <Dropdown.Toggle
                        variant=""
                        id=""
                        className="dkg-app-select-dropdown-toggle"
                      >
                        {jobindustry}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dkg-app-select-dropdown-menu">
                        {jobArrindustry.map((data, index) => (
                          <Dropdown.Item
                            href="#"
                            className="dkg-app-select-dropdown-item"
                            key={index + 1}
                            onClick={() =>
                              handleValueChange(
                                data.value,
                                "current_job_industry"
                              )
                            }
                          >
                            {data.value}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="form-group dkg-profile-frmgrp pl-0 pr-0 ">
                  <label htmlFor="" className="dkg-profile-label">
                    Current Technical Level
                  </label>
                  <div className="dkg-app-select-dropdown-Con">
                    <Dropdown className="dkg-app-select-dropdown">
                      <Dropdown.Toggle
                        variant=""
                        id=""
                        className="dkg-app-select-dropdown-toggle"
                      >
                        {technicalevel}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dkg-app-select-dropdown-menu">
                        {technicalArrlevel.map((data, index) => (
                          <Dropdown.Item
                            href="#"
                            className="dkg-app-select-dropdown-item"
                            key={index + 1}
                            onClick={() =>
                              handleValueChange(
                                data.value,
                                "current_tech_level"
                              )
                            }
                          >
                            {data.value}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="form-group dkg-profile-frmgrp pl-0 pr-0 ">
                  <label htmlFor="" className="dkg-profile-label">
                    Current Education Level
                  </label>
                  <div className="dkg-app-select-dropdown-Con">
                    <Dropdown className="dkg-app-select-dropdown">
                      <Dropdown.Toggle
                        variant=""
                        id=""
                        className="dkg-app-select-dropdown-toggle"
                      >
                        {education}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dkg-app-select-dropdown-menu">
                        {educationArr.map((data, index) => (
                          <Dropdown.Item
                            href="#"
                            className="dkg-app-select-dropdown-item"
                            key={index + 1}
                            onClick={() =>
                              handleValueChange(data.value, "current_edu_level")
                            }
                          >
                            {data.value}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="form-group dkg-profile-frmgrp pl-0 pr-0 ">
                  <label htmlFor="" className="dkg-profile-label">
                    Overall Work Experience
                  </label>
                  <div className="dkg-app-select-dropdown-Con">
                    <Dropdown className="dkg-app-select-dropdown">
                      <Dropdown.Toggle
                        variant=""
                        id=""
                        className="dkg-app-select-dropdown-toggle"
                      >
                        {workexp}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dkg-app-select-dropdown-menu">
                        {workArrexp.map((data, index) => (
                          <Dropdown.Item
                            href="#"
                            className="dkg-app-select-dropdown-item"
                            key={index + 1}
                            onClick={() =>
                              handleValueChange(data.value, "overall_work_exp")
                            }
                          >
                            {data.value}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
