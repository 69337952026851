import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom'
import  './contact-us.scss'
import {
  getCMS,
  getAboutService,
  submitQuery,
  clearState,
  getprojectData,
} from "../../../../../slice/cmsSlice";
import {
  showError,
  clearMessage,
  showSuccess,
} from "../../../../../slice/utils/message/messageSlice";
import FacebookIcon from "../../../../../assets/images/facebook.png";
import InstagramIcon from "../../../../../assets/images/instagram.png";
import LinkedinIcon from "../../../../../assets/images/linkedin.png";
import YoutubeIcon from "../../../../../assets/images/youtube.png";
import TwitterIcon from "../../../../../assets/images/dkglobal.png";

const ContactUs = () => {
    const dispatch = useDispatch();
    const {
      isProjectS,
      isSuccess,
      cmsList,
      isError,
      isInsert,
      msg,
      isLoading,
      aboutusServiceList,
      isAboutSuccess,
      projectDataList,
    } = useSelector((state) => state.cmsdata);
  
    const { userInfo } = useSelector((state) => state.auth);
    const [JobInstuctiondata, setJobInstuctiondata] = useState("");
    const [aboutusData, setAboutusData] = useState([]);
    const [data, setdata] = useState([]);
  
    const [candidateName, setCandidateName] = useState("");
    const [candidateEmail, setCandidateEmail] = useState("");
    const [candidatePhone, setCandidatePhone] = useState("");
    const [candidateSubject, setCandidateSubject] = useState("");
    const [candidateMessage, setCandidateMessage] = useState("");
    const [checked, setChecked] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
  
    useEffect(() => {
      dispatch(getCMS({}));
      dispatch(getAboutService({}));
      dispatch(getprojectData({}));
    }, []);
  
    useEffect(() => {
      if (isSuccess) {
        console.log(cmsList[1]);
        setJobInstuctiondata(cmsList[1]);
      }
      if (isAboutSuccess) {
        setAboutusData(aboutusServiceList);
      }
      if (isProjectS) {
        setdata(projectDataList);
      }
    }, [isSuccess, isProjectS, isAboutSuccess]);
  
    useEffect(() => {
      if (isError) {
        dispatch(clearState());
        dispatch(showError({ msg: msg }));
        setLoading(false);
        setSuccess(false);
      }
      if (isInsert && !isLoading) {
        //setChecked(false);
        setCandidateName("");
        setCandidateEmail("");
        setCandidatePhone("");
        setCandidateSubject("");
        setCandidateMessage("");
        dispatch(clearState());
        dispatch(showSuccess({ msg: msg }));
        setLoading(false);
        setSuccess(false);
      }
    }, [isError, isInsert]);
  
    const SubmitBtn = async (e) => {
      e.preventDefault();
      setSuccess(false);
      setLoading(true);
  
      if (
        !checked ||
        !candidateName ||
        !candidateEmail ||
        !candidatePhone ||
        !candidateSubject ||
        !candidateMessage
      ) {
        dispatch(showError({ msg: "All fields are required." }));
        setLoading(false);
      } else {
        try {
          setLoading(true);
          await dispatch(
            submitQuery({
              canid: userInfo.canId,
              terms: checked,
              name: candidateName,
              from: candidateEmail,
              phone: candidatePhone,
              subject: candidateSubject,
              message: candidateMessage,
            })
          );
        } catch (err) {
          setSuccess(false);
          setLoading(false);
        }
      }
    };
  return (
    <>
      <div className="dkg-setting-contactus-mainCon dkg-contactus-mainCon">
        <div className="dkg-setting-header">
          <h2 className="dkg-setting-header-title">Contact us</h2>
        </div>
        <div className="dkg-other-project-header">
          <p className="dkg-otherprject-title">
            {JobInstuctiondata != ""
              ? JobInstuctiondata.sendusOverview
              : ""}
          </p>
        </div>
        <div className="dkg-contactus-cardCon">
          <div className="card dkg-contact-card">
            <div className="row align-items-center dkg-contact-cardRow">
              <div className="col-12 col-md-5 dkg-contact-card-leftCol">
                <div className="contact_detail dkg-contact-card-detail">
                  <h3 className="dkg-card-detail-title">
                    {JobInstuctiondata != ""
                      ? JobInstuctiondata.sendusCompany
                      : ""}
                  </h3>
                  <ul className="dkg-contact-card-ul">
                    <li className="dkg-contact-card-li">
                      <i className="fa fa-map-marker"></i>
                      <Link to="#">
                        {JobInstuctiondata != ""
                          ? JobInstuctiondata.sendusAddress
                          : ""}
                      </Link>
                    </li>
                    <li className="dkg-contact-card-li">
                      <i className="fa fa-envelope"></i>
                      <Link to="#">
                        {JobInstuctiondata != ""
                          ? JobInstuctiondata.sendusEmail
                          : ""}
                      </Link>
                    </li>
                    <li className="dkg-contact-card-li">
                      <i className="fa fa-phone"></i>
                      <Link to="#">
                        {" "}
                        {JobInstuctiondata != ""
                          ? JobInstuctiondata.sendusPhone
                          : ""}
                      </Link>
                    </li>
                  </ul>
                  <div className="dkg-contact_social d-none">
                    <Link target="_blank">
                      <img src={FacebookIcon} alt="facebook" />
                    </Link>
                    <Link target="_blank">
                      <img src={InstagramIcon} alt="instagram" />
                    </Link>
                    <Link target="_blank">
                      <img src={LinkedinIcon} alt="linkedin" />
                    </Link>
                    <Link target="_blank">
                      <img src={YoutubeIcon} alt="youtube" />
                    </Link>
                    <Link target="_blank">
                      <img src={TwitterIcon} alt="twitter" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-7 dkg-contact-card-rightCol">
                <form>
                  <div className="row">
                    <div className="col-12 col-md-9">
                      <h2>Get In Touch</h2>
                      <div className="form-group">
                        <input
                          placeholder="Your Name"
                          className="form-control"
                          value={candidateName}
                          onChange={(e) =>
                            setCandidateName(e.target.value)
                          }
                        />
                      </div>
                      <div className="form-group">
                        <input
                          placeholder="Your Email"
                          className="form-control"
                          value={candidateEmail}
                          onChange={(e) =>
                            setCandidateEmail(e.target.value)
                          }
                        />
                      </div>
                      <div className="form-group">
                        <input
                          placeholder="Your Phone"
                          className="form-control"
                          value={candidatePhone}
                          onChange={(e) =>
                            setCandidatePhone(e.target.value)
                          }
                        />
                      </div>
                      <div className="form-group">
                        <input
                          placeholder="Subject"
                          className="form-control"
                          value={candidateSubject}
                          onChange={(e) =>
                            setCandidateSubject(e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                      placeholder="Message"
                      className="form-control"
                      value={candidateMessage}
                      onChange={(e) =>
                        setCandidateMessage(e.target.value)
                      }
                    ></textarea>
                  </div>
                  <p>
                    <input
                      type="checkbox"
                      className="cusbox"
                      defaultChecked={checked}
                      onChange={() => setChecked((state) => !state)}
                    />
                    I agree to the
                    <Link
                      to="/js-account/settings/terms-of-use"
                      className=""
                    >
                      Terms of Use
                    </Link>
                    and
                    <Link to="/js-account/settings/privacy" className="">
                      Privacy policy
                    </Link>
                  </p>
                  <button
                    loading={loading}
                    success={success}
                    type="button"
                    className="btn btn-light"
                    position="justify-content-center"
                    onClick={SubmitBtn}
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    
    </>
  )
}

export default ContactUs