import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom'
import { Tab, Nav,Dropdown } from "react-bootstrap";
import FaqAccordion from "./Accordion";
import { useDispatch, useSelector } from "react-redux";
import {
  getCandidateFaqCategory,
  getdefaultQuesAns,
  getQueAns,
} from "../../../../../slice/faqs/candidateServiceFaqSlice";

import { getCMSHeadingText } from "../../../../../slice/frontendCms/frontendCmsSlice";

const ServiceFaq = () => {
  const dispatch = useDispatch();
  const { CandidateFaqCategoryList, CandidateFaqQuesAnsList } = useSelector(
    (state) => state.candidateservicefaqData
  );

  const { isLoading, getcmsList } = useSelector((state) => state.cmslist);
  const [data, setData] = useState([]);
  useEffect(() => {
    if (!isLoading && getcmsList && getcmsList.length > 0) {
      setData(getcmsList);
    }
  }, [isLoading]);

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(getCMSHeadingText({}));
      } catch (err) {}
    }
    fetchData();
  }, [dispatch]);

  const [activeLink, setActiveLink] = useState(null);
  const [dropdownlvalue, setDropdownValue] = useState("Overview");
  const handleClick = async (id , name) => {
    console.log(id);
    setDropdownValue(name)
    dispatch(getQueAns({ id }));
    setActiveLink(id);
  };

  useEffect(() => {
    dispatch(getCandidateFaqCategory({}));
    dispatch(getdefaultQuesAns({}));
  }, []);

  useEffect(() => {
    if (
      !isLoading &&
      CandidateFaqQuesAnsList &&
      CandidateFaqCategoryList &&
      CandidateFaqCategoryList.length > 0
    ) {
      setActiveLink(CandidateFaqCategoryList[0].id);
    }
  }, [isLoading]);
  // const dummyData = [
  //   {
  //     id: 1,
  //     name: "Lorem Ipsum 1",
  //     gender: "male",
  //   },
  //   {
  //     id: 1,
  //     name: "Lorem Ipsum 2",
  //     gender: "female",
  //   },
  //   {
  //     id: 1,
  //     name: "Lorem Ipsum 3",
  //     gender: "trans",
  //   },
  // ];
  return (
    <>
      <div className="dkg-myacc-faqmain-Con dkg-service-faqmain-Con container-fluid">
        <div className="row dkg-faq-mainCon dkg-service-faqmain">
          <div className="col-md-12 dkg-faq-boxCon dkg-service-boxCon">
            <h2 className="dkg-consults-page-title">
              {data.length > 0 ? data[30].heading_decription : ""}
            </h2>
            <p className="dkg-consults-page-text">
              {data.length > 0 ? data[31].heading_decription : ""}
            </p>
            <div className="dkg-myaccount-faq-pageCon dkg-service-faq-bluesec-con">
            <div className="dkg-cand-faq-moCon">
              <div className="dkg-faq-header-centerCon">
                <h2
                  className="dkg-cand-categoty-title w-100 text-center"
                  style={{ fontSize: "1.25rem", fontWeight: "600" }}
                >
                  Select Category
                </h2>
                <div className="dkg-faq-DropdownCon">
                  <Dropdown className="dkg-faq-Dropdown">
                    <Dropdown.Toggle variant="" className="dkg-faq-Dropdown-toggle">
                      {dropdownlvalue}
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="dkg-faq-Dropdown-Menu w-100"
                      aria-labelledby="dropdownMenuButton"
                    >
                      {CandidateFaqCategoryList &&
                        CandidateFaqCategoryList.filter(
                          (item) => item.status === "Active"
                        ).map((link, index) => {
                          return (
                            <>
                              <Dropdown.Item href="#"
                                key={link.id}
                                // className="dropdown-item"
                                className={link.name === dropdownlvalue ? 'selected-item dropdown-item' : 'dropdown-item'}
                                onClick={() => handleClick(link.id, link.name)}
                              >
                                {link.name}
                              </Dropdown.Item>
                            </>
                          );
                        })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="dkg-cand-faq-mo-accordianCon">
                {CandidateFaqQuesAnsList.length > 0 ? (
                  <>
                    <div className="dkg-myaccount-faq-tabcontent">
                      <FaqAccordion quesAnswer={CandidateFaqQuesAnsList} />
                    </div>
                  </>
                ) : null}
              </div>
            </div>
              <Tab.Container defaultActiveKey="question-pt-overview">
                <div className="row dkg-myaccount-faq-tab-mainCon">
                  <div className="col-md-2 pr-2 dkg-myaccount-faq-tabCon-10">
                    <Nav
                      variant="pills"
                      className="flex-column dkg-myaccount-faq-tab-navpills"
                    >
                      {CandidateFaqCategoryList.map((link, index) => {
                        return (
                          <Nav.Item className="dkg-myaccount-faq-tab-nav-item">
                            {
                              <Nav.Link
                                to="#"
                                key={link.id}
                                data-id={link.id}
                                onClick={() => handleClick(link.id)}
                                className={
                                  activeLink === link.id
                                    ? "dkg-myaccount-faq-tab-navlink active"
                                    : "dkg-myaccount-faq-tab-navlink "
                                }
                                // className="dkg-myaccount-faq-tab-navlink"
                              >
                                {link.name}
                              </Nav.Link>
                            }
                          </Nav.Item>
                        );
                      })}
                    </Nav>
                  </div>
                  <div className="col-md-10 dkg-myaccount-faq-tabCon-10 pl-5">
                    {CandidateFaqQuesAnsList.length > 0 ? (
                      <>
                        <Tab.Content className="dkg-myaccount-faq-tabcontent">
                          <FaqAccordion quesAnswer={CandidateFaqQuesAnsList} />
                        </Tab.Content>
                      </>
                    ) : null}
                  </div>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceFaq;
