import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaArrowRight, FaCalendarAlt } from "react-icons/fa";
import ReviewprogressModal from "./modal/ReviewprogressModal";
import WithdrawApplicationModal from "./modal/WithdrawApplication";
import CandidateDocModal from "./modal/CandidateDocModal";
import CustomDateFormat from "../../../ui/dateFormats/CustomDateFormat";
import DetialViewPoup from "../../../../components/pages/jobs/livejobs/modal/DetialViewPoup";
import EditdetialviewPoup from "../../../../components/pages/jobs/livejobs/modal/EditdetialviewPoup";

import MainblockStatusDopdown from "./dropdown/StatusDopdown";

const AppliedTable = ({ itemdata }) => {
 
  const [isCaseViewProgress, setIsCaseViewProgress] = useState(false);
  const [isWithdrawApp, setIsWithdrawApp] = useState(false);
  const [isJobDetailModal, setIsJobDetailModal] = useState(false);
  const [editJobdetail, seteditJobdetail] = useState(false);
  const [appSpecjob_id, setAppjobSpec_id] = useState(itemdata.jobSpec_id);
  const [clientCompanyName, setClientCompanyName] = useState(
    itemdata.client_name
  );
  const [clientid, setClientid] = useState(itemdata.client_id);
  const [appjob_id, setAppjob_id] = useState(itemdata.job_id);

  const [isJobInfo, setIsJobInfo] = useState(false);
  const [editShow, setEditShow] = useState(false);

  const handleEditClose = () => setEditShow(false);

  const showcaseviewModal = () => {
    setIsCaseViewProgress(true);
  };
  const hidecaseviewModal = () => {
    setIsCaseViewProgress(false);
  };
  const commentHandleClose = () => {
    setIsCaseViewProgress(false);
  };
  const showWithdrawAppModal = () => {
    setIsWithdrawApp(true);
  };
  const hideWithdrawAppModal = () => {
    setIsWithdrawApp(false);
  };
  const handleEditJobdetailClose = () => seteditJobdetail(false);
  const showjobdetailviewModal = (jobbId) => {
    setIsJobDetailModal(true);
    seteditJobdetail(true);
  };

  const showeditjobdetailModal = () => {
    setIsJobInfo(true);
    setEditShow(true);
  };

  const [title, setTitle] = useState("VIEW CASE PROGRESS");
  const [newstatusList, setNewstatusList] = useState([]);


  return (
    <>
      {isJobDetailModal ? (
        <DetialViewPoup
          jobId={appSpecjob_id}
          show={editJobdetail}
          onHide={handleEditJobdetailClose}
          popupfor="disableEditCandidateApplied"
          clientidds={clientid}
          canjobs={appSpecjob_id}
          companyName={clientCompanyName}
        />
      ) : null}

      {isJobInfo ? (
        <EditdetialviewPoup
          jobId={appjob_id}
          show={editShow}
          onHide={handleEditClose}
          popupfor="disableEditCandidateApplied"
        />
      ) : null}

      {/* {isCaseViewProgress ? (
        <ReviewprogressModal onHide={hidecaseviewModal} />
      ) : null} */}
      {isCaseViewProgress ? (
        <ReviewprogressModal
          refTable={"candidates"}
          title={title}
          closedCommentPopup={commentHandleClose}
          itemdata={itemdata}
          onHide={hidecaseviewModal}
        />
      ) : null}

      {isWithdrawApp ? (
        <WithdrawApplicationModal
          onHide={hideWithdrawAppModal}
          itemdata={itemdata}
        />
      ) : null}
      <thead>
        <tr>
          <th></th>
          <th>Status</th>
          <th colSpan={2}>
            <div className="dkg-jobapplied-th-cols">
              <div className="dkg-job-applied-th-title">
                Stage
              </div>
              <div className="dkg-job-applied-th-title">
                Progress
              </div>
              <div className="dkg-job-applied-th-title">
                Situation
              </div>
            </div>
          </th>
          <th>CASE UPDATED ON</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          {/* <td>
            <div className="dkg-jobappied-datetime">
              <CustomDateFormat date={itemdata.createdAt} format="DD MMM YY" />
            </div>
          </td> */}
          <td>
            <div className="dkg-job-title-cols">
              <div
                className="dkg-job-title"
                style={{ textTransform: "uppercase" }}
              >
                JOB STATUS
              </div>
              <span className="dkg-arrow-right">
                <FaArrowRight />
              </span>
            </div>
          </td>
          <td>
            <MainblockStatusDopdown
              caseStatus={itemdata.progressMList.length > 0 ? itemdata.progressMList[0].status : "-"}
              list={newstatusList}
              statusBgColour={
                itemdata.progressMList.length > 0
                  ? itemdata.progressMList[0].statusBgColour
                  : ""
              }
              statusTxColour={
                itemdata.progressMList.length > 0
                  ? itemdata.progressMList[0].statusTxColour
                  : ""
              }
            />
          </td>
          <td colSpan={2}>
            <div className="dkg-app-st-status-dropdwnCon d-flex w-100">
              <div
                className="dkg-app-st-status-dropdwn"
                style={{ borderRight: "1px solid #ddd" }}
              >
                <MainblockStatusDopdown
                  caseStatus={ itemdata.progressMList.length > 0 ? itemdata.progressMList[0].stage : "-"}
                  list={newstatusList}
                  statusBgColour={
                    itemdata.progressMList.length > 0
                      ? itemdata.progressMList[0].stageBgColour
                      : ""
                  }
                  statusTxColour={
                    itemdata.progressMList.length > 0
                      ? itemdata.progressMList[0].stageTxColour
                      : ""
                  }
                />
              </div>
              <div
                className="dkg-app-st-status-dropdwn"
                style={{ borderRight: "1px solid #ddd" }}
              >
                <MainblockStatusDopdown
                  caseStatus={itemdata.progressMList.length > 0 ? itemdata.progressMList[0].progress : "-"}
                  list={newstatusList}
                  statusBgColour={
                    itemdata.progressMList.length > 0
                      ? itemdata.progressMList[0].progressBgColour
                      : ""
                  }
                  statusTxColour={
                    itemdata.progressMList.length > 0
                      ? itemdata.progressMList[0].progressTxColour
                      : ""
                  }
                />
              </div>
              <div className="dkg-app-st-status-dropdwn">
                <MainblockStatusDopdown
                  caseStatus={ itemdata.progressMList.length > 0
                    ? itemdata.progressMList[0].situation
                    : "-"}
                  list={newstatusList}
                  statusBgColour={
                    itemdata.progressMList.length > 0
                      ? itemdata.progressMList[0].situationBgColour
                      : ""
                  }
                  statusTxColour={
                    itemdata.progressMList.length > 0
                      ? itemdata.progressMList[0].situationTxColour
                      : ""
                  }
                />
              </div>
            </div>
          </td>
          <td className="dkg-aapiled-app-td">
            <Link
              to="#"
              className="dkg-app-viewjob-btn"
              style={{ backgroundColor: "#03a786", color: "#fff" }}
            >
              <CustomDateFormat
                date={itemdata.caseUpdated}
                format="DD MMM YYYY"
              />
              <FaCalendarAlt className="pl-2" style={{position: "relative" , top: "-0.10rem" , width: "1.35rem" , height: "1.35rem"}} />
            </Link>
          </td>
        </tr>

        <tr>
          <td>
            <div className="dkg-job-title-cols">
              <div className="dkg-job-title">JOB DETAILS</div>
              <span className="dkg-arrow-right">
                <FaArrowRight />
              </span>
            </div>
          </td>
          <td colSpan={3}>
            <span
              className="dkg-blue-jobtitle"
              onClick={() => showjobdetailviewModal(itemdata.jobId)}
            >
              {itemdata.jobSpecTitle === "-" ? "" : itemdata.jobSpecTitle}
            </span>
          </td>
          <td className="dkg-aapiled-app-td">
            <Link
              to="#"
              className="dkg-app-viewjob-btn"
              onClick={showcaseviewModal}
              style={{ backgroundColor: "#3c97b6", color: "#fff" }}
            >
              Track Progress
            </Link>
          </td>
        </tr>
        <tr>
          <td>
            <div className="dkg-job-title-cols">
              <div className="dkg-job-title">JOB SUMMARY</div>
              <span className="dkg-arrow-right">
                <FaArrowRight />
              </span>
            </div>
          </td>
          <td colSpan={3}>
            <span
              className="dkg-orange-jobtitle"
              onClick={showeditjobdetailModal}
            >
              {itemdata.jobtitle === "-" ? "" : itemdata.jobtitle}
            </span>
          </td>
          <td className="dkg-aapiled-app-td">
            <CandidateDocModal
              appliedApplication={itemdata ? itemdata.appliedApplicaition : []}
            />
          </td>
        </tr>
        <tr>
          <td className="p-0">
            <div className="dkg-app-user-dropdrownCon">
              <div className="dkg-app-user-dropdrown">
                <div variant="" id="" className="dkg-app-user-dropdrown-toggle">
                  <div className="tooltipMain">
                    <img
                      src={
                        process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                        itemdata.recInfo.profileImage
                      }
                      className="filterImg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </td>
          <td colSpan={3}>
            <div className="dkg-app-jobinfo-four-cols">
              <div className="dkg-app-jobinfo-cols">
                <div className="dkg-jobapplied-title">Category</div>
                <div className="dkg-app-select-dropdown-Con">
                  {itemdata.category}
                </div>
              </div>
              <div className="dkg-app-jobinfo-cols">
                <div className="dkg-jobapplied-title">Company</div>

                <div className="dkg-app-select-dropdown-Con">
                  {itemdata.company}
                </div>
              </div>
              <div className="dkg-app-jobinfo-cols">
                <div className="dkg-jobapplied-title">Project</div>
                <div className="dkg-app-select-dropdown-Con">
                  {itemdata.project}
                </div>
              </div>
              <div className="dkg-app-jobinfo-cols">
                <div className="dkg-jobapplied-title">Role</div>
                <div className="dkg-app-select-dropdown-Con">
                  {itemdata.role}
                </div>
              </div>
              <div className="dkg-app-jobinfo-cols">
                <div className="dkg-jobapplied-title">Language</div>
                <div className="dkg-app-select-dropdown-Con">
                  {itemdata.language}
                </div>
              </div>
            </div>
          </td>
          <td className="dkg-aapiled-app-td">
            <Link
              to="#"
              className="dkg-withdrawl-app-btn"
              onClick={showWithdrawAppModal}
              style={{ backgroundColor: "#CA464F", color: "#fff" }}
            >
              Withdraw Application
            </Link>
          </td>
        </tr>
      </tbody>
    </>
  );
};

export default AppliedTable;
