import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
// import './document.scss';

const FilePreview = ({ show, file, frameType, onClose }) => {
    const handleClose = () => {
        onClose();
    }

    const [fileUrl, setFileUrl] = useState('')

    useEffect(() => {
        if (show) {
            if (file !== "" || '') {
                setFileUrl(file);
            }
            else {
                setFileUrl('0')
            }
        }
    }, [show])


    return (
        <>
            <Modal show={show} onHide={handleClose} dialogClassName="dk-pipedocs-previewModal dkg-pipeline-candModal-234"
                centered animation={false}>
                <Modal.Header closeButton className='dkg-mailbox-preview-header' >
                   <Modal.Title>File Preview</Modal.Title>
                </Modal.Header>
                {
                    (frameType != '0') ?
                        (fileUrl != '0') ?
                            <Modal.Body className='dk-pipedocs-preview-modalbody'>
                                {
                                    (frameType === 'pdf' || frameType === 'PDF' || frameType === 'png' || frameType === 'PNG' || frameType === 'JPG' || frameType === 'jpg' || frameType === 'jpeg' || frameType === 'JPEG') ?
                                        <iframe src={fileUrl} frameBorder={0}></iframe>
                                        :
                                        <>
                                            {
                                                frameType === 'xlsx' || frameType === 'xls'  &&
                                                    <a href={fileUrl} download="file.xlsx">Download XLSX</a>
                                            }
                                            <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}`} frameBorder={0}></iframe>
                                        </>
                                }
                            </Modal.Body>
                            : <Modal.Body className='dk-pipedocs-preview-modalbody'>
                                <h1>Document Not Found</h1>
                            </Modal.Body>
                        : null
                }
            </Modal>
        </>
    )
}
export default FilePreview;