import React from 'react'
import { Link } from 'react-router-dom'
import Slider from "react-slick";
import   '../../../../../../node_modules/slick-carousel/slick/slick.css'
import   '../../../../../../node_modules/slick-carousel/slick/slick-theme.css'
import { FaLongArrowAltLeft , FaLongArrowAltRight , FaFacebook ,FaGooglePlus , FaInstagram , FaLinkedin} from "react-icons/fa";
import User from '../../../../../assets/images/deepak.png'
import DKGTeam from "../../../../../assets/images/user-defult.jpg";


const ConsultantsSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    // infinite: true,
    nextArrow: <FaLongArrowAltRight />,
    prevArrow: <FaLongArrowAltLeft />,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
  };
  return (
    <> 
        <div className='dkg-consult-slider-container'>
            <Slider {...settings}>
                <div className='dkg-consultants-cardCon'>
                    <div className='dkg-consultantsCols-flex'>
                        <div className="dkg-teams-box-cols">
                            <div className="dkg-our-team">
                                <div className="dkg-our-team-img">
                                    <img src={DKGTeam} alt="Team" className="dkg-teammate-img" />
                                </div>
                                <div className="dkg-team-content">
                                    <h3 className="dkg-teammate-name">Full Name</h3>
                                    <span className="dkg-team-post">Consultant</span>
                                </div>
                                <div className="dkg-ourteam-overlay">
                                <ul className="dkg-our-team-social">
                                    <li>
                                        <Link to="#">
                                            <FaFacebook />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <FaGooglePlus />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                        <FaInstagram />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <FaLinkedin />
                                        </Link>
                                    </li>
                                </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='dkg-consultants-cardCon'>
                    <div className='dkg-consultantsCols-flex'>
                        <div className="dkg-teams-box-cols">
                            <div className="dkg-our-team">
                                <div className="dkg-our-team-img">
                                    <img src={DKGTeam} alt="Team" className="dkg-teammate-img" />
                                </div>
                                <div className="dkg-team-content">
                                    <h3 className="dkg-teammate-name">Full Name</h3>
                                    <span className="dkg-team-post">Consultant</span>
                                </div>
                                <div className="dkg-ourteam-overlay">
                                <ul className="dkg-our-team-social">
                                    <li>
                                        <Link to="#">
                                            <FaFacebook />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <FaGooglePlus />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                        <FaInstagram />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <FaLinkedin />
                                        </Link>
                                    </li>
                                </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='dkg-consultants-cardCon'>
                    <div className='dkg-consultantsCols-flex'>
                        <div className="dkg-teams-box-cols">
                            <div className="dkg-our-team">
                                <div className="dkg-our-team-img">
                                    <img src={DKGTeam} alt="Team" className="dkg-teammate-img" />
                                </div>
                                <div className="dkg-team-content">
                                    <h3 className="dkg-teammate-name">Full Name</h3>
                                    <span className="dkg-team-post">Consultant</span>
                                </div>
                                <div className="dkg-ourteam-overlay">
                                <ul className="dkg-our-team-social">
                                    <li>
                                        <Link to="#">
                                            <FaFacebook />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <FaGooglePlus />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                        <FaInstagram />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <FaLinkedin />
                                        </Link>
                                    </li>
                                </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='dkg-consultants-cardCon'>
                    <div className='dkg-consultantsCols-flex'>
                        <div className="dkg-teams-box-cols">
                            <div className="dkg-our-team">
                                <div className="dkg-our-team-img">
                                    <img src={DKGTeam} alt="Team" className="dkg-teammate-img" />
                                </div>
                                <div className="dkg-team-content">
                                    <h3 className="dkg-teammate-name">Full Name</h3>
                                    <span className="dkg-team-post">Consultant</span>
                                </div>
                                <div className="dkg-ourteam-overlay">
                                <ul className="dkg-our-team-social">
                                    <li>
                                        <Link to="#">
                                            <FaFacebook />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <FaGooglePlus />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                        <FaInstagram />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <FaLinkedin />
                                        </Link>
                                    </li>
                                </ul>
                                </div>
                            </div>
                        </div>
                        {/* <div className="dkg-teams-box-cols">
                            <div className="dkg-our-team">
                                <div className="dkg-our-team-img">
                                    <img src={DKGTeam} alt="Team" className="dkg-teammate-img" />
                                </div>
                                <div className="dkg-team-content">
                                    <h3 className="dkg-teammate-name">Full Name 3.2</h3>
                                    <span className="dkg-team-post">Consultant 3.2</span>
                                </div>
                                <div className="dkg-ourteam-overlay">
                                <ul className="dkg-our-team-social">
                                    <li>
                                        <Link to="#">
                                            <FaFacebook />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <FaGooglePlus />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                        <FaInstagram />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <FaLinkedin />
                                        </Link>
                                    </li>
                                </ul>
                                </div>
                            </div>
                        </div>
                        <div className="dkg-teams-box-cols">
                            <div className="dkg-our-team">
                                <div className="dkg-our-team-img">
                                    <img src={DKGTeam} alt="Team" className="dkg-teammate-img" />
                                </div>
                                <div className="dkg-team-content">
                                    <h3 className="dkg-teammate-name">Full Name 3.3</h3>
                                    <span className="dkg-team-post">Consultant 3.3</span>
                                </div>
                                <div className="dkg-ourteam-overlay">
                                <ul className="dkg-our-team-social">
                                    <li>
                                        <Link to="#">
                                            <FaFacebook />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <FaGooglePlus />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                        <FaInstagram />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <FaLinkedin />
                                        </Link>
                                    </li>
                                </ul>
                                </div>
                            </div>
                        </div>
                        <div className="dkg-teams-box-cols">
                            <div className="dkg-our-team">
                                <div className="dkg-our-team-img">
                                    <img src={DKGTeam} alt="Team" className="dkg-teammate-img" />
                                </div>
                                <div className="dkg-team-content">
                                    <h3 className="dkg-teammate-name">Full Name 3.4</h3>
                                    <span className="dkg-team-post">Consultant 3.4</span>
                                </div>
                                <div className="dkg-ourteam-overlay">
                                <ul className="dkg-our-team-social">
                                    <li>
                                        <Link to="#">
                                            <FaFacebook />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <FaGooglePlus />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                        <FaInstagram />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <FaLinkedin />
                                        </Link>
                                    </li>
                                </ul>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className='dkg-consultants-cardCon'>
                    <div className='dkg-consultantsCols-flex'>
                        <div className="dkg-teams-box-cols">
                            <div className="dkg-our-team">
                                <div className="dkg-our-team-img">
                                    <img src={DKGTeam} alt="Team" className="dkg-teammate-img" />
                                </div>
                                <div className="dkg-team-content">
                                    <h3 className="dkg-teammate-name">Full Name</h3>
                                    <span className="dkg-team-post">Consultant</span>
                                </div>
                                <div className="dkg-ourteam-overlay">
                                <ul className="dkg-our-team-social">
                                    <li>
                                        <Link to="#">
                                            <FaFacebook />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <FaGooglePlus />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                        <FaInstagram />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <FaLinkedin />
                                        </Link>
                                    </li>
                                </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    </>
  )
}

export default ConsultantsSlider  ;