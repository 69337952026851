import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SubmitButton from "../../../../../../../ui/submitButton";
import {
  faEnvelope,
  faPaperclip,
  faXmark,
  faPaperPlane,
  faSignature,
  faFloppyDisk,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import IconButton from "./utils/button/IconSubmitButton";
import {
  sendMail,
  clearState,
  getUserMailboxSignature,
  sendBulkMail,
  internalMailBoxEmailCategory,
  sendInternalMail,
  getInternalMailCount,
  clearSignature,
} from "../../../../../../../../slice/mailbox/mailboxSlice";
import {
  showSuccess,
  showError,
  clearMessage,
} from "../../../../../../../../slice/utils/message/messageSlice";
import { useDispatch, useSelector } from "react-redux";
import { Form, Row } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { Autocomplete, TextField } from "@mui/material";
import { GetData } from "../../../../../../../../services";
import { createFilterOptions } from "@material-ui/lab";
import SendEmail from "./editor/SendEmail";
import ReciveEmail from "./editor/ReciveEmail";
import MessageBox from "./editor/MessageBox";
import { FaRegEdit } from "react-icons/fa";
import FilePreview from "./attachmentPreview/FilePreview";

const ComposeMail = (props) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const { isLoading, isBtnSuccess, message, signature, isInternalEmailCategory, internalEmailCategoryList } = useSelector(
    (state) => state.mailbox
  );
  const {isProfileLoading, profileDetails } = useSelector(
    (state) => state.candidates
  );
  const { userInfo } = useSelector(state => state.auth);

  //const { userInfo } = useSelector((state) => state.auth);
  const fileInput = useRef();

  const [emailCategory, setEmailCategory] = useState([]);
  const [categoryName, setCategoryName] = useState("Select...");
  const [mailList, setMailList] = useState([]);
  const [tempMailList, setTempMailList] = useState([]);
  const [from, setFrom] = useState("jobs@dkgrecruitment.com");
  const [to, setTo] = useState([]);
  const [cc, setCc] = useState([]);
  const [bcc, setBCc] = useState([]);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [isAttachment, setIsAttachment] = useState(1);
  const [attachment, setAttachment] = useState([]);
  const [displayFileAttachment, setDisplayFileAttachment] = useState([]);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fileName, setFileName] = useState("");
  const [currentAccount, setCurrentAccount] = useState("Form");
  const [systemEmail, setSystemEmail] = useState("");
  const [candidateId, setCandidateId] = useState("");
  const [id, setId] = useState("");
  const [categoryValue, setCategoryValue] = useState("");

  const [eventKey, SetEventKey] = useState("tabs_1");

  const [shoePreview, setPreview] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const [fileType, setFileType] = useState(null);

  const openPreview = (item) => {
    const split = item.name.split('.')
    const ext = split[split.length - 1]
    setFileType(ext);
    setPreview(true);
    setPreviewFile(URL.createObjectURL(item));
  };

  const closePreview = () => {
    setPreview(false);
    setPreviewFile(null);
  };


  const filterOptions = createFilterOptions({
    limit: 30,
  });

  useEffect(() => {
    if (isInternalEmailCategory && internalEmailCategoryList.length > 0) {
      setEmailCategory(internalEmailCategoryList);
    }
  }, [isInternalEmailCategory]);

  useEffect(() => {
    setSystemEmail(profileDetails.system_email);
    setCandidateId(profileDetails.canId)
    setId(profileDetails.id)
  }, [isProfileLoading]);

  useEffect(async () => {
    if (show) {
      clearForm();
      if (eventKey == "tabs_1" && process.env.REACT_APP_RECRUITER_EMAIL) {
        dispatch(clearSignature())
        dispatch(
          getUserMailboxSignature({ id: process.env.REACT_APP_RECRUITER_EMAIL, type: "compose" })
        );
      }
    }
  }, [show]);

  useEffect(async () => {
    if (eventKey) {
      switch (eventKey) {
        case "send_email":
          dispatch(clearSignature())
          dispatch(
            getUserMailboxSignature({ id: process.env.REACT_APP_RECRUITER_EMAIL, type: "compose" })
          );
          break;

        case "receive_email":
          dispatch(clearSignature())
          dispatch(
            getUserMailboxSignature({ id: process.env.REACT_APP_RECRUITER_EMAIL, type: "reply" })
          );
          break;

        case "tabs_1":
          dispatch(
            getUserMailboxSignature({ id: process.env.REACT_APP_RECRUITER_EMAIL, type: "compose" })
          );
          break;
      }
    }
  }, [eventKey]);

  useEffect(() => {
    if (props.currentAccount) {
      setCurrentAccount(props.currentAccount);
    }
  }, [props.currentAccount]);

  const getMailList = async () => {
    try {
      const res = await GetData(true, "/mail/candidates/list");
      const mailList = res.payload.result;
      if (mailList.length > 0) {
        setTempMailList(mailList);
        setMailList(mailList.map((item) => item.email1));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fileUploadHandler = (e) => {
    setAttachment([...e.target.files])
    setFileName(e.target.files.length + " files");
  };

  const removeAttachFile = (attachmentIndex) => {
    if (attachmentIndex > -1) {
      setAttachment((prevAttachments) => {
        const updatedAttachments = [...prevAttachments];
        updatedAttachments.splice(attachmentIndex, 1);
        return updatedAttachments;
      });
    }
  }

  useEffect(() => {
    if (attachment.length === 0) {
      setFileName("");
    } else {
      setFileName(attachment.length + " files");
    }
  }, [attachment]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccess(false);
    setLoading(true);
      if (!subject || !body || !categoryValue) {
        dispatch(showError({ msg: "All fields required" }));
        setLoading(false);
      } else {
        const formData = new FormData();
        formData.append("from", process.env.REACT_APP_RECRUITER_EMAIL);
        formData.append("to", systemEmail);
        formData.append("subject", subject);
        formData.append("body", body);
        formData.append("category_id", categoryValue);
        formData.append("candidate_id", candidateId);
        if (attachment.length > 0) {
          formData.append("isAttachment", 1);
          // formData.append("attachments", attachment);
          // for (let i = 0; i < attachment.length; i++) {
          // }
          attachment.forEach((file) => {
            // Append each attachment as an array with the same key
            formData.append("attachments", file);
          });
        }
        await dispatch(sendInternalMail(formData));
      }
  };

  const clearForm = () => {
    // setLoading(false);
    setFileName("");
    setTo([]);
    setCc([]);
    setBCc([]);
    setSubject("");
    setBody("");
    setCategoryValue("")
    setCategoryName("Select...")
    // fileInput.current.value = ''
    setIsAttachment(0);
    setAttachment([]);
    setDisplayFileAttachment([]);
  };

  const deleteImg = () => {
    setFileName("");
    fileInput.current.value = "";
    setIsAttachment(0);
    setAttachment([]);
    setDisplayFileAttachment([]);
  };

  const handleClose = () => {
    clearForm();
    setShow(false);
  }

  useEffect(() => {
    if (isBtnSuccess) {
      // setLoading(false);
      setFileName("");
      setSubject("");
      setBody("");
      // fileInput.current.value = ''
      setIsAttachment(0);
      setLoading(false);
      setAttachment([]);
      setDisplayFileAttachment([]);
      dispatch(showSuccess({ msg: message }));
      dispatch(getInternalMailCount({can_id: id, role: userInfo.role}))
      setShow(false);
      dispatch(clearState());
    }
  }, [isBtnSuccess]);

  const selectCategory = (e) => {
    const type = e.target.dataset.type;
    const value = e.target.dataset.value;
    const name = e.target.dataset.name;
    setCategoryValue(value)
    setCategoryName(name)

  };
  return (
    <>
    <FilePreview show={shoePreview} file={previewFile} frameType={'pdf'} onClose={closePreview} />
      <Link to="#" className="dkg-compose-email" onClick={handleShow}>
        <span className="dkg-emailwrite-logo">
          <FaRegEdit />
        </span>
        COMPOSE EMAIL
      </Link>
      <Modal
        className="dk-composeMailModal dkg-myacc-composeModal right"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton className="dkg-myacc-composeModalHeader  dkg-myacc-newHeader">
          <div className="dk-composeMail-option">
            <div className="d-flex align-items-center justify-content-end">
              <div className="dk-composeMail-PanelCols">
                <div className="dk-mailAttach-file">
                  {/* <input type="file" name="" id="" name="attachment-files" onChange={fileUploadHandler} ref={fileInput} multiple /> */}
                  <span>
                    <FontAwesomeIcon icon={faPaperclip} />
                  </span>
                  <Form.File
                    type="file"
                    className="custom-file-label"
                    ref={fileInput}
                    id="inputGroupFile01"
                    label={fileName}
                    onClick={(e) => setAttachment([])}
                    onChange={fileUploadHandler}
                    multiple
                    custom
                  />
                  {/* <span><FontAwesomeIcon icon={faPaperclip} /> Attach File</span> */}
                </div>
              </div>
              <div className="dk-composeMail-PanelCols">
                <div className="dk-mailAttach-file">
                  <Link
                    to="#"
                    onClick={deleteImg}
                    className="d-none"
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />{" "}
                    Delete
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <Modal.Title>CANDIDATE EMAIL</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dkg-myacc-composeModalBody">
          <div className="dkg-cand-compose-mainCon">
            <Tab.Container id="left-tabs-example" defaultActiveKey="tabs_1">
              <div className="d-flex">
                <div className="dk-mailboxLeft-panel">
                  <Nav
                    variant=""
                    onSelect={(selectedKey) => SetEventKey(selectedKey)}
                  >
                    <Nav.Item>
                      <Nav.Link eventKey="tabs_1">New Email</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="tabs_2">Mailshot</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="tabs_3">Templates</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="send_email">New Signature</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="receive_email">
                        Reply Signature
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <div className="dk-mailboxRight-panel">
                  <Tab.Content>
                    <Tab.Pane eventKey="tabs_1">
                      <div className="dk-composeMailForm">
                        <form
                          onSubmit={handleSubmit}
                          enctype="multipart/form-data"
                        >
                          <div className="d-flex">
                            <div className="dk-mailField">
                              
                              <div className="dkg-cand-mail-mainCon">
                                <div className="dkg-cand-mail-leftCon">
                                  <div className="dk-mailSendOption">
                                    <div className="dk-mailOption-items">To...</div>
                                    <input
                                      type="text"
                                      value={systemEmail}
                                      className="form-control readonly"
                                      readOnly
                                    />
                                  </div>
                                  <div className="dk-mailSendOption">
                                    <div className="dk-mailOption-items">From</div>
                                    <input
                                    type="text"
                                    className="form-control readonly"
                                    value="recruiter@dkgrecruitment.com"
                                    readOnly
                                    />
                                    {/* <div className="dk-mailsTagMain">
                                                                        <div className="dk-mailtags">mukul@gmail.com
                                                                            <span className="dk-mailTag-remove"><FontAwesomeIcon icon={faXmark} /></span>
                                                                        </div>
                                                                        <div className="dk-mailtags">deepak@dkmassterbox.com
                                                                            <span className="dk-mailTag-remove"><FontAwesomeIcon icon={faXmark} /></span>
                                                                        </div>
                                                                    </div> */}
                                  </div>
                                  <div className="dk-mailSendOption mb-2">
                                    <div className="dk-mailOption-items mb-0">
                                      Category
                                    </div>
                                    <div className="d-flex w-100">
                                      <div className="dk-mailsCategory">
                                        <Dropdown className="dkg-company-selectDropdown">
                                          <Dropdown.Toggle
                                            variant=""
                                            className="dkg-company-selectDropdown-toggle"
                                          >
                                            {categoryName}
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu className="dkg-company-selectDropdown-Menu">
                                            {emailCategory.map((data, index) => (
                                              <Dropdown.Item
                                                href="#"
                                                key={index + 1}
                                                data-type="category"
                                                data-value={data.id}
                                                data-name={data.name}
                                                onClick={selectCategory}
                                                className="dkg-company-selectDropdown-Item"
                                              >
                                                {data.name}
                                              </Dropdown.Item>
                                            ))}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="dkg-cand-mail-rightCon">
                                  <div className="dkg-cand-mail-attachtment-ul">
                                    {
                                      attachment.map((item, index) => (
                                        <li className="dkg-cand-mail-attachtment-li">
                                          <div className="dkg-file-typeicon">
                                            <i className="far fa-file-pdf"></i>
                                          </div>
                                          <div className="dkg-cand-mail-file">
                                            <div className="dkg-cand-mail-file-name">{ item.name }</div>
                                            <div className="dkg-cand-mail-cross-icon" onClick={() => openPreview(item)}>
                                                <i className="fas fa-eye"></i>
                                            </div>
                                            <div className="dkg-cand-mail-cross-icon" onClick={() => removeAttachFile(index)}>
                                                <i className="fas fa-times"></i>
                                            </div>
                                          </div>
                                        </li>
                                      ))
                                    }
                                  </div>
                                </div>
                              </div>
                              <div className="dk-mailSendOption">
                                <div className="dk-mailOption-items">
                                  Subject
                                </div>
                                <div className="d-flex w-100">
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => setSubject(e.target.value)}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* <div className="dk-sendMail-btn">
                                                            <IconButton txt="Send" loading={loading} success={success} onClick={handleSubmit} position="justify-content-center" />
                                                        </div> */}
                          </div>

                          <div className="dk-textCompose-mail">
                            <div className="dk-mailAttach-file d-none">
                              {/* <Link to="#"><FontAwesomeIcon icon={faSignature} /> Add Signature</Link> */}
                              <Dropdown>
                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                  <FontAwesomeIcon icon={faSignature} /> Add
                                  Signature
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item href="#/action-1">
                                    DK Global
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#/action-2">
                                    Deepak Kumar
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#/action-3">
                                    DK Global CRM
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                            <MessageBox signature={signature} onChange={(value) => setBody(value)} />
                            <div
                              className="dk-send-email-btnCon"
                              style={{ top: "90px" }}
                            >
                              <SubmitButton
                                txt="Send"
                                loading={isLoading}
                                success={isBtnSuccess}
                                position="justify-content-center"
                              />
                            </div>
                          </div>

                          <div className="dk-composeMail-option">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="dk-composeMail-PanelCols">
                              </div>
                            </div>
                          </div>
                        </form>
                        <iframe id="previewFrame" width="100%" height="700px" frameborder="0"></iframe>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="tabs_2"></Tab.Pane>
                    <Tab.Pane eventKey="tabs_2"></Tab.Pane>
                    <Tab.Pane eventKey="send_email">
                      <SendEmail mailboxUserId={process.env.REACT_APP_RECRUITER_EMAIL} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="receive_email">
                      <ReciveEmail mailboxUserId={process.env.REACT_APP_RECRUITER_EMAIL} />
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ComposeMail;
