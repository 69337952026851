import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './rightmodalfilter.scss';
import { Modal, Nav, Tab } from 'react-bootstrap';
import { FaSearch , FaBuilding  , FaCalendarAlt, 
    FaCheck, FaBriefcase, FaEuroSign,
     FaLanguage, FaIdCard , FaInfo,  
     FaArrowRight , FaFilter } from 'react-icons/fa'

const RightModalFilter = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    }

    const handleShow = () => {
        setShow(true);
    }

    return (
        <React.Fragment>
            <div className="dkg-openjobs-filter-btn-con"  onClick={handleShow}>
                <button className="dkg-openjobs-filter-btn">
                    <FaFilter/>
                </button>
                <div className="dkg-openjobs-filter-title">Filter Table</div>
            </div>
            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="dk-prerefreal-FliterModal"
            >
                <Modal.Header closeButton className="dkg-prereferal-header">
                    <Modal.Title className="dkg-prereferal-Title">
                        SEARCH & REPORT
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="dkg-prereferal-body dkg-pipelinesRightFilterBody-13">
                    <Tab.Container id="dk-jobsRightFilterModal" defaultActiveKey="referal-status">
                        <div className="row ml-0 mr-0">
                            <div className="col-md-4 pl-0 pr-0">
                                <Nav variant="pills" className="flex-column dk-leftSideMenu dkg-right-sideFilter-13">
                                    <Nav.Item className="dkg-referrals-navitem">
                                        <Nav.Link eventKey="referal-status" className="dkg-referrals-navlink">
                                                <div className="dkg-referal-navicon"><FaCheck /></div> 
                                                Status 
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="dkg-referrals-navitem">
                                        <Nav.Link eventKey="first" className="dkg-referrals-navlink">
                                            <div className="dkg-referal-navicon"><FaCalendarAlt  /></div> 
                                            Posted
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="dkg-referrals-navitem">
                                        <Nav.Link eventKey="company-logo" className="dkg-referrals-navlink">
                                            <div className="dkg-referal-navicon"><FaBuilding  /></div> 
                                            COMPANY LOGO
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="dkg-referrals-navitem">
                                        <Nav.Link eventKey="referal-jobtitle" className="dkg-referrals-navlink">
                                            <div className="dkg-referal-navicon"><FaBriefcase /></div> 
                                            Job Title
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="dkg-referrals-navitem">
                                        <Nav.Link eventKey="referal-company" className="dkg-referrals-navlink">
                                            <div className="dkg-referal-navicon"><FaBuilding /></div> 
                                            Company 
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="dkg-referrals-navitem">
                                    <Nav.Link eventKey="referal-project" className="dkg-referrals-navlink">
                                        <div className="dkg-referal-navicon"><FaBriefcase /></div> 
                                            Project 
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="dkg-referrals-navitem">
                                        <Nav.Link eventKey="referal-roletype" className="dkg-referrals-navlink">
                                        <div className="dkg-referal-navicon"><FaBriefcase /></div> 
                                        Role Type 
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="dkg-referrals-navitem">
                                        <Nav.Link eventKey="referal-language" className="dkg-referrals-navlink">
                                            <div className="dkg-referal-navicon"><FaLanguage /></div> 
                                            Language  
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="dkg-referrals-navitem">
                                        <Nav.Link eventKey="referal-bonus" className="dkg-referrals-navlink">
                                            <div className="dkg-referal-navicon"><FaEuroSign /></div> 
                                            AMOUNT  
                                            </Nav.Link>
                                    </Nav.Item>
                                    {/* <Nav.Item className="dkg-referrals-navitem">
                                        <Nav.Link eventKey="referal-payment" className="dkg-referrals-navlink">
                                            <div className="dkg-referal-navicon"><FaIdCard /></div> 
                                            Payments
                                        </Nav.Link>
                                    </Nav.Item> */}
                                </Nav>
                            </div>
                            <div className="col-md-8 pl-0 pr-0">
                                <Tab.Content>
                                    <Tab.Pane eventKey="referal-status">
                                        <div className="dk-rightMainContent">
                                            <div className="dk-satusListFilter">
                                                <ul>
                                                    <li>
                                                        <Link to="#" data-type="caseStatus" style={{ backgroundColor: "#03a786", color: "#fff" }} >Active 
                                                            <span className="text-orange" data-value="Active" >
                                                                11
                                                            </span>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#" data-type="caseStatus" style={{ backgroundColor: "#d58b5d", color: "#fff" }} >On Hold 
                                                            <span className="text-orange" data-value="On Hold" >
                                                                11
                                                            </span>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#" data-type="caseStatus" style={{ backgroundColor: "#ca464f", color: "#fff" }} >Closed 
                                                            <span className="text-orange" data-value="Closed" >
                                                                11
                                                            </span>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div> 
                                        </div>   
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="referal-jobtitle">
                                        <div className="dk-rightMainContent">
                                            <div className="dk-flagFilter">
                                                <ul>
                                                    <li>
                                                        <Link to="#">
                                                            German Technical Support Role in Greece
                                                            <span className="text-orange">
                                                                12
                                                            </span>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">
                                                            German Technical Support Role in Greece
                                                            <span className="text-orange">
                                                                12
                                                            </span>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">
                                                            German Technical Support Role in Greece
                                                            <span className="text-orange">
                                                                12
                                                            </span>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="referal-company">
                                        <div className="dk-rightMainContent">
                                            <div className="dk-flagFilter">
                                                <ul>
                                                    <li>
                                                        <Link to="#">
                                                            TP Greece
                                                            <span className="text-orange">
                                                                5
                                                            </span>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">
                                                            TTEC Greece
                                                            <span className="text-orange">
                                                                8
                                                            </span>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">
                                                            TM Romania
                                                            <span className="text-orange">
                                                                7
                                                            </span>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">
                                                            TTEC Athens
                                                            <span className="text-orange">
                                                                5
                                                            </span>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="referal-project">
                                        <div className="dk-rightMainContent">
                                            <div className="dk-flagFilter">
                                                <ul>
                                                    <li>
                                                        <Link to="#">
                                                            Booking
                                                            <span className="text-orange">
                                                                5
                                                            </span>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">
                                                            Ford Pro
                                                            <span className="text-orange">
                                                                8
                                                            </span>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">
                                                            Google NDFC
                                                            <span className="text-orange">
                                                                7
                                                            </span>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">
                                                            Daimler Truck
                                                            <span className="text-orange">
                                                                5
                                                            </span>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="referal-roletype">
                                        <div className="dk-rightMainContent">
                                            <div className="dk-flagFilter">
                                                <ul>
                                                    <li>
                                                        <Link to="#">
                                                            1st Line ITS
                                                            <span className="text-orange">
                                                                5
                                                            </span>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">
                                                            Customer Service
                                                            <span className="text-orange">
                                                                8
                                                            </span>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">
                                                            Customer Support
                                                            <span className="text-orange">
                                                                7
                                                            </span>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">
                                                            ITS 1st Line
                                                            <span className="text-orange">
                                                                5
                                                            </span>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="referal-language">
                                        <div className="dk-rightMainContent">
                                            <div className="dk-flagFilter">
                                                <ul>
                                                    <li>
                                                        <Link to="#">
                                                            Italian
                                                            <span className="text-orange">
                                                                5
                                                            </span>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">
                                                            German
                                                            <span className="text-orange">
                                                                8
                                                            </span>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">
                                                            Portuguese
                                                            <span className="text-orange">
                                                                7
                                                            </span>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">
                                                            Norwegian
                                                            <span className="text-orange">
                                                                5
                                                            </span>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="referal-bonus">
                                        <div className="dk-rightMainContent">
                                            <div className="dk-flagFilter">
                                                <ul>
                                                    <li>
                                                        <Link to="#">
                                                            345
                                                            <span className="text-orange">
                                                                5
                                                            </span>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">
                                                            457
                                                            <span className="text-orange">
                                                                8
                                                            </span>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">
                                                            460
                                                            <span className="text-orange">
                                                                7
                                                            </span>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">
                                                            456
                                                            <span className="text-orange">
                                                                5
                                                            </span>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="referal-payment">
                                        <div className="dk-rightMainContent">
                                            <div className="dk-flagFilter">
                                                <ul>
                                                    <li>
                                                        <Link to="#">
                                                            3456
                                                            <span className="text-orange">
                                                                
                                                            </span>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">
                                                            230
                                                            <span className="text-orange">
                                                                8
                                                            </span>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">
                                                            440
                                                            <span className="text-orange">
                                                                7
                                                            </span>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">
                                                            660
                                                            <span className="text-orange">
                                                                5
                                                            </span>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </div>
                    </Tab.Container>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}
export default RightModalFilter;